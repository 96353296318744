import * as React from 'react';
import './EditUserStoriesPopup.scss';
import { Box, Button, Popup, popupController, PopupProps, RsFormControl, RsFormGroup } from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import { useEffect, useState } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import DiscardChangesPopup, { DiscardChangesPopupProps } from '../discardChangesPopup/DiscardChangesPopup';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { ApiRequestV1 } from '../../generated/apiRequests';

export interface EditUserStoriesPopupProps extends PopupProps {}

enum FormKeys {
	DEMO_IDS = 'demoItemIds',
	STRETCH_IDS = 'stretchGoalIds'
}
const EditUserStoriesPopup: React.FC<EditUserStoriesPopupProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<number[]>(FormKeys.DEMO_IDS, []),
			new RsFormControl<number[]>(FormKeys.STRETCH_IDS, [])
		])
	);
	const [storyOptions, setStoryOptions] = useState<{ label: string; value: number }[]>([]);

	function handleClose() {
		if (formGroup.isModified()) {
			popupController.open<DiscardChangesPopupProps>(DiscardChangesPopup, {});
		} else {
			popupController.close(EditUserStoriesPopup);
		}
	}

	function handleSave() {}

	function handleUpdate(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.cloneDeep().update(control));
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsEditUserStoriesPopup'}>
				<PopupHeader title={'Edit Sprint User Stories'} onClose={handleClose} />
				<Box className={'content'}>
					<LabelSelect
						label={'Demo Items'}
						control={formGroup.get(FormKeys.DEMO_IDS)}
						updateControl={handleUpdate}
						options={storyOptions}
						placeholder={'Begin typing or select user stories'}
						isMulti
					/>
					<LabelSelect
						label={'Stretch Goal'}
						control={formGroup.get(FormKeys.STRETCH_IDS)}
						updateControl={handleUpdate}
						options={storyOptions}
						placeholder={'Begin typing or select user stories'}
						isMulti
					/>
					<Box className={'buttonGroup'}>
						<Button look={'outlinedPrimary'} onClick={handleClose} fullWidth>
							Cancel
						</Button>
						<Button
							look={'containedPrimary'}
							onClick={handleSave}
							disabled={!formGroup.isModified()}
							fullWidth
						>
							Save
						</Button>
					</Box>
				</Box>
			</Box>
		</Popup>
	);
};
export default EditUserStoriesPopup;
