import * as React from 'react';
import './BackButton.scss';
import { Box, Icon } from '@redskytech/framework/ui';
import themes from '../../themes/themes.scss?export';
import router from '../../utils/router';

interface BackButtonProps {
	backRoute: string;
}

const BackButton: React.FC<BackButtonProps> = (props) => {
	return (
		<Box className={'rsBackButton'}>
			<Icon
				iconImg={'icon-chevron-left'}
				fontSize={24}
				color={themes.neutralBeige600}
				mr={'20px'}
				onClick={() => router.navigate(props.backRoute)}
				cursorPointer
			/>
		</Box>
	);
};

export default BackButton;
