import * as React from 'react';
import { useEffect, useState } from 'react';
import './AddEditProjectPopup.scss';
import {
	Box,
	Button,
	Checkbox,
	Popup,
	popupController,
	PopupProps,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import LabelInputText from '../../components/labelInputText/LabelInputText';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { WebUtils } from '@redskytech/framework/utils';
import LoadingPopup, { LoadingPopupProps } from '../loadingPopup/LoadingPopup';
import ConfirmationPopup, { ConfirmationPopupProps } from '../confirmationPopup/ConfirmationPopup';
import DiscardChangesPopup, { DiscardChangesPopupProps } from '../discardChangesPopup/DiscardChangesPopup';
import ServiceFactory from '../../services/serviceFactory.js';
import CompanyService from '../../services/company/CompanyService.js';

export interface AddEditProjectPopupProps extends PopupProps {
	onUpdate: () => void;
	companyId?: number;
	projectId?: number;
	versionId?: number;
	phaseId?: number;
	name?: string;
	version?: string;
	phase?: string;
	isFixedBid?: boolean;
}

enum FormKeys {
	COMPANY = 'companyId',
	NAME = 'name',
	VERSION = 'versionName',
	PHASE = 'phaseName',
	IS_FIXED_BID = 'isFixedBid'
}

const AddEditProjectPopup: React.FC<AddEditProjectPopupProps> = (props) => {
	const companyService = ServiceFactory.get<CompanyService>('CompanyService');
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<number>(FormKeys.COMPANY, props.companyId || 0, [
				new RsValidator(RsValidatorEnum.REQ, 'Company is required')
			]),
			new RsFormControl<string>(FormKeys.NAME, props.name || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Name is required')
			]),
			new RsFormControl<string>(FormKeys.VERSION, props.version || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Version is required')
			]),
			new RsFormControl<string>(FormKeys.PHASE, props.phase || ''),
			new RsFormControl<boolean>(FormKeys.IS_FIXED_BID, props.isFixedBid || false)
		])
	);
	const [companyOptions, setCompanyOptions] = useState<{ label: string; value: number }[]>([]);
	const [isDisabled, setIsDisabled] = useState<boolean>(true);

	useEffect(() => {
		(async function getAllCompanies() {
			try {
				let res = await ApiRequestV1.getCompanyAll();
				setCompanyOptions(companyService.createCompanySelectOptions(res));
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
			}
		})();
	}, []);

	useEffect(() => {
		(async function getDisabledStatus() {
			let isValid = await formGroup.isValid();
			setIsDisabled(!isValid);
		})();
	}, [formGroup]);

	function handleUpdate(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function handleClose() {
		if (formGroup.isModified()) {
			popupController.open<DiscardChangesPopupProps>(DiscardChangesPopup, {});
		} else {
			popupController.close(AddEditProjectPopup);
		}
	}

	async function handleDelete() {
		if (!props.projectId || !props.phaseId) return;
		try {
			popupController.open<LoadingPopupProps>(LoadingPopup, {});
			await ApiRequestV1.patchPhase({ id: props.phaseId, isDeleted: 1 });
			await ApiRequestV1.deleteProject({ id: props.projectId });
			props.onUpdate();
			popupController.closeAll();
			rsToastify.success('Project successfully deleted');
		} catch (e) {
			popupController.close(LoadingPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	async function handleSaveProject() {
		if (!(await formGroup.isValid())) {
			rsToastify.error('Please fix the errors in the inputs', 'Input Error');
			setFormGroup(formGroup.clone());
			return;
		}
		popupController.open<LoadingPopupProps>(LoadingPopup, {});
		let formData = formGroup.toModel<{
			companyId: number;
			name: string;
			versionName: string;
			phaseName: string;
			isFixedBid: boolean;
		}>();

		try {
			if (props.projectId && props.phaseId && props.versionId) {
				await ApiRequestV1.patchProject({
					id: props.projectId,
					name: formData.name,
					companyId: formData.companyId
				});
				let res = await ApiRequestV1.patchPhase({
					id: props.phaseId,
					name: formData.phaseName,
					companyId: formData.companyId
				});
				await ApiRequestV1.patchVersion({
					id: props.versionId,
					name: formData.versionName,
					isFixedBid: formData.isFixedBid,
					companyId: formData.companyId,
					currentPhaseId: res.id
				});
				rsToastify.success('Project Updated', 'Success');
			} else {
				let newProject = await ApiRequestV1.postProject({ name: formData.name, companyId: formData.companyId });

				await ApiRequestV1.postVersion({
					name: formData.versionName,
					isFixedBid: formData.isFixedBid,
					projectId: newProject.id,
					companyId: formData.companyId,
					currentPhaseName: formData.phaseName
				});

				rsToastify.success('Project Created', 'Success');
			}
			props.onUpdate();
			popupController.closeAll();
		} catch (e) {
			popupController.close(LoadingPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Server Error'), 'Error');
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsAddEditProjectPopup'}>
				<PopupHeader title={`${props.companyId ? 'Edit Project' : 'Add Project'}`} onClose={handleClose} />
				<Box className={'content'}>
					<LabelSelect
						label={'Company'}
						options={companyOptions}
						control={formGroup.get(FormKeys.COMPANY)}
						updateControl={handleUpdate}
						required
					/>
					<LabelInputText
						label={'Name'}
						inputMode={'text'}
						control={formGroup.get(FormKeys.NAME)}
						updateControl={handleUpdate}
						required
					/>
					<LabelInputText
						label={'Version'}
						inputMode={'text'}
						control={formGroup.get(FormKeys.VERSION)}
						updateControl={handleUpdate}
						required
					/>
					<LabelSelect
						label={'Current Phase'}
						options={[
							{ label: 'Design & Discovery Phase', value: 'Design & Discovery' },
							{ label: 'Development Phase', value: 'Development' },
							{ label: 'Launch Phase', value: 'Launch' },
							{ label: 'Maintenance Phase', value: 'Maintenance' }
						]}
						control={formGroup.get(FormKeys.PHASE)}
						updateControl={handleUpdate}
					/>
					<Checkbox
						labelText={'Is Fixed Bid'}
						look={'containedPrimary'}
						control={formGroup.get(FormKeys.IS_FIXED_BID)}
						updateControl={handleUpdate}
					/>
					<Box className={'buttonGroup'}>
						{!!props.companyId && (
							<Button
								look={'iconSecondary'}
								icon={[{ iconImg: 'icon-trash', position: 'LEFT' }]}
								onClick={() => {
									popupController.open<ConfirmationPopupProps>(ConfirmationPopup, {
										headerLabel: 'Delete Project',
										label: 'Are you sure you want to delete project? This action cannot be undone.',
										acceptLabel: 'Delete',
										rejectLabel: 'Cancel',
										onAccept: handleDelete
									});
								}}
							/>
						)}
						<Button look={'outlinedPrimary'} fullWidth onClick={handleClose}>
							Cancel
						</Button>
						<Button look={'containedPrimary'} fullWidth disabled={isDisabled} onClick={handleSaveProject}>
							{!!props.companyId ? 'Save' : 'Create'}
						</Button>
					</Box>
				</Box>
			</Box>
		</Popup>
	);
};
export default AddEditProjectPopup;
