import * as React from 'react';
import './MeetingRecurring.scss';
import themes from '../../themes/themes.scss?export';
import { Box, Icon, Label } from '@redskytech/framework/ui';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import RichContentDisplay from '../richContentDisplay/RichContentDisplay.js';

interface MeetingRecurringProps {}

const MeetingRecurring: React.FC<MeetingRecurringProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);

	if (!version || !version.recurringMeetings) return <></>;

	return (
		<Box className={'rsMeetingRecurring'}>
			<Box display={'flex'} alignItems={'center'} gap={'4px'}>
				<Icon iconImg={'icon-groups'} color={themes.accentInfo} />
				<Label variant={'caption2'} weight={'regular'} color={themes.accentInfo}>
					RECURRING MEETINGS
				</Label>
			</Box>
			<RichContentDisplay content={version.recurringMeetings.trim()} />
		</Box>
	);
};

export default MeetingRecurring;
