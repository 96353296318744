import NotFoundPage from './pages/common/notFoundPage/notFoundPage';
import LoginPage from './pages/common/loginPage/LoginPage';
import { I996 } from '@redskytech/framework/common/Interfaces';
import HomePage from './pages/external/homePage/HomePage';
import SprintPage from './pages/external/sprintPage/SprintPage';
import BudgetPage from './pages/external/budgetPage/BudgetPage';
import TasksPage from './pages/external/tasksPage/TasksPage';
import ProfilePage from './pages/external/profilePage/ProfilePage';
import TaskListPage from './pages/internal/taskListPage/TaskListPage';
import TaskDetailsPage from './pages/internal/taskDetailsPage/TaskDetailsPage';
import BudgetPhaseDetailsPage from './pages/external/budgetPhaseDetailsPage/BudgetPhaseDetailsPage';
import TeamRolePage from './pages/external/teamRolePage/TeamRolePage';
import BudgetMonthlyBreakdownPage from './pages/external/budgetMonthlyBreakdownPage/BudgetMonthlyBreakdownPage';
import TeamRolesPage from './pages/internal/teamRolesPage/TeamRolesPage';
import TeamListPage from './pages/internal/teamListPage/TeamListPage';
import TasksAllPage from './pages/external/tasksAllPage/TasksAllPage';
import TaskDetailsExternalPage from './pages/external/taskDetailsExternalPage/TaskDetailsExternalPage';
import MeetingListPage from './pages/internal/meetingListPage/MeetingListPage';
import MeetingDetailsPage from './pages/internal/meetingDetailsPage/MeetingDetailsPage';
import MeetingPage from './pages/external/meetingPage/MeetingPage';
import MeetingCategoryPage from './pages/external/meetingCategoryPage/MeetingCategoryPage';
import MeetingDetailsExternalPage from './pages/external/meetingDetailsExternalPage/MeetingDetailsExternalPage';
import CompanyListPage from './pages/internal/companyListPage/CompanyListPage';
import ArtifactsPage from './pages/external/artifactsPage/ArtifactsPage.js';
import ProjectDetailsPage from './pages/internal/projectDetailsPage/ProjectDetailsPage';
import ArtifactDetailsPage from './pages/external/artifactDetailsPage/ArtifactDetailsPage.js';
import UserListPage from './pages/internal/userListPage/UserListPage';
import ArtifactListPage from './pages/internal/artifactListPage/ArtifactListPage';
import EditArtifactPage from './pages/internal/editArtifactPage/EditArtifactPage';
import UserDetailsPage from './pages/internal/userDetailsPage/UserDetailsPage';
import ProjectPage from './pages/external/projectPage/ProjectPage';
import ProjectPhaseDetailsPage from './pages/external/projectPhaseDetailsPage/ProjectPhaseDetailsPage';
import SprintListPage from './pages/internal/sprintListPage/SprintListPage';
import EditSprintPage from './pages/internal/editSprintPage/EditSprintPage';

export type RoutePaths =
	| '/'
	| '/home'
	| '/artifacts'
	| '/artifacts/details'
	| '/budget'
	| '/budget/phase'
	| '/budget/'
	| '/budget/monthly'
	| '/meeting'
	| '/meeting/view'
	| '/meeting/external/details'
	| '/sprint'
	| '/tasks'
	| '/tasks/all'
	| '/profile'
	| '/task/list'
	| '/task/details'
	| '/task/external/details'
	| '/task/add'
	| '/team/roles'
	| '/team/list'
	| '/team'
	| '/meeting/list'
	| '/meeting/details'
	| '/company/list'
	| '/project/details'
	| '/user/list'
	| '/user/details'
	| '/artifact/list'
	| '/artifact/edit'
	| '/project'
	| '/phase/details'
	| '/sprint/list'
	| '/sprint/edit'
	| '*';

const routes: I996.RouteDetails<RoutePaths>[] = [
	{
		path: '/',
		page: LoginPage,
		options: {
			view: 'login'
		}
	},
	{
		path: '/artifacts',
		page: ArtifactsPage,
		options: {
			view: 'artifacts'
		}
	},
	{
		path: '/artifacts/details',
		page: ArtifactDetailsPage,
		options: {
			view: 'artifacts'
		}
	},
	{
		path: '/home',
		page: HomePage,
		options: {
			view: 'home'
		}
	},
	{
		path: '/team',
		page: TeamRolePage,
		options: {
			view: 'home'
		}
	},
	{
		path: '/budget',
		page: BudgetPage,
		options: {
			view: 'budget'
		}
	},
	{
		path: '/budget/phase',
		page: BudgetPhaseDetailsPage,
		options: {
			view: 'budget'
		}
	},
	{
		path: '/budget/monthly',
		page: BudgetMonthlyBreakdownPage,
		options: {
			view: 'budget'
		}
	},
	{
		path: '/project',
		page: ProjectPage,
		options: {
			view: 'project'
		}
	},
	{
		path: '/phase/details',
		page: ProjectPhaseDetailsPage,
		options: {
			view: 'project'
		}
	},
	{
		path: '/meeting',
		page: MeetingPage,
		options: {
			view: 'home'
		}
	},
	{
		path: '/meeting/view',
		page: MeetingCategoryPage,
		options: {
			view: 'home'
		}
	},
	{
		path: '/meeting/external/details',
		page: MeetingDetailsExternalPage,
		options: {
			view: 'home'
		}
	},
	{
		path: '/sprint',
		page: SprintPage,
		options: {
			view: 'sprint'
		}
	},
	{
		path: '/tasks',
		page: TasksPage,
		options: {
			view: 'tasks'
		}
	},
	{
		path: '/tasks/all',
		page: TasksAllPage,
		options: {
			view: 'tasks'
		}
	},
	{
		path: '/task/external/details',
		page: TaskDetailsExternalPage,
		options: {
			view: 'tasks'
		}
	},
	{
		path: '/task/list',
		page: TaskListPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/task/details',
		page: TaskDetailsPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/task/add',
		page: TaskDetailsPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/profile',
		page: ProfilePage,
		options: {
			view: 'profile'
		}
	},
	{
		path: '/team/roles',
		page: TeamRolesPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/team/list',
		page: TeamListPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/meeting/list',
		page: MeetingListPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/meeting/details',
		page: MeetingDetailsPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/company/list',
		page: CompanyListPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/project/details',
		page: ProjectDetailsPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/user/list',
		page: UserListPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/user/details',
		page: UserDetailsPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/artifact/list',
		page: ArtifactListPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/artifact/edit',
		page: EditArtifactPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/sprint/list',
		page: SprintListPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/sprint/edit',
		page: EditSprintPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '*',
		page: NotFoundPage
	}
];

export default routes;
(window as any).routes = routes;
