import * as React from 'react';
import './TaskDetailsExternalPage.scss';
import themes from '../../../themes/themes.scss?export';
import { Page } from '@redskytech/framework/996';
import router from '../../../utils/router';
import { useEffect, useState } from 'react';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import {
	Avatar,
	Box,
	Button,
	InputTextarea,
	Label,
	RsFormControl,
	RsFormGroup,
	rsToastify
} from '@redskytech/framework/ui';
import { DateUtils, ObjectUtils, WebUtils } from '../../../utils/utils';
import SectionWithHeader from '../../../components/sectionWithHeader/SectionWithHeader';
import TaskItem, { TaskType } from '../../../components/taskItem/TaskItem';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';

enum FormKeys {
	MESSAGE = 'Message'
}

interface TaskDetailsExternalPageProps {}

const TaskDetailsExternalPage: React.FC<TaskDetailsExternalPageProps> = (props) => {
	const user = useRecoilValue<Api.V1.User.Get.Res | undefined>(globalState.user);
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);

	const [messageForm, setMessageForm] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl<string>(FormKeys.MESSAGE, '', [])])
	);
	const [task, setTask] = useState<Api.V1.Task.Paged.Get.Res>();
	const [tags, setTags] = useState<Api.V1.Tag.Paged.Get.Res[]>();
	const [taskNotes, setTaskNotes] = useState<Api.V1.TaskNote.All.Get.Res[]>();

	const params = router.getQueryParams<{ taskId: number }>([
		{
			key: 'id',
			type: 'integer',
			alias: 'taskId',
			default: 0
		}
	]);

	useEffect(() => {
		(async function getTaskDetails() {
			try {
				let responses: [
					Promise<RedSky.RsPagedResponseData<Api.V1.Tag.Paged.Get.Res[]>>,
					Promise<RedSky.RsPagedResponseData<Api.V1.Task.Paged.Get.Res[]>>,
					Promise<Api.V1.TaskNote.All.Get.Res[]>
				] = [
					ApiRequestV1.getTagPaged({
						perPage: 25,
						page: 1,
						filter: `(column:tag.taskId,value:${params.taskId})`
					}),
					ApiRequestV1.getTaskPaged({
						filter: `(column:task.id,value:${params.taskId})`,
						versionId: version?.id || 0
					}),
					ApiRequestV1.getTaskNoteAll({ taskId: params.taskId })
				];

				let res = await Promise.all(responses);
				const [allTags, taskDetailsRes, taskNotes] = res;
				setTask(taskDetailsRes.data[0]);
				setTags(allTags.data);
				setTaskNotes(taskNotes);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to get id'));
			}
		})();
	}, [params.taskId]);

	async function patchTaskDetails() {
		if (!task) return;
		try {
			let statusObject: {
				status: Api.V1.Task.Patch.Req['status'];
				completedOn: Api.V1.Task.Patch.Req['completedOn'];
			} =
				task.status === 'NOT_COMPLETED'
					? {
							status: 'COMPLETED',
							completedOn: DateUtils.dbNow()
					  }
					: { status: 'NOT_COMPLETED', completedOn: undefined };

			const result = await ApiRequestV1.patchTask({
				id: task.id,
				...statusObject
			});
			setTask(result as Api.V1.Task.Paged.Get.Res);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Did not change task details'));
		}
	}

	async function postNote() {
		if (!task || !taskNotes || !user || !version) return;
		try {
			const result = await ApiRequestV1.postTaskNote({
				taskId: task.id,
				message: messageForm.get<string>(FormKeys.MESSAGE).value.toString(),
				companyId: version.companyId
			});
			const newNotes: Api.V1.TaskNote.All.Get.Res[] = [];
			taskNotes.forEach((note) => newNotes.push(note));
			newNotes.push(result);
			setTaskNotes(newNotes);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Could not post a note.'));
		}
	}

	function getTaskTypeByStatus(): TaskType {
		if (!task) return 'OVERDUE';
		if (task.status === 'COMPLETED') {
			return 'COMPLETED';
		} else {
			if (new Date(task.dueOn || Date()) > new Date()) {
				return 'IN_PROGRESS';
			} else {
				return 'OVERDUE';
			}
		}
	}

	function renderTags(): React.ReactNode {
		if (!tags) return;
		let tagsString = '';
		tags.forEach((tag, index) => {
			tagsString += tag.name + (tags.length - 1 === index ? '' : ', ');
		});
		if (tagsString === '') return;

		return (
			<Label variant={'caption1'} weight={'regular'} color={themes.neutralBeige600}>
				Tags: {tagsString}
			</Label>
		);
	}

	function renderTaskDetails(): React.ReactNode {
		if (!task) return;
		return (
			<SectionWithHeader title={'TASK DETAILS'} className={'taskDetailsSection'}>
				<TaskItem task={task} taskType={getTaskTypeByStatus()} />
				{renderTags()}
			</SectionWithHeader>
		);
	}

	function renderMessages(): React.ReactNode {
		if (!ObjectUtils.isArrayWithData(taskNotes)) return;

		const notes = taskNotes.map((note) => {
			return (
				<Box key={note.id} className={'noteContainer'}>
					<Box width={'32px'}>
						<Avatar
							widthHeight={32}
							name={note.userFirstName + ' ' + note.userLastName}
							image={note.userAvatarUrl}
						/>
					</Box>
					<Box>
						<Box className={'nameAndDate'}>
							<Label variant={'body2'} weight={'semiBold'}>
								{note.userFirstName + ' ' + note.userLastName}
							</Label>
							<Label variant={'caption2'} weight={'regular'} color={themes.neutralBeige600}>
								{DateUtils.displayFriendlyDateTime(note.createdOn)}
							</Label>
						</Box>

						<Label variant={'caption1'} weight={'regular'}>
							{note.message}
						</Label>
					</Box>
				</Box>
			);
		});

		return (
			<SectionWithHeader title={'MESSAGE'} className={'messageSection'}>
				{notes}
			</SectionWithHeader>
		);
	}

	function renderActions(): React.ReactNode {
		if (!task) return;
		return (
			<SectionWithHeader title={'ACTIONS'} className={'actionsSection'}>
				{task.status === 'NOT_COMPLETED' ? (
					<>
						<Button look={'outlinedPrimary'} onClick={() => patchTaskDetails()}>
							Mark Task as Completed
						</Button>

						<Box className={'dividerBox'}>
							<Box className={'dividerLine'} />
							<Label variant={'caption1'} weight={'regular'} color={themes.neutralBeige600}>
								OR
							</Label>
							<Box className={'dividerLine'} />
						</Box>

						<InputTextarea
							placeholder={'Type Your Message'}
							control={messageForm.get(FormKeys.MESSAGE)}
							updateControl={(form) => setMessageForm(messageForm.clone().update(form))}
						/>

						<Box className={'buttonBar'}>
							{/*<Button*/}
							{/*	look={'outlinedPrimary'}*/}
							{/*	onClick={() => rsToastify.warning('Upload Not Implemented')}*/}
							{/*>*/}
							{/*	Upload*/}
							{/*</Button>*/}
							<Button
								look={'containedSecondary'}
								onClick={() => {
									postNote();
									setMessageForm(messageForm.resetToInitialValue());
								}}
								disabled={messageForm.get<string>(FormKeys.MESSAGE).value.toString() === ''}
							>
								Submit
							</Button>
						</Box>
					</>
				) : (
					<>
						<Button look={'outlinedPrimary'} onClick={() => patchTaskDetails()}>
							Mark Task as Incomplete
						</Button>
					</>
				)}
			</SectionWithHeader>
		);
	}

	return (
		<Page className={'rsTaskDetailsExternalPage'}>
			{renderTaskDetails()}
			{renderMessages()}
			{renderActions()}
		</Page>
	);
};

export default TaskDetailsExternalPage;
