import * as React from 'react';
import { useEffect, useState } from 'react';
import './TasksPage.scss';
import { Page } from '@redskytech/framework/996';
import { Box, Icon, InputText, Label, RsFormControl, RsFormGroup, rsToastify } from '@redskytech/framework/ui';
import { ObjectUtils, WebUtils } from '../../../utils/utils';
import themes from '../../../themes/themes.scss?export';
import Paper from '../../../components/paper/Paper';
import Pagination from '../../../components/pagination/Pagination';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import TaskItem from '../../../components/taskItem/TaskItem';
import TaskContainer from '../../../components/taskContainer/TaskContainer';
import router from '../../../utils/router';

enum FormKeys {
	SEARCH = 'search'
}

interface TasksPageProps {}

const TasksPage: React.FC<TasksPageProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);

	const [overdueTasks, setOverdueTasks] = useState<Api.V1.Task.Paged.Get.Res[] | undefined>();
	const [inProgressTasks, setInProgressTasks] = useState<Api.V1.Task.Paged.Get.Res[] | undefined>();
	const [completeTasks, setCompleteTasks] = useState<Api.V1.Task.Paged.Get.Res[] | undefined>();

	const [overdueCount, setOverdueCount] = useState<number>(0);
	const [inProgressCount, setInProgressCount] = useState<number>(0);
	const [completeCount, setCompleteCount] = useState<number>(0);

	const [orderPageNumber, setOrderPageNumber] = useState<number>(1);

	const [currentSearch, setCurrentSearch] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl<string>(FormKeys.SEARCH, '', [])])
	);

	useEffect(() => {
		if (!version) return;
		if (currentSearch.get<string>(FormKeys.SEARCH).value.toString() !== '') return;
		let isCancelled = false;
		setOrderPageNumber(1);

		(async function getOverdueTasks() {
			try {
				const result = await ApiRequestV1.getTaskPaged({
					perPage: 2,
					page: 1,
					versionId: version.id,
					sortBy: 'dueOn',
					filter: `(column:dueOn,value:${new Date().toISOString()},type:lessThan)and(column:status,value:NOT_COMPLETED)`
				});
				if (isCancelled) return;
				setOverdueTasks(result.data);
				setOverdueCount(result.total);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Could not get overdue items'));
			}
		})();

		(async function getInProgressTasks() {
			try {
				const result = await ApiRequestV1.getTaskPaged({
					perPage: 2,
					page: 1,
					versionId: version.id,
					sortBy: 'dueOn',
					filter: `(column:dueOn,value:${new Date().toISOString()},type:greaterThan)and(column:status,value:NOT_COMPLETED)`
				});
				if (isCancelled) return;
				setInProgressTasks(result.data);
				setInProgressCount(result.total);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Could not get overdue items'));
			}
		})();

		(async function getCompletedTasks() {
			try {
				const result = await ApiRequestV1.getTaskPaged({
					perPage: 2,
					page: 1,
					versionId: version.id,
					sortBy: 'dueOn',
					filter: `(column:status,value:COMPLETED)`
				});
				if (isCancelled) return;
				setCompleteTasks(result.data);
				setCompleteCount(result.total);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Could not get overdue items'));
			}
		})();

		return () => {
			isCancelled = true;
		};
	}, [currentSearch, version]);

	useEffect(() => {
		if (!version) return;
		if (currentSearch.get<string>(FormKeys.SEARCH).value.toString() === '') return;

		(async function searchFilter() {
			try {
				const result = await ApiRequestV1.getTaskPaged({
					perPage: 10,
					page: orderPageNumber,
					versionId: version.id,
					sortBy: 'dueOn',
					filter: `(column:name,value:${currentSearch
						.get<string>(FormKeys.SEARCH)
						.value.toString()},type:contains)`
				});

				setInProgressCount(result.total);
				setOverdueCount(0);
				setCompleteCount(0);
				setOverdueTasks(
					result.data.filter(
						(task) => new Date(task.dueOn || Date()) < new Date() && task.status === 'NOT_COMPLETED'
					)
				);
				setInProgressTasks(
					result.data.filter(
						(task) => new Date(task.dueOn || Date()) >= new Date() && task.status === 'NOT_COMPLETED'
					)
				);
				setCompleteTasks(result.data.filter((task) => task.status === 'COMPLETED'));
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Could not get searched items'));
			}
		})();
	}, [currentSearch, orderPageNumber]);

	function renderDueTodayList(): React.ReactNode {
		if (!overdueTasks) return;
		return overdueTasks.map((task) => (
			<TaskItem
				key={task.id}
				task={task}
				taskType={'OVERDUE'}
				onClick={() => {
					router.navigate('/task/external/details?id=' + task.id);
				}}
			/>
		));
	}

	function renderInProgressList(): React.ReactNode {
		if (!inProgressTasks) return;
		return inProgressTasks.map((task) => (
			<TaskItem
				key={task.id}
				task={task}
				taskType={'IN_PROGRESS'}
				onClick={() => {
					router.navigate('/task/external/details?id=' + task.id);
				}}
			/>
		));
	}

	function renderCompleted(): React.ReactNode {
		if (!completeTasks) return;
		return completeTasks.map((task) => (
			<TaskItem
				key={task.id}
				task={task}
				taskType={'COMPLETED'}
				onClick={() => {
					router.navigate('/task/external/details?id=' + task.id);
				}}
			/>
		));
	}

	function renderSearchView(): React.ReactNode {
		const resultsNumber = overdueCount + inProgressCount + completeCount;

		return (
			<Paper>
				<Label variant={'subheader2'} weight={'extraBold'} mb={'8px'}>
					{resultsNumber} RESULT{resultsNumber !== 1 ? 'S' : ''}
				</Label>
				{resultsNumber !== 0 ? (
					<Box className={'searchResults'}>
						{renderDueTodayList()}
						{renderInProgressList()}
						{renderCompleted()}
					</Box>
				) : (
					<Box className={'noResults'}>
						<Icon iconImg={'icon-search'} fontSize={80} color={themes.neutralBeige700} />
						<Label variant={'body1'} weight={'semiBold'}>
							No results found,
						</Label>
						<Label variant={'body1'} weight={'regular'}>
							try a different search.
						</Label>
					</Box>
				)}

				<Pagination
					itemsPerPage={10}
					totalItems={resultsNumber}
					currentPage={orderPageNumber}
					changePage={(newPage) => setOrderPageNumber(newPage)}
				/>
			</Paper>
		);
	}

	function renderTasksOrSearch() {
		if (
			currentSearch.get<string>(FormKeys.SEARCH).value.toString() === '' ||
			currentSearch.get<string>(FormKeys.SEARCH).value.toString() === null
		) {
			return (
				<>
					{ObjectUtils.isArrayWithData(overdueTasks) ? (
						<TaskContainer taskType={'OVERDUE'} taskCount={overdueCount} taskNodes={renderDueTodayList()} />
					) : (
						''
					)}
					{ObjectUtils.isArrayWithData(inProgressTasks) ? (
						<TaskContainer
							taskType={'IN_PROGRESS'}
							taskCount={inProgressCount}
							taskNodes={renderInProgressList()}
						/>
					) : (
						''
					)}
					{ObjectUtils.isArrayWithData(completeTasks) ? (
						<TaskContainer taskType={'COMPLETED'} taskCount={completeCount} taskNodes={renderCompleted()} />
					) : (
						''
					)}
				</>
			);
		} else {
			return renderSearchView();
		}
	}

	return (
		<Page className={'rsTasksPage'}>
			<Paper>
				<Box className={'paperHeader'}>
					<Label variant={'subheader2'} weight={'extraBold'}>
						TASKS
					</Label>
					{/*<Label variant={'caption1'} weight={'regular'} color={themes.neutralBeige600}>*/}
					{/*	Filter*/}
					{/*</Label>*/}
				</Box>
				<InputText
					inputMode={'search'}
					placeholder={'Search tasks'}
					control={currentSearch.get(FormKeys.SEARCH)}
					updateControl={(form) => setCurrentSearch(currentSearch.clone().update(form))}
					icon={[{ position: 'LEFT', iconImg: 'icon-search' }]}
				/>
			</Paper>
			{renderTasksOrSearch()}
		</Page>
	);
};

export default TasksPage;
