import * as React from 'react';
import './EditPhaseBreakdownPopup.scss';
import {
	Box,
	Popup,
	PopupProps,
	popupController,
	Button,
	RsFormGroup,
	RsFormControl,
	rsToastify
} from '@redskytech/framework/ui';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import DiscardChangesPopup, { DiscardChangesPopupProps } from '../discardChangesPopup/DiscardChangesPopup';
import { useState } from 'react';
import LabelInputText from '../../components/labelInputText/LabelInputText';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { WebUtils } from '../../utils/utils';
import LoadingPopup, { LoadingPopupProps } from '../loadingPopup/LoadingPopup';

export interface EditPhaseBreakdownPopupProps extends PopupProps {
	phaseWorkSummary: Api.V1.Phase.Version.Get.Res;
	onUpdate: () => void;
}

enum FormKeys {
	ADMINISTRATIVE = 'administrativeSpentPercent',
	MEETINGS = 'meetingsSpentPercent',
	BUGS = 'bugsSpentPercent',
	OTHER = 'otherSpentPercent',
	DESIGN = 'designSpentPercent',
	PM = 'pmSpentPercent',
	DEVELOPMENT = 'developmentSpentPercent',
	QA = 'qaPercqaSpentPercentent'
}

const EditPhaseBreakdownPopup: React.FC<EditPhaseBreakdownPopupProps> = (props) => {
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<number>(FormKeys.ADMINISTRATIVE, props.phaseWorkSummary.administrativeSpentPercent),
			new RsFormControl<number>(FormKeys.MEETINGS, props.phaseWorkSummary.meetingsSpentPercent),
			new RsFormControl<number>(FormKeys.BUGS, props.phaseWorkSummary.bugsSpentPercent),
			new RsFormControl<number>(FormKeys.OTHER, props.phaseWorkSummary.otherSpentPercent),
			new RsFormControl<number>(FormKeys.DESIGN, props.phaseWorkSummary.designSpentPercent),
			new RsFormControl<number>(FormKeys.PM, props.phaseWorkSummary.pmSpentPercent),
			new RsFormControl<number>(FormKeys.DEVELOPMENT, props.phaseWorkSummary.developmentSpentPercent),
			new RsFormControl<number>(FormKeys.QA, props.phaseWorkSummary.qaSpentPercent)
		])
	);
	function handleClose() {
		if (formGroup.isModified()) {
			popupController.open<DiscardChangesPopupProps>(DiscardChangesPopup, {});
		} else {
			popupController.close(EditPhaseBreakdownPopup);
		}
	}

	function handleUpdate(control: RsFormControl<IRsFormControl>) {
		if (control.value) {
			control.value = +control.value;
			if (isNaN(control.value)) control.value = 0;
		}
		setFormGroup(formGroup.clone().update(control));
	}

	async function handleSave() {
		if (!(await formGroup.isValid())) {
			rsToastify.error('Invalid values in inputs', 'Errors in inputs');
			return;
		}
		popupController.open<LoadingPopupProps>(LoadingPopup, {});
		try {
			let formData = formGroup.toModel<Api.V1.Phase.Patch.Req>();
			if (
				Object.values(formData).reduce((total, field) => {
					return total + field;
				}, 0) > 100
			) {
				rsToastify.error('Your totals are beyond 100%, please double check your numbers');
				return;
			}
			await ApiRequestV1.patchPhase({ ...formData, id: props.phaseWorkSummary.id });
			props.onUpdate();
			popupController.closeAll();
			rsToastify.success('Breakdown successfully updated');
		} catch (e) {
			popupController.close(LoadingPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsEditPhaseBreakdownPopup'}>
				<PopupHeader title={'Edit Breakdown'} onClose={handleClose} />
				<Box className={'content'}>
					<Box display={'flex'} gap={24}>
						<Box width={'100%'}>
							<LabelInputText
								label={'Administrative'}
								inputMode={'numeric'}
								control={formGroup.get(FormKeys.ADMINISTRATIVE)}
								updateControl={handleUpdate}
							/>
							<LabelInputText
								label={'Bugs'}
								inputMode={'numeric'}
								control={formGroup.get(FormKeys.BUGS)}
								updateControl={handleUpdate}
							/>
							<LabelInputText
								label={'Design'}
								inputMode={'numeric'}
								control={formGroup.get(FormKeys.DESIGN)}
								updateControl={handleUpdate}
							/>
							<LabelInputText
								label={'Development'}
								inputMode={'numeric'}
								control={formGroup.get(FormKeys.DEVELOPMENT)}
								updateControl={handleUpdate}
							/>
						</Box>
						<Box width={'100%'}>
							<LabelInputText
								label={'Meetings'}
								inputMode={'numeric'}
								control={formGroup.get(FormKeys.MEETINGS)}
								updateControl={handleUpdate}
							/>
							<LabelInputText
								label={'Other'}
								inputMode={'numeric'}
								control={formGroup.get(FormKeys.OTHER)}
								updateControl={handleUpdate}
							/>
							<LabelInputText
								label={'PM'}
								inputMode={'numeric'}
								control={formGroup.get(FormKeys.PM)}
								updateControl={handleUpdate}
							/>
							<LabelInputText
								label={'QA'}
								inputMode={'numeric'}
								control={formGroup.get(FormKeys.QA)}
								updateControl={handleUpdate}
							/>
						</Box>
					</Box>
					<Box className={'buttonGroup'}>
						<Button look={'outlinedPrimary'} fullWidth onClick={handleClose}>
							Cancel
						</Button>
						<Button
							look={'containedPrimary'}
							fullWidth
							onClick={handleSave}
							disabled={!formGroup.isModified()}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Box>
		</Popup>
	);
};
export default EditPhaseBreakdownPopup;
