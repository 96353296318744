import * as React from 'react';
import './TableSearchHeader.scss';
import { Box, InputText } from '@redskytech/framework/ui';

interface TableSearchHeaderProps {
	searchValue: string;
	onChange: (value: string) => void;
}

const TableSearchHeader: React.FC<TableSearchHeaderProps> = (props) => {
	return (
		<Box className={'rsTableSearchHeader'}>
			<InputText
				inputMode={'text'}
				placeholder={'Search'}
				value={props.searchValue}
				onChange={props.onChange}
				icon={[{ position: 'LEFT', iconImg: 'icon-search' }]}
			/>
		</Box>
	);
};

export default TableSearchHeader;
