import * as React from 'react';
import './NoTableFilterResults.scss';
import { Box, Icon, Label } from '@redskytech/framework/ui';
import themes from '../../themes/themes.scss?export';

interface NoTableFilterResultsProps {
	onClear: () => void;
}

const NoTableFilterResults: React.FC<NoTableFilterResultsProps> = (props) => {
	return (
		<Box className={'rsNoTableFilterResults'}>
			<Box padding={24}>
				<Icon iconImg={'icon-search'} fontSize={72} color={themes.neutralBeige500} marginBottom={10} />
				<Label variant={'subheader1'} weight={'regular'} mb={8}>
					No results found!
				</Label>
				<Label variant={'body1'} weight={'regular'} mb={8}>
					Try changing the filters or the search term.
				</Label>
				<Label
					className={'clearAll'}
					variant={'body1'}
					weight={'regular'}
					color={themes.primaryRed500}
					onClick={props.onClear}
				>
					Clear All
				</Label>
			</Box>
		</Box>
	);
};
export default NoTableFilterResults;
