import React, { useEffect, useState } from 'react';
import './TeamList.scss';
import { Avatar, Box, Button, Label, popupController, rsToastify } from '@redskytech/framework/ui';
import ListDataTable from '../../../../components/listDataTable/ListDataTable.js';
import TableSearchHeader from '../../../../components/listDataTable/tableSearchHeader/TableSearchHeader.js';
import ColumnHeader from '../../../../components/listDataTable/columnHeader/ColumnHeader.js';
import { ApiRequestV1 } from '../../../../generated/apiRequests.js';
import { WebUtils } from '../../../../utils/utils.js';
import AddEditTeamMemberPopup, {
	AddEditTeamMemberPopupProps
} from '../../../../popups/addEditTeamMemberPopup/AddEditTeamMemberPopup.js';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AddEditTeamPopup, { AddEditTeamPopupProps } from '../../../../popups/addEditTeamPopup/AddEditTeamPopup.js';
import { useRecoilValue } from 'recoil';
import globalState from '../../../../state/globalState.js';
import RsPagedResponseData = RedSky.RsPagedResponseData;
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner.js';

interface TeamListProps {
	team: Api.V1.TeamMap.Get.Res;
	onChange: () => void;
	onDelete: () => void;
}

const FieldKeys: { [key in 'NAME' | 'ROLE' | 'EMAIL' | 'PHONE']: { field: string; sortField: string } } = {
	NAME: { field: 'name', sortField: 'user.firstName' },
	ROLE: { field: 'role', sortField: 'teamRole.name' },
	EMAIL: { field: 'email', sortField: 'user.email' },
	PHONE: { field: 'phone', sortField: 'user.phone' }
};

const TeamList: React.FC<TeamListProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);

	const [activeSortColumn, setActiveSortColumn] = useState<string>();
	const [searchValue, setSearchValue] = useState<string>('');
	const [teamList, setTeamList] = useState<RsPagedResponseData<Api.V1.TeamMap.Paged.Get.Res[]>>();
	const [lastPageQuery, setLastPageQuery] = useState<RedSky.PageQuery>({});
	const [isMounted, setIsMounted] = useState<boolean>(true);

	useEffect(() => {
		setIsMounted(true);
		getData(lastPageQuery).catch(console.error);
		return () => {
			setIsMounted(false);
		};
	}, []);

	async function getData(pageQuery: RedSky.PageQuery) {
		if (!version) return;
		try {
			if (pageQuery.sortBy === undefined) {
				delete pageQuery.sortBy;
				delete pageQuery.sortOrder;
			}
			let filter = `(column:team.id,value:${props.team.id})`;
			if (pageQuery.filter) filter += `and(${pageQuery.filter})`;
			setLastPageQuery(pageQuery);
			const res = await ApiRequestV1.getTeamMapPaged({
				versionId: version.id,
				...pageQuery,
				filter
			});
			setTeamList(res);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function handleRowClick({ data }: DataTableRowClickEventParams) {
		if (!version) return;
		popupController.open<AddEditTeamMemberPopupProps>(AddEditTeamMemberPopup, {
			teamId: props.team.id,
			versionId: version.id,
			companyId: props.team.companyId,
			existingTeamMember: data,
			onChange: () => {
				getData(lastPageQuery).catch(console.error);
			}
		});
	}

	function handleAddTeamMember() {
		if (!version) return;
		popupController.open<AddEditTeamMemberPopupProps>(AddEditTeamMemberPopup, {
			teamId: props.team.id,
			versionId: version.id,
			companyId: props.team.companyId,
			onChange: () => {
				getData(lastPageQuery).catch(console.error);
			}
		});
	}

	function handleEditTeam() {
		popupController.open<AddEditTeamPopupProps>(AddEditTeamPopup, {
			teamId: props.team.id,
			teamName: props.team.name,
			companyId: props.team.companyId,
			onChange: () => {
				getData(lastPageQuery).catch(console.error);
				props.onChange();
			},
			onDelete: props.onDelete
		});
	}

	if (!teamList) return <LoadingSpinner />;
	return (
		<Box className={'rsTeamList'}>
			<Box className={'tableHeader'}>
				<Label variant={'subheader1'} weight={'semiBold'}>
					{props.team.name}
				</Label>
				<Box display={'flex'} gap={16}>
					<Button
						look={'iconSecondary'}
						icon={[{ iconImg: 'icon-edit', position: 'LEFT' }]}
						onClick={() => handleEditTeam()}
					/>
					<Button look={'containedPrimary'} onClick={() => handleAddTeamMember()}>
						Add Member
					</Button>
				</Box>
			</Box>
			<ListDataTable
				lazy
				loading={true}
				onClearFilters={() => {
					setSearchValue('');
				}}
				data={teamList}
				getData={(pageQuery) => getData(pageQuery)}
				isEmptyDataMessage={!teamList.total && !!searchValue.length}
				globalFilter={searchValue}
				globalFilterFields={['user.firstName', 'user.email', 'user.phone', 'teamRole.name']}
				onRowClick={(data) => handleRowClick(data)}
				header={
					<TableSearchHeader
						searchValue={searchValue}
						onChange={(value) => {
							setSearchValue(value);
						}}
					/>
				}
			>
				<Column
					body={(data: Api.V1.TeamMap.Paged.Get.Res) => {
						return (
							<Avatar
								widthHeight={32}
								name={`${data.userFirstName} ${data.userLastName}`}
								image={data.userAvatarUrl}
							/>
						);
					}}
				/>
				<Column
					className={'nameColumn'}
					sortField={FieldKeys.NAME.sortField}
					header={
						<ColumnHeader label={'Name'} isActiveSort={activeSortColumn === FieldKeys.ROLE.sortField} />
					}
					sortable
					body={(rowData: Api.V1.TeamMap.Paged.Get.Res) => {
						return (
							<Label variant={'body1'} weight={'regular'}>
								{rowData.userFirstName} {rowData.userLastName}
							</Label>
						);
					}}
				/>
				<Column
					className={'roleColumn'}
					sortField={FieldKeys.ROLE.sortField}
					header={
						<ColumnHeader label={'Role'} isActiveSort={activeSortColumn === FieldKeys.ROLE.sortField} />
					}
					sortable
					body={(rowData: Api.V1.TeamMap.Paged.Get.Res) => {
						return (
							<Label variant={'body1'} weight={'regular'}>
								{rowData.teamRoleName}
							</Label>
						);
					}}
				/>
				<Column
					className={'emailColumn'}
					sortField={FieldKeys.EMAIL.sortField}
					header={
						<ColumnHeader label={'Email'} isActiveSort={activeSortColumn === FieldKeys.EMAIL.sortField} />
					}
					sortable
					body={(rowData: Api.V1.TeamMap.Paged.Get.Res) => {
						return (
							<Label variant={'body1'} weight={'regular'}>
								{rowData.userEmail}
							</Label>
						);
					}}
				/>
				<Column
					className={'phoneColumn'}
					sortField={FieldKeys.PHONE.sortField}
					header={
						<ColumnHeader label={'Phone'} isActiveSort={activeSortColumn === FieldKeys.PHONE.sortField} />
					}
					sortable
					body={(rowData: Api.V1.TeamMap.Paged.Get.Res) => {
						return (
							<Label variant={'body1'} weight={'regular'}>
								{!!rowData.userPhone ? rowData.userPhone : '-'}
							</Label>
						);
					}}
				/>
			</ListDataTable>
		</Box>
	);
};

export default TeamList;
