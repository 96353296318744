import React from 'react';
import './Menu.scss';
import MenuItem from './menuItem/MenuItem';

import { Box, Icon, Label } from '@redskytech/framework/ui';
import classNames from 'classnames';
import serviceFactory from '../../services/serviceFactory';
import { useCurrentPath } from '@redskytech/framework/hooks';
import UserService from '../../services/user/UserService';
import MenuGroup from './menuGroup/MenuGroup';

const Menu: React.FC = () => {
	const currentPath = useCurrentPath();

	function isGroupSelected(pathBase: string) {
		return currentPath.startsWith(pathBase);
	}

	function handleLogout() {
		const userService = serviceFactory.get<UserService>('UserService');
		userService.logout();
	}

	return (
		<Box className="rsMenu">
			<Box className={'overviewItems'}>
				<MenuItem
					name={'Companies'}
					path={'/company/list'}
					isSelected={currentPath === '/company/list'}
					iconName={'icon-domain'}
				/>
				<MenuItem
					name={'Users'}
					path={'/user/list'}
					isSelected={currentPath === '/user/list'}
					iconName={'icon-person'}
				/>
			</Box>
			<MenuGroup name={'Tasks'} isChildSelected={isGroupSelected('/task/')} iconLeft={'icon-assignment'}>
				<MenuItem isSelected={isGroupSelected('/task/')} name={'Client Tasks'} path={'/task/list'} />
			</MenuGroup>
			<MenuGroup name={'Sprints'} isChildSelected={isGroupSelected('/sprint/')} iconLeft={'icon-sprint'}>
				<MenuItem name={'Sprints'} path={'/sprint/list'} isSelected={isGroupSelected('/sprint/')} />
			</MenuGroup>
			<MenuItem
				name={'Project'}
				path={'/project/details'}
				isSelected={currentPath === '/project/details'}
				iconName={'icon-budget-line'}
			/>
			<MenuItem
				name={'Meetings'}
				path={'/meeting/list'}
				isSelected={currentPath === '/meeting/list'}
				iconName={'icon-calendar'}
			/>
			<MenuItem
				name={'Artifacts'}
				path={'/artifact/list'}
				isSelected={currentPath === '/artifact/list'}
				iconName={'icon-artifacts'}
			/>
			<MenuGroup name={'Teams'} isChildSelected={isGroupSelected('/team/')} iconLeft={'icon-groups'}>
				<MenuItem isSelected={currentPath === '/team/roles'} name={'Roles'} path={'/team/roles'} />
				<MenuItem isSelected={currentPath === '/team/list'} name={'Team Lists'} path={'/team/list'} />
			</MenuGroup>
			<Box className={classNames('rsMenuItem', 'logout')} onClick={handleLogout}>
				<Icon iconImg={'icon-logout'} fontSize={16} />
				<Label variant={'body1'} weight={'medium'}>
					Log Out
				</Label>
			</Box>
		</Box>
	);
};

export default Menu;
