import * as React from 'react';
import './ProjectPhaseDetailsPage.scss';
import themes from '../../../themes/themes.scss?export';
import { Page } from '@redskytech/framework/996';
import { Box, Label, rsToastify } from '@redskytech/framework/ui';
import { DateUtils, ObjectUtils, WebUtils } from '../../../utils/utils';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import BudgetPieChart, { PieChartData } from '../../../components/budgetPieChart/BudgetPieChart';
import { useEffect, useState } from 'react';
import Paper from '../../../components/paper/Paper';
import SectionWithHeader from '../../../components/sectionWithHeader/SectionWithHeader';
import ProgressBar from '../../../components/progressBar/ProgressBar';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import router from '../../../utils/router';
import LoadingPage from '../../common/loadingPage/LoadingPage';

interface ProjectPhaseDetailsPageProps {}

const ProjectPhaseDetailsPage: React.FC<ProjectPhaseDetailsPageProps> = () => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [phase, setPhase] = useState<Api.V1.Phase.Version.Get.Res>();
	const [chartData, setChartData] = useState<PieChartData[]>([]);
	const params = router.getQueryParams<{ phaseId: number; color: string }>([
		{
			key: 'pi',
			default: '',
			type: 'integer',
			alias: 'phaseId'
		},
		{
			key: 'col',
			default: '',
			type: 'integer',
			alias: 'color'
		}
	]);

	useEffect(() => {
		if (!version) return;
		(async function getBudgetPhase() {
			try {
				const getPhasesResults = await ApiRequestV1.getPhaseVersion({
					versionId: version.id
				});
				setPhase(getPhasesResults[0]);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to get phase breakdown'));
			}
		})();
	}, [version]);

	useEffect(() => {
		if (!phase) return;
		const remainingPercentage =
			100 -
			((phase.developmentSpentPercent || 0) +
				(phase.bugsSpentPercent || 0) +
				(phase.pmSpentPercent || 0) +
				(phase.meetingsSpentPercent || 0) +
				(phase.administrativeSpentPercent || 0) +
				(phase.designSpentPercent || 0) +
				(phase.otherSpentPercent || 0) +
				(phase.qaSpentPercent || 0));

		setChartData([
			{
				label: `Development - ${phase.developmentSpentPercent}%`,
				amount: phase.developmentSpentPercent,
				labelColor: themes.budgetDev,
				labelBorder: themes.budgetDev
			},
			{
				label: `Bugs - ${phase.bugsSpentPercent}%`,
				amount: phase.bugsSpentPercent,
				labelColor: themes.budgetBugs,
				labelBorder: themes.budgetBugs
			},
			{
				label: `PM - ${phase.pmSpentPercent}%`,
				amount: phase.pmSpentPercent,
				labelColor: themes.budgetPM,
				labelBorder: themes.budgetPM
			},
			{
				label: `Meetings - ${phase.meetingsSpentPercent}%`,
				amount: phase.meetingsSpentPercent,
				labelColor: themes.budgetMeetings,
				labelBorder: themes.budgetMeetings
			},
			{
				label: `Administrative - ${phase.administrativeSpentPercent}%`,
				amount: phase.administrativeSpentPercent,
				labelColor: themes.budgetAdmin,
				labelBorder: themes.budgetAdmin
			},
			{
				label: `Design - ${phase.designSpentPercent}%`,
				amount: phase.designSpentPercent,
				labelColor: themes.budgetDesign,
				labelBorder: themes.budgetDesign
			},
			{
				label: `Other - ${phase.otherSpentPercent}%`,
				amount: phase.otherSpentPercent,
				labelColor: themes.budgetOther,
				labelBorder: themes.budgetOther
			},
			{
				label: `QA - ${phase.qaSpentPercent}%`,
				amount: phase.qaSpentPercent,
				labelColor: themes.budgetQA,
				labelBorder: themes.budgetQA
			},
			{
				label: `Remaining - ${remainingPercentage}%`,
				amount: remainingPercentage,
				labelColor: '#170D0D',
				labelBorder: themes.neutralBeige700
			}
		]);
	}, [phase]);

	function renderPhaseOverviewBudget() {
		if (!phase) return;
		return (
			<Paper>
				<Label textAlign={'center'} variant={'subheader2'} weight={'extraBold'} mb={'8px'}>
					{phase.name?.toUpperCase()} PHASE
				</Label>
				<ProgressBar
					barType={'PERCENTAGE'}
					barHeight={'SMALL'}
					progressBarSections={[
						{
							sectionColor: ObjectUtils.getProgressBarColor(parseInt(params.color)),
							percentageOrAmount: phase.donePercent || 0
						}
					]}
				/>

				<Box className={'projectCompletion'}>
					<Label variant={'caption1'} weight={'semiBold'}>
						Phase{' '}
						<span className={phase.donePercent === 100 ? 'complete' : 'inProgress'}>
							{phase.donePercent}%
						</span>{' '}
						Complete
					</Label>
				</Box>
			</Paper>
		);
	}

	function renderPhaseBreakdownList() {
		return chartData.map((item, index) => {
			return (
				<Box className={'breakdownContainer'} key={'breakdownList' + index + item.label} display={'flex'}>
					<Box
						className={'budgetColorContainer'}
						bgColor={item.labelColor}
						border={`1px solid ${item.labelBorder}`}
					/>
					<Label variant={'body2'} weight={'semiBold'}>
						{item.label}
					</Label>
				</Box>
			);
		});
	}

	function renderUpdated() {
		if (!phase) return;
		return (
			<Label variant={'caption2'} weight={'regular'} textAlign={'center'} color={themes.neutralBeige600}>
				Updated {DateUtils.getDateDifferenceFriendly(new Date(), new Date(phase.modifiedOn))} ago
			</Label>
		);
	}

	if (ObjectUtils.isEmpty(phase)) return <LoadingPage />;
	return (
		<Page className={'rsProjectPhaseDetailsPage'}>
			{renderPhaseOverviewBudget()}

			<SectionWithHeader title={'BREAKDOWN'} className={'breakdownSection'}>
				<BudgetPieChart tableData={chartData} />
				<Box className={'breakdownList'}>{renderPhaseBreakdownList()}</Box>
			</SectionWithHeader>

			{renderUpdated()}
		</Page>
	);
};

export default ProjectPhaseDetailsPage;
