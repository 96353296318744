import * as React from 'react';
import './BudgetLineChart.scss';
import { Box } from '@redskytech/framework/ui';
import 'chart.js/auto';
import { Chart, ChartArea, ChartData, ChartOptions, TooltipItem } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useMemo } from 'react';
import { StringUtils } from '../../utils/utils';

export interface BudgetLineChartData {
	label: string;
	amount: number;
}

interface BudgetLineChartProps {
	tableData: BudgetLineChartData[];
}

const BudgetLineChart: React.FC<BudgetLineChartProps> = (props) => {
	const data = useMemo<ChartData>(() => {
		return {
			labels: props.tableData.map((item) => item.label),
			datasets: [
				{
					data: props.tableData.map((item) => item.amount),
					backgroundColor: function (context: { chart: Chart }) {
						const chart = context.chart;
						const { ctx, chartArea } = chart;
						if (!chartArea) {
							// This case happens on initial chart load
							return;
						}
						return getBackgroundGradient(ctx, chartArea);
					},
					borderColor: function (context: { chart: Chart }) {
						const chart = context.chart;
						const { ctx, chartArea } = chart;
						if (!chartArea) {
							return;
						}
						return getLineGradient(ctx, chartArea);
					},
					cubicInterpolationMode: 'monotone',
					borderWidth: 4,
					fill: true
				}
			]
		};
	}, [props.tableData]);

	const options = useMemo<ChartOptions>(
		() => ({
			plugins: {
				legend: {
					display: false
				},
				tooltip: {
					displayColors: false,
					bodyFont: {
						family: 'Poppins',
						size: 10,
						weight: '600'
					},
					bodyColor: '#EBE3E3',
					backgroundColor: '#282625',
					caretSize: 4,
					callbacks: {
						label: function (tooltipItem: TooltipItem<'line'>) {
							return '$' + StringUtils.addCommasToNumber(tooltipItem.dataset.data[tooltipItem.dataIndex]);
						}
					}
				}
			},
			elements: {
				point: {
					radius: 2
				}
			},
			scales: {
				yAxes: {
					suggestedMax:
						props.tableData[props.tableData.length - 1].amount +
						props.tableData[props.tableData.length - 1].amount * 0.2,
					ticks: {
						fontFamily: 'Poppins',
						fontSize: 12,
						fontStyle: 'bold',
						color: '#EBE3E3',
						callback: function (label, index, labels) {
							return '$' + Number(label) / 1000 + 'k';
						}
					},
					grid: {
						color: 'rgba(235, 227, 227, 0.32)',
						borderDash: [5, 6]
					}
				},
				xAxes: {
					ticks: {
						fontFamily: 'Poppins',
						fontSize: 12,
						fontStyle: 'bold',
						color: '#EBE3E3'
					}
				}
			}
		}),
		[]
	);

	function getBackgroundGradient(ctx: CanvasRenderingContext2D, chartArea: ChartArea) {
		let gradientBackground: CanvasGradient;
		gradientBackground = ctx.createLinearGradient(0, chartArea.left, 20, chartArea.right);
		gradientBackground.addColorStop(0, 'rgba(38, 208, 223, 0.16)');
		gradientBackground.addColorStop(0.7, 'rgba(56, 207, 137, 0.16)');

		return gradientBackground;
	}

	function getLineGradient(ctx: CanvasRenderingContext2D, chartArea: ChartArea) {
		let gradientLine: CanvasGradient;
		gradientLine = ctx.createLinearGradient(0, chartArea.left, 20, chartArea.right);
		gradientLine.addColorStop(0, 'rgba(38, 208, 223, 1)');
		gradientLine.addColorStop(0.7, 'rgba(56, 207, 137, 1)');
		return gradientLine;
	}

	return (
		<Box className={'rsBudgetLineChart'}>
			<Line data={data as ChartData<'line'>} options={options as ChartOptions<'line'>} />
		</Box>
	);
};

export default BudgetLineChart;
