import SparkMD5 from 'spark-md5';
import { Service } from '../Service';
import globalState, { clearPersistentState, setRecoilExternalValue } from '../../state/globalState';
import router from '../../utils/router';
import { RoutePaths } from '../../routes';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { WebUtils } from '../../utils/utils';

export interface AuthTokens {
	refreshToken: string;
	token: string;
	tokenExpiresOn: string;
}

const externalUserRoles = ['client', 'partner'];

export default class UserService extends Service {
	async loginUserByPassword(username: string, password: string) {
		password = SparkMD5.hash(password);
		const loginResponse = await ApiRequestV1.postUserLogin({
			username,
			password
		});

		await this.onAfterLogin({
			token: loginResponse.token,
			refreshToken: loginResponse.refreshToken,
			tokenExpiresOn: loginResponse.expiresOn
		});
	}

	async createUser(data: Api.V1.User.Post.Req): Promise<Api.V1.User.Post.Res> {
		data.password = SparkMD5.hash(data.password);
		return ApiRequestV1.postUser(data);
	}

	updateUser(data: Api.V1.User.Patch.Req): Promise<Api.V1.User.Patch.Res> {
		return ApiRequestV1.patchUser(data);
	}

	updateUserPassword(currentPassword: string, newPassword: string): Promise<boolean> {
		return ApiRequestV1.putUserUpdatePassword({
			oldPassword: SparkMD5.hash(currentPassword),
			newPassword: SparkMD5.hash(newPassword)
		});
	}

	async refreshToken(authToken: AuthTokens): Promise<AuthTokens> {
		const reAuthResponse = await ApiRequestV1.postUserReAuth({
			token: authToken.token,
			refreshToken: authToken.refreshToken
		});
		let newAuthTokens: AuthTokens = {
			token: reAuthResponse.token,
			...(reAuthResponse.refreshToken
				? { refreshToken: reAuthResponse.refreshToken }
				: { refreshToken: authToken.refreshToken }),
			tokenExpiresOn: reAuthResponse.expiresOn
		};
		setRecoilExternalValue(globalState.authTokens, newAuthTokens);
		return newAuthTokens;
	}

	logout() {
		setRecoilExternalValue<AuthTokens | undefined>(globalState.authTokens, undefined);
		clearPersistentState();
		router.navigate<RoutePaths>('/').catch(console.error);
	}

	async onAfterLogin(authTokens: AuthTokens) {
		setRecoilExternalValue(globalState.authTokens, authTokens);

		// Since recoil can take a bit to update we need to delay so our authorization Token is updated before
		// doing any requests that might require it.
		await WebUtils.sleep(50);
		let userDetails = await this.getUserDetails();
		if (!userDetails.defaultVersionId) return;
		await this.getVersionDetails(userDetails.defaultVersionId);
	}

	async reloadVersionSpecificDetails() {}

	private async getUserDetails(): Promise<Api.V1.User.Get.Res> {
		const userDetails = await ApiRequestV1.getUser();
		setRecoilExternalValue<Api.V1.User.Get.Res | undefined>(globalState.user, userDetails);
		setRecoilExternalValue<boolean>(globalState.isExternalUser, externalUserRoles.includes(userDetails.role));
		return userDetails;
	}

	private async getVersionDetails(versionId: number) {
		const versionDetails = await ApiRequestV1.getVersion({ versionId });
		setRecoilExternalValue<Api.V1.Version.Get.Res | undefined>(globalState.version, versionDetails);
		return versionDetails;
	}
}
