import React from 'react';
import '@redskytech/framework/index.css';
import './App.scss';
import './icons/style.css';

import useLoginState, { LoginStatus } from './customHooks/useLoginState';
import AppBar from './components/appBar/AppBar';
import { useLoadInitialPath } from './utils/router';
import { Box, popupController, ToastContainer } from '@redskytech/framework/ui';
import { View } from '@redskytech/framework/996';
import useIsAtBreakpoint from './customHooks/useIsAtBreakpoint';
import classNames from 'classnames';
import ExternalAppBar from './components/externalAppBar/ExternalAppBar';
import TabBar from './components/tabBar/TabBar';
import { useRecoilValue } from 'recoil';
import globalState from './state/globalState';
import Menu from './components/menu/Menu';

function App() {
	const isSmallerThan1920 = useIsAtBreakpoint(1919);
	const isSmallerThan1440 = useIsAtBreakpoint(1439);
	const isSmallerThan1024 = useIsAtBreakpoint(1023);
	const isSmallerThan768 = useIsAtBreakpoint(767);
	const isSmallerThan425 = useIsAtBreakpoint(424);

	const loginStatus = useLoginState();
	const isExternalUser = useRecoilValue<boolean>(globalState.isExternalUser);

	useLoadInitialPath(loginStatus);

	function renderViewsBasedOnLoginStatus() {
		switch (loginStatus) {
			case LoginStatus.UNKNOWN:
				return null;
			case LoginStatus.LOGGED_OUT:
				return (
					<>
						<View key="login" id="login" default initialPath="/" />
					</>
				);
			case LoginStatus.LOGGED_IN:
				if (isExternalUser) {
					return (
						<Box className="loggedInExternalView">
							<ExternalAppBar />
							<View key="home" id="home" default initialPath="/home" />
							<View key="budget" id="budget" initialPath="/budget" />
							<View key="project" id="project" initialPath="/project" />
							<View key="sprint" id="sprint" initialPath="/sprint" />
							<View key="artifacts" id="artifacts" initialPath={'/artifacts'} />
							<View key="tasks" id="tasks" initialPath="/tasks" />
							<View key="profile" id="profile" initialPath="/profile" />
							<TabBar />
						</Box>
					);
				} else {
					return (
						<Box className="loggedInInternalView">
							<AppBar />
							<Box display={'flex'} height={'calc(100% - 80px)'}>
								<Menu />
								<View key="admin" id="admin" default initialPath="/task/list" />
							</Box>
						</Box>
					);
				}
		}
	}

	return (
		<Box
			className={classNames('App', {
				smallerThan1920: isSmallerThan1920,
				smallerThan1440: isSmallerThan1440,
				smallerThan1024: isSmallerThan1024,
				smallerThan768: isSmallerThan768,
				smallerThan425: isSmallerThan425
			})}
		>
			{renderViewsBasedOnLoginStatus()}
			{popupController.instance}
			<ToastContainer />
		</Box>
	);
}

export default App;
