import * as React from 'react';
import './ErrorPage.scss';
import { Page } from '@redskytech/framework/996';
import { Label } from '@redskytech/framework/ui';

interface ErrorPageProps {
	error: string | null;
}

const ErrorPage: React.FC<ErrorPageProps> = (props) => {
	return (
		<Page className={'rsErrorPage'}>
			<Label variant={'subheader1'} weight={'bold'}>
				Unfortunately, an error has occurred.
			</Label>
			<Label variant={'subheader2'} weight={'semiBold'}>
				The information associated with this error can be found below.
			</Label>
			<Label mt={32} variant={'body1'} weight={'regular'}>
				{props.error}
			</Label>
		</Page>
	);
};

export default ErrorPage;
