import * as React from 'react';
import './MeetingSection.scss';
import SectionWithHeader from '../../components/sectionWithHeader/SectionWithHeader';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import MeetingItem from '../../components/meetingItem/MeetingItem';
import themes from '../../themes/themes.scss?export';
import MeetingRecurring from '../../components/meetingRecurring/MeetingRecurring';
import router from '../../utils/router';
import { useEffect } from 'react';
import { ApiRequestV1 } from '../../generated/apiRequests.js';

interface MeetingSectionProps {
	meetings: Api.V1.Meeting.Paged.Get.Res[];
}

const MeetingSection: React.FC<MeetingSectionProps> = (props) => {
	function renderMeetingReminders(): React.ReactNode {
		return props.meetings
			.filter(
				(meeting) =>
					new Date(meeting.occurredOn) <=
					new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1, 0, 0, 0)
			)
			.map((meeting) => {
				return (
					<MeetingItem
						key={meeting.id}
						meeting={meeting}
						isMeetingReminder
						onClick={() => router.navigate('/meeting/external/details?id=' + meeting.id)}
					/>
				);
			});
	}

	function renderMeetingItems(): React.ReactNode {
		return props.meetings
			.filter((meeting) => new Date(meeting.occurredOn) >= new Date())
			.map((meeting) => {
				return (
					<MeetingItem
						key={meeting.id}
						meeting={meeting}
						onClick={() => router.navigate('/meeting/external/details?id=' + meeting.id)}
					/>
				);
			})
			.slice(0, 3);
	}

	return (
		<SectionWithHeader className={'rsMeetingSection'} title={'Meetings'} viewAllLink={'/meeting'}>
			<MeetingRecurring />
			{renderMeetingReminders()}
			{renderMeetingItems()}
		</SectionWithHeader>
	);
};

export default MeetingSection;
