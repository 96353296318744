import * as React from 'react';
import './ProjectPage.scss';
import { Box, Label, rsToastify } from '@redskytech/framework/ui';
import { Page } from '@redskytech/framework/996';
import Paper from '../../../components/paper/Paper';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import themes from '../../../themes/themes.scss?export';
import ProgressBar from '../../../components/progressBar/ProgressBar';
import { DateUtils, ObjectUtils, WebUtils } from '../../../utils/utils';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import router from '../../../utils/router';
import BulletedKey from '../../../components/bulletedKey/BulletedKey';

interface ProjectPageProps {}

const ProjectPage: React.FC<ProjectPageProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [phases, setPhases] = useState<Api.V1.Phase.Version.Get.Res[]>([]);
	const [projectDetails, setProjectDetails] = useState<Api.V1.Project.Get.Res>();
	useEffect(() => {
		if (!version) return;
		let isCancelled = false;
		(async function getPhases() {
			try {
				const phaseResults = await ApiRequestV1.getPhaseVersion({ versionId: version.id });
				if (isCancelled) return;
				setPhases(phaseResults);
				let projectId = version.projectId;
				if (!projectId) return;
				let projectRes = await ApiRequestV1.getProject({ projectId });
				if (isCancelled) return;
				setProjectDetails(projectRes);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error Getting Phases'));
			}
		})();
		return () => {
			isCancelled = true;
		};
	}, [version]);

	function renderOverallProgressReport(): React.ReactNode {
		if (!projectDetails) return;
		return (
			<Paper>
				<Label
					className={'projectPercentage'}
					variant={'caption1'}
					weight={'semiBold'}
					color={themes.neutralBeige600}
				>
					PROJECT COMPLETION STATUS <span>{projectDetails.donePercent}%</span>
				</Label>
				<ProgressBar
					barType={'PERCENTAGE'}
					barHeight={'LARGE'}
					progressBarSections={[
						{
							percentageOrAmount: projectDetails.donePercent || 0,
							sectionColor: ObjectUtils.getProgressBarColor(2)
						}
					]}
				/>
				<Box display={'flex'} justifyContent={'space-between'} m={8}>
					<BulletedKey label={'Done'} bulletColor={themes.accentWarning} />
					<BulletedKey label={'Remaining'} bulletColor={themes.neutralBeige700} />
				</Box>
			</Paper>
		);
	}

	function renderBrokenDownReports(): React.ReactNode {
		return phases.map((phaseItem, phaseIndex) => {
			return (
				<Paper
					className={'reportContainer'}
					key={phaseItem.name + phaseIndex}
					onClick={() => {
						return;
						//removing for current mvp, will add back later when decided this is viable for customer
						// router
						// 	.navigate('/phase/details?pi=' + phaseItem.id + '&col=' + phaseIndex)
						// 	.catch(console.error);
					}}
				>
					<Label className={'title'} variant={'subheader2'} weight={'extraBold'}>
						{phaseItem.name.toUpperCase()} PHASE
					</Label>
					<ProgressBar
						barType={'PERCENTAGE'}
						barHeight={'SMALL'}
						progressBarSections={[
							{
								percentageOrAmount: phaseItem.donePercent || 0,
								sectionColor: ObjectUtils.getProgressBarColor(phaseIndex)
							}
						]}
					/>
					<Box className={'projectCompletion'}>
						<Label variant={'caption1'} weight={'semiBold'}>
							Phase{' '}
							<span className={phaseItem.donePercent === 100 ? 'complete' : 'inProgress'}>
								{phaseItem.donePercent}%
							</span>{' '}
							Complete
						</Label>
					</Box>
				</Paper>
			);
		});
	}

	function renderLastUpdated(): React.ReactNode {
		return (
			<Label variant={'caption2'} weight={'regular'} textAlign={'center'} color={themes.neutralBeige600}>
				Updated {DateUtils.getDateDifferenceFriendly(new Date(), new Date(phases[0].modifiedOn))} ago
			</Label>
		);
	}

	if (!version || !projectDetails || !ObjectUtils.isArrayWithData(phases)) return <></>;
	return (
		<Page className={'rsProjectPage'}>
			{renderOverallProgressReport()}
			{renderBrokenDownReports()}
			{renderLastUpdated()}
		</Page>
	);
};

export default ProjectPage;
