import * as React from 'react';
import { useEffect, useState } from 'react';
import './EditProjectEstimationsPopup.scss';
import {
	Box,
	Button,
	Label,
	Popup,
	popupController,
	PopupProps,
	RsFormControl,
	RsFormGroup,
	rsToastify
} from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import LabelInputText from '../../components/labelInputText/LabelInputText';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import LoadingPopup, { LoadingPopupProps } from '../loadingPopup/LoadingPopup';
import { ApiRequestV1 } from '../../generated/apiRequests';
import DiscardChangesPopup, { DiscardChangesPopupProps } from '../discardChangesPopup/DiscardChangesPopup';
import { WebUtils } from '../../utils/utils';

export interface EditProjectEstimationsPopupProps extends PopupProps {
	phases: Api.V1.Phase.Version.Get.Res[];
	project: Api.V1.Project.Get.Res;
	onUpdate: () => void;
}

enum FormKeys {
	PROJECT = 'project'
}

const EditProjectEstimationsPopup: React.FC<EditProjectEstimationsPopupProps> = (props) => {
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			...props.phases.map((phase) => new RsFormControl<number>(phase.id.toString(), phase.donePercent || 0)),
			new RsFormControl<number>(FormKeys.PROJECT, props.project.donePercent || 0)
		])
	);

	async function handleSave() {
		if (!(await formGroup.isValid())) {
			rsToastify.error('Invalid values in inputs', 'Errors in inputs');
			return;
		}
		popupController.open<LoadingPopupProps>(LoadingPopup, {});
		try {
			let formData = formGroup.toModel<{ [key: string]: number; project: number }>();
			await Promise.all(
				props.phases.map((phase) => {
					return ApiRequestV1.patchPhase({ id: phase.id, donePercent: formData[phase.id] });
				})
			);
			await ApiRequestV1.patchProject({ id: props.project.id, donePercent: formData.project });
			props.onUpdate();
			popupController.closeAll();
			rsToastify.success('Estimations successfully updated');
		} catch (e) {
			popupController.close(LoadingPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function handleClose() {
		if (formGroup.isModified()) {
			popupController.open<DiscardChangesPopupProps>(DiscardChangesPopup, {});
		} else {
			popupController.close(EditProjectEstimationsPopup);
		}
	}

	function handleUpdate(control: RsFormControl<IRsFormControl>) {
		if (control.value) {
			control.value = +control.value;
			if (isNaN(control.value)) control.value = 0;
		}
		setFormGroup(formGroup.clone().update(control));
	}

	function renderPhaseInputs() {
		return props.phases.map((phase) => {
			return (
				<Box key={phase.name}>
					<Label variant={'subheader2'} weight={'regular'} mb={3}>
						{phase.name.replace('/', '&')} Phase
					</Label>
					<LabelInputText
						label={'Percent Completed'}
						inputMode={'numeric'}
						immediateValidate
						control={formGroup.get<number>(phase.id.toString())}
						updateControl={handleUpdate}
					/>
				</Box>
			);
		});
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsEditProjectEstimationsPopup'}>
				<PopupHeader title={'Edit Estimations'} onClose={handleClose} />
				<Box className={'content'}>
					{renderPhaseInputs()}
					<Box className={'projectTotal'}>
						<Label variant={'subheader2'} weight={'regular'} mb={3}>
							Project Total
						</Label>
						<LabelInputText
							label={'Percent Completed'}
							inputMode={'numeric'}
							control={formGroup.get<number>(FormKeys.PROJECT)}
							updateControl={handleUpdate}
						/>
					</Box>
					<Box className={'buttonGroup'}>
						<Button look={'outlinedPrimary'} fullWidth onClick={handleClose}>
							Cancel
						</Button>
						<Button
							look={'containedPrimary'}
							fullWidth
							onClick={handleSave}
							disabled={!formGroup.isModified()}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Box>
		</Popup>
	);
};
export default EditProjectEstimationsPopup;
