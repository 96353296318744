import * as React from 'react';
import { useEffect, useState } from 'react';
import './ProfilePage.scss';
import { Page } from '@redskytech/framework/996';
import {
	Avatar,
	Box,
	Button,
	Icon,
	Label,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import Paper from '../../../components/paper/Paper';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import serviceFactory from '../../../services/serviceFactory';
import UserService from '../../../services/user/UserService';
import themes from '../../../themes/themes.scss?export';
import LabelInputText from '../../../components/labelInputText/LabelInputText';
import { WebUtils } from '../../../utils/utils';
import { ApiRequestV1 } from '../../../generated/apiRequests';

enum FormKeys {
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	EMAIL = 'email',
	PHONE = 'phone',
	SLACK_ID = 'slackId',
	CURRENT_PASSWORD = 'currentPassword',
	NEW_PASSWORD = 'newPassword',
	CONFIRM_NEW_PASSWORD = 'confirmNewPassword'
}

interface ProfilePageProps {}

interface UserFormData {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	slackId: string;
}

interface PasswordFormData {
	currentPassword: string;
	newPassword: string;
	confirmNewPassword: string;
}

const ProfilePage: React.FC<ProfilePageProps> = (props) => {
	const specialCharacterPattern = new RegExp('^(?=.*[^a-zA-Z0-9])');
	const numberPattern = new RegExp('^(?=.*[0-9])');

	const userService = serviceFactory.get<UserService>('UserService');
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [user, setUser] = useRecoilState<Api.V1.User.Get.Res | undefined>(globalState.user);
	const [teams, setTeams] = useState<Api.V1.Team.Version.Get.Res[]>([]);
	const [isEditingProfile, setIsEditingProfile] = useState<boolean>(false);
	const [isEditingPassword, setIsEditingPassword] = useState<boolean>(false);
	const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
	const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
	const [showConfirmNewPassword, setShowConfirmNewPassword] = useState<boolean>(false);
	const [passwordFormIsValid, setPasswordFormIsValid] = useState<boolean>(false);

	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.FIRST_NAME, user?.firstName || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Missing first name')
			]),
			new RsFormControl<string>(FormKeys.LAST_NAME, user?.lastName || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Missing last name')
			]),
			new RsFormControl<string>(FormKeys.EMAIL, user?.email || '', [
				new RsValidator(RsValidatorEnum.EMAIL, 'Email is required')
			]),
			new RsFormControl<string>(FormKeys.PHONE, user?.phone || '', []),
			new RsFormControl<string>(FormKeys.SLACK_ID, user?.slackId || '', [])
		])
	);
	const [passwordFormGroup, setPasswordFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.CURRENT_PASSWORD, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Password is required')
			]),
			new RsFormControl<string>(FormKeys.NEW_PASSWORD, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Password is required'),
				new RsValidator(RsValidatorEnum.MIN_LENGTH, 'Must contain at least 8 characters', 8),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Must contain one symbol', (control) => {
					if (!control.value) return false;
					return specialCharacterPattern.test(control.value.toString());
				}),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Must contain one number', (control) => {
					if (!control.value) return false;
					return numberPattern.test(control.value.toString());
				})
			]),
			new RsFormControl<string>(FormKeys.CONFIRM_NEW_PASSWORD, '', [
				new RsValidator(RsValidatorEnum.CUSTOM, 'Passwords must match', (control) => {
					if (!control.value) return false;
					return (
						passwordFormGroup.get<string>(FormKeys.NEW_PASSWORD).value.toString() ===
						passwordFormGroup.get<string>(FormKeys.CONFIRM_NEW_PASSWORD).value.toString()
					);
				})
			])
		])
	);

	useEffect(() => {
		if (!version) return;
		let isCancelled = false;
		(async function getTeams() {
			const res = await ApiRequestV1.getTeamVersion({ versionId: version.id });
			if (isCancelled) return;
			setTeams(res);
		})();
		return () => {
			isCancelled = true;
		};
	}, [version]);

	useEffect(() => {
		(async function validatePasswordForm() {
			setPasswordFormIsValid(await passwordFormGroup.isValid());
		})();
	}, [passwordFormGroup]);

	if (!user) return <></>;

	async function patchUserInformation() {
		if (!user) return;
		if (!(await formGroup.isValid())) {
			rsToastify.error('Please fix the errors on required user fields', 'User Info Input Error');
			setFormGroup(formGroup.clone());
			return;
		}

		let formData = formGroup.toModel<UserFormData>();

		try {
			const { firstName, lastName, email, phone, slackId } = await ApiRequestV1.patchUser({
				firstName: formData.firstName,
				lastName: formData.lastName,
				email: formData.email,
				phone: formData.phone,
				slackId: formData.slackId,
				id: user.id
			});
			setFormGroup(formGroup.clone().updateInitialValues());
			setUser((prev) => {
				if (!prev) return;
				return { ...prev, firstName, lastName, email, phone, slackId };
			});
			rsToastify.success('Successfully updated user information', 'Updated User Information');
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to update user'), 'Update User Failed');
		}
	}

	async function updateUserPassword() {
		if (!(await passwordFormGroup.isValid())) {
			rsToastify.error('Please fix the errors on required fields', 'User Password Input Error');
			setPasswordFormGroup(passwordFormGroup.clone());
			return;
		}

		let formData = passwordFormGroup.toModel<PasswordFormData>();

		try {
			await userService.updateUserPassword(formData.currentPassword, formData.confirmNewPassword);
			rsToastify.success('Password updated successfully', 'Update Success');
			passwordFormGroup.resetToInitialValue();
			setIsEditingPassword(false);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to update password'), 'Password update failed');
		}
	}

	function updateForm(control: RsFormControl<string>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function updatePasswordForm(control: RsFormControl<string>) {
		setPasswordFormGroup(passwordFormGroup.clone().update(control));
	}

	function renderTeamRole(): React.ReactNode {
		if (!user) return;

		const mappedTeams = teams
			.filter((team) => team.teamMembers.find((teamRole) => teamRole.userId === user.id))
			.map((team) => team.teamMembers.find((teamRole) => teamRole.userId === user.id))[0];

		if (!mappedTeams) return;

		return (
			<Label variant={'body1'} weight={'regular'} mt={8}>
				{mappedTeams.teamRoleName || ''}
			</Label>
		);
	}

	function renderCompany(): React.ReactNode {
		if (!user) return;
		let companyList = user.companies.map((company) => company.name).join(', ');
		return (
			<Paper>
				<Label variant={'body1'} weight={'regular'}>
					Company
				</Label>
				<Label variant={'body1'} weight={'regular'} mt={8}>
					{companyList}
				</Label>
			</Paper>
		);
	}

	function renderProfile(): React.ReactNode {
		if (!user) return;
		if (!isEditingProfile) {
			return (
				<Paper className={'containerCenterStyling'}>
					<Box className={'profileHeaderContainer'}>
						<Label variant={'body1'} weight={'semiBold'}>
							Profile
						</Label>
						<Button look={'textPrimary'} onClick={() => setIsEditingProfile(true)}>
							<Icon iconImg={'icon-edit-solid'} />
						</Button>
					</Box>
					<Avatar widthHeight={64} name={`${user.firstName} ${user.lastName}`} />
					<Label variant={'body1'} weight={'regular'} mt={8}>
						{user.firstName} {user.lastName}
					</Label>
					{renderTeamRole()}
					<Label variant={'body1'} weight={'regular'} mt={8}>
						{user.email}
					</Label>
					<Label variant={'body1'} weight={'regular'} mt={8}>
						{user.phone}
					</Label>
				</Paper>
			);
		}

		return (
			<Paper className={'containerCenterStyling'}>
				<Box className={'profileHeaderContainer'}>
					<Label variant={'body1'} weight={'semiBold'}>
						Profile
					</Label>
					<Box display={'flex'} ml={'auto'}>
						<Button
							look={'textPrimary'}
							ml={'auto'}
							onClick={() => {
								setIsEditingProfile(false);
								patchUserInformation();
								formGroup.updateInitialValues();
							}}
							disabled={!formGroup.isModified()}
						>
							<Icon
								iconImg={'icon-check'}
								color={!formGroup.isModified() ? themes.neutralBeige600 : themes.accentSuccess}
							/>
						</Button>
						1
						<Button
							look={'textPrimary'}
							ml={'auto'}
							onClick={() => {
								setIsEditingProfile(false);
								formGroup.resetToInitialValue();
							}}
						>
							<Icon iconImg={'icon-close'} color={themes.accentError} />
						</Button>
					</Box>
				</Box>
				<Avatar widthHeight={64} name={`${user.firstName} ${user.lastName}`} />
				<LabelInputText
					label={'First Name'}
					inputMode={'text'}
					control={formGroup.get(FormKeys.FIRST_NAME)}
					updateControl={(control) => updateForm(control as RsFormControl<string>)}
					required
				/>

				<LabelInputText
					label={'Last Name'}
					inputMode={'text'}
					control={formGroup.get(FormKeys.LAST_NAME)}
					updateControl={(control) => updateForm(control as RsFormControl<string>)}
					required
				/>
				<LabelInputText
					label={'Email'}
					inputMode={'email'}
					control={formGroup.get(FormKeys.EMAIL)}
					updateControl={(control) => updateForm(control as RsFormControl<string>)}
					required
				/>
				<LabelInputText
					label={'Phone'}
					inputMode={'text'}
					type={'tel'}
					control={formGroup.get(FormKeys.PHONE)}
					maxLength={12}
					updateControl={(control) => updateForm(control as RsFormControl<string>)}
				/>
				<LabelInputText
					label={'Slack Link'}
					inputMode={'text'}
					control={formGroup.get(FormKeys.SLACK_ID)}
					updateControl={(control) => updateForm(control as RsFormControl<string>)}
				/>
			</Paper>
		);
	}

	function renderPassword(): React.ReactNode {
		if (!user) return;
		if (!isEditingPassword) {
			return (
				<Paper className={'containerCenterStyling'}>
					<Box className={'profileHeaderContainer'}>
						<Label variant={'body1'} weight={'semiBold'}>
							Password
						</Label>
						<Button look={'textPrimary'} onClick={() => setIsEditingPassword(true)}>
							<Icon iconImg={'icon-edit-solid'} />
						</Button>
					</Box>
				</Paper>
			);
		}
		return (
			<Paper className={'containerCenterStyling'}>
				<Box className={'profileHeaderContainer'}>
					<Label variant={'body1'} weight={'semiBold'}>
						Password
					</Label>
					<Box display={'flex'} ml={'auto'}>
						<Button
							look={'textPrimary'}
							ml={'auto'}
							onClick={updateUserPassword}
							disabled={!passwordFormIsValid}
						>
							<Icon
								iconImg={'icon-check'}
								color={!passwordFormIsValid ? themes.neutralBeige600 : themes.accentSuccess}
							/>
						</Button>
						<Button
							look={'textPrimary'}
							ml={'auto'}
							onClick={() => {
								setIsEditingPassword(false);
								passwordFormGroup.resetToInitialValue();
							}}
						>
							<Icon iconImg={'icon-close'} color={themes.accentError} />
						</Button>
					</Box>
				</Box>
				<LabelInputText
					label={'Current Password'}
					inputMode={'text'}
					type={showCurrentPassword ? 'text' : 'password'}
					control={passwordFormGroup.get(FormKeys.CURRENT_PASSWORD)}
					updateControl={(control) => updatePasswordForm(control as RsFormControl<string>)}
					icon={[
						{
							position: 'RIGHT',
							iconImg: showCurrentPassword ? 'icon-hide' : 'icon-show',
							color: themes.neutralBeige500,
							fontSize: 16,
							cursorPointer: true,
							onClick: () => setShowCurrentPassword(!showCurrentPassword)
						}
					]}
					required
				/>

				<LabelInputText
					label={'New Password'}
					inputMode={'text'}
					type={showNewPassword ? 'text' : 'password'}
					control={passwordFormGroup.get(FormKeys.NEW_PASSWORD)}
					updateControl={(control) => updatePasswordForm(control as RsFormControl<string>)}
					icon={[
						{
							position: 'RIGHT',
							iconImg: showNewPassword ? 'icon-hide' : 'icon-show',
							color: themes.neutralBeige500,
							fontSize: 16,
							cursorPointer: true,
							onClick: () => setShowNewPassword(!showNewPassword)
						}
					]}
					required
				/>
				<LabelInputText
					label={'Confirm New Password'}
					inputMode={'text'}
					type={showConfirmNewPassword ? 'text' : 'password'}
					control={passwordFormGroup.get(FormKeys.CONFIRM_NEW_PASSWORD)}
					updateControl={(control) => updatePasswordForm(control as RsFormControl<string>)}
					icon={[
						{
							position: 'RIGHT',
							iconImg: showConfirmNewPassword ? 'icon-hide' : 'icon-show',
							color: themes.neutralBeige500,
							fontSize: 16,
							cursorPointer: true,
							onClick: () => setShowConfirmNewPassword(!showConfirmNewPassword)
						}
					]}
					required
				/>
			</Paper>
		);
	}

	return (
		<Page className={'rsProfilePage'}>
			{renderCompany()}
			{renderProfile()}
			{renderPassword()}

			<Button
				className={'logout'}
				look={'outlinedPrimary'}
				fullWidth
				onClick={() => {
					const userService = serviceFactory.get<UserService>('UserService');
					userService.logout();
				}}
			>
				Log Out
			</Button>

			{/* TODO: Need to allow mocking from internal and discuss how to implement this when mocking */}
			{!user && (
				<Button
					className={'logout'}
					look={'containedSecondary'}
					fullWidth
					mt={'16px'}
					onClick={() => {
						rsToastify.warning('', 'Not Implemented');
					}}
				>
					Stop Mocking
				</Button>
			)}
		</Page>
	);
};

export default ProfilePage;
