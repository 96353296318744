import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import './TaskDetailsPage.scss';
import { Page } from '@redskytech/framework/996';
import PageHeader from '../../../components/pageHeader/PageHeader';
import router from '../../../utils/router';
import {
	Box,
	Button,
	Label,
	popupController,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum,
	Select
} from '@redskytech/framework/ui';
import LoadingPage from '../../common/loadingPage/LoadingPage';
import ErrorPage from '../../common/errorPage/ErrorPage';
import PaperHeaderBar from '../../../components/paperHeaderBar/PaperHeaderBar';
import LabelInputText from '../../../components/labelInputText/LabelInputText';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import useWarnOnUnsavedChanges from '../../../customHooks/useWarnOnUnsavedChanges';
import { DateUtils, WebUtils } from '../../../utils/utils';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import LoadingPopup, { LoadingPopupProps } from '../../../popups/loadingPopup/LoadingPopup';
import themes from '../../../themes/themes.scss?export';
import LabelInputTextarea from '../../../components/labelInputTextarea/LabelInputTextarea';
import LabelSelect from '../../../components/labelSelect/LabelSelect';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import { RoutePaths } from '../../../routes';
import ConfirmationPopup, { ConfirmationPopupProps } from '../../../popups/confirmationPopup/ConfirmationPopup';

interface TaskDetailsPageProps {}

enum FormKeys {
	TITLE = 'name',
	DESCRIPTION = 'description',
	STATUS = 'status',
	TAGS = 'tags',
	ASSIGNED_TO = 'assignedUserId',
	DUE_ON = 'dueOn'
}

interface FormData extends Api.V1.Task.Patch.Req {
	tags?: string[];
}

interface NewTaskData extends Api.V1.Task.Post.Req {
	tags?: string[];
}

interface TeamMemberDetails {
	userId: number;
	firstName: string;
	lastName: string;
	teamRoleNames: string[];
}

const TaskDetailsPage: React.FC<TaskDetailsPageProps> = (props) => {
	const isEditing = router.getCurrentPath() === '/task/details';
	const { taskId } = router.getQueryParams<{ taskId: number }>([
		{
			key: 'ti',
			type: 'integer',
			alias: 'taskId',
			default: 0
		}
	]);
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [taskNotes, setTaskNotes] = useState<Api.V1.TaskNote.All.Get.Res[]>([]);
	const [taskDetails, setTaskDetails] = useState<Api.V1.Task.Paged.Get.Res | undefined>();
	const [fatalErrorMessage, setFatalErrorMessage] = useState<string>('');

	const [assignmentOptions, setAssignmentOptions] = useState<{ label: string; value: number }[]>([]);

	const [tagOptions, setTagOptions] = useState<{ label: string; value: string }[]>([]);
	const [tagsForTask, setTagsForTask] = useState<string[]>([]);

	const [leaveANoteText, setLeaveANoteText] = useState<string>('');

	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.TITLE, '', [new RsValidator(RsValidatorEnum.REQ, 'Title is required')]),
			new RsFormControl<string>(FormKeys.DESCRIPTION, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Description is required')
			]),
			new RsFormControl<string>(FormKeys.STATUS, 'NOT_COMPLETED', [
				new RsValidator(RsValidatorEnum.REQ, 'Status is required')
			]),
			new RsFormControl<string[]>(FormKeys.TAGS, []),
			new RsFormControl<number>(FormKeys.ASSIGNED_TO, 0),
			new RsFormControl<string>(FormKeys.DUE_ON, '')
		])
	);

	const statusOptions = useMemo<{ label: string; value: string }[]>(() => {
		return [
			{ label: 'Completed', value: 'COMPLETED' },
			{ label: 'In Progress', value: 'NOT_COMPLETED' },
			{ label: 'Hidden', value: 'HIDDEN' }
		];
	}, []);

	useEffect(() => {
		(async function getTagOptions() {
			try {
				let res = await ApiRequestV1.getTagUniqueAll();
				setTagOptions(res.map((tag) => ({ label: tag.name, value: tag.name })));
			} catch (e) {
				setFatalErrorMessage(WebUtils.getRsErrorMessage(e, 'Error getting task details'));
			}
		})();
	}, []);

	useEffect(() => {
		if (!isEditing || !taskId) return;
		(async function getTaskDetails() {
			try {
				// Get all tags that have been used by this customer before
				let responses: [
					Promise<RedSky.RsPagedResponseData<Api.V1.Tag.Paged.Get.Res[]>>,
					Promise<RedSky.RsPagedResponseData<Api.V1.Task.Paged.Get.Res[]>>,
					Promise<Api.V1.TaskNote.All.Get.Res[]>
				] = [
					ApiRequestV1.getTagPaged({
						perPage: 25,
						page: 1,
						filter: `(column:tag.taskId,value:${taskId})`
					}),
					ApiRequestV1.getTaskPaged({
						filter: `(column:task.id,value:${taskId})`,
						versionId: version?.id || 0
					}),
					ApiRequestV1.getTaskNoteAll({ taskId })
				];

				let res = await Promise.all(responses);
				const [tagsForTaskRes, taskDetailsRes, taskNotesRes] = res;

				setTagsForTask(tagsForTaskRes.data.map((tag) => tag.name));

				if (taskDetailsRes.data.length !== 1) {
					setFatalErrorMessage('Could not find task');
					return;
				}
				const taskData = taskDetailsRes.data[0];
				setTaskDetails(taskData);

				setTaskNotes(taskNotesRes);

				let updatedForm = formGroup.cloneDeep();
				updatedForm.get(FormKeys.TITLE).value = taskData.name;
				updatedForm.get(FormKeys.DESCRIPTION).value = taskData.description;
				updatedForm.get(FormKeys.STATUS).value = taskData.status;
				updatedForm.get(FormKeys.ASSIGNED_TO).value = taskData.assignedUserId || 0;
				if (taskData.dueOn) {
					let dueOn = new Date(taskData.dueOn);
					updatedForm.get(FormKeys.DUE_ON).value = dueOn.toISOString().split('T')[0];
				}
				updatedForm.get(FormKeys.TAGS).value = tagsForTaskRes.data.map((tag) => tag.name);
				updatedForm.updateInitialValues();
				setFormGroup(updatedForm);
			} catch (e) {
				setFatalErrorMessage(WebUtils.getRsErrorMessage(e, 'Error getting task details'));
			}
		})();
	}, [isEditing, taskId]);

	useEffect(() => {
		if (!version) return;
		(async function getAssignmentOptions() {
			try {
				let teamRes = await ApiRequestV1.getTeamVersion({ versionId: version.id });

				let teamMembers: TeamMemberDetails[] = [];
				for (let team of teamRes) {
					for (let member of team.teamMembers) {
						let existingMember = teamMembers.find((m) => m.userId === member.userId);
						if (existingMember) {
							existingMember.teamRoleNames.push(member.teamRoleName);
						} else {
							teamMembers.push({
								userId: member.userId,
								firstName: member.userFirstName,
								lastName: member.userLastName,
								teamRoleNames: [member.teamRoleName]
							});
						}
					}
				}
				setAssignmentOptions(
					teamMembers.map((member) => ({
						label: `${member.firstName} ${member.lastName} - ${member.teamRoleNames.join(', ')}`,
						value: member.userId
					}))
				);
			} catch (e) {
				setFatalErrorMessage(WebUtils.getRsErrorMessage(e, 'Error getting team members'));
			}
		})();
	}, []);

	useWarnOnUnsavedChanges(formGroup.isModified());

	if (fatalErrorMessage) return <ErrorPage error={fatalErrorMessage} />;
	if (isEditing && !taskDetails) return <LoadingPage />;

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	async function handleSaveExistingTask() {
		if (!taskDetails || !version) return;

		if (!(await formGroup.isValid())) {
			setFormGroup(formGroup.clone());
			rsToastify.error('Please fix the errors in the inputs.', 'Invalid Inputs');
			return;
		}

		let updatedValues = formGroup.toChangedModel<FormData>();

		// Tags need to be handled differently as we call a different endpoint
		if (updatedValues.tags !== undefined) {
			let newTags: string[] = updatedValues.tags.filter((item) => {
				return !tagsForTask.includes(item);
			});
			let deleteTags: string[] = tagsForTask.filter((item) => {
				return !updatedValues.tags!.includes(item);
			});
			await ApiRequestV1.putTagMany({
				companyId: version.companyId,
				createTagList: newTags,
				deleteTagList: deleteTags,
				id: taskId,
				type: 'TASK'
			});

			setTagsForTask(updatedValues.tags);
			delete updatedValues.tags;
		}

		if (Object.keys(updatedValues).length === 0) {
			setFormGroup(formGroup.clone().updateInitialValues());
			popupController.close(LoadingPopup);
			rsToastify.success('Task updated successfully');
			return;
		}

		updatedValues.id = taskDetails.id;

		try {
			popupController.open<LoadingPopupProps>(LoadingPopup, {});
			await ApiRequestV1.patchTask(updatedValues);
			setFormGroup(formGroup.clone().updateInitialValues());
			popupController.close(LoadingPopup);
			rsToastify.success('Task updated successfully');
		} catch (e) {
			popupController.close(LoadingPopup);
			setFatalErrorMessage(WebUtils.getRsErrorMessage(e, 'Error saving task'));
		}
	}

	function getJustBeforeMidnightTime(date: Date): string {
		return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59).toISOString();
	}

	async function handleCreateNewTask() {
		if (!version) return;
		if (!(await formGroup.isValid())) {
			setFormGroup(formGroup.clone());
			rsToastify.error('Please fix the errors in the inputs.', 'Invalid Inputs');
			return;
		}

		let values = formGroup.toModel<NewTaskData>();

		// Tags need to be handled differently as we call a different endpoint
		let newTags: string[] = [];
		if (values.tags !== undefined) {
			newTags = values.tags;
			delete values.tags;
		}

		// dueOn could be an empty string if the user didn't enter a value
		if (values.dueOn) values.dueOn = getJustBeforeMidnightTime(DateUtils.dateFromString(values.dueOn));
		else delete values.dueOn;

		if (!values.assignedUserId) delete values.assignedUserId;

		try {
			popupController.open<LoadingPopupProps>(LoadingPopup, {});
			let newTaskRes = await ApiRequestV1.postTask({
				...values,
				versionId: version.id,
				companyId: version.companyId
			});
			await ApiRequestV1.putTagMany({
				companyId: version.companyId,
				deleteTagList: [],
				createTagList: newTags,
				id: newTaskRes.id,
				type: 'TASK'
			});
			rsToastify.success('Task created successfully');
			setFormGroup(formGroup.clone().updateInitialValues());

			// We need to wait a bit to allow the form control to reset, otherwise we will get a warning on leaving the page
			setTimeout(() => {
				router.navigate<RoutePaths>('/task/list').catch(console.error);
			}, 50);
		} catch (e) {
			setFatalErrorMessage(WebUtils.getRsErrorMessage(e, 'Error creating task'));
		}
		popupController.close(LoadingPopup);
	}

	async function handleAddNote() {
		if (!taskDetails) return;

		try {
			let res = await ApiRequestV1.postTaskNote({
				taskId: taskDetails.id,
				message: leaveANoteText,
				companyId: taskDetails.companyId
			});
			setLeaveANoteText('');
			rsToastify.success('Note added successfully', 'Success');
			setTaskNotes((notes) => {
				return [res, ...notes];
			});
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error adding note'), 'Error');
		}
	}

	async function handleDeleteTask() {
		popupController.open<ConfirmationPopupProps>(ConfirmationPopup, {
			headerLabel: 'Delete Task',
			label: 'Are you sure you want to delete this task?',
			acceptLabel: 'Delete',
			rejectLabel: 'Cancel',
			onAccept: async () => {
				try {
					popupController.open<LoadingPopupProps>(LoadingPopup, {});
					await ApiRequestV1.deleteTask({ id: taskId });
					rsToastify.success('Task deleted successfully');
					setFormGroup(formGroup.clone().updateInitialValues());

					// We need to wait a bit to allow the form control to reset, otherwise we will get a warning on leaving the page
					setTimeout(() => {
						popupController.close(LoadingPopup);
						router.navigate<RoutePaths>('/task/list').catch(console.error);
					}, 50);
				} catch (e) {
					popupController.close(LoadingPopup);
					rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error deleting task'), 'Server Error');
				}
			}
		});
	}

	function handleAddTagOption(newOption: string) {
		let updatedFormGroup = formGroup.cloneDeep();
		let tagsControl = updatedFormGroup.get<string[]>(FormKeys.TAGS);
		tagsControl.value = [...tagsControl.value, newOption];
		setFormGroup(updatedFormGroup);
		setTagOptions([...tagOptions, { label: newOption, value: newOption }]);
	}

	function isOverdue(): boolean {
		if (formGroup.get(FormKeys.STATUS).value !== 'NOT_COMPLETED') return false;
		let dueOn = new Date(formGroup.get<string>(FormKeys.DUE_ON).value!);
		return dueOn < new Date();
	}

	function renderCreateButtons() {
		if (isEditing) return null;
		return (
			<Box display={'flex'} gap={24}>
				<Button look={'outlinedPrimary'} onClick={() => router.navigate<RoutePaths>('/task/list')}>
					Discard
				</Button>
				<Button look={'containedPrimary'} onClick={handleCreateNewTask}>
					Create
				</Button>
			</Box>
		);
	}

	function renderNotes() {
		return taskNotes.map((note) => {
			return (
				<Box key={note.id} className={'taskNote'}>
					<Label variant={'caption2'} weight={'regular'} color={themes.neutralBeige500}>
						{DateUtils.displayFriendlyDateTime(note.createdOn)}
					</Label>
					<Label variant={'body2'} weight={'semiBold'}>
						{note.userFirstName} {note.userLastName}
					</Label>
					<Label variant={'caption1'} weight={'regular'}>
						{note.message}
					</Label>
				</Box>
			);
		});
	}

	return (
		<Page className={'rsTaskDetailsPage'}>
			<PageHeader
				title={isEditing ? 'Edit Task' : 'New Task'}
				onBack={() => {
					router.back('/task/list');
				}}
				isModified={isEditing && formGroup.isModified()}
				onSave={handleSaveExistingTask}
				onCancel={() => {
					setFormGroup(formGroup.cloneDeep().resetToInitialValue());
				}}
				rightNode={renderCreateButtons()}
			/>

			<Box padding={32} className={'scrolledContent'}>
				<Box flex={'2'}>
					<PaperHeaderBar title={'Task Information'}>
						<LabelInputText
							label={'Title'}
							inputMode={'text'}
							control={formGroup.get(FormKeys.TITLE)}
							updateControl={handleUpdateControl}
							required
							mb={32}
						/>
						<LabelInputTextarea
							label={'Description'}
							inputMode={'text'}
							control={formGroup.get(FormKeys.DESCRIPTION)}
							updateControl={handleUpdateControl}
							required
							mb={32}
						/>
						<LabelSelect<{ label: string; value: string }, true>
							label={'Tags'}
							placeholder={'Begin typing or select tags'}
							isCreatable
							isMulti
							options={tagOptions}
							control={formGroup.get(FormKeys.TAGS)}
							updateControl={handleUpdateControl}
							onCreateOption={handleAddTagOption}
						/>
						<Label ml={8} mt={4} color={themes.neutralBeige500} variant={'caption1'} weight={'regular'}>
							Add multiple tags at once by hitting enter after each tag
						</Label>
					</PaperHeaderBar>
					{taskId && (
						<PaperHeaderBar title={'Notes'}>
							{renderNotes()}
							<Box className={'noteInput'}>
								<LabelInputText
									label={'Note'}
									inputMode={'text'}
									placeholder={'Leave a note'}
									value={leaveANoteText}
									onKeyDown={(keyEvent) => {
										// Handle enter key
										if (keyEvent.key === 'Enter' && leaveANoteText.length > 0) {
											handleAddNote().catch(console.error);
										}
									}}
									onChange={(newValue) => setLeaveANoteText(newValue)}
								/>
								<Button
									look={'containedPrimary'}
									onClick={handleAddNote}
									disabled={!leaveANoteText.length}
								>
									Submit
								</Button>
							</Box>
						</PaperHeaderBar>
					)}
				</Box>
				<Box flex={'1'}>
					<PaperHeaderBar title={'Status'}>
						<Select<{ label: string; value: string }>
							control={formGroup.get(FormKeys.STATUS)}
							updateControl={handleUpdateControl}
							options={statusOptions}
							mb={24}
						/>
						{isOverdue() && (
							<Label variant={'body1'} weight={'regular'} mb={24} color={themes.accentError}>
								Overdue by{' '}
								{DateUtils.getDateDifferenceFriendly(
									new Date(formGroup.get<string>(FormKeys.DUE_ON).value!),
									new Date(),
									false
								)}
							</Label>
						)}
						<Label variant={'body1'} weight={'regular'}>
							Created on {DateUtils.displayFriendlyDateTime(taskDetails?.createdOn || new Date())}
						</Label>
					</PaperHeaderBar>
					<PaperHeaderBar title={'Organization'}>
						<LabelSelect
							label={'Assigned To'}
							options={assignmentOptions}
							mb={32}
							control={formGroup.get(FormKeys.ASSIGNED_TO)}
							updateControl={handleUpdateControl}
						/>
						<LabelInputText
							className={'dateInput'}
							label={'Due On'}
							inputMode={'text'}
							type={'date'}
							control={formGroup.get(FormKeys.DUE_ON)}
							updateControl={handleUpdateControl}
							icon={[
								{
									position: 'LEFT',
									iconImg: 'icon-calendar',
									color: themes.neutralBeige500
								}
							]}
						/>
					</PaperHeaderBar>
					{isEditing && (
						<Button mt={32} fullWidth look={'outlinedPrimary'} onClick={handleDeleteTask}>
							Delete Task
						</Button>
					)}
				</Box>
			</Box>
		</Page>
	);
};

export default TaskDetailsPage;
