import { Service } from './Service';
import UserService from './user/UserService';
import CompanyService from './company/CompanyService.js';

type ServiceKey = 'CompanyService' | 'UserService';

class ServiceFactory {
	private services: { [key: string]: Service } = {};

	create() {
		// Add new models here to the factory
		this.services['CompanyService'] = new CompanyService();
		this.services['UserService'] = new UserService();

		for (let key in this.services) {
			this.services[key].start();
		}
	}

	get<T extends Service>(name: ServiceKey): T {
		return this.services[name] as T;
	}
}

let serviceFactory = new ServiceFactory();
export default serviceFactory;

// TEMP
window.sf = serviceFactory;
