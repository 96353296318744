import * as React from 'react';
import './MeetingCategoryPage.scss';
import themes from '../../../themes/themes.scss?export';
import { Page } from '@redskytech/framework/996';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import { useEffect, useState } from 'react';
import { Box, Icon, InputText, Label, RsFormControl, RsFormGroup, rsToastify } from '@redskytech/framework/ui';
import MeetingItem from '../../../components/meetingItem/MeetingItem';
import SectionWithHeader from '../../../components/sectionWithHeader/SectionWithHeader';
import classNames from 'classnames';
import Pagination from '../../../components/pagination/Pagination';
import router from '../../../utils/router';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { WebUtils } from '../../../utils/utils';
import MeetingRecurring from '../../../components/meetingRecurring/MeetingRecurring';
import RsPagedResponseData = RedSky.RsPagedResponseData;
import LoadingPage from '../../common/loadingPage/LoadingPage.js';

enum FormKeys {
	SEARCH = 'Search'
}

const MeetingCategoryPage: React.FC = () => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [meetingTypes, setMeetingTypes] = useRecoilState<Api.V1.MeetingType.All.Get.Res[]>(globalState.meetingTypes);
	const [currentMeetingType, setCurrentMeetingType] = useState<Api.V1.MeetingType.All.Get.Res | undefined>();
	const [meetings, setMeetings] = useState<RsPagedResponseData<Api.V1.Meeting.Paged.Get.Res[]>>();
	const [searchGroup, setSearchGroup] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl<string>(FormKeys.SEARCH, '', [])])
	);
	const [pageNumber, setPageNumber] = useState<number>(1);

	const searchValue = searchGroup.get<string>(FormKeys.SEARCH).value;

	const params = router.getQueryParams<{ meetingTypeId: number }>([
		{
			key: 'ti',
			default: '',
			type: 'integer',
			alias: 'meetingTypeId'
		}
	]);

	useEffect(() => {
		let isCancelled = false;
		(async function getMeetingTypes() {
			let meetingType = meetingTypes.find((meetingType) => meetingType.id === params.meetingTypeId);
			if (meetingType) {
				setCurrentMeetingType(meetingType);
			} else {
				const res = await ApiRequestV1.getMeetingTypeAll();
				if (isCancelled) return;
				setMeetingTypes(res);
				meetingType = res.find((meetingType) => meetingType.id === params.meetingTypeId);
				if (!meetingType) {
					rsToastify.error(
						'The meeting category requested was unfound, returning to meetings view',
						'No category found'
					);
					return await router.navigate('/meeting');
				}
				setCurrentMeetingType(meetingType);
			}
		})();
		return () => {
			isCancelled = true;
		};
	}, [params.meetingTypeId]);

	useEffect(() => {
		if (!version) return;

		(async function searchMeetings() {
			let searchQuery =
				searchGroup.get<string>(FormKeys.SEARCH).value !== ''
					? `and((column:title,value:${searchValue},type:contains)or(column:description,value:${searchValue},type:contains)or(column:occurredOn,value:${searchValue},type:contains))`
					: '';
			try {
				const result = await ApiRequestV1.getMeetingPaged({
					perPage: 10,
					page: pageNumber,
					sortBy: 'occurredOn',
					sortOrder: 'ASC',
					filter: `(column:occurredOn,value:${new Date().toISOString()},type:greaterThanEqual)and(column:meetingTypeId,value:${
						params.meetingTypeId
					},type:exact)and(column:status,value:VISIBLE)${searchQuery}`,
					versionId: version.id
				});
				setMeetings(result);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Search Text Failed'));
			}
		})();
	}, [searchGroup, pageNumber]);

	function renderMeetingReminders(): React.ReactNode {
		if (!meetings) return;
		return meetings.data
			.filter(
				(meeting) =>
					new Date(meeting.occurredOn).getMonth() === new Date().getMonth() &&
					new Date(meeting.occurredOn).getDate() === new Date().getDate()
			)
			.map((meeting) => {
				return (
					<MeetingItem
						key={meeting.id}
						meeting={meeting}
						isMeetingReminder
						containColor
						onClick={() => router.navigate('/meeting/external/details?id=' + meeting.id)}
					/>
				);
			});
	}

	function renderSearch(): React.ReactNode {
		return (
			<SectionWithHeader
				title={`${currentMeetingType?.name || 'Meeting'}s`}
				className={classNames('searchContainer', {
					search: searchGroup.get<string>(FormKeys.SEARCH).value.toString() !== ''
				})}
				viewAllLink={'/meeting/view?ti=' + params.meetingTypeId}
				viewAllText={'Filter'}
				key={'SearchContainerKey'}
			>
				<InputText
					inputMode={'text'}
					placeholder={`Search ${currentMeetingType?.name || 'Meeting'}s`}
					icon={[{ position: 'LEFT', iconImg: 'icon-search' }]}
					control={searchGroup.get(FormKeys.SEARCH)}
					updateControl={(form) => setSearchGroup(searchGroup.clone().update(form))}
				/>
				{searchValue === '' && <MeetingRecurring />}
				{searchValue === '' && renderMeetingReminders()}
			</SectionWithHeader>
		);
	}

	function renderMeetingItems() {
		if (!meetings) return;
		return meetings.data.map((meeting) => {
			return (
				<MeetingItem
					key={meeting.id}
					meeting={meeting}
					onClick={() => router.navigate('/meeting/external/details?id=' + meeting.id)}
				/>
			);
		});
	}

	function renderNoresults() {
		return (
			<Box className={'noResults'}>
				<Icon iconImg={'icon-search'} fontSize={80} color={themes.neutralBeige700} />
				<Label variant={'body1'} weight={'semiBold'}>
					No results found,
				</Label>
				<Label variant={'body1'} weight={'regular'}>
					try a different search.
				</Label>
			</Box>
		);
	}

	if (!meetings) return <LoadingPage />;

	return (
		<Page className={'rsMeetingCategoryPage'}>
			{renderSearch()}
			<SectionWithHeader
				title={meetings.total + ' Result' + (meetings.total > 1 ? 's' : '')}
				className={'categoryBody'}
			>
				{meetings.total < 1 ? renderNoresults() : renderMeetingItems()}
				<Pagination
					itemsPerPage={10}
					totalItems={meetings.total}
					currentPage={pageNumber}
					changePage={(newPage) => setPageNumber(newPage)}
				/>
			</SectionWithHeader>
		</Page>
	);
};

export default MeetingCategoryPage;
