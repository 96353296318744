import * as React from 'react';
import './EditArtifactPage.scss';
import { Page } from '@redskytech/framework/996';
import PageHeader from '../../../components/pageHeader/PageHeader';
import router from '../../../utils/router';
import PaperHeaderBar from '../../../components/paperHeaderBar/PaperHeaderBar';
import LabelInputText from '../../../components/labelInputText/LabelInputText';
import LabelInputRichText from '../../../components/labelInputRichText/LabelInputRichText';
import LabelSelect from '../../../components/labelSelect/LabelSelect';
import {
	Box,
	Button,
	InputText,
	Label,
	popupController,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum,
	Select
} from '@redskytech/framework/ui';
import themes from '../../../themes/themes.scss?export';
import { useEffect, useState } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { DateUtils } from '../../../utils/utils';
import ConfirmationPopup, { ConfirmationPopupProps } from '../../../popups/confirmationPopup/ConfirmationPopup';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { StringUtils, WebUtils } from '@redskytech/framework/utils';
import LoadingPopup, { LoadingPopupProps } from '../../../popups/loadingPopup/LoadingPopup';
import useIsMounted from '../../../customHooks/useIsMounted';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState.js';

interface EditArtifactPageProps {}

enum FormKeys {
	TITLE = 'name',
	DESCRIPTION = 'description',
	TAGS = 'tags',
	STATUS = 'isActive',
	TYPE = 'type',
	LINK = 'link'
}

interface FormData extends Api.V1.Artifact.Patch.Req {
	tags?: string[];
}
const EditArtifactPage: React.FC<EditArtifactPageProps> = (props) => {
	const { artifactId } = router.getQueryParams<{ artifactId: number }>([
		{
			key: 'ai',
			default: 0,
			type: 'integer',
			alias: 'artifactId'
		}
	]);

	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [artifactDetails, setArtifactDetails] = useState<Api.V1.Artifact.Get.Res>();
	const [tagOptions, setTagOptions] = useState<{ label: string; value: string }[]>([]);
	const [tagsForArtifact, setTagsForArtifact] = useState<string[]>([]);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.TITLE, '', [new RsValidator(RsValidatorEnum.REQ, 'Title is required')]),
			new RsFormControl<string>(FormKeys.DESCRIPTION, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Description is required')
			]),
			new RsFormControl<string[]>(FormKeys.TAGS, []),
			new RsFormControl<number>(FormKeys.STATUS, 0, [new RsValidator(RsValidatorEnum.REQ, 'Status is required')]),
			new RsFormControl<string>(FormKeys.TYPE, '', [new RsValidator(RsValidatorEnum.REQ, 'Type is required')]),
			new RsFormControl<string>(FormKeys.LINK, '', [
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid URL', (control) => {
					if (!control.value) return true;
					return StringUtils.isValidUrl(control.value.toString());
				})
			])
		])
	);
	const isMounted = useIsMounted();

	const statusOptions = [
		{ label: 'Visible', value: 1 },
		{ label: 'Hidden', value: 0 }
	];
	const typeOptions = [
		{ label: 'Change Order', value: 'CHANGE_ORDER' },
		{ label: 'Document', value: 'DOCUMENT' },
		{ label: 'Link', value: 'LINK' }
	];

	useEffect(() => {
		if (!artifactId) {
			rsToastify.error('Unable to get artifact details');
			router.navigate('/artifact/list').catch(console.error);
			return;
		}
		(async function getArtifactDetails() {
			try {
				let allTags = await ApiRequestV1.getTagUniqueAll();
				let artifactRes = await ApiRequestV1.getArtifact({ id: artifactId });

				if (!isMounted) return;
				setTagOptions(allTags.map((tag) => ({ label: tag.name, value: tag.name })));
				setArtifactDetails(artifactRes);
				let updatedForm = formGroup.cloneDeep();
				updatedForm.get(FormKeys.TITLE).value = artifactRes.name;
				updatedForm.get(FormKeys.TYPE).value = artifactRes.type;
				updatedForm.get(FormKeys.DESCRIPTION).value = artifactRes.description;
				updatedForm.get(FormKeys.LINK).value = artifactRes.link || '';
				updatedForm.get(FormKeys.TAGS).value = artifactRes.tags.map((tag) => tag.name);
				updatedForm.get(FormKeys.STATUS).value = !!artifactRes.isActive ? 1 : 0;
				updatedForm.updateInitialValues();
				setFormGroup(updatedForm);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
			}
		})();
	}, [artifactId]);

	function handleUpdate(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function handleAddTagOption(newOption: string) {
		let updatedFormGroup = formGroup.cloneDeep();
		let tagsControl = updatedFormGroup.get<string[]>(FormKeys.TAGS);
		tagsControl.value = [...tagsControl.value, newOption];
		setFormGroup(updatedFormGroup);
		setTagOptions([...tagOptions, { label: newOption, value: newOption }]);
	}

	async function handleSave() {
		if (!artifactDetails || !version) return;
		if (!(await formGroup.isValid())) {
			setFormGroup(formGroup.clone());
			rsToastify.error('Please fix the errors in the inputs.', 'Invalid Inputs');
			return;
		}

		let formData = formGroup.toChangedModel<FormData>();

		if (formData.tags !== undefined) {
			let newTags: string[] = formData.tags.filter((item) => {
				return !tagsForArtifact.includes(item);
			});
			let deleteTags: string[] = tagsForArtifact.filter((item) => {
				return !formData.tags!.includes(item);
			});
			await ApiRequestV1.putTagMany({
				companyId: version.companyId,
				createTagList: newTags,
				deleteTagList: deleteTags,
				id: artifactId,
				type: 'ARTIFACT'
			});

			setTagsForArtifact(formData.tags);
			delete formData.tags;
		}

		if (Object.keys(formData).length === 0) {
			setFormGroup(formGroup.clone().updateInitialValues());
			popupController.close(LoadingPopup);
			rsToastify.success('Artifact updated successfully');
			return;
		}

		formData.id = artifactDetails.id;

		try {
			popupController.open<LoadingPopupProps>(LoadingPopup, {});
			await ApiRequestV1.patchArtifact(formData);
			setFormGroup(formGroup.clone().updateInitialValues());
			popupController.close(LoadingPopup);
			rsToastify.success('Artifact updated successfully');
		} catch (e) {
			popupController.close(LoadingPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error saving artifact'));
		}
	}

	async function handleDelete() {
		try {
			popupController.open<LoadingPopupProps>(LoadingPopup, {});
			await ApiRequestV1.deleteArtifact({ id: artifactId });
			popupController.close(LoadingPopup);
			rsToastify.success('Artifact successfully deleted');
			router.navigate('/artifact/list').catch(console.error);
		} catch (e) {
			popupController.close(LoadingPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Error deleting artifact');
		}
	}

	return (
		<Page className={'rsEditArtifactPage'}>
			<PageHeader
				title={'Edit Artifact'}
				isModified={formGroup.isModified()}
				onBack={() => {
					router.navigate('/artifact/list').catch(console.error);
				}}
				onSave={handleSave}
				onCancel={() => {
					setFormGroup(formGroup.cloneDeep().resetToInitialValue());
				}}
			/>
			<Box padding={32} className={'scrolledContent'}>
				<Box flex={'2'}>
					<PaperHeaderBar title={'Artifact Details'}>
						<LabelInputText
							label={'Title'}
							inputMode={'text'}
							control={formGroup.get(FormKeys.TITLE)}
							updateControl={handleUpdate}
							required
						/>
						<LabelInputRichText
							label={'Description'}
							control={formGroup.get(FormKeys.DESCRIPTION)}
							updateControl={handleUpdate}
							height={200}
							required
						/>
						<LabelSelect
							label={'Tags'}
							isMulti
							control={formGroup.get(FormKeys.TAGS)}
							updateControl={handleUpdate}
							onCreateOption={handleAddTagOption}
							options={tagOptions}
						/>
						<Label variant={'caption1'} weight={'regular'} color={themes.neutralBeige500} mt={4}>
							Add multiple tags at once by hitting enter after each tag
						</Label>
					</PaperHeaderBar>
				</Box>
				<Box flex={'1'}>
					<PaperHeaderBar title={'Status'}>
						<Select<{ label: string; value: number }>
							control={formGroup.get(FormKeys.STATUS)}
							updateControl={handleUpdate}
							options={statusOptions}
							mb={24}
						/>
						<Label variant={'body1'} weight={'regular'}>
							Created on {DateUtils.displayFriendlyDateTime(artifactDetails?.createdOn || new Date())}
						</Label>
					</PaperHeaderBar>
					<PaperHeaderBar title={'Organization'}>
						<LabelSelect
							label={'Type'}
							control={formGroup.get(FormKeys.TYPE)}
							updateControl={handleUpdate}
							options={typeOptions}
							required
						/>
					</PaperHeaderBar>
					<PaperHeaderBar title={'Link'}>
						<InputText
							inputMode={'text'}
							placeholder={'URL'}
							control={formGroup.get(FormKeys.LINK)}
							updateControl={handleUpdate}
						/>
					</PaperHeaderBar>
					<Button
						look={'outlinedPrimary'}
						onClick={() => {
							popupController.open<ConfirmationPopupProps>(ConfirmationPopup, {
								headerLabel: 'Delete Artifact',
								label: `Are you sure you want to delete ${
									formGroup.get<string>(FormKeys.TITLE).value
								}? This action cannot be undone.`,
								acceptLabel: 'Delete',
								rejectLabel: 'Cancel',
								onAccept: handleDelete
							});
						}}
						className={'fullWidth'}
						mt={32}
					>
						Delete Artifact
					</Button>
				</Box>
			</Box>
		</Page>
	);
};
export default EditArtifactPage;
