import * as React from 'react';
import './UserListPage.scss';
import { Page } from '@redskytech/framework/996';
import PageHeader from '../../../components/pageHeader/PageHeader';
import { Avatar, Box, Button, Icon, Label, popupController, rsToastify } from '@redskytech/framework/ui';
import ListDataTable from '../../../components/listDataTable/ListDataTable';
import { useState } from 'react';
import { Column } from 'primereact/column';
import ColumnHeader from '../../../components/listDataTable/columnHeader/ColumnHeader';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { StringUtils, WebUtils } from '../../../utils/utils';
import TableSearchHeader from '../../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import themes from '../../../themes/themes.scss?export';
import AddEditUserPopup, { AddEditUserPopupProps } from '../../../popups/addEditUserPopup/AddEditUserPopup';
import router from '../../../utils/router';

interface UserListPageProps {}

const FieldKeys: {
	[key in 'NAME' | 'EMAIL' | 'COMPANIES' | 'PHONE' | 'PERMISSION' | 'AVATAR']: { field: string; sortField: string };
} = {
	NAME: { field: 'name', sortField: 'user.firstName' },
	EMAIL: { field: 'email', sortField: 'user.email' },
	COMPANIES: { field: 'companies', sortField: 'company.name' },
	PHONE: { field: 'phone', sortField: 'user.phone' },
	PERMISSION: { field: 'userRole', sortField: 'user.role' },
	AVATAR: { field: 'userAvatarUrl', sortField: 'user.avatarUrl' }
};

const UserListPage: React.FC<UserListPageProps> = (props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeSortColumn, setActiveSortColumn] = useState<string>();
	const [globalSearch, setGlobalSearch] = useState<string>('');
	const [currentQuery, setCurrentQuery] = useState<RedSky.PageQuery>();
	const [userList, setUserList] = useState<RedSky.RsPagedResponseData<Api.V1.User.Paged.Get.Res[]>>({
		data: [],
		total: 0
	});

	async function getData(pageQuery: RedSky.PageQuery) {
		setIsLoading(true);
		setActiveSortColumn(pageQuery.sortBy);
		setCurrentQuery(pageQuery);
		try {
			if (pageQuery.sortBy === undefined) {
				delete pageQuery.sortBy;
				delete pageQuery.sortOrder;
			}
			if (!pageQuery.filter) delete pageQuery.filter;

			const res = await ApiRequestV1.getUserPaged({ ...pageQuery });
			setUserList(res);
			setIsLoading(false);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	async function handleListUpdate() {
		if (!currentQuery) return;
		await getData(currentQuery);
	}
	function renderHeaderButtons() {
		return (
			<Box display={'flex'} gap={24}>
				<Button
					look={'containedPrimary'}
					small
					onClick={() => {
						popupController.open<AddEditUserPopupProps>(AddEditUserPopup, {
							onUpdate: handleListUpdate
						});
					}}
				>
					Add User
				</Button>
			</Box>
		);
	}

	return (
		<Page className={'rsUserListPage'}>
			<PageHeader title={'Users'} rightNode={renderHeaderButtons()} />
			<Box padding={32} className={'scrolledContent'}>
				<ListDataTable
					loading={isLoading}
					onClearFilters={() => {}}
					data={userList}
					getData={getData}
					globalFilter={globalSearch}
					globalFilterFields={['user.firstName', 'user.lastName', 'user.email', 'user.phone', 'user.role']}
					onRowClick={({ data }) => {
						router.navigate(`/user/details?ui=${data.id}`);
					}}
					header={
						<TableSearchHeader searchValue={globalSearch} onChange={(value) => setGlobalSearch(value)} />
					}
				>
					<Column
						body={(data) => {
							return (
								<Avatar
									widthHeight={24}
									name={`${data.firstName} ${data.lastName}`}
									image={data.userAvatarUrl}
								/>
							);
						}}
					/>
					<Column
						field={FieldKeys.NAME.field}
						sortField={FieldKeys.NAME.sortField}
						header={
							<ColumnHeader label={'Name'} isActiveSort={activeSortColumn === FieldKeys.NAME.sortField} />
						}
						body={(data) => {
							return `${data.firstName} ${data.lastName}`;
						}}
						sortable
						filter
					/>
					<Column
						field={FieldKeys.EMAIL.field}
						sortField={FieldKeys.EMAIL.sortField}
						header={
							<ColumnHeader
								label={'Email'}
								isActiveSort={activeSortColumn === FieldKeys.EMAIL.sortField}
							/>
						}
						sortable
						filter
					/>
					<Column
						header={
							<ColumnHeader
								label={'Companies'}
								isActiveSort={activeSortColumn === FieldKeys.COMPANIES.sortField}
							/>
						}
						sortField={FieldKeys.COMPANIES.sortField}
						body={(data: Api.V1.User.Paged.Get.Res) => {
							return (
								<Label variant={'body1'} weight={'regular'}>
									{data.companies.map((company) => company.companyName).join(', ')}
								</Label>
							);
						}}
						sortable
						filter
					/>
					<Column
						field={FieldKeys.PHONE.field}
						sortField={FieldKeys.PHONE.sortField}
						header={
							<ColumnHeader
								label={'Phone'}
								isActiveSort={activeSortColumn === FieldKeys.PHONE.sortField}
							/>
						}
						sortable
						filter
					/>
					<Column
						field={FieldKeys.PERMISSION.field}
						sortField={FieldKeys.PERMISSION.sortField}
						header={
							<ColumnHeader
								label={'Permission'}
								isActiveSort={activeSortColumn === FieldKeys.PERMISSION.sortField}
							/>
						}
						body={(data) => {
							return StringUtils.camelCaseToHumanReadable(data.userRole);
						}}
						sortable
						filter
					/>
					<Column
						body={(data: Api.V1.User.Paged.Get.Res) => {
							return (
								<Icon
									iconImg={'icon-edit'}
									fontSize={16}
									color={themes.neutralBeige600}
									onClick={(event) => {
										event.stopPropagation();
										popupController.open<AddEditUserPopupProps>(AddEditUserPopup, {
											onUpdate: handleListUpdate,
											user: data
										});
									}}
								/>
							);
						}}
					/>
				</ListDataTable>
			</Box>
		</Page>
	);
};
export default UserListPage;
