import {
	StringUtils as BaseStringUtils,
	ObjectUtils as BaseObjectUtils,
	RegionUtils as BaseRegionUtils,
	WebUtils as BaseWebUtils,
	DateUtils as BaseDateUtils
} from '@redskytech/framework/utils';
import themes from '../themes/themes.scss?export';
import { TaskType } from '../components/taskItem/TaskItem';

class StringUtils extends BaseStringUtils {
	static camelCaseToHumanReadable(camelCase: string): string {
		let words = camelCase.match(/^[a-z]+|[A-Z][a-z]*/g)?.map(function (x) {
			return x[0].toUpperCase() + x.substr(1).toLowerCase();
		});
		if (!ObjectUtils.isArrayWithData(words)) return '';
		return words.join(' ');
	}
}

class ObjectUtils extends BaseObjectUtils {
	static getProgressBarColor(index: number) {
		const availableColors = [
			themes.accentSuccess,
			themes.accentInfo,
			themes.accentWarning,
			themes.accentInfoLight,
			themes.accentWarningLight
		];
		return availableColors[index];
	}

	static getTaskColor(taskType: TaskType) {
		if (taskType === 'OVERDUE') {
			return themes.accentError;
		} else if (taskType === 'IN_PROGRESS') {
			return themes.accentWarning;
		} else {
			return themes.accentSuccess;
		}
	}
}

class RegionUtils extends BaseRegionUtils {}

class WebUtils extends BaseWebUtils {}

type formatTypes = 'MMM-DD';
class DateUtils extends BaseDateUtils {
	/**
	 * Returns formatted date
	 * @param date
	 * @param format
	 * @returns Formatted date
	 */

	static formatDate(date: Date | string, format: formatTypes): string {
		if (typeof date === 'string') {
			date = new Date(date);
		}
		switch (format) {
			case 'MMM-DD':
			default:
				const month = DateUtils.getMonthName(date);
				const day = date.getDate();
				return `${month.slice(0, 3).toUpperCase()}-${day}`;
		}
	}

	/**
	 * @param comparedDate
	 * @returns boolean if date is same as today
	 */
	static isToday(comparedDate: Date): boolean {
		const today = new Date();
		return (
			comparedDate.getDate() === today.getDate() &&
			comparedDate.getMonth() === today.getMonth() &&
			comparedDate.getFullYear() === today.getFullYear()
		);
	}

	/**
	 * Returns a friendly string of the difference between two dates
	 * @param endDate
	 * @param startDate
	 * @returns Friendly string Example: 1 day, 2 hours, 3 minutes, 4 seconds
	 */
	static getDateDifferenceFriendly(endDate: Date, startDate: Date, showNegative = true): string {
		let isNegative = endDate < startDate;
		let diff = Math.abs(endDate.getTime() - startDate.getTime());
		let days = Math.floor(diff / (1000 * 60 * 60 * 24));
		diff -= days * (1000 * 60 * 60 * 24);
		let hours = Math.floor(diff / (1000 * 60 * 60));
		diff -= hours * (1000 * 60 * 60);
		let minutes = Math.floor(diff / (1000 * 60));
		diff -= minutes * (1000 * 60);
		let seconds = Math.floor(diff / 1000);

		if (days > 0) return `${showNegative && isNegative ? '-' : ''}${days} day${days > 1 ? 's' : ''}`;
		if (hours > 0) return `${showNegative && isNegative ? '-' : ''}${hours} hour${hours > 1 ? 's' : ''}`;
		if (minutes > 0) return `${showNegative && isNegative ? '-' : ''}${minutes} minute${minutes > 1 ? 's' : ''}`;
		return `${showNegative && isNegative ? '-' : ''}${seconds} second${seconds > 1 ? 's' : ''}`;
	}

	/**
	 * Returns the month name for a date object
	 * @param date
	 * @returns Month name
	 */
	static getMonthName(date: Date): string {
		return date.toLocaleString('default', { month: 'long' });
	}

	/**
	 * Returns the date in a friendly format
	 * @param date
	 * @returns Date Time in format of Today, Yesterday, or Month Day at Time
	 */
	static displayFriendlyDateTime(date: string | Date): string {
		let displayDate: Date;
		if (typeof date === 'string') displayDate = new Date(date);
		else displayDate = date;

		if (DateUtils.isSameDayAsCurrent(displayDate)) return `Today at ${DateUtils.displayTime(displayDate)}`;
		else if (DateUtils.isYesterday(displayDate)) return `Yesterday at ${DateUtils.displayTime(displayDate)}`;
		else return `${DateUtils.displayFriendlyDate(displayDate)}, at ${DateUtils.displayTime(displayDate)}`;
	}

	/**
	 * Returns the date in a friendly format
	 * @param date
	 * @returns Date Time in format of Today, Yesterday, or Month Day at Time
	 */
	static displayFriendlyTime(date: string | Date): string {
		let displayDate: Date;
		if (typeof date === 'string') displayDate = new Date(date);
		else displayDate = date;

		if (DateUtils.isSameDayAsCurrent(displayDate)) return `at ${DateUtils.displayTime(displayDate)} Today`;
		else return `${DateUtils.displayFriendlyDate(displayDate)}, at ${DateUtils.displayTime(displayDate)}`;
	}

	/**
	 * Returns the date in a friendly format with short month name
	 * @param date
	 * @returns Date in format of Short Month Day Year
	 */
	static displayFriendlyDate(date: Date): string {
		return `${DateUtils.getMonthName(date).substr(0, 3)} ${date.getDate()} ${date.getFullYear()}`;
	}

	/**
	 * Returns a date string formatted for inputs with date/time types
	 * @param isoString
	 * @returns string in YYYY-MM-DDTHH:MM format
	 */
	static convertIsoStringToHtmlDateInputString(isoString: string): string {
		let dateArray = isoString.split('T');
		dateArray[1] = dateArray[1].slice(0, 5);
		return dateArray.join('T');
	}
}

export { StringUtils, ObjectUtils, RegionUtils, WebUtils, DateUtils };
