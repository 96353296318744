import * as React from 'react';
import { useState } from 'react';
import './AddEditTeamRolePopup.scss';
import {
	Box,
	Button,
	Popup,
	popupController,
	PopupProps,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import LabelInputText from '../../components/labelInputText/LabelInputText';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import LabelInputRichText from '../../components/labelInputRichText/LabelInputRichText';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { WebUtils } from '../../utils/utils';

export interface AddEditTeamRolePopupProps extends PopupProps {
	teamRole?: Api.V1.TeamRole.Paged.Get.Res;
	onChange: () => void;
}

enum FormKeys {
	NAME = 'name',
	DESCRIPTION = 'description'
}

const AddEditTeamRolePopup: React.FC<AddEditTeamRolePopupProps> = (props) => {
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.NAME, props.teamRole?.name || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Name is required')
			]),
			new RsFormControl<string>(FormKeys.DESCRIPTION, props.teamRole?.description || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Description is required')
			])
		])
	);

	function handleUpdate(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function handleClose() {
		popupController.close(AddEditTeamRolePopup);
	}

	async function handleDeleteTeamRole() {
		if (!props.teamRole) return;
		try {
			await ApiRequestV1.deleteTeamRole({ id: props.teamRole.id });
			rsToastify.success('Team Role Deleted', 'Success');
			props.onChange();
			popupController.close(AddEditTeamRolePopup);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Server Error'), 'Error');
		}
	}

	async function handleSaveTeamRole() {
		if (!(await formGroup.isValid())) {
			rsToastify.error('Please fix the errors in the inputs', 'Input Error');
			setFormGroup(formGroup.clone());
			return;
		}

		let formData = formGroup.toModel<{ name: string; description: string }>();

		try {
			if (props.teamRole) {
				await ApiRequestV1.patchTeamRole({ id: props.teamRole.id, ...formData });
				rsToastify.success('Team Role Updated', 'Success');
			} else {
				await ApiRequestV1.postTeamRole(formData);
				rsToastify.success('Team Role Created', 'Success');
			}
			props.onChange();
			popupController.close(AddEditTeamRolePopup);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Server Error'), 'Error');
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsAddEditTeamRolePopup'}>
				<PopupHeader title={`${!!props.teamRole?.id ? 'Edit' : 'Add'} Team Role`} onClose={handleClose} />
				<Box className={'content'}>
					<LabelInputText
						label={'Role'}
						inputMode={'text'}
						required
						control={formGroup.get<string>(FormKeys.NAME)}
						updateControl={handleUpdate}
					/>
					<LabelInputRichText
						label={'Description'}
						control={formGroup.get<string>(FormKeys.DESCRIPTION)}
						updateControl={handleUpdate}
						height={300}
					/>
					<Box className={'buttonGroup'}>
						{!!props.teamRole && (
							<Button
								look={'iconSecondary'}
								icon={[
									{
										iconImg: 'icon-trash',
										position: 'LEFT',
										fontSize: 16
									}
								]}
								onClick={handleDeleteTeamRole}
							/>
						)}
						<Button look={'outlinedPrimary'} fullWidth onClick={handleClose}>
							Cancel
						</Button>
						<Button
							look={'containedPrimary'}
							fullWidth
							disabled={!formGroup.isModified()}
							onClick={handleSaveTeamRole}
						>
							{!!props.teamRole ? 'Save' : 'Create'}
						</Button>
					</Box>
				</Box>
			</Box>
		</Popup>
	);
};

export default AddEditTeamRolePopup;
