import * as React from 'react';
import './TabBarButton.scss';
import classNames from 'classnames';

import { Box, Icon, Label } from '@redskytech/framework/ui';

interface TabBarButtonProps {
	isSelected: boolean;
	iconImg: string;
	onClick: () => void;
	labelText: string;
}

const TabBarButton: React.FC<TabBarButtonProps> = (props) => {
	return (
		<Box className={'rsTabBarButton'} onClick={props.onClick}>
			<Icon className={classNames({ selected: props.isSelected })} iconImg={props.iconImg} fontSize={24} />
			<Label className={classNames({ selected: props.isSelected })} variant={'caption1'} weight={'regular'}>
				{props.labelText}
			</Label>
		</Box>
	);
};

export default TabBarButton;
