import * as React from 'react';
import './ProgressBar.scss';
import { Box, Icon, Label } from '@redskytech/framework/ui';
import classNames from 'classnames';
import { NumberUtils, ObjectUtils } from '@redskytech/framework/utils';
import themes from '../../themes/themes.scss?export';

export type BarTypeBehavior = 'PERCENTAGE' | 'AMOUNT_SPENT';
type BarHeight = 'LARGE' | 'SMALL' | number;

export interface ProgressBarProps {
	barType: BarTypeBehavior;
	barHeight: BarHeight;
	progressBarSections: {
		percentageOrAmount: number;
		sectionColor: string;
	}[];
	budget?: {
		totalBudget: number;
		totalSpent: number;
	};
	markerPercentage?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
	function handleBarSpacing(currentValue: number) {
		if (props.barType === 'PERCENTAGE') return currentValue;
		if (!props.budget) return;
		const currentValuePercentage = (currentValue / (props.budget?.totalBudget || 1)) * 100;
		return NumberUtils.round(currentValuePercentage, 1);
	}

	function getTransparentLayerWidth() {
		let maskWidth = '';
		if (props.barType === 'PERCENTAGE') {
			maskWidth = '100%';
		} else {
			let barWidth = 0;
			props.progressBarSections.forEach(
				(item) => (barWidth += (item.percentageOrAmount / (props.budget?.totalBudget || 1)) * 100)
			);
			if (barWidth > 100) {
				maskWidth = '100%';
			} else {
				maskWidth = barWidth + '%';
			}
		}
		return maskWidth;
	}

	function getBarHeight() {
		if (props.barHeight === 'LARGE') {
			return '48px';
		} else if (props.barHeight === 'SMALL') {
			return '16px';
		} else {
			return `${props.barHeight}px`;
		}
	}

	function getProgressBorderRadius() {
		if (getTransparentLayerWidth() === '100%') {
			return '0px 24px 24px 0px';
		} else {
			return '24px 0px 0px 24px';
		}
	}

	function getMarkerPercentage() {
		// Trying to make to fit inside the thermometer
		const percentage = parseInt(props.markerPercentage || '0');
		if (percentage <= 2) {
			return '2%';
		} else if (percentage >= 91) {
			return '91%';
		} else {
			return props.markerPercentage + '%';
		}
	}

	function renderProgressAsOverSpent(sectionColor: string) {
		if (!props.budget) return;
		const spentValue = NumberUtils.round((props.budget.totalBudget / props.budget.totalSpent) * 100, 1);

		return (
			<Box className={'barContainer'} style={{ height: getBarHeight() }}>
				<Box
					style={{
						position: 'absolute',
						width: spentValue + '%',
						height: getBarHeight(),
						zIndex: 35
					}}
					bgColor={sectionColor}
				/>
				<Box
					style={{
						position: 'absolute',
						zIndex: 34,
						width: '100%',
						height: getBarHeight()
					}}
					bgColor={themes.accentError}
				/>
				<Box
					className={'barTransparentMask'}
					style={{
						position: 'absolute',
						height: getBarHeight(),
						zIndex: 36,
						width: '100%',
						borderRadius: getProgressBorderRadius()
					}}
				/>
			</Box>
		);
	}

	function renderProgressSections() {
		return props.progressBarSections.map((section, index) => {
			return (
				<Box
					key={section.percentageOrAmount + index}
					className={classNames('progressSection')}
					style={{
						width: handleBarSpacing(section.percentageOrAmount) + '%',
						height: getBarHeight()
					}}
					bgColor={section.sectionColor}
				/>
			);
		});
	}

	return (
		<Box className={'rsProgressBar'}>
			{props.budget && props.budget.totalSpent > props.budget.totalBudget ? (
				renderProgressAsOverSpent(props.progressBarSections[0].sectionColor)
			) : (
				<Box
					className={classNames('barContainer', {
						outlined:
							!ObjectUtils.isArrayWithData(props.progressBarSections) ||
							props.progressBarSections.every((section) => section.percentageOrAmount === 0)
					})}
					style={{ height: getBarHeight() }}
				>
					{renderProgressSections()}
					<Box
						className={classNames('barTransparentMask', {
							outlined:
								!ObjectUtils.isArrayWithData(props.progressBarSections) ||
								props.progressBarSections.every((section) => section.percentageOrAmount === 0)
						})}
						style={{
							height: getBarHeight(),
							width: getTransparentLayerWidth(),
							borderRadius: getProgressBorderRadius()
						}}
					/>
					<Box
						className={classNames('markerContainer', { show: props.markerPercentage })}
						style={{
							left: getMarkerPercentage(),
							height: getBarHeight()
						}}
					>
						<Box className={'markerTextBox'}>
							<Label variant={'caption2'} weight={'semiBold'}>
								{props.markerPercentage}%
							</Label>
						</Box>
						<Box className={'marker'} />
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default ProgressBar;
