import * as React from 'react';
import './LabelInputRichText.scss';
import { Editor } from '@tinymce/tinymce-react';
import { Box, Label, RsFormControl } from '@redskytech/framework/ui';
import classNames from 'classnames';
import { useRef } from 'react';
import cloneDeep from 'lodash.clonedeep';

interface LabelInputRichTextProps {
	label: string | React.ReactNode;
	control: RsFormControl<string>;
	updateControl: (control: RsFormControl<string>) => void;
	height?: number;
	labelColor?: string;
	required?: boolean;
	mb?: number;
}

const LabelInputRichText: React.FC<LabelInputRichTextProps> = (props) => {
	const tinyMceRef = useRef<any | null>(null);

	return (
		<Box className={'rsLabelInputRichText'} mb={props.mb}>
			<Label
				className={classNames({ required: props.required })}
				variant={'body1'}
				weight={'regular'}
				mb={4}
				color={props.labelColor}
			>
				{props.label}
			</Label>
			<Editor
				tinymceScriptSrc={'/tinymce/js/tinymce/tinymce.min.js'}
				onInit={(evt, editor) => (tinyMceRef.current = editor)}
				value={props.control.value.toString() || ''}
				onEditorChange={(value, textEditor) => {
					let updatedControl = cloneDeep(props.control);
					updatedControl.value = textEditor.getContent({ format: 'raw' });
					props.updateControl(updatedControl);
				}}
				init={{
					menubar: true,
					resize: false,
					height: props.height || 'auto',
					plugins: [
						'advlist autolink lists link image charmap print preview anchor importcss',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime media table paste code help wordcount'
					],
					toolbar: `undo redo | formatselect | bullist numlist`
				}}
			/>
		</Box>
	);
};

export default LabelInputRichText;
