import * as React from 'react';
import './SprintDataSection.scss';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { useEffect, useState } from 'react';
import { Box, Icon, Label, rsToastify } from '@redskytech/framework/ui';
import { DateUtils, WebUtils } from '../../utils/utils';
import Paper from '../../components/paper/Paper';
import themes from '../../themes/themes.scss?export';

interface SprintDataSectionProps {}

// TODO: Need to figure out a way to identify which is the current sprint. Possibly through date range of what the current sprint encompasses?
const SprintDataSection: React.FC<SprintDataSectionProps> = () => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [sprint, setSprint] = useState<CustomTypes.Sprint>();

	useEffect(() => {
		if (!version) return;
		let isCancelled = false;
		(async function getAllSprints() {
			try {
				const result = await ApiRequestV1.getSprintAll({ versionId: version.id });
				if (isCancelled) return;
				setSprint(result[0]);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'could not get sprints'), 'Sprints load failure.');
			}
		})();
		return () => {
			isCancelled = true;
		};
	}, []);

	function renderSprint(): React.ReactNode {
		if (!sprint) return;
		let statusColor = themes.accentSuccess;
		if (sprint.status === 'BEHIND') {
			statusColor = themes.accentWarning;
		} else if (sprint.status === 'ON_TRACK') {
			statusColor = themes.accentInfo;
		}
		return (
			<Box key={sprint.id} id={'sprint' + sprint.id} className={'sprintCard'}>
				<Label variant={'caption1'} weight={'semiBold'} color={themes.neutralBeige600}>
					{sprint.name.toUpperCase()} STATUS
				</Label>
				<Label variant={'caption1'} weight={'semiBold'} color={statusColor}>
					{sprint.status.replace('_', ' ')}
				</Label>

				<Label variant={'caption1'} weight={'semiBold'} color={themes.neutralBeige300}>
					{sprint.completedHours} / {sprint.plannedHours} Hours {sprint.status === 'BEHIND' && 'Behind'}
				</Label>

				<Label variant={'caption2'} weight={'regular'} color={themes.neutralBeige600}>
					Updated {DateUtils.getDateDifferenceFriendly(new Date(), new Date(sprint.modifiedOn))} days ago
				</Label>
			</Box>
		);
	}

	return (
		<Paper className={'rsSprintDataSection sprintData'}>
			<Box overflow={'hidden'} width={'1060px'}>
				{renderSprint()}
			</Box>
		</Paper>
	);
};

export default SprintDataSection;
