import * as React from 'react';
import './DiscardChangesPopup.scss';
import { Box, Button, Label, Popup, popupController, PopupProps } from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';

export interface DiscardChangesPopupProps extends PopupProps {}

const DiscardChangesPopup: React.FC<DiscardChangesPopupProps> = (props) => {
	function onAccept() {
		popupController.closeAll();
	}

	function onReject() {
		popupController.close(DiscardChangesPopup);
	}

	function renderButtons() {
		return (
			<>
				<Button look={'outlinedPrimary'} onClick={onReject}>
					Cancel
				</Button>

				<Button look={'containedPrimary'} onClick={onAccept}>
					Discard
				</Button>
			</>
		);
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsDiscardChangesPopup'}>
				<PopupHeader title={'Discard Changes'} onClose={onReject} />
				<Box p={'16px 24px 24px 24px'}>
					<Label variant={'body1'} mb={24} weight={'regular'}>
						Are you sure you want to discard your changes?
					</Label>
					<Box display={'flex'} alignItems={'center'} justifyContent={'space-around'} gap={24}>
						{renderButtons()}
					</Box>
				</Box>
			</Box>
		</Popup>
	);
};
export default DiscardChangesPopup;
