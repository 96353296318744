import React from 'react';
import './ArtifactItem.scss';
import { Box, Label } from '@redskytech/framework/ui';
import router from '../../../../utils/router.js';
import RichContentDisplay from '../../../../components/richContentDisplay/RichContentDisplay.js';

interface ArtifactItemProps {
	artifact: Api.V1.Artifact.All.Get.Res['artifact'][0];
}
const ArtifactItem: React.FC<ArtifactItemProps> = (props) => {
	return (
		<Box
			className={'rsArtifactItem'}
			onClick={() => {
				router.navigate(`/artifacts/details?id=${props.artifact.id}`).catch(console.error);
			}}
		>
			<Label variant={'body2'} weight={'semiBold'}>
				{props.artifact.name}
			</Label>
			<RichContentDisplay content={props.artifact.description} />
		</Box>
	);
};

export default ArtifactItem;
