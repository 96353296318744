import * as React from 'react';
import './TaskContainer.scss';
import themes from '../../themes/themes.scss?export';
import { Box, Label } from '@redskytech/framework/ui';
import Paper from '../paper/Paper';
import router from '../../utils/router';
import { TaskType } from '../taskItem/TaskItem';
import { ObjectUtils } from '../../utils/utils';

interface TaskContainerProps {
	taskType: TaskType;
	taskCount: number;
	taskNodes: React.ReactNode;
}

const TaskContainer: React.FC<TaskContainerProps> = (props) => {
	function getDarkColor(): string {
		if (props.taskType === 'OVERDUE') {
			return themes.accentErrorDark;
		} else if (props.taskType === 'IN_PROGRESS') {
			return themes.accentWarningDark;
		} else {
			return themes.accentSuccessDark;
		}
	}

	return (
		<Paper className={'rsTaskContainer'}>
			<Box className={'paperHeader'}>
				<Box className={'taskHeader'}>
					<Label variant={'subheader2'} weight={'extraBold'}>
						<span style={{ color: ObjectUtils.getTaskColor(props.taskType) }}>
							{props.taskType.toUpperCase().replace('_', ' ')}
						</span>{' '}
						TASKS
					</Label>
					<Box
						className={'counterContainer'}
						style={{
							background: getDarkColor(),
							border: '1px solid ' + ObjectUtils.getTaskColor(props.taskType)
						}}
					>
						<Label variant={'caption2'} weight={'semiBold'}>
							{props.taskCount}
						</Label>
					</Box>
				</Box>
				<Label
					className={'viewAll'}
					variant={'caption1'}
					weight={'regular'}
					onClick={() => router.navigate(`/tasks/all?by=${props.taskType.toLowerCase().replace('_', '-')}`)}
				>
					View All
				</Label>
			</Box>
			{props.taskNodes}
		</Paper>
	);
};

export default TaskContainer;
