import * as React from 'react';
import { Icon, Label, RsDataTable, RsDataTableProps } from '@redskytech/framework/ui';
import {
	PaginatorCurrentPageReportOptions,
	PaginatorNextPageLinkOptions,
	PaginatorPageLinksOptions,
	PaginatorPrevPageLinkOptions,
	PaginatorTemplateOptions
} from 'primereact/paginator';
import themes from '../../themes/themes.scss?export';
import NoTableFilterResults from '../noTableFilterResults/NoTableFilterResults';
import EmptyDataMessage from '../emptyDataMessage/EmptyDataMessage';

interface ListDataTableProps extends RsDataTableProps<any> {
	onClearFilters: () => void;
	checkForPageChange?: () => Promise<boolean>;
	currentPageQuery?: RedSky.PageQuery;
	isEmptyDataMessage?: boolean;
}

const ListDataTable: React.FC<ListDataTableProps> = (props) => {
	const { onClearFilters, isEmptyDataMessage, ...baseProps } = props;
	const paginatorTemplate = {
		layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink',
		CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => (
			<Label
				variant={'caption1'}
				weight={'regular'}
				margin={'auto 64px'}
			>{`${options.first}-${options.last} of ${options.totalRecords}`}</Label>
		),
		PrevPageLink: (options: PaginatorPrevPageLinkOptions) => (
			<Icon
				color={options.disabled ? themes.neutralBeige600 : themes.neutralBeige400}
				fontSize={12}
				iconImg={'icon-arrow-back'}
				onClick={async (event) => {
					if (props.checkForPageChange && !(await props.checkForPageChange())) {
						return;
					}
					options.onClick(event);
				}}
				className={options.disabled ? 'notSelectable' : 'chevronSelect'}
			/>
		),
		PageLinks: (options: PaginatorPageLinksOptions) => {
			if (options.page !== options.currentPage) return;
			return (
				<Label
					variant={'caption1'}
					weight={'regular'}
					onClick={(event) => {
						options.onClick(event);
					}}
					className={'pageLink'}
				>
					{options.page + 1}
				</Label>
			);
		},
		NextPageLink: (options: PaginatorNextPageLinkOptions) => (
			<Icon
				color={options.disabled ? themes.neutralBeige600 : themes.neutralBeige400}
				fontSize={12}
				iconImg={'icon-arrow-next'}
				cursorPointer={!options.disabled}
				onClick={async (event) => {
					if (props.checkForPageChange && !(await props.checkForPageChange())) {
						return;
					}
					options.onClick(event);
				}}
				className={options.disabled ? 'notSelectable' : 'chevronSelect'}
			/>
		)
	};

	return (
		<RsDataTable
			{...baseProps}
			onFilter={() => {}}
			globalFilterDebounceMs={600}
			responsiveLayout={'scroll'}
			emptyMessage={
				isEmptyDataMessage ? <EmptyDataMessage /> : <NoTableFilterResults onClear={props.onClearFilters} />
			}
			paginatorTemplate={props.data.total ? (paginatorTemplate as PaginatorTemplateOptions) : undefined}
			rowClassName={() => 'tableRow'}
			paginatorLeft={
				props.data.total ? (
					<Label variant={'caption1'} weight={'regular'} mr={4}>
						Rows per page:
					</Label>
				) : undefined
			}
		>
			{props.children}
		</RsDataTable>
	);
};

export default ListDataTable;
