import * as React from 'react';
import './RichContentDisplay.scss';
import { Box } from '@redskytech/framework/ui';
import InnerHtml from '../innerHtml/InnerHtml';

export interface RichContentDisplayProps {
	content: string;
}

const RichContentDisplay: React.FC<RichContentDisplayProps> = (props) => {
	function getAdjustedContent(): string {
		let adjustedContent = props.content || '';
		const imgTags = adjustedContent.match(/<img[^>]*>/g);
		if (imgTags) {
			imgTags.forEach((imgTag) => {
				const src = imgTag.match(/src="([^"]*)"/);
				if (src && src.length > 1) {
					const srcValue = src[1];
					const adjustedSrcValue = srcValue + '?tr=w-800';
					adjustedContent = adjustedContent.replace(srcValue, adjustedSrcValue);
				}
			});
		}
		return adjustedContent;
	}

	const adjustedContent = getAdjustedContent();

	return (
		<Box className={'rsRichContentDisplay'}>
			<InnerHtml html={adjustedContent} />
		</Box>
	);
};

export default RichContentDisplay;
