import * as React from 'react';
import './EmptyDataMessage.scss';
import { Box, Icon, Label } from '@redskytech/framework/ui';
import themes from '../../themes/themes.scss?export';

interface EmptyDataMessageProps {}

const EmptyDataMessage: React.FC<EmptyDataMessageProps> = (props) => {
	return (
		<Box className={'rsEmptyDataMessage'}>
			<Icon iconImg={'icon-task'} fontSize={72} color={themes.neutralBeige500} />
			<Label variant={'subheader1'} weight={'regular'}>
				No data yet!
			</Label>
		</Box>
	);
};
export default EmptyDataMessage;
