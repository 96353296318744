import * as React from 'react';
import './LabelInputTextarea.scss';
import { Box, InputTextarea, InputTextareaProps, Label } from '@redskytech/framework/ui';
import classNames from 'classnames';

interface LabelInputTextareaProps extends InputTextareaProps {
	label: string | React.ReactNode;
	labelColor?: string;
}

const LabelInputTextarea: React.FC<LabelInputTextareaProps> = (props) => {
	const { label, labelColor, ...inputProps } = props;
	return (
		<Box className={'rsLabelInputTextarea'}>
			<Label
				className={classNames({ required: props.required })}
				variant={'body1'}
				weight={'regular'}
				mb={4}
				color={labelColor}
			>
				{label}
			</Label>
			<InputTextarea {...inputProps} />
		</Box>
	);
};

export default LabelInputTextarea;
