import React, { useEffect } from 'react';
import { Page } from '@redskytech/framework/996';
import { Label } from '@redskytech/framework/ui';
import router from '../../../utils/router';
import { RoutePaths } from '../../../routes';

function NotFoundPage(): JSX.Element {
	useEffect(() => {
		setTimeout(() => {
			router.navigate<RoutePaths>('/');
		}, 3000);
	}, []);

	return (
		<Page className="rsNotFoundPage">
			<Label variant={'h5'} weight={'medium'}>
				Page Not Found...redirecting in 3 seconds
			</Label>
		</Page>
	);
}

export default NotFoundPage;
