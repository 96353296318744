import * as React from 'react';
import './AddEditMeetingPopup.scss';
import {
	Box,
	Button,
	Popup,
	popupController,
	PopupProps,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { useEffect, useState } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { WebUtils } from '../../utils/utils';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import LabelInputText from '../../components/labelInputText/LabelInputText';
import LabelInputRichText from '../../components/labelInputRichText/LabelInputRichText';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import router from '../../utils/router';
import { useRecoilState } from 'recoil';
import globalState from '../../state/globalState';

export interface AddEditMeetingPopupProps extends PopupProps {
	isRecurringMeeting?: boolean;
	recurringMeetingText?: string;
}

enum FormKeys {
	TITLE = 'title',
	TYPE = 'meetingTypeId',
	DESCRIPTION = 'description'
}

const AddEditMeetingPopup: React.FC<AddEditMeetingPopupProps> = (props) => {
	const [version, setVersion] = useRecoilState<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.TITLE, '', [new RsValidator(RsValidatorEnum.REQ, 'Name is required')]),
			new RsFormControl<number | null>(FormKeys.TYPE, null, [
				new RsValidator(RsValidatorEnum.REQ, 'Type is required')
			]),
			new RsFormControl<string>(FormKeys.DESCRIPTION, props.recurringMeetingText || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Description is required')
			])
		])
	);

	const [meetingTypeOptions, setMeetingTypeOptions] = useState<{ label: string; value: number }[]>([]);

	useEffect(() => {
		// We don't need types if we are just doing a recurring meeting edit
		if (props.recurringMeetingText) return;

		(async function getAllMeetingTypes() {
			try {
				let res = await ApiRequestV1.getMeetingTypeAll();
				setMeetingTypeOptions(
					res.map((meetingType) => {
						return { label: meetingType.name, value: meetingType.id };
					})
				);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
			}
		})();
	}, []);

	function handleUpdate(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function handleClose() {
		popupController.close(AddEditMeetingPopup);
	}

	async function handleSaveMeeting() {
		if (!version) return;
		if (!props.isRecurringMeeting && !(await formGroup.isValid())) {
			rsToastify.error('Please fix the errors in the inputs', 'Input Error');
			setFormGroup(formGroup.clone());
			return;
		} else if (props.isRecurringMeeting && !(await formGroup.get(FormKeys.DESCRIPTION).validate())) {
			rsToastify.error('Please fix the errors in the inputs', 'Input Error');
			setFormGroup(formGroup.clone());
			return;
		}

		let formData = formGroup.toModel<{ title: string; description: string; meetingTypeId: number }>();

		try {
			if (props.isRecurringMeeting) {
				await ApiRequestV1.patchVersion({ id: version.id, recurringMeetings: formData.description });
				setVersion({ ...version, recurringMeetings: formData.description });
				rsToastify.success('Recurring Meeting Updated', 'Success');
			} else {
				let res = await ApiRequestV1.postMeeting({
					description: formData.description,
					meetingTypeId: formData.meetingTypeId,
					occurredOn: new Date().toISOString(),
					companyId: version.companyId,
					status: 'HIDDEN',
					title: formData.title,
					versionId: version.id
				});
				rsToastify.success('Meeting Created', 'Success');
				router.navigate(`/meeting/details?mi=${res.id}`).catch(console.error);
			}
			popupController.close(AddEditMeetingPopup);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Server Error'), 'Error');
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsAddEditMeetingPopup'}>
				<PopupHeader
					title={`${props.isRecurringMeeting ? 'Edit Recurring Meetings' : 'Add Meeting'}`}
					onClose={handleClose}
				/>
				<Box className={'content'}>
					{!props.isRecurringMeeting && (
						<Box className={'titleType'}>
							<LabelInputText
								label={'Title'}
								inputMode={'text'}
								required
								control={formGroup.get<string>(FormKeys.TITLE)}
								updateControl={handleUpdate}
							/>
							<LabelSelect
								label={'Type'}
								required
								control={formGroup.get<string>(FormKeys.TYPE)}
								updateControl={handleUpdate}
								options={meetingTypeOptions}
							/>
						</Box>
					)}
					<LabelInputRichText
						label={'Description'}
						control={formGroup.get<string>(FormKeys.DESCRIPTION)}
						updateControl={handleUpdate}
						height={300}
					/>
					<Box className={'buttonGroup'}>
						<Button look={'outlinedPrimary'} fullWidth onClick={handleClose}>
							Cancel
						</Button>
						<Button
							look={'containedPrimary'}
							fullWidth
							disabled={!formGroup.isModified()}
							onClick={handleSaveMeeting}
						>
							{!!props.isRecurringMeeting ? 'Save' : 'Create'}
						</Button>
					</Box>
				</Box>
			</Box>
		</Popup>
	);
};

export default AddEditMeetingPopup;
