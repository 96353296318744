import * as React from 'react';
import './ColumnHeader.scss';
import { Label } from '@redskytech/framework/ui';
import classNames from 'classnames';

interface ColumnHeaderProps {
	label: string;
	isActiveSort?: boolean;
}

const ColumnHeader: React.FC<ColumnHeaderProps> = (props) => {
	return (
		<Label
			variant={'caption1'}
			weight={'regular'}
			className={classNames('rsColumnHeader', { activeSort: props.isActiveSort })}
		>
			{props.label.toUpperCase()}
		</Label>
	);
};
export default ColumnHeader;
