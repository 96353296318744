import * as React from 'react';
import './PhaseCompletionSection.scss';
import { Box, Label } from '@redskytech/framework/ui';
import { useEffect, useState } from 'react';
import ProgressBar, { ProgressBarProps } from '../../components/progressBar/ProgressBar';
import themes from '../../themes/themes.scss?export';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import Paper from '../../components/paper/Paper';
import BulletedKey from '../../components/bulletedKey/BulletedKey';
import { DateUtils } from '../../utils/utils';

interface PhaseCompletionSectionProps {}

const PhaseCompletionSection: React.FC<PhaseCompletionSectionProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [phase, setPhase] = useState<ProgressBarProps>({
		barType: 'PERCENTAGE',
		barHeight: 'LARGE',
		progressBarSections: []
	});

	useEffect(() => {
		if (!version) return;
		const phaseVersionIndex = version.phases.findIndex((phase) => phase.id === version.currentPhaseId);
		setPhase({
			barType: 'PERCENTAGE',
			barHeight: 'LARGE',
			progressBarSections: [
				{
					percentageOrAmount:
						phaseVersionIndex >= 0 ? (version.phases[phaseVersionIndex].toDoPercent as number) : 0,
					sectionColor: themes.accentWarningLight
				},
				{
					percentageOrAmount:
						phaseVersionIndex >= 0 ? (version.phases[phaseVersionIndex].inProgressPercent as number) : 0,
					sectionColor: themes.accentInfo
				},
				{
					percentageOrAmount:
						phaseVersionIndex >= 0 ? (version.phases[phaseVersionIndex].donePercent as number) : 0,
					sectionColor: themes.accentSuccess
				}
			]
		});
	}, []);

	function renderProgressBar(): React.ReactNode {
		if (!version) return;
		const phaseVersionIndex = version.phases.findIndex((phase) => phase.id === version.currentPhaseId);
		return (
			<>
				<Label
					variant={'caption1'}
					weight={'semiBold'}
					textAlign={'center'}
					mb={8}
					color={themes.neutralBeige600}
				>
					PHASE COMPLETION STATUS:{' '}
					<span style={{ color: 'yellow' }}>
						{phaseVersionIndex >= 0 ? version.phases[phaseVersionIndex]?.donePercent : 0}%
					</span>
				</Label>

				<ProgressBar
					barType={phase.barType}
					barHeight={phase.barHeight}
					progressBarSections={phase?.progressBarSections || []}
				/>
				<Box display={'flex'} justifyContent={'space-between'} m={8}>
					<BulletedKey label={'To Do'} bulletColor={themes.accentWarningLight} />
					<BulletedKey label={'In Progress'} bulletColor={themes.accentInfo} />
					<BulletedKey label={'Done'} bulletColor={themes.accentSuccess} />
				</Box>

				{version.phases[0] && (
					<Label variant={'caption2'} weight={'regular'} textAlign={'center'} color={themes.neutralBeige600}>
						Updated{' '}
						{DateUtils.getDateDifferenceFriendly(new Date(), new Date(version.phases[0].modifiedOn))} ago
					</Label>
				)}
			</>
		);
	}

	return <Paper className={'rsPhaseCompletionSection completionStatus'}>{renderProgressBar()}</Paper>;
};

export default PhaseCompletionSection;
