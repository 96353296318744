import * as React from 'react';
import './UserDetailsPage.scss';
import { Page } from '@redskytech/framework/996';
import router from '../../../utils/router';
import { useEffect, useState } from 'react';
import useIsMounted from '../../../customHooks/useIsMounted';
import { Avatar, Box, Button, Label, popupController, rsToastify } from '@redskytech/framework/ui';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { WebUtils } from '@redskytech/framework/utils';
import LoadingPopup, { LoadingPopupProps } from '../../../popups/loadingPopup/LoadingPopup';
import PageHeader from '../../../components/pageHeader/PageHeader';
import PaperHeaderBar from '../../../components/paperHeaderBar/PaperHeaderBar';
import ConfirmationPopup, { ConfirmationPopupProps } from '../../../popups/confirmationPopup/ConfirmationPopup';
import themes from '../../../themes/themes.scss?export';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import AddEditUserPopup, { AddEditUserPopupProps } from '../../../popups/addEditUserPopup/AddEditUserPopup';

interface UserDetailsPageProps {}

const UserDetailsPage: React.FC<UserDetailsPageProps> = (props) => {
	const currentUser = useRecoilValue<Api.V1.User.Get.Res | undefined>(globalState.user);
	const { userId } = router.getQueryParams<{ userId: number }>([
		{
			key: 'ui',
			default: 0,
			type: 'integer',
			alias: 'userId'
		}
	]);

	const [userDetails, setUserDetails] = useState<Api.V1.User.Public.Get.Res>();
	const isMounted = useIsMounted();

	useEffect(() => {
		if (!userId) {
			rsToastify.error('No user id in parameters', 'Unable to get user details');
			router.navigate('/user/list').catch(console.error);
			return;
		}
		getUserDetails().catch(console.error);
	}, [userId]);

	async function getUserDetails() {
		try {
			let res = await ApiRequestV1.getUserPublic({ userId });
			if (!isMounted) return;
			setUserDetails(res);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	async function handleDelete() {
		try {
			popupController.open<LoadingPopupProps>(LoadingPopup, {});
			await ApiRequestV1.deleteUser({ id: userId });
			rsToastify.success('User successfully deleted');
			router.navigate('/user/list').catch(console.error);
			popupController.closeAll();
		} catch (e) {
			popupController.close(LoadingPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function renderHeaderButtons() {
		return (
			<Box display={'flex'} gap={24}>
				<Button
					look={'outlinedPrimary'}
					onClick={() => {
						if (!userDetails) return;
						popupController.open<AddEditUserPopupProps>(AddEditUserPopup, {
							user: {
								...userDetails
							},
							onUpdate: () => {
								getUserDetails().catch(console.error);
							}
						});
					}}
				>
					Edit User
				</Button>
				{currentUser?.role === 'superAdmin' && (
					<Button look={'containedPrimary'} onClick={() => {}}>
						Mock User
					</Button>
				)}
			</Box>
		);
	}

	if (!userDetails) return <></>;

	return (
		<Page className={'rsUserDetailsPage'}>
			<PageHeader
				title={'User Details'}
				onBack={() => {
					router.navigate('/user/list').catch(console.error);
				}}
				rightNode={renderHeaderButtons()}
			/>
			<Box padding={32} className={'scrolledContent'}>
				<Box flex={'2'}>
					<PaperHeaderBar title={'Personal Information'}>
						<Box display={'flex'}>
							<Avatar
								widthHeight={64}
								name={`${userDetails.firstName} ${userDetails.lastName}`}
								image={userDetails.userAvatarUrl}
							/>
							<Box ml={16}>
								<Label
									variant={'body1'}
									weight={'regular'}
								>{`${userDetails.firstName} ${userDetails.lastName}`}</Label>
								<Label variant={'body1'} weight={'regular'} color={themes.neutralBeige500}>
									{userDetails?.companies[0].companyName}
								</Label>
								<Label variant={'body1'} weight={'regular'} color={themes.neutralBeige500}>
									{userDetails.email}
								</Label>
								<Label variant={'body1'} weight={'regular'} color={themes.neutralBeige500}>
									{userDetails?.phone}
								</Label>
							</Box>
						</Box>
					</PaperHeaderBar>
				</Box>
				<Box flex={'1'}>
					<PaperHeaderBar title={'Permissions'}>
						<Label variant={'body1'} weight={'regular'} textTransform={'capitalize'}>
							{userDetails?.userRole}
						</Label>
						<Label variant={'body1'} weight={'regular'} mt={24}>{`Log In Access ${
							userDetails?.userPermissionLogin ? 'On' : 'Off'
						}`}</Label>
					</PaperHeaderBar>
					<Button
						look={'outlinedPrimary'}
						onClick={() => {
							popupController.open<ConfirmationPopupProps>(ConfirmationPopup, {
								headerLabel: 'Delete User',
								label: `Are you sure you want to delete user? This action cannot be undone.`,
								acceptLabel: 'Delete',
								rejectLabel: 'Cancel',
								onAccept: handleDelete
							});
						}}
						fullWidth
						mt={32}
					>
						Delete User
					</Button>
				</Box>
			</Box>
		</Page>
	);
};
export default UserDetailsPage;
