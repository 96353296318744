import * as React from 'react';
import './BudgetPieChart.scss';
import { Box } from '@redskytech/framework/ui';
import 'chart.js/auto';
import { Pie } from 'react-chartjs-2';
import { TooltipItem } from 'chart.js';
import { useMemo } from 'react';

export interface PieChartData {
	label: string;
	amount: number;
	status?: 'HEALTHY' | 'UNHEALTHY';
	unhealthyInformationBoxContent?: {
		phase: string;
		averagePercentage: number;
	};
	labelColor?: string;
	labelBorder?: string;
}

interface BudgetPieChartProps {
	tableData: PieChartData[];
}

const BudgetPieChart: React.FC<BudgetPieChartProps> = (props) => {
	const data = useMemo(() => {
		return {
			labels: props.tableData.map((item) => item.label),
			datasets: [
				{
					data: props.tableData.map((item) => item.amount),
					backgroundColor: [
						'#003b74', // themes.budgetDev,
						'#005996', // themes.budgetBugs,
						'#0078a9', // themes.budgetPM,
						'#0095a8', // themes.budgetMeetings,
						'#00b092', // themes.budgetAdmin,
						'#00c96e', // themes.budgetDesign,
						'#82dc41', // themes.budgetOther,
						'#e5e800', // themes.budgetQA,
						'#170D0D' // themes.neutralBeige700 //BudgetRemaining
					],
					borderWidth: 0
				}
			]
		};
	}, [props.tableData]);

	return (
		<Box className={'rsBudgetPieChart'}>
			<Pie
				data={data}
				options={{
					plugins: {
						legend: {
							display: false
						},
						tooltip: {
							displayColors: false,
							bodyFont: {
								family: 'Poppins',
								size: 10,
								weight: '600'
							},
							bodyColor: '#EBE3E3',
							backgroundColor: '#282625',
							caretSize: 4,
							callbacks: {
								label: function (tooltipItem: TooltipItem<'pie'>) {
									return tooltipItem.label.toString();
								}
							}
						}
					}
				}}
			/>
		</Box>
	);
};

export default BudgetPieChart;
