import React, { useEffect, useState } from 'react';
import './ArtifactDetailsPage.scss';
import '../../../themes/tinyMce.scss';
import { Link, Page } from '@redskytech/framework/996';
import { Box, Button, Icon, Label, rsToastify } from '@redskytech/framework/ui';
import router from '../../../utils/router.js';
import { ApiRequestV1 } from '../../../generated/apiRequests.js';
import getArtifact = ApiRequestV1.getArtifact;
import LoadingPage from '../../common/loadingPage/LoadingPage.js';
import { StringUtils } from '../../../utils/utils.js';
import RichContentDisplay from '../../../components/richContentDisplay/RichContentDisplay.js';
import themes from '../../../themes/themes.scss?export';

const ArtifactDetailsPage: React.FC = () => {
	const params = router.getQueryParams<{ artifactId: number }>([
		{
			key: 'id',
			default: 0,
			type: 'integer',
			alias: 'artifactId'
		}
	]);

	const [artifact, setArtifact] = useState<Api.V1.Artifact.Get.Res>();

	useEffect(() => {
		if (!params.artifactId) return;
		let isCancelled = false;
		(async function getArtifactDetails() {
			const res = await getArtifact({ id: params.artifactId });
			if (isCancelled) return;
			setArtifact(res);
		})();
		return () => {
			isCancelled = true;
		};
	}, [params.artifactId]);

	if (!artifact) return <LoadingPage />;

	return (
		<Page className={'rsArtifactDetailsPage'}>
			<Label variant={'subheader2'} weight={'extraBold'}>
				{artifact.type} DETAILS
			</Label>
			<Box className={'type'}>
				<Box display={'flex'} alignItems={'baseline'} gap={4}>
					<Icon iconImg={'icon-artifacts'} fontSize={16} color={themes.neutralBeige500} mr={4} />
					<Label variant={'caption2'} weight={'regular'} color={themes.neutralBeige500}>
						{artifact.type}
					</Label>
				</Box>
				<RichContentDisplay content={artifact.description} />
			</Box>
			{!!artifact.link && (
				<Box className={'actions'}>
					<Label variant={'subheader2'} weight={'extraBold'}>
						ACTIONS
					</Label>
					<Box className={'buttons'}>
						<Button
							look={'outlinedPrimary'}
							onClick={async () => {
								try {
									await StringUtils.copyToClipboard(artifact.link!);
									rsToastify.success('Link successfully copied to clipboard', 'Success');
								} catch (e) {
									rsToastify.error('Unable to copy link to clipboard', 'Unexpected Error');
								}
							}}
						>
							Copy Link
						</Button>
						<Link external target={'blank'} path={artifact.link}>
							<Button look={'containedSecondary'}>View in Browser</Button>
						</Link>
					</Box>
				</Box>
			)}
		</Page>
	);
};

export default ArtifactDetailsPage;
