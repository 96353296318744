import * as React from 'react';
import './PageHeader.scss';
import classNames from 'classnames';
import themes from '../../themes/themes.scss?export';
import { Box, Button, Icon, Label } from '@redskytech/framework/ui';

interface PageHeaderButtonsProps {
	title: string | React.ReactNode;
	isModified?: boolean;
	onCancel?: () => void;
	onSave?: () => void;
	onBack?: () => void;
	rightNode?: React.ReactNode;
	centerNode?: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderButtonsProps> = (props) => {
	function renderModifiedComponents() {
		if (!props.isModified) return <></>;
		if (props.isModified && props.centerNode) return <></>;

		return (
			<>
				<Box display={'flex'} gap={24}>
					<Button small look={'outlinedPrimary'} onClick={props.onCancel}>
						Discard
					</Button>
					<Button small look={'containedPrimary'} onClick={props.onSave}>
						Save
					</Button>
				</Box>
			</>
		);
	}

	function renderTitle() {
		if (typeof props.title === 'string')
			return (
				<Label variant={'h2'} weight={'semiBold'} className={'title'}>
					{props.title}
				</Label>
			);
		return props.title;
	}

	function renderBackArrow() {
		if (!props.onBack) return;
		return (
			<Icon
				className={'backArrow'}
				iconImg={'icon-chevron-left'}
				cursorPointer
				onClick={props.onBack}
				color={themes.neutralWhite}
				fontSize={16}
			/>
		);
	}

	return (
		<Box className={classNames('rsPageHeader', { modified: props.isModified })}>
			<Box display={'flex'} alignItems={'end'}>
				{renderBackArrow()}
				{renderTitle()}
			</Box>
			{props.isModified && (
				<Label variant={'body1'} weight={'semiBold'} color={themes.neutralWhite}>
					Unsaved Changes
				</Label>
			)}
			{renderModifiedComponents()}
			{props.centerNode}
			{props.rightNode}
		</Box>
	);
};

export default PageHeader;
