import * as React from 'react';
import './AddEditTeamMemberPopup.scss';
import {
	Box,
	Button,
	Popup,
	popupController,
	PopupProps,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import { useEffect, useState } from 'react';
import { WebUtils } from '../../utils/utils';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import ConfirmationPopup, { ConfirmationPopupProps } from '../confirmationPopup/ConfirmationPopup.js';

export interface AddEditTeamMemberPopupProps extends PopupProps {
	teamId: number;
	versionId: number;
	companyId: number;
	existingTeamMember?: Api.V1.TeamMap.Paged.Get.Res;
	onChange: () => void;
}

enum FormKeys {
	USER_ID = 'userId',
	ROLE_ID = 'teamRoleId'
}

const AddEditTeamMemberPopup: React.FC<AddEditTeamMemberPopupProps> = (props) => {
	const [userList, setUserList] = useState<Api.V1.User.Paged.Get.Res[]>([]);
	const [roles, setRoles] = useState<Api.V1.TeamRole.Paged.Get.Res[]>([]);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<number | null>(FormKeys.USER_ID, props.existingTeamMember?.userId || null, [
				new RsValidator(RsValidatorEnum.REQ, 'User is required')
			]),
			new RsFormControl<number | null>(FormKeys.ROLE_ID, props.existingTeamMember?.teamRoleId || null, [
				new RsValidator(RsValidatorEnum.REQ, 'Role is required')
			])
		])
	);

	useEffect(() => {
		(async () => {
			try {
				let userListRes = await ApiRequestV1.getUserPaged({
					page: 1,
					perPage: 1000,
					filter: `(column:companyUser.companyId,value:${props.companyId})`
				});
				let rolesRes = await ApiRequestV1.getTeamRolePaged({ page: 1, perPage: 1000 });
				setUserList(userListRes.data);
				setRoles(rolesRes.data);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Server Error'), 'Error');
			}
		})();
	}, []);

	function handleUpdate(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function handleClose() {
		popupController.close(AddEditTeamMemberPopup);
	}

	async function handleDeleteTeamMember() {
		if (!props.existingTeamMember) return;
		try {
			await ApiRequestV1.deleteTeamMap({ id: props.existingTeamMember.id });
			rsToastify.success('Team Member Deleted', 'Success');
			props.onChange();
			popupController.close(AddEditTeamMemberPopup);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Server Error'), 'Error');
		}
	}

	async function handleSaveTeam() {
		if (!(await formGroup.isValid())) {
			rsToastify.error('Please fix the errors in the inputs', 'Input Error');
			setFormGroup(formGroup.clone());
			return;
		}

		let formData = formGroup.toModel<{ userId: number; teamRoleId: number }>();

		try {
			if (props.existingTeamMember) {
				await ApiRequestV1.patchTeamMap({
					id: props.existingTeamMember.id,
					userId: formData.userId,
					teamRoleId: formData.teamRoleId
				});
				rsToastify.success('Team Updated', 'Success');
			} else {
				await ApiRequestV1.postTeamMap({
					userId: formData.userId,
					teamRoleId: formData.teamRoleId,
					teamId: props.teamId
				});
				rsToastify.success('Team Member Added', 'Success');
			}
			await ApiRequestV1.postUserDefaultVersion({ userId: formData.userId, versionId: props.versionId });
			props.onChange();
			popupController.close(AddEditTeamMemberPopup);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Server Error'), 'Error');
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsAddEditTeamMemberPopup'}>
				<PopupHeader
					title={`${!!props.existingTeamMember?.id ? 'Edit' : 'Add'} Member`}
					onClose={handleClose}
				/>
				<Box className={'content'}>
					<LabelSelect
						label={'User'}
						required
						options={userList.map((user) => ({
							label: `${user.firstName} ${user.lastName}`,
							value: user.id
						}))}
						control={formGroup.get<string>(FormKeys.USER_ID)}
						updateControl={handleUpdate}
					/>
					<LabelSelect
						label={'Role'}
						required
						options={roles.map((role) => ({
							label: role.name,
							value: role.id
						}))}
						control={formGroup.get<string>(FormKeys.ROLE_ID)}
						updateControl={handleUpdate}
					/>
					<Box className={'buttonGroup'}>
						{!!props.existingTeamMember && (
							<Button
								look={'iconSecondary'}
								icon={[
									{
										iconImg: 'icon-trash',
										position: 'LEFT',
										fontSize: 16
									}
								]}
								onClick={() => {
									if (!props.existingTeamMember) return;
									popupController.open<ConfirmationPopupProps>(ConfirmationPopup, {
										headerLabel: 'Delete Meeting',
										label: `Are you sure you want to delete ${props.existingTeamMember.userFirstName} from the ${props.existingTeamMember.teamName}? This action cannot be undone.`,
										acceptLabel: 'Delete',
										rejectLabel: 'Cancel',
										onAccept: handleDeleteTeamMember
									});
								}}
							/>
						)}
						<Button look={'outlinedPrimary'} fullWidth onClick={handleClose}>
							Cancel
						</Button>
						<Button
							look={'containedPrimary'}
							fullWidth
							disabled={!formGroup.isModified()}
							onClick={handleSaveTeam}
						>
							{!!props.existingTeamMember ? 'Save' : 'Add'}
						</Button>
					</Box>
				</Box>
			</Box>
		</Popup>
	);
};

export default AddEditTeamMemberPopup;
