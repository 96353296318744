import * as React from 'react';
import './PopupHeader.scss';
import { Box, Icon, Label } from '@redskytech/framework/ui';

interface PopupHeaderProps {
	title: string;
	onClose: () => void;
}

const PopupHeader: React.FC<PopupHeaderProps> = (props) => {
	return (
		<Box className={'rsPopupHeader'}>
			<Label variant={'subheader1'} weight={'semiBold'}>
				{props.title}
			</Label>
			<Icon iconImg={'icon-close'} onClick={props.onClose} fontSize={24} />
		</Box>
	);
};

export default PopupHeader;
