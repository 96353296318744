import React from 'react';
import './StatusChip.scss';
import { Label } from '@redskytech/framework/ui';
import classNames from 'classnames';

interface StatusChipProps {
	status: 'DONE' | 'IN_PROGRESS' | 'TO_DO';
}

const StatusChip: React.FC<StatusChipProps> = (props) => {
	function renderLabel() {
		return props.status.replace('_', ' ');
	}

	return (
		<Label
			className={classNames('rsStatusChip', {
				done: props.status === 'DONE',
				inProgress: props.status === 'IN_PROGRESS',
				toDo: props.status === 'TO_DO'
			})}
			variant={'caption2'}
			weight={'semiBold'}
		>
			{renderLabel()}
		</Label>
	);
};

export default StatusChip;
