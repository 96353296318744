import * as React from 'react';
import './TeamRolePage.scss';
import { Page } from '@redskytech/framework/996';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import LoadingPage from '../../common/loadingPage/LoadingPage';
import SectionWithHeader from '../../../components/sectionWithHeader/SectionWithHeader';
import { Avatar, Box, Label, popupController } from '@redskytech/framework/ui';
import TeamUserPopup, { TeamUserPopupProps } from '../../../popups/teamUserPopup/TeamUserPopup';
import { useEffect, useState } from 'react';
import { ApiRequestV1 } from '../../../generated/apiRequests.js';

interface TeamRolePageProps {}

const TeamRolePage: React.FC<TeamRolePageProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [teams, setTeams] = useState<Api.V1.Team.Version.Get.Res[]>();

	useEffect(() => {
		if (!version) return;
		let isCancelled = false;
		(async function getTeams() {
			const res = await ApiRequestV1.getTeamVersion({ versionId: version.id });
			if (isCancelled) return;
			setTeams(res);
		})();
		return () => {
			isCancelled = true;
		};
	}, [version]);

	function renderTeams() {
		if (!teams) return;

		return teams.map((team) => {
			const teamList = team.teamMembers.map((teamMember) => (
				<Box
					className={'teamMember'}
					key={teamMember.userId + teamMember.teamRoleName}
					onClick={() => {
						if (!version) return;
						popupController.open<TeamUserPopupProps>(TeamUserPopup, {
							userId: teamMember.userId,
							role: teamMember.teamRoleName,
							roleDescription: teamMember.teamRoleDescription || '',
							companyId: version.companyId
						});
					}}
				>
					<Box className={'avatarNameContainer'}>
						<Avatar
							widthHeight={32}
							name={teamMember.userFirstName + ' ' + teamMember.userLastName}
							image={teamMember.userAvatarUrl}
						/>
						<Label variant={'body2'} weight={'semiBold'} ml={'8px'}>
							{teamMember.userFirstName + ' ' + teamMember.userLastName}
						</Label>
					</Box>
					<Box className={'roleColumn'}>
						<Label variant={'caption1'} weight={'regular'}>
							{teamMember.teamRoleName}
						</Label>
					</Box>
					<Box className={'emailColumn'}>
						<Label variant={'caption1'} weight={'regular'}>
							{teamMember.userEmail}
						</Label>
					</Box>
				</Box>
			));

			return (
				<SectionWithHeader title={team.name.toUpperCase() + ' TEAM'} className={'teamSection'} key={team.id}>
					<Box className={'teamGrid'}>
						<Box className={'teamMember labelContainer'}>
							<Label className={'displayHeader firstColumn'} variant={'caption1'} weight={'semiBold'}>
								NAME
							</Label>
							<Label className={'displayHeader'} variant={'caption1'} weight={'semiBold'}>
								ROLE
							</Label>
							<Label className={'displayHeader emailColumn'} variant={'caption1'} weight={'semiBold'}>
								EMAIL
							</Label>
						</Box>
						{teamList}
					</Box>
				</SectionWithHeader>
			);
		});
	}

	if (!teams) return <LoadingPage />;
	return <Page className={'rsTeamRolePage'}>{renderTeams()}</Page>;
};

export default TeamRolePage;
