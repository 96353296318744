import * as React from 'react';
import './ProjectPhaseDetails.scss';
import { Box, Label } from '@redskytech/framework/ui';
import themes from '../../../../themes/themes.scss?export';

interface ProjectPhaseDetailsProps {
	percentage: number;
	phaseName?: string;
	isProjectTotal?: boolean;
}

const ProjectPhaseDetails: React.FC<ProjectPhaseDetailsProps> = (props) => {
	return (
		<Box className={'rsProjectPhaseDetails'}>
			<Label variant={'subheader2'} weight={'semiBold'} textTransform={'uppercase'}>
				{props.isProjectTotal ? 'Project Total' : `${props.phaseName?.replace('/', '&')} Phase`}
			</Label>
			<Label variant={'body1'} weight={'regular'} mt={4}>
				{props.isProjectTotal ? 'Project ' : 'Phase '}
				<Label
					variant={'body1'}
					weight={'regular'}
					color={props.percentage === 100 ? themes.accentSuccess : themes.accentWarning}
					display={'inline'}
					elementType={'span'}
				>
					{props.percentage}%
				</Label>{' '}
				Complete
			</Label>
		</Box>
	);
};
export default ProjectPhaseDetails;
