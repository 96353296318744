import React, { useEffect, useState } from 'react';
import './ArtifactsPage.scss';
import { Page } from '@redskytech/framework/996';
import SectionWithHeader from '../../../components/sectionWithHeader/SectionWithHeader.js';
import classNames from 'classnames';
import { Box, InputText, RsFormControl, RsFormGroup, rsToastify } from '@redskytech/framework/ui';
import { ApiRequestV1 } from '../../../generated/apiRequests.js';
import getArtifactAll = ApiRequestV1.getArtifactAll;
import ArtifactGroup from './artifactGroup/ArtifactGroup.js';
import ArtifactSearchGroup from './artifactSearchGroup/ArtifactSearchGroup.js';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState.js';
import { ObjectUtils, WebUtils } from '../../../utils/utils.js';
enum FormKeys {
	SEARCH = 'Search'
}

const ArtifactsPage: React.FC = () => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [artifacts, setArtifacts] = useState<Api.V1.Artifact.All.Get.Res[]>();
	const [searchGroup, setSearchGroup] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl<string>(FormKeys.SEARCH, '', [])])
	);

	useEffect(() => {
		let isCancelled = false;
		if (!version) return;
		(async function getAllArtifacts() {
			try {
				let res = await getArtifactAll({ versionId: version.id });
				if (isCancelled) return;
				setArtifacts(res.filter((artifactGroup) => ObjectUtils.isArrayWithData(artifactGroup.artifact)));
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to get artifacts'), 'Server Error');
			}
		})();
		return () => {
			isCancelled = true;
		};
	}, [version]);

	function renderSearch(): React.ReactNode {
		return (
			<SectionWithHeader
				title={'ARTIFACTS'}
				className={classNames('searchContainer', {
					search: !!searchGroup.get<string>(FormKeys.SEARCH).value
				})}
				key={'SearchContainerKey'}
			>
				<InputText
					inputMode={'text'}
					placeholder={'Search Artifacts'}
					icon={[{ position: 'LEFT', iconImg: 'icon-search' }]}
					control={searchGroup.get(FormKeys.SEARCH)}
					updateControl={(form) => setSearchGroup(searchGroup.clone().update(form))}
				/>
			</SectionWithHeader>
		);
	}

	function renderSection(section: 1 | 2) {
		if (!artifacts) return;
		const halfIndex = Math.ceil(artifacts.length / 2);
		const isFirstSection = section === 1;
		return (
			<Box className={`column column${section}`}>
				{artifacts
					.slice(isFirstSection ? 0 : halfIndex, isFirstSection ? halfIndex : undefined)
					.map((artifactGroup) => {
						return <ArtifactGroup key={artifactGroup.artifactType} group={artifactGroup} />;
					})}
			</Box>
		);
	}

	function renderSearchOrArtifacts() {
		const searchValue = searchGroup.get<string>(FormKeys.SEARCH).value;
		return searchValue ? (
			<ArtifactSearchGroup searchValue={searchValue} />
		) : (
			<>
				{renderSection(1)}
				{renderSection(2)}
			</>
		);
	}

	return (
		<Page className={'rsArtifactsPage'}>
			{renderSearch()}
			{renderSearchOrArtifacts()}
		</Page>
	);
};

export default ArtifactsPage;
