import * as React from 'react';
import './TaskSection.scss';
import { Label, rsToastify } from '@redskytech/framework/ui';
import SectionWithHeader from '../../components/sectionWithHeader/SectionWithHeader';
import { DateUtils, ObjectUtils, WebUtils } from '../../utils/utils';
import TaskItem from '../../components/taskItem/TaskItem';
import router from '../../utils/router';

interface TaskSectionProps {
	overdueTasks: Api.V1.Task.Paged.Get.Res[];
	inProgressTasks: Api.V1.Task.Paged.Get.Res[];
}

const TaskSection: React.FC<TaskSectionProps> = (props) => {
	function getMonthDayText(date: Date) {
		return `${DateUtils.getMonthName(date)} ${date.getDate()}`;
	}

	function renderDueTodayOverdue() {
		return props.overdueTasks.map((task) => {
			return (
				<TaskItem
					key={task.id}
					task={task}
					taskType={'OVERDUE'}
					onClick={() => {
						router.navigate('/task/external/details?id=' + task.id);
					}}
				/>
			);
		});
	}

	function renderGroupedTasks() {
		let lastDate: Date | null = null;

		const closestTwoDays: string[] = [];
		props.inProgressTasks.forEach((date) => {
			if (closestTwoDays.findIndex((dateIndex) => dateIndex === date.dueOn) === -1 && closestTwoDays.length < 2) {
				closestTwoDays.push(date.dueOn || Date());
			}
		});

		return props.inProgressTasks
			.filter((task) => {
				if (!task.dueOn) return true;
				return (
					task.dueOn === closestTwoDays[0] ||
					(task.dueOn === closestTwoDays[1] && props.overdueTasks.length === 0)
				);
			})
			.map((task) => {
				let taskComp = (
					<TaskItem
						task={task}
						taskType={'IN_PROGRESS'}
						onClick={() => {
							router.navigate('/task/external/details?id=' + task.id);
						}}
					/>
				);

				if (!lastDate || DateUtils.daysBetweenStartAndEndDates(lastDate, new Date(task.dueOn || '')) !== 0) {
					lastDate = new Date(task.dueOn || '');
					return (
						<React.Fragment key={task.id}>
							<Label variant={'body2'} weight={'semiBold'} className={'overlineText'}>
								{getMonthDayText(lastDate)}
							</Label>
							{taskComp}
						</React.Fragment>
					);
				} else {
					return <React.Fragment key={task.id}>{taskComp}</React.Fragment>;
				}
			});
	}

	return (
		<SectionWithHeader className={'rsTaskSection'} title={'tasks'} viewAllLink={'/tasks'}>
			{ObjectUtils.isArrayWithData(props.overdueTasks) ? (
				<Label variant={'body2'} weight={'semiBold'} className={'overlineText'}>
					Today, {getMonthDayText(new Date())}
				</Label>
			) : (
				''
			)}
			{renderDueTodayOverdue()}
			{renderGroupedTasks()}
		</SectionWithHeader>
	);
};

export default TaskSection;
