import * as React from 'react';
import './ArtifactSection.scss';
import SectionWithHeader from '../../components/sectionWithHeader/SectionWithHeader';
import ArtifactItem from '../../pages/external/artifactsPage/artifactItem/ArtifactItem';
import { Label } from '@redskytech/framework/ui';

interface ArtifactSectionProps {
	artifacts?: Api.V1.Artifact.Paged.Get.Res[];
}

const ArtifactSection: React.FC<ArtifactSectionProps> = (props) => {
	return (
		<SectionWithHeader className={'rsArtifactSection'} title={'Artifacts'} viewAllLink={'/artifacts'}>
			{!props.artifacts || props.artifacts.length === 0 ? (
				<Label variant={'body1'} weight={'regular'}>
					No Artifacts yet, look back later
				</Label>
			) : (
				props.artifacts.map((artifact) => <ArtifactItem key={'aItem_' + artifact.id} artifact={artifact} />)
			)}
		</SectionWithHeader>
	);
};

export default ArtifactSection;
