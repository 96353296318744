import * as React from 'react';
import './MeetingDetailsExternalPage.scss';
import themes from '../../../themes/themes.scss?export';
import { Link, Page } from '@redskytech/framework/996';
import router from '../../../utils/router';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import { useEffect, useState } from 'react';
import SectionWithHeader from '../../../components/sectionWithHeader/SectionWithHeader';
import MeetingItem from '../../../components/meetingItem/MeetingItem';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { Button, Label, rsToastify } from '@redskytech/framework/ui';
import { ObjectUtils, WebUtils } from '../../../utils/utils';

interface MeetingDetailsExternalPageProps {}

const MeetingDetailsExternalPage: React.FC<MeetingDetailsExternalPageProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [meeting, setMeeting] = useState<Api.V1.Meeting.Paged.Get.Res>();
	const [tags, setTags] = useState<Api.V1.Tag.Paged.Get.Res[]>();

	const params = router.getQueryParams<{ meetingId: number }>([
		{
			key: 'id',
			default: '',
			type: 'integer',
			alias: 'meetingId'
		}
	]);

	useEffect(() => {
		if (!version) return;
		(async function searchMeetings() {
			try {
				let responses: [
					Promise<RedSky.RsPagedResponseData<Api.V1.Tag.Paged.Get.Res[]>>,
					Promise<RedSky.RsPagedResponseData<Api.V1.Meeting.Paged.Get.Res[]>>
				] = [
					ApiRequestV1.getTagPaged({
						perPage: 25,
						page: 1,
						filter: `(column:tag.meetingId,value:${params.meetingId})`
					}),
					ApiRequestV1.getMeetingPaged({
						perPage: 1,
						page: 1,
						filter: `(column:meeting.id,value:${params.meetingId})`,
						versionId: version.id
					})
				];
				let res = await Promise.all(responses);
				const [allTags, meeting] = res;
				setTags(allTags.data);
				setMeeting(meeting.data[0]);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Failed to retrieve meeting'), 'Get Meeting Failed');
			}
		})();
	}, [version]);

	function renderTags(): React.ReactNode {
		if (!tags) return;
		let tagsString = tags.map((tag) => tag.name).join(',');
		return (
			<Label variant={'caption1'} weight={'regular'} color={themes.neutralBeige600}>
				Tags: {tagsString}
			</Label>
		);
	}

	function renderMeetingDetails(): React.ReactNode {
		if (!meeting) return;
		return (
			<SectionWithHeader title={meeting.meetingTypeName + ' Details'} className={'meetingDetails'}>
				<MeetingItem meeting={meeting} showFullDescription showMeetingNotes />
				{ObjectUtils.isArrayWithData(tags) && renderTags()}
			</SectionWithHeader>
		);
	}

	function renderActions(): React.ReactNode {
		if (!meeting || !meeting.link) return <></>;
		return (
			<SectionWithHeader title={'Actions'} className={'actionsSection'}>
				<Link external path={meeting.link}>
					<Button look={'containedSecondary'}>View Recording</Button>
				</Link>
			</SectionWithHeader>
		);
	}

	return (
		<Page className={'rsMeetingDetailsExternalPage'}>
			{renderMeetingDetails()}
			{renderActions()}
		</Page>
	);
};

export default MeetingDetailsExternalPage;
