import * as React from 'react';
import { useState } from 'react';
import './ConfigureSprintsPopup.scss';
import {
	Box,
	Button,
	Popup,
	popupController,
	PopupProps,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import DiscardChangesPopup, { DiscardChangesPopupProps } from '../discardChangesPopup/DiscardChangesPopup';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import LoadingPopup, { LoadingPopupProps } from '../loadingPopup/LoadingPopup';
import { WebUtils } from '@redskytech/framework/utils';
import LabelInputText from '../../components/labelInputText/LabelInputText';
import { ApiRequestV1 } from '../../generated/apiRequests';

export interface ConfigureSprintsPopupProps extends PopupProps {
	sprintsLeftInPhase: number;
	versionId: number;
}

enum FormKeys {
	REMAINING = 'sprintsLeftInPhase'
}
const ConfigureSprintsPopup: React.FC<ConfigureSprintsPopupProps> = (props) => {
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<number>(FormKeys.REMAINING, props.sprintsLeftInPhase, [
				new RsValidator(RsValidatorEnum.NUM, 'Invalid value')
			])
		])
	);

	function handleUpdate(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function handleClose() {
		if (formGroup.isModified()) {
			popupController.open<DiscardChangesPopupProps>(DiscardChangesPopup, {});
		} else {
			popupController.close(ConfigureSprintsPopup);
		}
	}

	async function handleSave() {
		if (!(await formGroup.isValid())) {
			rsToastify.error('Please fix the errors in the inputs', 'Input Error');
			setFormGroup(formGroup.clone());
			return;
		}
		popupController.open<LoadingPopupProps>(LoadingPopup, {});
		let formData = formGroup.toModel<{ sprintsLeftInPhase: number }>();
		try {
			await ApiRequestV1.patchVersion({
				...formData,
				id: props.versionId
			});
			popupController.closeAll();
		} catch (e) {
			popupController.close(LoadingPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Server Error'), 'Error');
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsConfigureSprintsPopup'}>
				<PopupHeader title={'Configure Sprints'} onClose={handleClose} />
				<Box className={'content'}>
					<LabelInputText
						label={'Sprints Remaining to End of Phase'}
						inputMode={'numeric'}
						control={formGroup.get<number>(FormKeys.REMAINING)}
						updateControl={handleUpdate}
					/>
					<Box className={'buttonGroup'}>
						<Button look={'outlinedPrimary'} fullWidth onClick={handleClose}>
							Cancel
						</Button>
						<Button
							look={'containedPrimary'}
							fullWidth
							disabled={!formGroup.isModified()}
							onClick={handleSave}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Box>
		</Popup>
	);
};
export default ConfigureSprintsPopup;
