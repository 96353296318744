import * as React from 'react';
import './LoadingPage.scss';
import { Page } from '@redskytech/framework/996';
import LoadingSpinner from '../../../components/loadingSpinner/LoadingSpinner';

interface LoadingPageProps {}

const LoadingPage: React.FC<LoadingPageProps> = (props) => {
	return (
		<Page className={'rsLoadingPage'}>
			<LoadingSpinner />
		</Page>
	);
};

export default LoadingPage;
