import * as React from 'react';
import './SprintListPage.scss';
import { Page } from '@redskytech/framework/996';
import PageHeader from '../../../components/pageHeader/PageHeader';
import ListDataTable from '../../../components/listDataTable/ListDataTable';
import { useEffect, useState } from 'react';
import useIsMounted from '../../../customHooks/useIsMounted';
import TableSearchHeader from '../../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import RowGroupHeader from '../../../components/rowGroupHeader/RowGroupHeader';
import { Column } from 'primereact/column';
import { DataTableExpandedRows } from 'primereact/datatable';
import ColumnHeader from '../../../components/listDataTable/columnHeader/ColumnHeader';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import { Box, Button, Icon, Label, popupController, rsToastify } from '@redskytech/framework/ui';
import { DateUtils, ObjectUtils, WebUtils } from '../../../utils/utils';
import StatusChip from '../../../components/statusChip/StatusChip';
import ConfigureSprintsPopup, {
	ConfigureSprintsPopupProps
} from '../../../popups/configureSprintsPopup/ConfigureSprintsPopup';
import AddSprintPopup, { AddSprintPopupProps } from '../../../popups/addSprintPopup/AddSprintPopup';
import router from '../../../utils/router';

interface SprintListPageProps {}

interface SprintListData {
	name: string;
	sprintId: number;
	isStretchGoal?: boolean;
	startOn: string;
	endOn: string;
	epic?: string;
	userStory?: string;
	status?: 'DONE' | 'IN_PROGRESS' | 'TO_DO';
}

const FieldKeys: {
	[key in 'NAME' | 'TYPE' | 'EPIC' | 'USER_STORY' | 'STATUS']: { field: string; sortField: string };
} = {
	NAME: { field: 'name', sortField: 'name' },
	TYPE: { field: 'isStretchGoal', sortField: 'userStory.isStretchGoal' },
	EPIC: { field: 'epic', sortField: 'userStory.epicName' },
	USER_STORY: { field: 'userStory', sortField: 'userStory.name' },
	STATUS: { field: 'status', sortField: 'userStory.status' }
};

const SprintListPage: React.FC<SprintListPageProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeSortColumn, setActiveSortColumn] = useState<string>();
	const [globalSearch, setGlobalSearch] = useState<string>('');
	const [expandedRows, setExpandedRows] = useState<any[] | DataTableExpandedRows>([]);
	const [currentPageQuery, setCurrentPageQuery] = useState<RedSky.PageQuery>();
	const [sprintListData, setSprintListData] = useState<RedSky.RsPagedResponseData<SprintListData[]>>({
		data: [],
		total: 0
	});

	const isMounted = useIsMounted();

	useEffect(() => {
		if (!currentPageQuery) return;
		getData(currentPageQuery);
	}, [version]);

	async function getData(pageQuery: RedSky.PageQuery) {
		setIsLoading(true);
		setActiveSortColumn(pageQuery.sortBy);
		setCurrentPageQuery(pageQuery);
		try {
			if (!version) return;
			// TODO: this needs to be paged - custom endpoint
			let res = await ApiRequestV1.getSprintAll({ versionId: version.id });
			let sprintData: SprintListData[] = [];
			res.forEach((sprint) => {
				if (!ObjectUtils.isArrayWithData(sprint.userStories)) {
					sprintData.push({
						name: sprint.name,
						startOn: sprint.startOn,
						endOn: sprint.endOn,
						sprintId: sprint.id
					});
				}
				sprint.userStories.forEach((story: any) => {
					sprintData.push({
						name: sprint.name,
						isStretchGoal: story.isStretchGoal,
						startOn: sprint.startOn,
						endOn: sprint.endOn,
						sprintId: sprint.id,
						userStory: story.name,
						epic: story.epicName,
						status: story.status
					});
				});
			});
			if (!isMounted) return;
			setSprintListData({ data: sprintData, total: sprintData.length });
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function renderHeaderButtons() {
		return (
			<Box display={'flex'} gap={24}>
				<Button
					look={'containedPrimary'}
					onClick={() => {
						popupController.open<AddSprintPopupProps>(AddSprintPopup, {});
					}}
				>
					Add Sprint
				</Button>
				<Icon
					iconImg={'icon-settings'}
					onClick={() => {
						if (!version) return;
						popupController.open<ConfigureSprintsPopupProps>(ConfigureSprintsPopup, {
							sprintsLeftInPhase: version?.sprintsLeftInPhase || 0,
							versionId: version?.id
						});
					}}
					className={'configureSettingsButton'}
				/>
			</Box>
		);
	}

	return (
		<Page className={'rsSprintListPage'}>
			<PageHeader title={'Sprints'} rightNode={renderHeaderButtons()} />
			<Box className={'scrolledContent'}>
				<ListDataTable
					loading={isLoading}
					onClearFilters={() => {}}
					data={sprintListData}
					getData={getData}
					globalFilter={globalSearch}
					globalFilterFields={['sprint.name']}
					onRowClick={({ data }) => {
						router.navigate(`/sprint/edit?si=${data.sprintId}`).catch(console.error);
					}}
					cellClassName={(data: SprintListData) => {
						return data === undefined ? 'hidden' : '';
					}}
					header={
						<TableSearchHeader searchValue={globalSearch} onChange={(value) => setGlobalSearch(value)} />
					}
					rowGroupMode="subheader"
					rows={500}
					groupRowsBy={FieldKeys.NAME.field}
					expandableRowGroups
					expandedRows={expandedRows}
					onRowToggle={(e) => setExpandedRows(e.data)}
					rowGroupHeaderTemplate={(data: SprintListData) => (
						<RowGroupHeader
							label={
								<Box display={'inline-flex'}>
									<Label variant={'body1'} weight={'semiBold'}>
										{data.name}
									</Label>
									<Label
										variant={'body1'}
										weight={'regular'}
										ml={8}
									>{`${DateUtils.displayFriendlyDate(
										new Date(data.startOn)
									)} - ${DateUtils.displayFriendlyDate(new Date(data.endOn))}`}</Label>
								</Box>
							}
							onEditClick={() => {
								router.navigate(`/sprint/edit?si=${data.sprintId}`).catch(console.error);
							}}
						/>
					)}
				>
					<Column
						field={FieldKeys.TYPE.field}
						sortField={FieldKeys.TYPE.sortField}
						header={
							<ColumnHeader label={'Type'} isActiveSort={activeSortColumn === FieldKeys.TYPE.sortField} />
						}
						body={(data: SprintListData) => {
							if (data.isStretchGoal === undefined) return null;
							return (
								<Label variant={'body1'} weight={'regular'}>
									{data.isStretchGoal ? 'Stretch Goal' : 'Demo Items'}
								</Label>
							);
						}}
						sortable
						filter
					/>
					<Column
						field={FieldKeys.EPIC.field}
						sortField={FieldKeys.EPIC.sortField}
						header={
							<ColumnHeader label={'Epic'} isActiveSort={activeSortColumn === FieldKeys.EPIC.sortField} />
						}
						sortable
						filter
					/>
					<Column
						field={FieldKeys.USER_STORY.field}
						sortField={FieldKeys.USER_STORY.sortField}
						header={
							<ColumnHeader
								label={'User Story'}
								isActiveSort={activeSortColumn === FieldKeys.USER_STORY.sortField}
							/>
						}
						sortable
						filter
					/>
					<Column
						field={FieldKeys.STATUS.field}
						sortField={FieldKeys.STATUS.sortField}
						header={
							<ColumnHeader
								label={'Status'}
								isActiveSort={activeSortColumn === FieldKeys.STATUS.sortField}
							/>
						}
						body={(data: SprintListData) => (
							<Box maxWidth={'fit-content'}>{data.status && <StatusChip status={data.status} />}</Box>
						)}
						sortable
						filter
					/>
				</ListDataTable>
			</Box>
		</Page>
	);
};
export default SprintListPage;
