import * as React from 'react';
import { useState } from 'react';
import './CompanyListPage.scss';
import { Page } from '@redskytech/framework/996';
import PageHeader from '../../../components/pageHeader/PageHeader';
import { Box, Button, popupController, rsToastify } from '@redskytech/framework/ui';
import ListDataTable from '../../../components/listDataTable/ListDataTable';
import TableSearchHeader from '../../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { WebUtils } from '../../../utils/utils';
import { DataTableRowClickEventParams, DataTableExpandedRows } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ColumnHeader from '../../../components/listDataTable/columnHeader/ColumnHeader';
import { ObjectUtils } from '@redskytech/framework/utils';
import AddEditCompanyPopup, { AddEditCompanyPopupProps } from '../../../popups/addEditCompanyPopup/AddEditCompanyPopup';
import AddEditProjectPopup, { AddEditProjectPopupProps } from '../../../popups/addEditProjectPopup/AddEditProjectPopup';
import RowGroupHeader from '../../../components/rowGroupHeader/RowGroupHeader';
import useIsMounted from '../../../customHooks/useIsMounted';

interface CompanyListPageProps {}

interface CompanyListData {
	companyId: number;
	companyName: string;
	projectId?: number;
	projectName?: string;
	versionId?: number;
	versionName?: string;
	phaseId?: number;
	phaseName?: string;
	isFixedBid?: boolean;
}

const FieldKeys: {
	[key in 'COMPANY' | 'PROJECT' | 'VERSION' | 'PHASE' | 'BILLING']: { field: string; sortField: string };
} = {
	COMPANY: { field: 'companyName', sortField: 'company.name' },
	PROJECT: { field: 'projectName', sortField: 'company.project.name' },
	VERSION: { field: 'versionName', sortField: 'company.project.versionName' },
	PHASE: { field: 'phaseName', sortField: 'company.project.phaseName' },
	BILLING: { field: 'isFixedBid', sortField: 'company.project.versionIsFixedBid' }
};

const CompanyListPage: React.FC<CompanyListPageProps> = (props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeSortColumn, setActiveSortColumn] = useState<string>();
	const [globalSearch, setGlobalSearch] = useState<string>('');
	const [expandedRows, setExpandedRows] = useState<any[] | DataTableExpandedRows>([]);
	const [currentPageQuery, setCurrentPageQuery] = useState<RedSky.PageQuery>();
	const isMounted = useIsMounted();

	const [companyList, setCompanyList] = useState<RedSky.RsPagedResponseData<CompanyListData[]>>({
		data: [],
		total: 0
	});

	async function getData(pageQuery: RedSky.PageQuery) {
		setIsLoading(true);
		setActiveSortColumn(pageQuery.sortBy);
		setCurrentPageQuery(pageQuery);
		try {
			if (pageQuery.sortBy === undefined) {
				delete pageQuery.sortBy;
				delete pageQuery.sortOrder;
			}
			if (!pageQuery.filter) delete pageQuery.filter;

			const res = await ApiRequestV1.getCompanyPaged({ ...pageQuery });
			const data: CompanyListData[] = [];
			res.data.forEach((company: Api.V1.Company.Paged.Get.Res) => {
				if (!ObjectUtils.isArrayWithData(company.projects)) {
					if (!company.name) return;
					data.push({ companyName: company.name, companyId: company.id });
					return;
				}
				company.projects.forEach((project) => {
					if (!ObjectUtils.isArrayWithData(project.versions)) return;
					project.versions.forEach((version) => {
						data.push({
							companyId: company.id,
							companyName: company.name || '',
							projectId: project.id,
							projectName: project.name,
							versionId: version.id,
							versionName: version.name,
							phaseId: version.currentPhaseId,
							phaseName: version.phaseName,
							isFixedBid: version.isFixedBid
						});
					});
				});
			});
			if (isMounted) setCompanyList({ ...res, data: data });
			setIsLoading(false);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	async function handleListUpdate() {
		if (!currentPageQuery) return;
		await getData(currentPageQuery);
	}

	function handleRowClick({ data }: DataTableRowClickEventParams) {
		popupController.open<AddEditProjectPopupProps>(AddEditProjectPopup, {
			onUpdate: handleListUpdate,
			projectId: data.projectId,
			companyId: data.companyId,
			name: data.projectName,
			version: data.versionName,
			versionId: data.versionId,
			phase: data.phaseName,
			phaseId: data.phaseId,
			isFixedBid: data.isFixedBid
		});
	}

	function handleEditClick(data: CompanyListData) {
		popupController.open<AddEditCompanyPopupProps>(AddEditCompanyPopup, {
			onUpdate: handleListUpdate,
			companyId: data.companyId,
			companyName: data.companyName
		});
	}

	function renderHeaderButtons() {
		return (
			<Box display={'flex'} gap={24}>
				<Button
					look={'outlinedPrimary'}
					small
					onClick={() => {
						popupController.open<AddEditProjectPopupProps>(AddEditProjectPopup, {
							onUpdate: handleListUpdate
						});
					}}
				>
					Add Project
				</Button>
				<Button
					look={'containedPrimary'}
					small
					onClick={() => {
						popupController.open<AddEditCompanyPopupProps>(AddEditCompanyPopup, {
							onUpdate: handleListUpdate
						});
					}}
				>
					Add Company
				</Button>
			</Box>
		);
	}

	return (
		<Page className={'rsCompanyListPage'}>
			<PageHeader title={'Companies'} rightNode={renderHeaderButtons()} />
			<Box className={'scrolledContent'}>
				<ListDataTable
					loading={isLoading}
					onClearFilters={() => {}}
					data={companyList}
					rows={500}
					getData={getData}
					globalFilter={globalSearch}
					globalFilterFields={['company.name']}
					onRowClick={handleRowClick}
					header={
						<TableSearchHeader searchValue={globalSearch} onChange={(value) => setGlobalSearch(value)} />
					}
					rowGroupMode="subheader"
					groupRowsBy="companyName"
					expandableRowGroups
					expandedRows={expandedRows}
					onRowToggle={(e) => {
						setExpandedRows(e.data);
					}}
					cellClassName={(data: CompanyListData) => {
						return data === undefined ? 'hidden' : '';
					}}
					rowGroupHeaderTemplate={(data: CompanyListData) => (
						<RowGroupHeader label={data.companyName} onEditClick={() => handleEditClick(data)} />
					)}
					rowGroupFooterTemplate={(data: CompanyListData) => {
						if (!data.phaseId && !data.projectId) {
							return (
								<td colSpan={4} style={{ textAlign: 'center' }}>
									No projects yet. Add new projects with the Add Project button.
								</td>
							);
						}
					}}
				>
					<Column
						field={FieldKeys.PROJECT.field}
						sortField={FieldKeys.PROJECT.sortField}
						header={
							<ColumnHeader
								label={'Project'}
								isActiveSort={activeSortColumn === FieldKeys.PROJECT.sortField}
							/>
						}
						sortable
						filter
					/>
					<Column
						field={FieldKeys.VERSION.field}
						sortField={FieldKeys.VERSION.sortField}
						header={
							<ColumnHeader
								label={'Version'}
								isActiveSort={activeSortColumn === FieldKeys.VERSION.sortField}
							/>
						}
						sortable
						filter
					/>
					<Column
						field={FieldKeys.PHASE.field}
						sortField={FieldKeys.PHASE.sortField}
						header={
							<ColumnHeader
								label={'Current Phase'}
								isActiveSort={activeSortColumn === FieldKeys.PHASE.sortField}
							/>
						}
						sortable
						filter
					/>
					<Column
						field={FieldKeys.BILLING.field}
						sortField={FieldKeys.BILLING.sortField}
						header={
							<ColumnHeader
								label={'Billing'}
								isActiveSort={activeSortColumn === FieldKeys.BILLING.sortField}
							/>
						}
						body={(row) => {
							if (row.isFixedBid === undefined) return null;
							return row.isFixedBid ? 'FixedBid' : 'Time and Materials';
						}}
						sortable
						filter
					/>
				</ListDataTable>
			</Box>
		</Page>
	);
};
export default CompanyListPage;
