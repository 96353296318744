import * as React from 'react';
import './TasksAllPage.scss';
import themes from '../../../themes/themes.scss?export';
import { Page } from '@redskytech/framework/996';
import { useEffect, useState } from 'react';
import { Box, Icon, InputText, Label, RsFormControl, RsFormGroup, rsToastify } from '@redskytech/framework/ui';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { ObjectUtils, WebUtils } from '../../../utils/utils';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import Pagination from '../../../components/pagination/Pagination';
import Paper from '../../../components/paper/Paper';
import router from '../../../utils/router';
import TaskItem, { TaskType } from '../../../components/taskItem/TaskItem';
import SectionWithHeader from '../../../components/sectionWithHeader/SectionWithHeader';

enum FormKeys {
	SEARCH = 'search'
}

interface TasksAllPageProps {}

const TasksAllPage: React.FC<TasksAllPageProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);

	const [overdueTasks, setOverdueTasks] = useState<Api.V1.Task.Paged.Get.Res[] | undefined>();
	const [inProgressTasks, setInProgressTasks] = useState<Api.V1.Task.Paged.Get.Res[] | undefined>();
	const [completeTasks, setCompleteTasks] = useState<Api.V1.Task.Paged.Get.Res[] | undefined>();

	const [total, setTotal] = useState<number>(0);
	const [pageNumber, setPageNumber] = useState<number>(1);

	const [currentSearch, setCurrentSearch] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl<string>(FormKeys.SEARCH, '', [])])
	);

	const params = router.getQueryParams<{ taskType: string }>([
		{
			key: 'by',
			default: '',
			type: 'string',
			alias: 'taskType'
		}
	]);

	useEffect(() => {
		if (pageNumber === 1) {
			fetchTasks(pageNumber);
		} else {
			setPageNumber(1);
		}
	}, [version, currentSearch]);

	useEffect(() => {
		fetchTasks(pageNumber);
	}, [pageNumber]);

	async function fetchTasks(page: number): Promise<void> {
		if (!version) return;
		try {
			const result = await ApiRequestV1.getTaskPaged({
				perPage: 10,
				page,
				versionId: version.id,
				sortBy: 'dueOn',
				filter: getFilter()
			});
			setTotal(result.total);
			saveTaskStateByType(result.data);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Could not get overdue items'));
		}
	}

	function getTaskType(): TaskType {
		return params.taskType.toUpperCase().replace('-', '_') as TaskType;
	}

	function getFilter(): string {
		if (currentSearch.get<string>(FormKeys.SEARCH).value.toString() === '') {
			if (getTaskType() === 'OVERDUE') {
				return `(column:dueOn,value:${new Date().toISOString()},type:lessThan)and(column:status,value:NOT_COMPLETED)`;
			} else if (getTaskType() === 'IN_PROGRESS') {
				return `(column:dueOn,value:${new Date().toISOString()},type:greaterThan)and(column:status,value:NOT_COMPLETED)`;
			} else {
				return `(column:status,value:COMPLETED)`;
			}
		} else {
			return `(column:name,value:${currentSearch
				.get<string>(FormKeys.SEARCH)
				.value.toString()},type:contains)and(column:status,value:${
				getTaskType() === 'COMPLETED' ? 'COMPLETED' : 'NOT_COMPLETED'
			})`;
		}
	}

	function getTaskState(): Api.V1.Task.Paged.Get.Res[] {
		if (getTaskType() === 'OVERDUE') {
			return overdueTasks || [];
		} else if (getTaskType() === 'IN_PROGRESS') {
			return inProgressTasks || [];
		} else {
			return completeTasks || [];
		}
	}

	function saveTaskStateByType(resultsToSave: Api.V1.Task.Paged.Get.Res[]) {
		if (getTaskType() === 'OVERDUE') {
			setOverdueTasks(resultsToSave);
		} else if (getTaskType() === 'IN_PROGRESS') {
			setInProgressTasks(resultsToSave);
		} else {
			setCompleteTasks(resultsToSave);
		}
	}

	function renderTasks(): React.ReactNode {
		const tasks = getTaskState().map((task) => (
			<TaskItem
				key={task.id}
				task={task}
				taskType={getTaskType()}
				onClick={() => {
					router.navigate('/task/external/details?id=' + task.id);
				}}
			/>
		));
		return (
			<SectionWithHeader
				title={`${total} RESULT${getTaskState().length > 1 ? 'S' : ''}`}
				className={'tasksContainer'}
			>
				{tasks.length > 0 ? (
					tasks
				) : (
					<Box className={'noResults'}>
						<Icon iconImg={'icon-search'} fontSize={80} color={themes.neutralBeige700} />
						<Label variant={'body1'} weight={'semiBold'}>
							No results found,
						</Label>
						<Label variant={'body1'} weight={'regular'}>
							try a different search.
						</Label>
					</Box>
				)}
				<Pagination
					itemsPerPage={10}
					totalItems={total}
					currentPage={pageNumber}
					changePage={(newPage) => setPageNumber(newPage)}
				/>
			</SectionWithHeader>
		);
	}

	return (
		<Page className={'rsTasksAllPage'}>
			<Paper>
				<Box className={'paperHeader'}>
					<Label variant={'subheader2'} weight={'extraBold'}>
						<span style={{ color: ObjectUtils.getTaskColor(getTaskType()) }}>
							{getTaskType().replace('_', ' ')}
						</span>{' '}
						TASKS
					</Label>
					<Label variant={'caption1'} weight={'regular'} color={themes.neutralBeige600}>
						Filter
					</Label>
				</Box>
				<InputText
					inputMode={'search'}
					placeholder={`Search ${getTaskType().toLowerCase().replace('_', ' ')} tasks`}
					control={currentSearch.get(FormKeys.SEARCH)}
					updateControl={(form) => setCurrentSearch(currentSearch.clone().update(form))}
					icon={[{ position: 'LEFT', iconImg: 'icon-search' }]}
				/>
			</Paper>
			{renderTasks()}
		</Page>
	);
};

export default TasksAllPage;
