import * as React from 'react';
import './TabBar.scss';
import { Box } from '@redskytech/framework/ui';
import { useEffect, useMemo, useRef } from 'react';
import router from '../../utils/router';
import useActiveViewName from '../../customHooks/useActiveViewName';
import TabBarButton from './tabBarButton/TabBarButton';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';

interface TabBarProps {}

const TabBar: React.FC<TabBarProps> = (props) => {
	const tabBarRef = useRef<HTMLDivElement | null>(null);
	const activeViewName = useActiveViewName();
	const isSmallerThan768 = useIsAtBreakpoint(767);

	/**
	 * This UseEffect controls setting the global scss variable --pageOffsetBottom; Very important for mobile app bars.
	 */
	useEffect(() => {
		if (!tabBarRef.current) return;
		let app = document.querySelector<HTMLDivElement>('.App')!;

		if (!isSmallerThan768) {
			app.style.setProperty('--pageOffsetBottom', '0px');
			return;
		}

		// Delay here so page can re-render first with tab bar at bottom so we get correct height.
		setTimeout(() => {
			if (!tabBarRef.current) return;
			let height = tabBarRef.current.offsetHeight;
			app.style.setProperty('--pageOffsetBottom', height + 'px');
		}, 100);
	}, [tabBarRef.current, isSmallerThan768]);

	const tabItems = useMemo<{ icon: string; name: string; viewName: string }[]>(() => {
		return [
			{
				icon: 'icon-dashboard',
				name: 'Home',
				viewName: 'home'
			},
			{
				icon: 'icon-budget-line',
				name: 'Project',
				viewName: 'project'
			},
			{
				icon: 'icon-artifacts',
				name: 'Artifacts',
				viewName: 'artifacts'
			},
			// {
			// 	icon: 'icon-sprint',
			// 	name: 'Sprint',
			// 	viewName: 'sprint'
			// },
			{
				icon: 'icon-assignment',
				name: 'Tasks',
				viewName: 'tasks'
			}
		];
	}, []);

	return (
		<Box elementRef={tabBarRef} className={'rsTabBar'}>
			{tabItems.map((tabItem) => {
				return (
					<TabBarButton
						key={tabItem.viewName}
						isSelected={activeViewName === tabItem.viewName}
						iconImg={tabItem.icon}
						onClick={() => {
							if (activeViewName === tabItem.viewName) router.forceHome();
							else router.changeView(tabItem.viewName);
						}}
						labelText={tabItem.name}
					/>
				);
			})}
		</Box>
	);
};

export default TabBar;
