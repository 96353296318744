import * as React from 'react';
import { useEffect, useState } from 'react';
import './EditSprintPage.scss';
import { Page } from '@redskytech/framework/996';
import PageHeader from '../../../components/pageHeader/PageHeader';
import router from '../../../utils/router';
import {
	Box,
	Button,
	Label,
	popupController,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum,
	Select
} from '@redskytech/framework/ui';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import useIsMounted from '../../../customHooks/useIsMounted';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { WebUtils } from '@redskytech/framework/utils';
import PaperHeaderBar from '../../../components/paperHeaderBar/PaperHeaderBar';
import LabelInputText from '../../../components/labelInputText/LabelInputText';
import ListDataTable from '../../../components/listDataTable/ListDataTable';
import TableSearchHeader from '../../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import RowGroupHeader from '../../../components/rowGroupHeader/RowGroupHeader';
import { DateUtils } from '../../../utils/utils';
import { DataTableExpandedRows } from 'primereact/datatable';
import ConfirmationPopup, { ConfirmationPopupProps } from '../../../popups/confirmationPopup/ConfirmationPopup';
import LoadingPopup, { LoadingPopupProps } from '../../../popups/loadingPopup/LoadingPopup';
import themes from '../../../themes/themes.scss?export';
import LabelInputRichText from '../../../components/labelInputRichText/LabelInputRichText';
import LabelSelect from '../../../components/labelSelect/LabelSelect';
import { Column } from 'primereact/column';
import ColumnHeader from '../../../components/listDataTable/columnHeader/ColumnHeader';
import StatusChip from '../../../components/statusChip/StatusChip';
import EditUserStoriesPopup, {
	EditUserStoriesPopupProps
} from '../../../popups/editUserStoriesPopup/EditUserStoriesPopup';

interface EditSprintPageProps {}

enum FormKeys {
	TITLE = 'name',
	START = 'startOn',
	END = 'endOn',
	TAKEAWAYS = 'takeaways',
	STATUS = 'isActive',
	PROGRESS = 'status',
	PLANNED_HOURS = 'plannedHours',
	COMPLETED_HOURS = 'completedHours',
	UNPLANNED_HOURS = 'unplannedHours',
	UNPLANNED_REASON = 'unplannedHoursReason'
}

interface FormData {
	name: string;
	startOn: string;
	endOn: string;
	takeaways: string;
	isActive: number;
	status: string;
	plannedHours: number;
	completedHours: number;
	unplannedHours: number;
	unplannedHoursReason: string;
}

interface StoryListData {
	type: 'Demo Items' | 'Stretch Goal';
	sprintId: number;
	epic?: string;
	userStory?: string;
	status?: 'DONE' | 'IN_PROGRESS' | 'TO_DO';
}

const FieldKeys: { [key in 'TYPE' | 'EPIC' | 'STORY' | 'STATUS']: { field: string; sortField: string } } = {
	TYPE: { field: 'type', sortField: 'isStretchGoal' },
	EPIC: { field: 'epic', sortField: 'epicName' },
	STORY: { field: 'userStory', sortField: 'storyName' },
	STATUS: { field: 'status', sortField: 'status' }
};

const EditSprintPage: React.FC<EditSprintPageProps> = (props) => {
	const { sprintId } = router.getQueryParams<{ sprintId: number }>([
		{
			key: 'si',
			default: 0,
			type: 'integer',
			alias: 'sprintId'
		}
	]);

	const [sprintDetails, setSprintDetails] = useState<Api.V1.Sprint.Get.Res>();
	const [globalSearch, setGlobalSearch] = useState<string>('');
	const [expandedRows, setExpandedRows] = useState<any[] | DataTableExpandedRows>([]);
	const [activeSortColumn, setActiveSortColumn] = useState<string>();
	const [currentPageQuery, setCurrentPageQuery] = useState<RedSky.PageQuery>();
	const [storyListData, setStoryListData] = useState<RedSky.RsPagedResponseData<StoryListData[]>>({
		data: [],
		total: 0
	});

	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.TITLE, '', [new RsValidator(RsValidatorEnum.REQ, 'Title is required')]),
			new RsFormControl<string>(FormKeys.START, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Start date is required')
			]),
			new RsFormControl<string>(FormKeys.END, '', [new RsValidator(RsValidatorEnum.REQ, 'End date is required')]),
			new RsFormControl<string>(FormKeys.TAKEAWAYS, ''),
			new RsFormControl<boolean>(FormKeys.STATUS, false),
			new RsFormControl<string>(FormKeys.PROGRESS, ''),
			new RsFormControl<number>(FormKeys.PLANNED_HOURS, 0),
			new RsFormControl<number>(FormKeys.COMPLETED_HOURS, 0),
			new RsFormControl<number>(FormKeys.UNPLANNED_HOURS, 0),
			new RsFormControl<string>(FormKeys.UNPLANNED_REASON, '')
		])
	);
	const isMounted = useIsMounted();

	const statusOptions = [
		{ label: 'Visible', value: 1 },
		{ label: 'Hidden', value: 0 }
	];

	const progressOptions = [
		{ label: 'Ahead', value: 'AHEAD' },
		{ label: 'On Track', value: 'ON_TRACK' },
		{ label: 'Behind', value: 'BEHIND' }
	];

	useEffect(() => {
		if (!sprintId) {
			rsToastify.error('Unable to get sprint details');
			router.navigate('/sprint/list').catch(console.error);
			return;
		}

		(async function getSprintDetails() {
			try {
				let res: Api.V1.Sprint.Get.Res = await ApiRequestV1.getSprint({
					id: sprintId
				});
				if (!isMounted) return;
				setSprintDetails(res);
				let updatedForm = formGroup.cloneDeep();
				updatedForm.get(FormKeys.TITLE).value = res.name || '';
				updatedForm.get(FormKeys.START).value = res.startOn.split('T')[0];
				updatedForm.get(FormKeys.END).value = res.endOn.split('T')[0];
				updatedForm.get(FormKeys.TAKEAWAYS).value = res.takeaways.length
					? res.takeaways[0].description || ''
					: '';
				updatedForm.get(FormKeys.STATUS).value = res.isActive ? 1 : 0;
				updatedForm.get(FormKeys.PROGRESS).value = res.status;
				updatedForm.get(FormKeys.PLANNED_HOURS).value = res.plannedHours || 0;
				updatedForm.get(FormKeys.COMPLETED_HOURS).value = res.completedHours || 0;
				updatedForm.get(FormKeys.UNPLANNED_HOURS).value = res.unplannedHours || 0;
				updatedForm.get(FormKeys.UNPLANNED_REASON).value = res.unplannedHoursReason || '';
				updatedForm.updateInitialValues();
				setFormGroup(updatedForm);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
			}
		})();
	}, [sprintId]);

	async function getData(pageQuery: RedSky.PageQuery) {
		setActiveSortColumn(pageQuery.sortBy);
		setCurrentPageQuery(pageQuery);
		try {
			if (pageQuery.sortBy === undefined) {
				delete pageQuery.sortBy;
				delete pageQuery.sortOrder;
			}
			if (!pageQuery.filter) delete pageQuery.filter;
			let res: RedSky.RsPagedResponseData<Api.V1.SprintStory.Paged.Get.Res[]> =
				await ApiRequestV1.getSprintStoryPaged({ ...pageQuery, sprintId });
			if (!isMounted) return;
			setStoryListData({
				data: res.data.map((story: Api.V1.SprintStory.Paged.Get.Res) => {
					return {
						sprintId: story.sprintId,
						type: story.isStretchGoal ? 'Stretch Goal' : 'Demo Items',
						epic: story.epics[0].name,
						userStory: story.storyName,
						status: story.storyStatus
					};
				}),
				total: res.total
			});
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function handleUpdate(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	async function handleSave() {
		if (!(await formGroup.isValid())) {
			setFormGroup(formGroup.clone());
			rsToastify.error('Please fix the errors in the inputs.', 'Invalid Inputs');
			return;
		}
		let { takeaways, ...formData } = formGroup.toChangedModel<FormData>();
		try {
			popupController.open<LoadingPopupProps>(LoadingPopup, {});
			if (Object.keys(formData).length !== 0) {
				await ApiRequestV1.patchSprint({ ...formData, id: sprintId });
			}
			let takeawayId = sprintDetails?.takeaways[0].id;
			if (takeaways && takeawayId) {
				await ApiRequestV1.patchSprintTakeaway({ id: takeawayId, description: takeaways });
			}
			setFormGroup(formGroup.clone().updateInitialValues());
			popupController.close(LoadingPopup);
			rsToastify.success('Sprint updated successfully');
		} catch (e) {
			popupController.close(LoadingPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error saving sprint'));
		}
	}

	async function handleDelete() {
		try {
			popupController.open<LoadingPopupProps>(LoadingPopup, {});
			await ApiRequestV1.deleteSprint({ id: sprintId });
			popupController.close(LoadingPopup);
			rsToastify.success('Sprint successfully deleted');
			router.navigate('/sprint/list').catch(console.error);
		} catch (e) {
			popupController.close(LoadingPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Error deleting sprint');
		}
	}

	return (
		<Page className={'rsEditSprintPage'}>
			<PageHeader
				title={'Edit Sprint'}
				isModified={formGroup.isModified()}
				onBack={() => {
					router.navigate('/sprint/list').catch(console.error);
				}}
				onSave={handleSave}
				onCancel={() => formGroup.resetToInitialValue()}
			/>

			<Box padding={32} className={'scrolledContent'}>
				<Box flex={'2'}>
					<PaperHeaderBar title={'Sprint Details'}>
						<LabelInputText
							label={'Title'}
							inputMode={'text'}
							control={formGroup.get(FormKeys.TITLE)}
							updateControl={handleUpdate}
							required
						/>
						<Box display={'flex'} mt={24} gap={24}>
							<LabelInputText
								className={'dateInput'}
								label={'Start Date'}
								inputMode={'text'}
								type={'date'}
								control={formGroup.get(FormKeys.START)}
								updateControl={handleUpdate}
								icon={[
									{
										position: 'LEFT',
										iconImg: 'icon-calendar',
										color: themes.neutralBeige500
									}
								]}
								required
							/>
							<LabelInputText
								className={'dateInput'}
								label={'End Date'}
								inputMode={'text'}
								type={'date'}
								control={formGroup.get(FormKeys.END)}
								updateControl={handleUpdate}
								icon={[
									{
										position: 'LEFT',
										iconImg: 'icon-calendar',
										color: themes.neutralBeige500
									}
								]}
								required
							/>
						</Box>
						<LabelInputRichText
							label={'Takeaways'}
							control={formGroup.get(FormKeys.TAKEAWAYS)}
							updateControl={handleUpdate}
							height={200}
						/>
					</PaperHeaderBar>
					<PaperHeaderBar
						title={'User Stories'}
						rightNode={
							<Button
								look={'containedPrimary'}
								onClick={() => {
									// TODO: finish crud for EditUserStoriesPopup. Should assign to sprint as isStretchGoal=true | false
									popupController.open<EditUserStoriesPopupProps>(EditUserStoriesPopup, {});
								}}
							>
								Edit Stories
							</Button>
						}
						contentPadding={'0px'}
					>
						<ListDataTable
							onClearFilters={() => {}}
							data={storyListData}
							getData={getData}
							globalFilter={globalSearch}
							globalFilterFields={['story.name']}
							onRowClick={() => {
								// TODO: finish crud for EditUserStoriesPopup. Should assign to sprint as isStretchGoal=true | false
								popupController.open<EditUserStoriesPopupProps>(EditUserStoriesPopup, {});
							}}
							header={
								<TableSearchHeader
									searchValue={globalSearch}
									onChange={(value) => setGlobalSearch(value)}
								/>
							}
							rowGroupMode="subheader"
							groupRowsBy={FieldKeys.TYPE.field}
							expandableRowGroups
							expandedRows={expandedRows}
							onRowToggle={(e) => setExpandedRows(e.data)}
							rowGroupHeaderTemplate={(data) => <RowGroupHeader label={data.type} />}
						>
							<Column
								field={FieldKeys.EPIC.field}
								sortField={FieldKeys.EPIC.sortField}
								header={
									<ColumnHeader
										label={'Epic'}
										isActiveSort={activeSortColumn === FieldKeys.EPIC.sortField}
									/>
								}
								sortable
								filter
							/>
							<Column
								field={FieldKeys.STORY.field}
								sortField={FieldKeys.STORY.sortField}
								header={
									<ColumnHeader
										label={'User Story'}
										isActiveSort={activeSortColumn === FieldKeys.STORY.sortField}
									/>
								}
								sortable
								filter
							/>
							<Column
								field={FieldKeys.STATUS.field}
								sortField={FieldKeys.STATUS.sortField}
								header={
									<ColumnHeader
										label={'Status'}
										isActiveSort={activeSortColumn === FieldKeys.STATUS.sortField}
									/>
								}
								body={(data: StoryListData) => (
									<Box maxWidth={'fit-content'}>
										{data.status && <StatusChip status={data.status} />}
									</Box>
								)}
								sortable
								filter
							/>
						</ListDataTable>
					</PaperHeaderBar>
				</Box>
				<Box flex={'1'}>
					<PaperHeaderBar title={'Status'}>
						<Select<{ label: string; value: number }>
							control={formGroup.get(FormKeys.STATUS)}
							updateControl={handleUpdate}
							options={statusOptions}
							mb={24}
						/>
						<Label variant={'body1'} weight={'regular'}>
							Created on {DateUtils.displayFriendlyDateTime(sprintDetails?.createdOn || new Date())}
						</Label>
					</PaperHeaderBar>
					<PaperHeaderBar title={'Hours'}>
						<Box display={'flex'} flexDirection={'column'} gap={24}>
							<LabelSelect
								label={'Progress'}
								control={formGroup.get(FormKeys.PROGRESS)}
								updateControl={handleUpdate}
								options={progressOptions}
							/>
							<LabelInputText
								label={'Planned Hours'}
								inputMode={'numeric'}
								control={formGroup.get(FormKeys.PLANNED_HOURS)}
								updateControl={handleUpdate}
							/>
							<LabelInputText
								label={'Completed Hours'}
								inputMode={'numeric'}
								control={formGroup.get(FormKeys.COMPLETED_HOURS)}
								updateControl={handleUpdate}
							/>
							<LabelInputText
								label={'Unplanned Hours'}
								inputMode={'numeric'}
								control={formGroup.get(FormKeys.UNPLANNED_HOURS)}
								updateControl={handleUpdate}
							/>
							<LabelInputText
								label={'Unplanned Hours Reason'}
								inputMode={'text'}
								control={formGroup.get(FormKeys.UNPLANNED_REASON)}
								updateControl={handleUpdate}
							/>
						</Box>
					</PaperHeaderBar>
					<Button
						look={'outlinedPrimary'}
						onClick={() => {
							popupController.open<ConfirmationPopupProps>(ConfirmationPopup, {
								headerLabel: 'Delete Sprint',
								label: `Are you sure you want to delete ${
									sprintDetails?.name || 'sprint'
								}? This action cannot be undone.`,
								acceptLabel: 'Delete',
								rejectLabel: 'Cancel',
								onAccept: handleDelete
							});
						}}
						className={'fullWidth'}
						mt={32}
					>
						Delete Sprint
					</Button>
				</Box>
			</Box>
		</Page>
	);
};
export default EditSprintPage;
