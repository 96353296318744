import { useEffect, useState } from 'react';
import router from '../utils/router';

export default function useActiveViewName() {
	const [activeViewName, setActiveViewName] = useState<string>('');

	useEffect(() => {
		let id = router.subscribeToActiveViewChanged((newView) => {
			setActiveViewName(newView);
		});
		return () => router.unsubscribeFromActiveViewChanged(id);
	}, []);

	return activeViewName;
}
