import * as React from 'react';
import './AddEditCompanyPopup.scss';
import {
	Box,
	Button,
	Popup,
	popupController,
	PopupProps,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { useState } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import LabelInputText from '../../components/labelInputText/LabelInputText';
import { WebUtils } from '../../utils/utils';
import ConfirmationPopup, { ConfirmationPopupProps } from '../confirmationPopup/ConfirmationPopup';
import { ApiRequestV1 } from '../../generated/apiRequests';
import LoadingPopup, { LoadingPopupProps } from '../loadingPopup/LoadingPopup';
import DiscardChangesPopup, { DiscardChangesPopupProps } from '../discardChangesPopup/DiscardChangesPopup';

export interface AddEditCompanyPopupProps extends PopupProps {
	onUpdate: () => void;
	companyId?: number;
	companyName?: string;
}

enum FormKeys {
	NAME = 'name'
}

const AddEditCompanyPopup: React.FC<AddEditCompanyPopupProps> = (props) => {
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.NAME, props.companyName || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Company name is required')
			])
		])
	);

	function handleUpdate(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function handleClose() {
		if (formGroup.isModified()) {
			popupController.open<DiscardChangesPopupProps>(DiscardChangesPopup, {});
		} else {
			popupController.close(AddEditCompanyPopup);
		}
	}

	async function handleDelete() {
		if (!props.companyId) return;
		try {
			popupController.open<LoadingPopupProps>(LoadingPopup, {});
			await ApiRequestV1.patchCompany({ id: props.companyId, isDeleted: 1 });
			props.onUpdate();
			popupController.closeAll();
			rsToastify.success('Company successfully deleted');
		} catch (e) {
			popupController.close(LoadingPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	async function handleSaveCompany() {
		if (!(await formGroup.isValid())) {
			rsToastify.error('Please fix the errors in the inputs', 'Input Error');
			setFormGroup(formGroup.clone());
			return;
		}
		popupController.open<LoadingPopupProps>(LoadingPopup, {});
		let formData = formGroup.toModel<{ name: string }>();

		try {
			if (props.companyId) {
				await ApiRequestV1.patchCompany({ ...formData, id: props.companyId });
				rsToastify.success('Company Updated', 'Success');
			} else {
				await ApiRequestV1.postCompany({ ...formData });
				rsToastify.success('Company Created', 'Success');
			}
			props.onUpdate();
			popupController.closeAll();
		} catch (e) {
			popupController.close(LoadingPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Server Error'), 'Error');
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsAddEditCompanyPopup'}>
				<PopupHeader title={`${props.companyId ? 'Edit Company' : 'Add Company'}`} onClose={handleClose} />
				<Box className={'scrolledContent'}>
					<LabelInputText
						label={'Name'}
						inputMode={'text'}
						control={formGroup.get(FormKeys.NAME)}
						updateControl={handleUpdate}
						required
					/>
					<Box className={'buttonGroup'}>
						{!!props.companyId && (
							<Button
								look={'iconSecondary'}
								icon={[{ iconImg: 'icon-trash', position: 'LEFT' }]}
								onClick={() => {
									popupController.open<ConfirmationPopupProps>(ConfirmationPopup, {
										headerLabel: 'Delete Company',
										label: 'Are you sure you want to delete company? This action cannot be undone.',
										acceptLabel: 'Delete',
										rejectLabel: 'Cancel',
										onAccept: handleDelete
									});
								}}
							/>
						)}
						<Button look={'outlinedPrimary'} fullWidth onClick={handleClose}>
							Cancel
						</Button>
						<Button
							look={'containedPrimary'}
							fullWidth
							disabled={!formGroup.isModified()}
							onClick={handleSaveCompany}
						>
							{!!props.companyId ? 'Save' : 'Create'}
						</Button>
					</Box>
				</Box>
			</Box>
		</Popup>
	);
};
export default AddEditCompanyPopup;
