import * as React from 'react';
import './TeamSection.scss';
import { Box, Label } from '@redskytech/framework/ui';
import TeamAvatar from '../../components/teamAvatar/TeamAvatar';
import SectionWithHeader from '../../components/sectionWithHeader/SectionWithHeader';

interface TeamSectionProps {
	teams: Api.V1.Team.Version.Get.Res[];
}

const TeamSection: React.FC<TeamSectionProps> = (props) => {
	function renderTeams() {
		let teamMap = props.teams.map((team, index) => {
			return (
				<Box className={'teamContainer'} key={team.name + index + team.id}>
					<Label variant={'body2'} weight={'semiBold'} textAlign={'center'}>
						{team.name}
					</Label>
					<TeamAvatar
						teamRoster={team.teamMembers.map(
							(teamMember: Api.V1.Team.Version.Get.Res['teamMembers'][0]) => {
								return {
									firstName: teamMember.userFirstName,
									lastName: teamMember.userLastName,
									avatarUrl: teamMember.userAvatarUrl
								};
							}
						)}
					/>
				</Box>
			);
		});
		return teamMap;
	}

	return (
		<SectionWithHeader title={'TEAMS'} className={'rsTeamSection'} viewAllLink={'/team'}>
			<Box className={'teamGrid'}>{renderTeams()}</Box>
		</SectionWithHeader>
	);
};

export default TeamSection;
