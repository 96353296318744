import React, { useEffect } from 'react';
import './Switch.scss';
import { Box, Label } from '@redskytech/framework/ui';

export interface SwitchProps {
	className?: string;
	checked?: boolean;
	name?: string;
	value?: string | number;
	disabled?: boolean;
	onChange?: (checked: boolean) => void;
}

const Switch: React.FC<SwitchProps> = (props) => {
	const name = props.name || `RsSwitch_${Date.now()}`;

	const [checked, setChecked] = React.useState(props.checked ?? false);
	const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
		if (props.onChange) props.onChange(event.target.checked);
	};

	useEffect(() => {
		setChecked(props.checked ?? false);
	}, [props.checked]);

	return (
		<Box id={name} className={'rsSwitch'}>
			<label className={'rsSwitchControl'}>
				<Label variant={'caption1'} weight={'regular'} className={checked ? 'checked' : ''}>
					{checked ? 'On' : 'Off'}
				</Label>
				<input
					id={`${name}_checkbox`}
					name={`${name}_checkbox`}
					type="checkbox"
					value={props.value}
					checked={checked}
					onChange={changeHandler}
					disabled={props.disabled}
				/>
				<span className={'slider round'}></span>
			</label>
		</Box>
	);
};

export default Switch;
