import * as React from 'react';
import './TeamAvatar.scss';
import { Avatar, Box } from '@redskytech/framework/ui';

interface TeamAvatarProps {
	teamRoster: {
		firstName: string;
		lastName: string;
		avatarUrl?: string;
	}[];
}

const TeamAvatar: React.FC<TeamAvatarProps> = (props) => {
	function renderTeam() {
		let leftSpacing = 0;

		const team = props.teamRoster.map((member, index) => {
			if (index !== 0 && index < 5) {
				leftSpacing += 24;
			}
			if (index <= 5) {
				return (
					<Box
						className={'avatarContainer'}
						key={member.lastName + member.firstName + index}
						style={{ left: leftSpacing }}
					>
						<Avatar
							widthHeight={32}
							name={member.firstName + ' ' + member.lastName}
							image={member.avatarUrl}
						/>
					</Box>
				);
			}
		});
		if (props.teamRoster.length > 5) {
			let plusNumber = props.teamRoster.length - 4;
			team.push(
				<Box className={'avatarContainer'} key={'extra '} style={{ left: leftSpacing }}>
					<Avatar widthHeight={32} name={'+ ' + plusNumber.toString()} />
				</Box>
			);
		}
		return team;
	}

	return (
		<Box className={'rsTeamAvatar'} style={{ width: 24 * props.teamRoster.length + 'px' }}>
			{renderTeam()}
		</Box>
	);
};

export default TeamAvatar;
