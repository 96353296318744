import http from '../utils/http';

/**
 * Api v1 Endpoints
 * @summary Api v1 
 */
export namespace ApiRequestV1 {
	/**
	 * Logs In a user using their username, typically email, and a passwords
	 * @summary User Login 
	 */
	export async function postUserLogin(req: Api.V1.User.Login.Post.Req) : Promise<CustomTypes.LoginResponse> {
		let res = await http.post<RedSky.RsResponseData<CustomTypes.LoginResponse>, Api.V1.User.Login.Post.Req>('/user/login', req);
		return res.data.data;
	}

	/**
	 * Refresh an old, possibly expired token and returns a new token.
	 * @summary Re-Authenticates a Token 
	 */
	export async function postUserReAuth(req: Api.V1.User.ReAuth.Post.Req) : Promise<CustomTypes.ReAuthResponse> {
		let res = await http.post<RedSky.RsResponseData<CustomTypes.ReAuthResponse>, Api.V1.User.ReAuth.Post.Req>('/user/re-auth', req);
		return res.data.data;
	}

	/**
	 * Returns User details for the current user given their token
	 * @summary User Details 
	 */
	export async function getUser() : Promise<Api.V1.User.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.User.Get.Res>, void>('/user');
		return res.data.data;
	}

	/**
	 * Gets the version details for the given id.
	 * @summary Version Details 
	 */
	export async function getVersion(req: Api.V1.Version.Get.Req) : Promise<Api.V1.Version.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Version.Get.Res>, Api.V1.Version.Get.Req>('/version', req);
		return res.data.data;
	}

	/**
	 * Get tasks in a paginated list for the given project
	 * @summary Tasks Paged 
	 */
	export async function getTaskPaged(req: Api.V1.Task.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.Task.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.Task.Paged.Get.Res[]>, Api.V1.Task.Paged.Get.Req>('/task/paged', req);
		return res.data;
	}

	/**
	 * Update a task
	 * @summary Update Task 
	 */
	export async function patchTask(req: Api.V1.Task.Patch.Req) : Promise<Api.V1.Task.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.Task.Patch.Res>, Api.V1.Task.Patch.Req>('/task', req);
		return res.data.data;
	}

	/**
	 * Returns all Phases for a Version
	 * @summary Phases For Version 
	 */
	export async function getPhaseVersion(req: Api.V1.Phase.Version.Get.Req) : Promise<Api.V1.Phase.Version.Get.Res[]> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Phase.Version.Get.Res[]>, Api.V1.Phase.Version.Get.Req>('/phase/version', req);
		return res.data.data;
	}

	/**
	 * Returns a Paginated List of Tags
	 * @summary Unique Tags Paged 
	 */
	export async function getTagUniqueAll() : Promise<Api.V1.Tag.Unique.All.Get.Res[]> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Tag.Unique.All.Get.Res[]>, void>('/tag/unique/all');
		return res.data.data;
	}

	/**
	 * Returns the paginated list of tags
	 * @summary Tag Paged 
	 */
	export async function getTagPaged(req: Api.V1.Tag.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.Tag.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.Tag.Paged.Get.Res[]>, Api.V1.Tag.Paged.Get.Req>('/tag/paged', req);
		return res.data;
	}

	/**
	 * Returns all the teams for a give version number
	 * @summary Teams For Version 
	 */
	export async function getTeamVersion(req: Api.V1.Team.Version.Get.Req) : Promise<Api.V1.Team.Version.Get.Res[]> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Team.Version.Get.Res[]>, Api.V1.Team.Version.Get.Req>('/team/version', req);
		return res.data.data;
	}

	/**
	 * Adds or deletes multiple tags on the given id
	 * @summary Add or Delete Multiple Tags 
	 */
	export async function putTagMany(req: Api.V1.Tag.Many.Put.Req) : Promise<boolean> {
		let res = await http.put<RedSky.RsResponseData<boolean>, Api.V1.Tag.Many.Put.Req>('/tag/many', req);
		return res.data.data;
	}

	/**
	 * Gets User Details for the External site
	 * @summary Get User Public 
	 */
	export async function getUserPublic(req: Api.V1.User.Public.Get.Req) : Promise<Api.V1.User.Public.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.User.Public.Get.Res>, Api.V1.User.Public.Get.Req>('/user/public', req);
		return res.data.data;
	}

	/**
	 * Deletes a Task given by id.
	 * @summary Delete Task 
	 */
	export async function deleteTask(req: Api.V1.Task.Delete.Req) : Promise<Api.V1.Task.Delete.Res> {
		let res = await http.delete<RedSky.RsResponseData<Api.V1.Task.Delete.Res>, Api.V1.Task.Delete.Req>('/task', req);
		return res.data.data;
	}

	/**
	 * Creates a new Task 
	 * @summary New Task 
	 */
	export async function postTask(req: Api.V1.Task.Post.Req) : Promise<Api.V1.Task.Post.Res> {
		let res = await http.post<RedSky.RsResponseData<Api.V1.Task.Post.Res>, Api.V1.Task.Post.Req>('/task', req);
		return res.data.data;
	}

	/**
	 * Paginated List of Roles for a company
	 * @summary Roles Paged 
	 */
	export async function getTeamRolePaged(req: Api.V1.TeamRole.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.TeamRole.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.TeamRole.Paged.Get.Res[]>, Api.V1.TeamRole.Paged.Get.Req>('/team-role/paged', req);
		return res.data;
	}

	/**
	 * Updates a team role
	 * @summary Updates Team Role 
	 */
	export async function patchTeamRole(req: Api.V1.TeamRole.Patch.Req) : Promise<Api.V1.TeamRole.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.TeamRole.Patch.Res>, Api.V1.TeamRole.Patch.Req>('/team-role', req);
		return res.data.data;
	}

	/**
	 * Creates a new team role
	 * @summary Create Team Role 
	 */
	export async function postTeamRole(req: Api.V1.TeamRole.Post.Req) : Promise<Api.V1.TeamRole.Post.Res> {
		let res = await http.post<RedSky.RsResponseData<Api.V1.TeamRole.Post.Res>, Api.V1.TeamRole.Post.Req>('/team-role', req);
		return res.data.data;
	}

	/**
	 * Deletes a team role
	 * @summary Removes a team role 
	 */
	export async function deleteTeamRole(req: Api.V1.TeamRole.Delete.Req) : Promise<Api.V1.TeamRole.Delete.Res> {
		let res = await http.delete<RedSky.RsResponseData<Api.V1.TeamRole.Delete.Res>, Api.V1.TeamRole.Delete.Req>('/team-role', req);
		return res.data.data;
	}

	/**
	 * Returns the team map
	 * @summary Team Map Paged 
	 */
	export async function getTeamMapPaged(req: Api.V1.TeamMap.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.TeamMap.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.TeamMap.Paged.Get.Res[]>, Api.V1.TeamMap.Paged.Get.Req>('/team-map/paged', req);
		return res.data;
	}

	/**
	 * Creates a New Team
	 * @summary Create Team 
	 */
	export async function postTeam(req: Api.V1.Team.Post.Req) : Promise<Api.V1.Team.Post.Res> {
		let res = await http.post<RedSky.RsResponseData<Api.V1.Team.Post.Res>, Api.V1.Team.Post.Req>('/team', req);
		return res.data.data;
	}

	/**
	 * Updates a team
	 * @summary Team Update 
	 */
	export async function patchTeam(req: Api.V1.Team.Patch.Req) : Promise<Api.V1.Team.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.Team.Patch.Res>, Api.V1.Team.Patch.Req>('/team', req);
		return res.data.data;
	}

	/**
	 * Deletes a Team and Team Map links
	 * @summary Delete Team 
	 */
	export async function deleteTeam(req: Api.V1.Team.Delete.Req) : Promise<Api.V1.Team.Delete.Res> {
		let res = await http.delete<RedSky.RsResponseData<Api.V1.Team.Delete.Res>, Api.V1.Team.Delete.Req>('/team', req);
		return res.data.data;
	}

	/**
	 * Posts a Note from a user about a task.
	 * @summary Post a Task Note 
	 */
	export async function postTaskNote(req: Api.V1.TaskNote.Post.Req) : Promise<Api.V1.TaskNote.Post.Res> {
		let res = await http.post<RedSky.RsResponseData<Api.V1.TaskNote.Post.Res>, Api.V1.TaskNote.Post.Req>('/task-note', req);
		return res.data.data;
	}

	/**
	 * Get Task Notes for an id
	 * @summary Post a Task Note 
	 */
	export async function getTaskNoteAll(req: Api.V1.TaskNote.All.Get.Req) : Promise<Api.V1.TaskNote.All.Get.Res[]> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.TaskNote.All.Get.Res[]>, Api.V1.TaskNote.All.Get.Req>('/task-note/all', req);
		return res.data.data;
	}

	/**
	 * Gets all meetings by company and version
	 * @summary Get Meetings 
	 */
	export async function getMeetingPaged(req: Api.V1.Meeting.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.Meeting.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.Meeting.Paged.Get.Res[]>, Api.V1.Meeting.Paged.Get.Req>('/meeting/paged', req);
		return res.data;
	}

	/**
	 * Returns all the Users for a company
	 * @summary User Paged 
	 */
	export async function getUserPaged(req: Api.V1.User.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.User.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.User.Paged.Get.Res[]>, Api.V1.User.Paged.Get.Req>('/user/paged', req);
		return res.data;
	}

	/**
	 * Remove a Team Member from Team Map
	 * @summary Team Map Delete 
	 */
	export async function deleteTeamMap(req: Api.V1.TeamMap.Delete.Req) : Promise<Api.V1.TeamMap.Delete.Res> {
		let res = await http.delete<RedSky.RsResponseData<Api.V1.TeamMap.Delete.Res>, Api.V1.TeamMap.Delete.Req>('/team-map', req);
		return res.data.data;
	}

	/**
	 * Creates a new team member
	 * @summary Team Member Create 
	 */
	export async function postTeamMap(req: Api.V1.TeamMap.Post.Req) : Promise<Api.V1.TeamMap.Post.Res> {
		let res = await http.post<RedSky.RsResponseData<Api.V1.TeamMap.Post.Res>, Api.V1.TeamMap.Post.Req>('/team-map', req);
		return res.data.data;
	}

	/**
	 * Updates a team member role or user
	 * @summary Update Team Member 
	 */
	export async function patchTeamMap(req: Api.V1.TeamMap.Patch.Req) : Promise<Api.V1.TeamMap.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.TeamMap.Patch.Res>, Api.V1.TeamMap.Patch.Req>('/team-map', req);
		return res.data.data;
	}

	/**
	 * Get all meeting types for a company
	 * @summary Meeting Types 
	 */
	export async function getMeetingTypeAll() : Promise<Api.V1.MeetingType.All.Get.Res[]> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.MeetingType.All.Get.Res[]>, void>('/meeting-type/all');
		return res.data.data;
	}

	/**
	 * Updates the details of the version
	 * @summary Update Version Details 
	 */
	export async function patchVersion(req: Api.V1.Version.Patch.Req) : Promise<Api.V1.Version.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.Version.Patch.Res>, Api.V1.Version.Patch.Req>('/version', req);
		return res.data.data;
	}

	/**
	 * Create a new meeting
	 * @summary Create Meeting 
	 */
	export async function postMeeting(req: Api.V1.Meeting.Post.Req) : Promise<Api.V1.Meeting.Post.Res> {
		let res = await http.post<RedSky.RsResponseData<Api.V1.Meeting.Post.Res>, Api.V1.Meeting.Post.Req>('/meeting', req);
		return res.data.data;
	}

	/**
	 * Updates user information
	 * @summary User Details 
	 */
	export async function patchUser(req: Api.V1.User.Patch.Req) : Promise<Api.V1.User.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.User.Patch.Res>, Api.V1.User.Patch.Req>('/user', req);
		return res.data.data;
	}

	/**
	 * Custom Endpoint that Updates the user's password.
	 * @summary Update User Password 
	 */
	export async function putUserUpdatePassword(req: Api.V1.User.UpdatePassword.Put.Req) : Promise<boolean> {
		let res = await http.put<RedSky.RsResponseData<boolean>, Api.V1.User.UpdatePassword.Put.Req>('/user/update-password', req);
		return res.data.data;
	}

	/**
	 * Create a new meeting
	 * @summary Create Meeting 
	 */
	export async function patchMeeting(req: Api.V1.Meeting.Patch.Req) : Promise<Api.V1.Meeting.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.Meeting.Patch.Res>, Api.V1.Meeting.Patch.Req>('/meeting', req);
		return res.data.data;
	}

	/**
	 * Delete a new meeting
	 * @summary Delete Meeting 
	 */
	export async function deleteMeeting(req: Api.V1.Meeting.Delete.Req) : Promise<Api.V1.Meeting.Delete.Res> {
		let res = await http.delete<RedSky.RsResponseData<Api.V1.Meeting.Delete.Res>, Api.V1.Meeting.Delete.Req>('/meeting', req);
		return res.data.data;
	}

	/**
	 * get meetings grouped by meeting type
	 * @summary getGroupedMeetings 
	 */
	export async function getMeetingGrouped(req: Api.V1.Meeting.Grouped.Get.Req) : Promise<Api.V1.Meeting.Grouped.Get.Res[]> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Meeting.Grouped.Get.Res[]>, Api.V1.Meeting.Grouped.Get.Req>('/meeting/grouped', req);
		return res.data.data;
	}

	/**
	 * Create a new company
	 * @summary Create Company 
	 */
	export async function postCompany(req: Api.V1.Company.Post.Req) : Promise<Api.V1.Company.Post.Res> {
		let res = await http.post<RedSky.RsResponseData<Api.V1.Company.Post.Res>, Api.V1.Company.Post.Req>('/company', req);
		return res.data.data;
	}

	/**
	 * Update a company
	 * @summary Update Company 
	 */
	export async function patchCompany(req: Api.V1.Company.Patch.Req) : Promise<Api.V1.Company.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.Company.Patch.Res>, Api.V1.Company.Patch.Req>('/company', req);
		return res.data.data;
	}

	/**
	 * Gets all companies
	 * @summary Get All Companies 
	 */
	export async function getCompanyAll() : Promise<Api.V1.Company.All.Get.Res[]> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Company.All.Get.Res[]>, void>('/company/all');
		return res.data.data;
	}

	/**
	 * get all artifacts for project
	 * @summary All artifacts 
	 */
	export async function getArtifactAll(req: Api.V1.Artifact.All.Get.Req) : Promise<Api.V1.Artifact.All.Get.Res[]> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Artifact.All.Get.Res[]>, Api.V1.Artifact.All.Get.Req>('/artifact/all', req);
		return res.data.data;
	}

	/**
	 * Delete project
	 * @summary Delete Project 
	 */
	export async function deleteProject(req: Api.V1.Project.Delete.Req) : Promise<boolean> {
		let res = await http.delete<RedSky.RsResponseData<boolean>, Api.V1.Project.Delete.Req>('/project', req);
		return res.data.data;
	}

	/**
	 * Create a new project
	 * @summary Create Project 
	 */
	export async function postProject(req: Api.V1.Project.Post.Req) : Promise<Api.V1.Project.Post.Res> {
		let res = await http.post<RedSky.RsResponseData<Api.V1.Project.Post.Res>, Api.V1.Project.Post.Req>('/project', req);
		return res.data.data;
	}

	/**
	 * Update a project
	 * @summary Update Project 
	 */
	export async function patchProject(req: Api.V1.Project.Patch.Req) : Promise<Api.V1.Project.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.Project.Patch.Res>, Api.V1.Project.Patch.Req>('/project', req);
		return res.data.data;
	}

	/**
	 * Gets paginated list of companies
	 * @summary Get Companies Paged 
	 */
	export async function getCompanyPaged(req: Api.V1.Company.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.Company.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.Company.Paged.Get.Res[]>, Api.V1.Company.Paged.Get.Req>('/company/paged', req);
		return res.data;
	}

	/**
	 * Returns phases paged
	 * @summary Phases Paged 
	 */
	export async function getPhasePaged(req: Api.V1.Phase.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.Phase.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.Phase.Paged.Get.Res[]>, Api.V1.Phase.Paged.Get.Req>('/phase/paged', req);
		return res.data;
	}

	/**
	 * get a paged list of artifacts
	 * @summary paged artifacts 
	 */
	export async function getArtifactPaged(req: Api.V1.Artifact.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.Artifact.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.Artifact.Paged.Get.Res[]>, Api.V1.Artifact.Paged.Get.Req>('/artifact/paged', req);
		return res.data;
	}

	/**
	 * Create a new phase
	 * @summary Create Phase 
	 */
	export async function postPhase(req: Api.V1.Phase.Post.Req) : Promise<Api.V1.Phase.Post.Res> {
		let res = await http.post<RedSky.RsResponseData<Api.V1.Phase.Post.Res>, Api.V1.Phase.Post.Req>('/phase', req);
		return res.data.data;
	}

	/**
	 * Update a phase
	 * @summary Update Phase 
	 */
	export async function patchPhase(req: Api.V1.Phase.Patch.Req) : Promise<Api.V1.Phase.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.Phase.Patch.Res>, Api.V1.Phase.Patch.Req>('/phase', req);
		return res.data.data;
	}

	/**
	 * Create a new version
	 * @summary Create Version 
	 */
	export async function postVersion(req: Api.V1.Version.Post.Req) : Promise<CustomTypes.VersionCreate> {
		let res = await http.post<RedSky.RsResponseData<CustomTypes.VersionCreate>, Api.V1.Version.Post.Req>('/version', req);
		return res.data.data;
	}

	/**
	 * get single artifact
	 * @summary get artifact 
	 */
	export async function getArtifact(req: Api.V1.Artifact.Get.Req) : Promise<Api.V1.Artifact.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Artifact.Get.Res>, Api.V1.Artifact.Get.Req>('/artifact', req);
		return res.data.data;
	}

	/**
	 * Gets a project by id
	 * @summary Get Project By Id 
	 */
	export async function getProject(req: Api.V1.Project.Get.Req) : Promise<Api.V1.Project.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Project.Get.Res>, Api.V1.Project.Get.Req>('/project', req);
		return res.data.data;
	}

	/**
	 * get sprints
	 * @summary sprints 
	 */
	export async function getSprintAll(req: Api.V1.Sprint.All.Get.Req) : Promise<CustomTypes.Sprint[]> {
		let res = await http.get<RedSky.RsResponseData<CustomTypes.Sprint[]>, Api.V1.Sprint.All.Get.Req>('/sprint/all', req);
		return res.data.data;
	}

	/**
	 * get epics by page
	 * @summary epics by page 
	 */
	export async function getEpicPaged(req: Api.V1.Epic.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.Epic.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.Epic.Paged.Get.Res[]>, Api.V1.Epic.Paged.Get.Req>('/epic/paged', req);
		return res.data;
	}

	/**
	 * Create a new artifact
	 * @summary Create Artifact 
	 */
	export async function postArtifact(req: Api.V1.Artifact.Post.Req) : Promise<Api.V1.Artifact.Post.Res> {
		let res = await http.post<RedSky.RsResponseData<Api.V1.Artifact.Post.Res>, Api.V1.Artifact.Post.Req>('/artifact', req);
		return res.data.data;
	}

	/**
	 * Delete an artifact
	 * @summary Delete Artifact 
	 */
	export async function deleteArtifact(req: Api.V1.Artifact.Delete.Req) : Promise<Api.V1.Artifact.Delete.Res> {
		let res = await http.delete<RedSky.RsResponseData<Api.V1.Artifact.Delete.Res>, Api.V1.Artifact.Delete.Req>('/artifact', req);
		return res.data.data;
	}

	/**
	 * Update an artifact
	 * @summary Update Artifact 
	 */
	export async function patchArtifact(req: Api.V1.Artifact.Patch.Req) : Promise<Api.V1.Artifact.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.Artifact.Patch.Res>, Api.V1.Artifact.Patch.Req>('/artifact', req);
		return res.data.data;
	}

	/**
	 * get available versions for specific user
	 * @summary Get available versions 
	 */
	export async function getVersionMe() : Promise<CustomTypes.VersionDetails[]> {
		let res = await http.get<RedSky.RsResponseData<CustomTypes.VersionDetails[]>, void>('/version/me');
		return res.data.data;
	}

	/**
	 * Creates a new user
	 * @summary Create User 
	 */
	export async function postUser(req: Api.V1.User.Post.Req) : Promise<CustomTypes.FilteredUser> {
		let res = await http.post<RedSky.RsResponseData<CustomTypes.FilteredUser>, Api.V1.User.Post.Req>('/user', req);
		return res.data.data;
	}

	/**
	 * Deletes a user
	 * @summary Delete User 
	 */
	export async function deleteUser(req: Api.V1.User.Delete.Req) : Promise<Api.V1.User.Delete.Res> {
		let res = await http.delete<RedSky.RsResponseData<Api.V1.User.Delete.Res>, Api.V1.User.Delete.Req>('/user', req);
		return res.data.data;
	}

	/**
	 * Creates a new companyUser
	 * @summary Create CompanyUser 
	 */
	export async function postCompanyUser(req: Api.V1.CompanyUser.Post.Req) : Promise<Api.V1.CompanyUser.Post.Res> {
		let res = await http.post<RedSky.RsResponseData<Api.V1.CompanyUser.Post.Res>, Api.V1.CompanyUser.Post.Req>('/company-user', req);
		return res.data.data;
	}

	/**
	 * Create a new sprint
	 * @summary Create Sprint 
	 */
	export async function postSprint(req: Api.V1.Sprint.Post.Req) : Promise<Api.V1.Sprint.Post.Res> {
		let res = await http.post<RedSky.RsResponseData<Api.V1.Sprint.Post.Res>, Api.V1.Sprint.Post.Req>('/sprint', req);
		return res.data.data;
	}

	/**
	 * get list of teams for version
	 * @summary team list 
	 */
	export async function getTeamMap(req: Api.V1.TeamMap.Get.Req) : Promise<Api.V1.TeamMap.Get.Res[]> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.TeamMap.Get.Res[]>, Api.V1.TeamMap.Get.Req>('/team-map', req);
		return res.data.data;
	}

	/**
	 * Updates a companyUser
	 * @summary Update CompanyUser 
	 */
	export async function patchCompanyUser(req: Api.V1.CompanyUser.Patch.Req) : Promise<Api.V1.CompanyUser.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.CompanyUser.Patch.Res>, Api.V1.CompanyUser.Patch.Req>('/company-user', req);
		return res.data.data;
	}

	/**
	 * Get company for a user
	 * @summary Get User Company 
	 */
	export async function getCompanyUser(req: Api.V1.CompanyUser.Get.Req) : Promise<Api.V1.CompanyUser.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.CompanyUser.Get.Res>, Api.V1.CompanyUser.Get.Req>('/company-user', req);
		return res.data.data;
	}

	/**
	 * Delete a sprint
	 * @summary Delete Sprint 
	 */
	export async function deleteSprint(req: Api.V1.Sprint.Delete.Req) : Promise<Api.V1.Sprint.Delete.Res> {
		let res = await http.delete<RedSky.RsResponseData<Api.V1.Sprint.Delete.Res>, Api.V1.Sprint.Delete.Req>('/sprint', req);
		return res.data.data;
	}

	/**
	 * Get user stories paged
	 * @summary Get Stories Paged 
	 */
	export async function getSprintStoryPaged(req: Api.V1.SprintStory.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.SprintStory.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.SprintStory.Paged.Get.Res[]>, Api.V1.SprintStory.Paged.Get.Req>('/sprint-story/paged', req);
		return res.data;
	}

	/**
	 * Get sprint details by Id
	 * @summary Get Sprint by Id 
	 */
	export async function getSprint(req: Api.V1.Sprint.Get.Req) : Promise<Api.V1.Sprint.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Sprint.Get.Res>, Api.V1.Sprint.Get.Req>('/sprint', req);
		return res.data.data;
	}

	/**
	 * Update Sprint details
	 * @summary Update Sprint 
	 */
	export async function patchSprint(req: Api.V1.Sprint.Patch.Req) : Promise<Api.V1.Sprint.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.Sprint.Patch.Res>, Api.V1.Sprint.Patch.Req>('/sprint', req);
		return res.data.data;
	}

	/**
	 * Update Sprint Takeaway
	 * @summary Update Sprint Takeaway 
	 */
	export async function patchSprintTakeaway(req: Api.V1.SprintTakeaway.Patch.Req) : Promise<Api.V1.SprintTakeaway.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.SprintTakeaway.Patch.Res>, Api.V1.SprintTakeaway.Patch.Req>('/sprint-takeaway', req);
		return res.data.data;
	}

	/**
	 * Get Phase By Id
	 * @summary Get Phase By Id 
	 */
	export async function getPhase(req: Api.V1.Phase.Get.Req) : Promise<Api.V1.Phase.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Phase.Get.Res>, Api.V1.Phase.Get.Req>('/phase', req);
		return res.data.data;
	}

	/**
	 * Set a default version for user if it doesn't exist, otherwise update
	 * @summary Set Default Version 
	 */
	export async function postUserDefaultVersion(req: Api.V1.User.DefaultVersion.Post.Req) : Promise<boolean> {
		let res = await http.post<RedSky.RsResponseData<boolean>, Api.V1.User.DefaultVersion.Post.Req>('/user/default-version', req);
		return res.data.data;
	}

}
