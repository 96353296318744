import * as React from 'react';
import './ArtifactListPage.scss';
import { Page } from '@redskytech/framework/996';
import PageHeader from '../../../components/pageHeader/PageHeader';
import { Box, Button, Label, popupController, rsToastify } from '@redskytech/framework/ui';
import ListDataTable from '../../../components/listDataTable/ListDataTable';
import TableSearchHeader from '../../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import { useEffect, useState } from 'react';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { WebUtils } from '../../../utils/utils';
import { Column } from 'primereact/column';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import ColumnHeader from '../../../components/listDataTable/columnHeader/ColumnHeader';
import { StringUtils } from '@redskytech/framework/utils';
import AddArtifactPopup, { AddArtifactPopupProps } from '../../../popups/addArtifactPopup/AddArtifactPopup';
import router from '../../../utils/router';
import useIsMounted from '../../../customHooks/useIsMounted';
import themes from '../../../themes/themes.scss?export';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';

interface ArtifactListPageProps {}

const FieldKeys: {
	[key in 'TYPE' | 'TITLE' | 'DESCRIPTION' | 'TAGS' | 'APPROVAL' | 'STATUS']: { field: string; sortField: string };
} = {
	TYPE: { field: 'type', sortField: 'type' },
	TITLE: { field: 'name', sortField: 'name' },
	DESCRIPTION: { field: 'description', sortField: 'description' },
	TAGS: { field: 'tag', sortField: 'tag' },
	APPROVAL: { field: 'approval', sortField: 'approval' },
	STATUS: { field: 'isActive', sortField: 'isActive' }
};

const ArtifactListPage: React.FC<ArtifactListPageProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeSortColumn, setActiveSortColumn] = useState<string>();
	const [globalSearch, setGlobalSearch] = useState<string>('');
	const [artifactList, setArtifactList] = useState<RedSky.RsPagedResponseData<Api.V1.Artifact.Paged.Get.Res[]>>({
		data: [],
		total: 0
	});
	const [currentQuery, setCurrentQuery] = useState<RedSky.PageQuery>();
	const isMounted = useIsMounted();

	useEffect(() => {
		if (!currentQuery) return;
		getData(currentQuery);
	}, [version]);

	async function getData(pageQuery: RedSky.PageQuery) {
		setIsLoading(true);
		setActiveSortColumn(pageQuery.sortBy);
		setCurrentQuery(pageQuery);
		try {
			if (!version) return;
			if (pageQuery.sortBy === undefined) {
				delete pageQuery.sortBy;
				delete pageQuery.sortOrder;
			}
			if (!pageQuery.filter) delete pageQuery.filter;

			const res = await ApiRequestV1.getArtifactPaged({ ...pageQuery, versionId: version.id });
			if (isMounted) setArtifactList(res);
			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function handleRowClick({ data }: DataTableRowClickEventParams) {
		router.navigate(`/artifact/edit?ai=${data.id}`);
	}

	return (
		<Page className={'rsArtifactListPage'}>
			<PageHeader
				title={'Artifacts'}
				rightNode={
					<Button
						look={'containedPrimary'}
						onClick={() => {
							popupController.open<AddArtifactPopupProps>(AddArtifactPopup, {});
						}}
					>
						Add Artifact
					</Button>
				}
			/>
			<Box padding={32} className={'scrolledContent'}>
				<ListDataTable
					loading={isLoading}
					onClearFilters={() => {}}
					data={artifactList}
					getData={getData}
					globalFilter={globalSearch}
					globalFilterFields={['artifact.type', 'artifact.name', 'artifact.description']}
					onRowClick={handleRowClick}
					header={
						<TableSearchHeader searchValue={globalSearch} onChange={(value) => setGlobalSearch(value)} />
					}
				>
					<Column
						field={FieldKeys.TYPE.field}
						header={
							<ColumnHeader label={'Type'} isActiveSort={activeSortColumn === FieldKeys.TYPE.sortField} />
						}
						body={(data: Api.V1.Artifact.Paged.Get.Res) => {
							return (
								<Label variant={'body1'} weight={'regular'} textTransform={'capitalize'}>
									{StringUtils.snakeCaseToHuman(data.type).toLowerCase()}
								</Label>
							);
						}}
						sortable
						sortField={FieldKeys.TYPE.sortField}
						filter
					/>
					<Column
						field={FieldKeys.TITLE.field}
						header={
							<ColumnHeader
								label={'Title'}
								isActiveSort={activeSortColumn === FieldKeys.TITLE.sortField}
							/>
						}
						sortable
						sortField={FieldKeys.TITLE.sortField}
						filter
					/>
					<Column
						field={FieldKeys.DESCRIPTION.field}
						header={
							<ColumnHeader
								label={'Description'}
								isActiveSort={activeSortColumn === FieldKeys.DESCRIPTION.sortField}
							/>
						}
						body={(data: Api.V1.Artifact.Paged.Get.Res) => {
							return (
								<Label variant={'body1'} weight={'regular'} className={'ellipsis'}>
									{StringUtils.removeHtmlTags(data.description)}
								</Label>
							);
						}}
						sortable
						sortField={FieldKeys.DESCRIPTION.sortField}
						filter
					/>
					<Column
						field={FieldKeys.TAGS.field}
						header={
							<ColumnHeader label={'Tags'} isActiveSort={activeSortColumn === FieldKeys.TAGS.sortField} />
						}
						body={(data: Api.V1.Artifact.Paged.Get.Res) => (
							<Label variant={'body1'} weight={'regular'} className={'ellipsis'}>
								{data.tags.map(({ name }) => name).join(', ')}
							</Label>
						)}
						sortable
						sortField={FieldKeys.TAGS.sortField}
						filter
					/>
					<Column
						field={FieldKeys.APPROVAL.field}
						header={
							<ColumnHeader
								label={'Approval'}
								isActiveSort={activeSortColumn === FieldKeys.APPROVAL.sortField}
							/>
						}
						body={(data: Api.V1.Artifact.Paged.Get.Res) => {
							if (!data.approval) {
								return '-';
							}
							return (
								<Label
									variant={'body1'}
									weight={'regular'}
									color={data.approval === 'APPROVED' ? themes.accentSuccess : themes.accentWarning}
								>
									{data.approval === 'APPROVED' ? 'Approved' : 'Needs Approval'}
								</Label>
							);
						}}
						sortable
						sortField={FieldKeys.APPROVAL.sortField}
						filter
					/>
					<Column
						field={FieldKeys.STATUS.field}
						header={
							<ColumnHeader
								label={'Status'}
								isActiveSort={activeSortColumn === FieldKeys.STATUS.sortField}
							/>
						}
						body={(data: Api.V1.Artifact.Paged.Get.Res) => (
							<Label
								variant={'body1'}
								weight={'regular'}
								color={data.isActive ? themes.accentSuccess : undefined}
							>
								{data.isActive ? 'Visible' : 'Hidden'}
							</Label>
						)}
						sortable
						sortField={FieldKeys.STATUS.sortField}
						filter
					/>
				</ListDataTable>
			</Box>
		</Page>
	);
};
export default ArtifactListPage;
