import * as React from 'react';
import './TeamRolesPage.scss';
import { Page } from '@redskytech/framework/996';
import PageHeader from '../../../components/pageHeader/PageHeader';
import ListDataTable from '../../../components/listDataTable/ListDataTable';
import TableSearchHeader from '../../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import ColumnHeader from '../../../components/listDataTable/columnHeader/ColumnHeader';
import { StringUtils, WebUtils } from '../../../utils/utils';
import { Box, Button, popupController, rsToastify } from '@redskytech/framework/ui';
import { useState } from 'react';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AddEditTeamRolePopup, {
	AddEditTeamRolePopupProps
} from '../../../popups/addEditTeamRolePopup/AddEditTeamRolePopup';

interface TeamRolesPageProps {}

const FieldKeys: { [key in 'ROLE' | 'DESCRIPTION']: { field: string; sortField: string } } = {
	ROLE: { field: 'name', sortField: 'teamRole.name' },
	DESCRIPTION: { field: 'description', sortField: 'teamRole.description' }
};

const TeamRolesPage: React.FC<TeamRolesPageProps> = (props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeSortColumn, setActiveSortColumn] = useState<string>();
	const [globalSearch, setGlobalSearch] = useState<string>('');
	const [lastPageQuery, setLastPageQuery] = useState<RedSky.PageQuery | undefined>();

	const [roleList, setRoleList] = useState<RedSky.RsPagedResponseData<Api.V1.TeamRole.Paged.Get.Res[]>>({
		data: [],
		total: 0
	});

	async function getData(pageQuery: RedSky.PageQuery) {
		setLastPageQuery(pageQuery);
		setIsLoading(true);
		setActiveSortColumn(pageQuery.sortBy);
		try {
			if (pageQuery.sortBy === undefined) {
				delete pageQuery.sortBy;
				delete pageQuery.sortOrder;
			}
			if (!pageQuery.filter) delete pageQuery.filter;

			const res = await ApiRequestV1.getTeamRolePaged({ ...pageQuery });
			setRoleList(res);
			setIsLoading(false);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function handleRowClick({ data }: DataTableRowClickEventParams) {
		popupController.open<AddEditTeamRolePopupProps>(AddEditTeamRolePopup, {
			teamRole: data,
			onChange: () => {
				if (lastPageQuery) getData(lastPageQuery);
			}
		});
	}

	function handleAddRole() {
		popupController.open<AddEditTeamRolePopupProps>(AddEditTeamRolePopup, {
			onChange: () => {
				if (lastPageQuery) getData(lastPageQuery);
			}
		});
	}

	function renderHeaderButtons() {
		return (
			<Button look={'containedPrimary'} onClick={handleAddRole} small>
				Add Role
			</Button>
		);
	}

	return (
		<Page className={'rsTeamRolesPage'}>
			<PageHeader title={'Roles'} rightNode={renderHeaderButtons()} />
			<Box padding={32} className={'scrolledContent'}>
				<ListDataTable
					loading={isLoading}
					onClearFilters={() => {}}
					data={roleList}
					getData={getData}
					globalFilter={globalSearch}
					globalFilterFields={['teamRole.name']}
					onRowClick={handleRowClick}
					responsiveLayout={'stack'}
					header={
						<TableSearchHeader searchValue={globalSearch} onChange={(value) => setGlobalSearch(value)} />
					}
				>
					<Column
						className={'roleColumn'}
						field={FieldKeys.ROLE.field}
						sortField={FieldKeys.ROLE.sortField}
						header={
							<ColumnHeader label={'Role'} isActiveSort={activeSortColumn === FieldKeys.ROLE.sortField} />
						}
						sortable
					/>
					<Column
						className={'descriptionColumn'}
						sortField={FieldKeys.DESCRIPTION.sortField}
						header={
							<ColumnHeader
								label={'Description'}
								isActiveSort={activeSortColumn === FieldKeys.DESCRIPTION.sortField}
							/>
						}
						sortable
						body={(rowData: Api.V1.TeamRole.Paged.Get.Res) => {
							return StringUtils.removeHtmlTags(rowData.description || '-');
						}}
					/>
				</ListDataTable>
			</Box>
		</Page>
	);
};

export default TeamRolesPage;
