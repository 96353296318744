import React, { useEffect, useRef } from 'react';

// The standard method for using div dangerouslySetInnerHTML={{ __html: props.html }} does not work for script tags.
// This component is a workaround for that which originally came from here: https://github.com/christo-pr/dangerously-set-html-content

interface InnerHtmlProps extends React.HTMLAttributes<HTMLDivElement> {
	html: string;
}

const InnerHtml: React.FC<InnerHtmlProps> = ({ html, dangerouslySetInnerHTML, ...rest }) => {
	// We remove 'dangerouslySetInnerHTML' from props passed to the div
	const divRef = useRef<HTMLElement>(null);

	useEffect(() => {
		if (!divRef.current) throw new Error('no divRef.current');
		const htmlNoEmpty = !html ? ' ' : html;

		const slotHtml = document.createRange().createContextualFragment(htmlNoEmpty); // Create a 'tiny' document and parse the html string
		divRef.current.innerHTML = ''; // Clear the container
		divRef.current.appendChild(slotHtml); // Append the new content
	}, [html, divRef]);

	// eslint-disable-next-line react/react-in-jsx-scope
	return <div {...rest} ref={divRef} />;
};

export default InnerHtml;
