import * as React from 'react';
import './LabelInputText.scss';
import { Box, InputText, InputTextProps, Label } from '@redskytech/framework/ui';
import classNames from 'classnames';

interface LabelInputTextProps extends InputTextProps {
	label: string | React.ReactNode;
	labelColor?: string;
}

const LabelInputText: React.FC<LabelInputTextProps> = (props) => {
	const { label, labelColor, ...inputProps } = props;
	return (
		<Box className={'rsLabelInputText'}>
			<Label
				className={classNames({ required: props.required })}
				variant={'body1'}
				weight={'regular'}
				mb={4}
				color={labelColor}
			>
				{label}
			</Label>
			<InputText {...inputProps} />
		</Box>
	);
};

export default LabelInputText;
