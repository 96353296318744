import * as React from 'react';
import { Box } from '@redskytech/framework/ui';
import themes from '../../themes/themes.scss?export';
import classNames from 'classnames';

interface PaperProps {
	className?: string;
	onClick?: () => void;
}

const Paper: React.FC<PaperProps> = (props) => {
	return (
		<Box
			className={classNames('rsPaper', props.className)}
			padding={16}
			bgColor={themes.neutralBeige900}
			borderRadius={4}
			onClick={props.onClick}
		>
			{props.children}
		</Box>
	);
};

export default Paper;
