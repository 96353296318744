import * as React from 'react';
import './HeroBackground.scss';
import { Box } from '@redskytech/framework/ui';

interface HeroSectionProps {
	heroImage: string;
	height?: number | string;
	removeGradient?: boolean;
}

const HeroBackground: React.FC<HeroSectionProps> = (props) => {
	const backgroundImage = props.removeGradient
		? `url(${props.heroImage})`
		: `linear-gradient(0deg, rgba(44, 51, 61, 0.5), rgba(44, 51, 61, 0.5)), url(${props.heroImage})`;

	return (
		<Box
			className={'rsHeroBackground'}
			style={{
				backgroundImage,
				height: props.height
			}}
		>
			{props.children}
		</Box>
	);
};

export default HeroBackground;
