import * as React from 'react';
import './LabelSelect.scss';
import { Box, Label, Select, SelectProps } from '@redskytech/framework/ui';
import classNames from 'classnames';

interface LabelSelectProps<Option, IsMulti extends boolean = false> extends SelectProps<Option, IsMulti> {
	label: string | React.ReactNode;
	required?: boolean;
	labelColor?: string;
}

function LabelSelect<Option, IsMulti extends boolean = false>(props: LabelSelectProps<Option, IsMulti>) {
	const { label, required, labelColor, ...inputProps } = props;
	return (
		<Box className={'rsLabelSelect'}>
			<Label
				className={classNames({ required: props.required })}
				variant={'body1'}
				weight={'regular'}
				mb={4}
				color={labelColor}
			>
				{label}
			</Label>
			<Select {...inputProps} />
		</Box>
	);
}

export default LabelSelect;
