import React, { useEffect, useState } from 'react';
import './HomePage.scss';
import { Page } from '@redskytech/framework/996';
import { Label, Box } from '@redskytech/framework/ui';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import TaskSection from '../../../sections/taskSection/TaskSection';
import MeetingSection from '../../../sections/meetingSection/MeetingSection';
import TeamSection from '../../../sections/teamSection/TeamSection';
import { ApiRequestV1 } from '../../../generated/apiRequests.js';
import RsPagedResponseData = RedSky.RsPagedResponseData;
import ArtifactSection from '../../../sections/artifactSection/ArtifactSection';
import PhaseCompletionSection from '../../../sections/phaseCompletionSection/PhaseCompletionSection';
import SprintDataSection from '../../../sections/sprintDataSection/SprintDataSection';
import SprintGroupSection from '../../../sections/sprintGroupSection/SprintGroupSection';

const HomePage: React.FC = () => {
	const user = useRecoilValue<Api.V1.User.Get.Res | undefined>(globalState.user);
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [teams, setTeams] = useState<Api.V1.Team.Version.Get.Res[]>([]);
	const [meetings, setMeetings] = useState<Api.V1.Meeting.Paged.Get.Res[]>([]);
	const [epics, setEpics] = useRecoilState<Api.V1.Epic.Paged.Get.Res[]>(globalState.epics);
	const [artifacts, setArtifacts] = useState<Api.V1.Artifact.Paged.Get.Res[]>();
	const [overdueTasks, setOverdueTasks] = useState<Api.V1.Task.Paged.Get.Res[]>([]);
	const [inProgressTasks, setInProgressTasks] = useState<Api.V1.Task.Paged.Get.Res[]>([]);
	const [isVersionLoading, setIsVersionLoading] = useRecoilState<boolean>(globalState.versionLoading);
	const [hideSprints, setHideSprints] = useState<boolean>(false);

	useEffect(() => {
		let isCancelled = false;
		const currentPhase = version?.phases.find((phase) => phase.id === version?.currentPhaseId);
		if (!currentPhase || currentPhase.name === 'Design & Discovery') {
			setHideSprints(true);
		}

		(async function setVersionedItems() {
			setIsVersionLoading(true);
			if (!version) {
				setTeams([]);
				setMeetings([]);
				setEpics([]);
				setIsVersionLoading(false);
				return;
			}
			const responses: [
				Promise<Api.V1.Team.Version.Get.Res[]>,
				Promise<RsPagedResponseData<Api.V1.Meeting.Paged.Get.Res[]>>,
				Promise<RsPagedResponseData<Api.V1.Epic.Paged.Get.Res[]>>,
				Promise<RsPagedResponseData<Api.V1.Task.Paged.Get.Res[]>>,
				Promise<RsPagedResponseData<Api.V1.Task.Paged.Get.Res[]>>,
				Promise<RsPagedResponseData<Api.V1.Artifact.Paged.Get.Res[]>>
			] = [
				ApiRequestV1.getTeamVersion({ versionId: version.id }),
				ApiRequestV1.getMeetingPaged({
					perPage: 100,
					sortBy: 'occurredOn',
					sortOrder: 'ASC',
					filter: `(column:occurredOn,value:${new Date().toISOString()},type:greaterThanEqual)and(column:status,value:VISIBLE)`,
					versionId: version.id
				}),
				ApiRequestV1.getEpicPaged({ versionId: version.id }),
				ApiRequestV1.getTaskPaged({
					perPage: 10,
					page: 1,
					versionId: version.id,
					sortBy: 'dueOn',
					filter: `(column:dueOn,value:${new Date().toISOString()},type:lessThan)and(column:status,value:NOT_COMPLETED)`
				}),
				ApiRequestV1.getTaskPaged({
					perPage: 20,
					page: 1,
					versionId: version.id,
					sortBy: 'dueOn',
					sortOrder: 'ASC',
					filter: `(column:dueOn,value:${new Date().toISOString()},type:greaterThan)and(column:status,value:NOT_COMPLETED)`
				}),
				ApiRequestV1.getArtifactPaged({
					page: 1,
					perPage: 3,
					versionId: version.id,
					sortBy: 'createdOn',
					sortOrder: 'DESC',
					filter: `(column:isActive,value:1,type:exact)`
				})
			];
			await Promise.all(responses).then((res) => {
				const [
					teamResponse,
					meetingsResponse,
					epicsResponse,
					overDueTasksResponse,
					inProgressTasksResponse,
					artifactsResponse
				] = res;
				if (isCancelled) return;
				setTeams(teamResponse);
				setMeetings(meetingsResponse.data);
				setEpics(epicsResponse.data);
				setOverdueTasks(overDueTasksResponse.data);
				setInProgressTasks(inProgressTasksResponse.data);
				setArtifacts(artifactsResponse.data);
			});

			setIsVersionLoading(false);
		})();

		return () => {
			isCancelled = true;
		};
	}, [version]);

	if (!user || !version || !teams || isVersionLoading)
		return (
			<Label variant={'h3'} weight={'regular'}>
				Loading...
			</Label>
		);

	return (
		<Page className="rsHomePage" title={'PM Tool'} description={'A pm tool that helps you manage your projects.'}>
			{!hideSprints && <SprintGroupSection />}
			{!hideSprints && <SprintDataSection />}
			<PhaseCompletionSection />
			<Box className={'columnGroup'}>
				<TaskSection overdueTasks={overdueTasks} inProgressTasks={inProgressTasks} />
				<ArtifactSection artifacts={artifacts} />
			</Box>
			<Box className={'columnGroup'}>
				<MeetingSection meetings={meetings} />
				<TeamSection teams={teams} />
			</Box>
		</Page>
	);
};

export default HomePage;
