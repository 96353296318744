import React, { useEffect, useMemo, useState } from 'react';
import './AppBar.scss';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { Box, Img, Label, Select } from '@redskytech/framework/ui';
import adminLogo from '../../images/redsky_logo.png?webp&imagetools';
import UserBadge from '../userBadge/UserBadge';
import { ApiRequestV1 } from '../../generated/apiRequests.js';

const AppBar: React.FC = () => {
	const user = useRecoilValue<Api.V1.User.Get.Res | undefined>(globalState.user);
	const [version, setVersion] = useRecoilState<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [availableVersions, setAvailableVersions] = useState<CustomTypes.VersionDetails[]>([]);

	useEffect(() => {
		let isCancelled = false;
		(async function getAvailableVersions() {
			const res = await ApiRequestV1.getVersionMe();
			if (isCancelled) return;
			setAvailableVersions(res);
		})();
		return () => {
			isCancelled = true;
		};
	}, []);

	const availableVersionSelect: { value: number; label: JSX.Element }[] = useMemo(() => {
		return availableVersions.map((availableVersion: CustomTypes.VersionDetails) => {
			return {
				value: availableVersion.versionId,
				label: (
					<Box className={'versionOption'}>
						<Label variant={'body1'} weight={'bold'}>
							{availableVersion.projectName} - {availableVersion.versionName}
						</Label>
					</Box>
				)
			};
		});
	}, [availableVersions]);

	if (!user) return <></>;
	return (
		<Box className="rsAppBar">
			<Box className="topName">
				<Img width={'auto'} height={32} src={adminLogo} alt="" disableImageKit />
			</Box>
			<Box display={'flex'} alignItems={'center'}>
				<Select<{ value: number; label: JSX.Element }>
					value={availableVersionSelect.find((availableVersion) => {
						return availableVersion.value === version?.id;
					})}
					onChange={async (newValue) => {
						if (!newValue) return;
						const res = await ApiRequestV1.getVersion({
							versionId: newValue.value
						});
						setVersion(res);
						await ApiRequestV1.patchUser({ id: user.id, defaultVersionId: newValue.value });
					}}
					options={availableVersionSelect}
				/>
				<Box className={'dividerLine'} />
				<UserBadge userName={`${user.firstName} ${user.lastName}`} imageUrl={''} title={'Head Clown'} />
			</Box>
		</Box>
	);
};

export default AppBar;
