import * as React from 'react';
import './TeamUserPopup.scss';
import { Avatar, Box, Icon, Label, Popup, PopupProps, rsToastify } from '@redskytech/framework/ui';
import { useEffect, useState } from 'react';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { WebUtils } from '../../utils/utils';
import RichContentDisplay from '../../components/richContentDisplay/RichContentDisplay.js';

export interface TeamUserPopupProps extends PopupProps {
	userId: number;
	companyId: number;
	role: string;
	roleDescription: string;
}

const TeamUserPopup: React.FC<TeamUserPopupProps> = (props) => {
	const [userDetails, setUserDetails] = useState<Api.V1.User.Public.Get.Res | undefined>();

	useEffect(() => {
		(async function getUserDetails() {
			try {
				const result: Api.V1.User.Public.Get.Res = await ApiRequestV1.getUserPublic({
					userId: props.userId
				});
				setUserDetails(result);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Failed to get public user data'));
			}
		})();
	}, []);

	function renderPopupInformation(): React.ReactNode {
		if (!userDetails) return;
		return (
			<Box className={'rsTeamUserPopup'}>
				<Box className={'infoSection'}>
					<Avatar
						widthHeight={64}
						m={'0 auto'}
						name={userDetails.firstName + ' ' + userDetails.lastName}
						image={userDetails.userAvatarUrl}
					/>
					<Label variant={'body1'} weight={'semiBold'}>
						{userDetails.firstName + ' ' + userDetails.lastName}
					</Label>
					<Label variant={'body1'} weight={'regular'}>
						{userDetails.email}
					</Label>
					{userDetails.phone && (
						<Label variant={'body1'} weight={'regular'}>
							{userDetails.phone}
						</Label>
					)}
					<Label variant={'body1'} weight={'regular'}>
						{props.role}
					</Label>
				</Box>
				<RichContentDisplay content={props.roleDescription.trim()} />
				<Box className={'buttonSection'}>
					{/*{userDetails.slackId && (*/}
					{/*	<Box*/}
					{/*		className={'userBigButton'}*/}
					{/*		onClick={() => {*/}
					{/*			if (!userDetails) return;*/}
					{/*			window.open(*/}
					{/*				'https://slack.com/app_redirect?channel=' + userDetails.slackId || '',*/}
					{/*				'_blank'*/}
					{/*			);*/}
					{/*		}}*/}
					{/*	>*/}
					{/*		<Icon iconImg={'icon-slack'} />*/}
					{/*		<Label variant={'caption1'} weight={'regular'}>*/}
					{/*			Message*/}
					{/*		</Label>*/}
					{/*	</Box>*/}
					{/*)}*/}
					{userDetails.phone && (
						<Box
							className={'userBigButton'}
							onClick={() => {
								if (!userDetails) return;
								window.open('tel:' + userDetails.phone || '', '_blank');
							}}
						>
							<Icon iconImg={'icon-phone'} />
							<Label variant={'caption1'} weight={'regular'}>
								Call
							</Label>
						</Box>
					)}
					<Box
						className={'userBigButton'}
						onClick={() => {
							if (!userDetails) return;
							window.open('mailto:' + userDetails.email || '', '_blank');
						}}
					>
						<Icon iconImg={'icon-email'} />
						<Label variant={'caption1'} weight={'regular'}>
							Email
						</Label>
					</Box>
				</Box>
			</Box>
		);
	}

	return <Popup {...props}>{renderPopupInformation()}</Popup>;
};

export default TeamUserPopup;
