import * as React from 'react';
import { Box, Label } from '@redskytech/framework/ui';
import './SectionWithHeader.scss';
import { Link } from '@redskytech/framework/996';
import themes from '../../themes/themes.scss?export';
import Paper from '../paper/Paper';
import classNames from 'classnames';

interface SectionWithHeaderProps {
	title: string;
	className: string;
	leftNode?: React.ReactNode;
	viewAllLink?: string;
	viewAllText?: string;
}

const SectionWithHeader: React.FC<SectionWithHeaderProps> = (props) => {
	return (
		<Paper className={classNames('rsSectionWithHeader', props.className)}>
			<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
				<Box>
					<Label variant={'subheader2'} weight={'extraBold'}>
						{props.title.toUpperCase()}
					</Label>
					{!!props.leftNode && props.leftNode}
				</Box>
				{!!props.viewAllLink && (
					<Link path={props.viewAllLink}>
						<Label variant={'caption1'} weight={'regular'} color={themes.neutralBeige600}>
							{props.viewAllText ? props.viewAllText : 'View All'}
						</Label>
					</Link>
				)}
			</Box>
			{props.children}
		</Paper>
	);
};

export default SectionWithHeader;
