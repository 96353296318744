import * as React from 'react';
import './LabelSwitch.scss';
import { Box, Label } from '@redskytech/framework/ui';
import Switch from '../switch/Switch';

interface LabelSwitchProps {
	label: string;
	labelPosition: 'left' | 'right';
	className?: string;
	checked?: boolean;
	onChange?: (checked: boolean) => void;
}

const LabelSwitch: React.FC<LabelSwitchProps> = (props) => {
	return (
		<Box className={'rsLabelSwitch'} flexDirection={props.labelPosition === 'left' ? 'row' : 'row-reverse'}>
			<Label variant={'body1'} weight={'regular'} marginX={8}>
				{props.label}
			</Label>
			<Switch checked={props.checked} onChange={props.onChange} />
		</Box>
	);
};
export default LabelSwitch;
