import * as React from 'react';
import './UserBadge.scss';

import { Label, Avatar, Button, Icon, Box } from '@redskytech/framework/ui';

interface UserBadgeProps {
	userName: string;
	title: string;
	imageUrl?: string;
}

const UserBadge: React.FC<UserBadgeProps> = (props) => {
	return (
		<div className={'rsUserBadge'}>
			<Avatar widthHeight={40} image={props.imageUrl} name={props.userName} />
			<Box>
				<Label variant={'caption1'} weight={'regular'}>
					{props.title}
				</Label>
				<Label variant={'subheader2'} weight={'regular'}>
					{props.userName}
				</Label>
			</Box>
		</div>
	);
};

export default UserBadge;
