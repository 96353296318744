import * as React from 'react';
import './MeetingListPage.scss';
import { Page } from '@redskytech/framework/996';
import PageHeader from '../../../components/pageHeader/PageHeader';
import { Box, Button, Icon, Label, popupController, rsToastify } from '@redskytech/framework/ui';
import TableSearchHeader from '../../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import ColumnHeader from '../../../components/listDataTable/columnHeader/ColumnHeader';
import ListDataTable from '../../../components/listDataTable/ListDataTable';
import { useEffect, useState } from 'react';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { DateUtils, StringUtils, WebUtils } from '../../../utils/utils';
import { Column } from 'primereact/column';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import router from '../../../utils/router';
import themes from '../../../themes/themes.scss?export';
import PaperHeaderBar from '../../../components/paperHeaderBar/PaperHeaderBar';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import AddEditMeetingPopup, { AddEditMeetingPopupProps } from '../../../popups/addEditMeetingPopup/AddEditMeetingPopup';
import RichContentDisplay from '../../../components/richContentDisplay/RichContentDisplay.js';

interface MeetingListPageProps {}

const FieldKeys: {
	[key in 'TYPE' | 'TITLE' | 'DESCRIPTION' | 'STATUS' | 'DATE']: { field: string; sortField: string };
} = {
	TYPE: { field: 'meetingTypeName', sortField: 'meetingType.name' },
	TITLE: { field: 'title', sortField: 'meeting.title' },
	DESCRIPTION: { field: 'description', sortField: 'meeting.description' },
	STATUS: { field: 'status', sortField: 'meeting.status' },
	DATE: { field: 'occurredOn', sortField: 'meeting.occurredOn' }
};

const MeetingListPage: React.FC<MeetingListPageProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeSortColumn, setActiveSortColumn] = useState<string>();
	const [globalSearch, setGlobalSearch] = useState<string>('');
	const [currentQuery, setCurrentQuery] = useState<RedSky.PageQuery>();

	const [meetingList, setMeetingList] = useState<RedSky.RsPagedResponseData<Api.V1.Meeting.Paged.Get.Res[]>>({
		data: [],
		total: 0
	});

	useEffect(() => {
		if (!currentQuery) return;
		getData(currentQuery);
	}, [version]);

	async function getData(pageQuery: RedSky.PageQuery) {
		setIsLoading(true);
		setActiveSortColumn(pageQuery.sortBy);
		setCurrentQuery(pageQuery);
		try {
			if (!version) return;
			if (pageQuery.sortBy === undefined) {
				delete pageQuery.sortBy;
				delete pageQuery.sortOrder;
			}
			if (!pageQuery.filter) delete pageQuery.filter;

			const res = await ApiRequestV1.getMeetingPaged({ ...pageQuery, versionId: version.id });
			setMeetingList(res);
			setIsLoading(false);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function handleRowClick({ data }: DataTableRowClickEventParams) {
		router.navigate('/meeting/details?mi=' + data.id).catch(console.error);
	}

	function renderHeaderButtons(): React.ReactNode {
		return (
			<Box display={'flex'} gap={24}>
				<Button
					look={'containedPrimary'}
					small
					onClick={() => {
						popupController.open<AddEditMeetingPopupProps>(AddEditMeetingPopup, {});
					}}
				>
					Add Meeting
				</Button>
			</Box>
		);
	}

	function handleEditRecurring() {
		popupController.open<AddEditMeetingPopupProps>(AddEditMeetingPopup, {
			isRecurringMeeting: true,
			recurringMeetingText: version?.recurringMeetings || ''
		});
	}

	return (
		<Page className={'rsMeetingListPage'}>
			<PageHeader title={'Meetings'} rightNode={renderHeaderButtons()} />
			<Box padding={32} className={'scrolledContent'}>
				<ListDataTable
					loading={isLoading}
					onClearFilters={() => {}}
					data={meetingList}
					getData={getData}
					globalFilter={globalSearch}
					globalFilterFields={['meeting.title', 'meetingType.name', 'meeting.description']}
					onRowClick={handleRowClick}
					header={
						<TableSearchHeader searchValue={globalSearch} onChange={(value) => setGlobalSearch(value)} />
					}
				>
					<Column
						field={FieldKeys.TYPE.field}
						sortField={FieldKeys.TYPE.sortField}
						header={
							<ColumnHeader label={'Type'} isActiveSort={activeSortColumn === FieldKeys.TYPE.sortField} />
						}
						sortable
					/>
					<Column
						field={FieldKeys.TITLE.field}
						sortField={FieldKeys.TITLE.sortField}
						header={
							<ColumnHeader
								label={'Title'}
								isActiveSort={activeSortColumn === FieldKeys.TITLE.sortField}
							/>
						}
					/>
					<Column
						className={'descriptionColumn'}
						field={FieldKeys.DESCRIPTION.field}
						sortField={FieldKeys.DESCRIPTION.sortField}
						header={
							<ColumnHeader
								label={'Description'}
								isActiveSort={activeSortColumn === FieldKeys.DESCRIPTION.sortField}
							/>
						}
						body={(meeting: Api.V1.Meeting.Paged.Get.Res) => {
							return StringUtils.removeHtmlTags(meeting.description);
						}}
					/>
					<Column
						field={FieldKeys.STATUS.field}
						sortField={FieldKeys.STATUS.sortField}
						className={'statusColumn'}
						header={
							<ColumnHeader
								label={'Status'}
								isActiveSort={activeSortColumn === FieldKeys.STATUS.sortField}
							/>
						}
						body={(meeting: Api.V1.Meeting.Paged.Get.Res) => {
							return (
								<span
									style={{
										color:
											meeting.status === 'VISIBLE' ? themes.accentSuccess : themes.neutralBeige300
									}}
								>
									{meeting.status.toLowerCase()}
								</span>
							);
						}}
					/>
					<Column
						sortField={FieldKeys.DATE.sortField}
						header={
							<ColumnHeader label={'Date'} isActiveSort={activeSortColumn === FieldKeys.DATE.sortField} />
						}
						body={(meeting: Api.V1.Meeting.Paged.Get.Res) => {
							return <>{DateUtils.displayFriendlyDateTime(meeting.occurredOn)}</>;
						}}
					/>
				</ListDataTable>
				<PaperHeaderBar
					className={'recurringMeetingSection'}
					title={'Recurring'}
					rightNode={
						<Button
							look={'iconSecondary'}
							icon={[{ iconImg: 'icon-edit', position: 'LEFT' }]}
							onClick={handleEditRecurring}
						/>
					}
				>
					<Box display={'flex'} gap={4} alignItems={'flex-end'}>
						<Icon iconImg={'icon-groups'} color={themes.accentInfo} fontSize={16} />
						<Label variant={'caption2'} weight={'regular'} color={themes.accentInfo}>
							RECURRING MEETINGS
						</Label>
					</Box>
					<RichContentDisplay content={version?.recurringMeetings || ''} />
				</PaperHeaderBar>
			</Box>
		</Page>
	);
};

export default MeetingListPage;
