import React, { FormEvent, useState } from 'react';
import './LoginPage.scss';
import UserService from '../../../services/user/UserService';
import serviceFactory from '../../../services/serviceFactory';
import { WebUtils } from '../../../utils/utils';
import themes from '../../../themes/themes.scss?export';
import {
	Label,
	Box,
	Button,
	InputText,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum,
	Img
} from '@redskytech/framework/ui';
import { Page } from '@redskytech/framework/996';
import HeroBackground from '../../../components/heroBackground/HeroBackground';
import circuitBackground from '../../../images/circuit-background.png?webp&w=1920&imagetools';
import demoUserIcon from '../../../images/demo-user-icon.png?webp&imagetools';
import googleIcon from '../../../images/google-icon.png?webp&imagetools';
import facebookIcon from '../../../images/facebook-icon.png?webp&imagetools';
import upArrowIcon from '../../../images/up-arrow.png?webp&imagetools';

enum FormKeys {
	PASSWORD = 'password',
	EMAIL = 'email'
}

const LoginPage: React.FC = () => {
	let userService = serviceFactory.get<UserService>('UserService');
	const [isAttemptingLogin, setIsAttemptingLogin] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [loginForm, setLoginForm] = useState(
		new RsFormGroup([
			new RsFormControl(FormKeys.PASSWORD, '', [new RsValidator(RsValidatorEnum.REQ, 'Password is required')]),
			new RsFormControl(FormKeys.EMAIL, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Email is required'),
				new RsValidator(RsValidatorEnum.EMAIL, 'Email is invalid')
			])
		])
	);

	async function signInUser(e: FormEvent) {
		e.preventDefault();

		if (!(await loginForm.isValid())) {
			setLoginForm(loginForm.clone());
			return;
		}

		try {
			setIsAttemptingLogin(true);
			let loginValues = loginForm.toModel<{ email: string; password: string }>();
			await userService.loginUserByPassword(loginValues.email, loginValues.password);
		} catch (e) {
			setIsAttemptingLogin(false);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Failed to login.'), 'Login Error');
		}
	}

	return (
		<Page className="rsLoginPage">
			<HeroBackground heroImage={circuitBackground} removeGradient>
				<Box className={'fullSize'}>
					<Box className={'loginPaper'}>
						<Label variant={'h2'} weight={'black'} width={'fit-content'} className={'gradient90'}>
							Log In
						</Label>
						<Label variant={'body1'} weight={'medium'}>
							Continue to Project Management Tool
						</Label>
						<form action={'#'} onSubmit={signInUser}>
							<InputText
								placeholder={'Email'}
								inputMode={'email'}
								control={loginForm.get<string>(FormKeys.EMAIL)}
								updateControl={(control) => setLoginForm(loginForm.clone().update(control))}
							/>
							<InputText
								mt={16}
								placeholder={'Password'}
								type={showPassword ? 'text' : 'password'}
								inputMode={'text'}
								control={loginForm.get<string>(FormKeys.PASSWORD)}
								updateControl={(control) => setLoginForm(loginForm.clone().update(control))}
								icon={[
									{
										position: 'RIGHT',
										iconImg: showPassword ? 'icon-hide' : 'icon-show',
										color: themes.neutralBeige500,
										fontSize: 16,
										cursorPointer: true,
										onClick: () => {
											setShowPassword(!showPassword);
										}
									}
								]}
							/>
							<Button mt={16} look={'containedPrimary'} type="submit" disabled={isAttemptingLogin}>
								{isAttemptingLogin ? 'LOGGING IN...' : 'LOG IN'}
							</Button>
						</form>
						{/*<Label variant={'body1'} weight={'medium'} textAlign={'center'} width={'100%'}>*/}
						{/*	Forgot your password?*/}
						{/*</Label>*/}
						{/*<Box display={'flex'} alignItems={'center'} gap={8} width={'100%'}>*/}
						{/*	<Box className={'line'} />*/}
						{/*	<Label variant={'body1'} weight={'medium'} color={themes.neutralBeige600}>*/}
						{/*		OR*/}
						{/*	</Label>*/}
						{/*	<Box className={'line'} />*/}
						{/*</Box>*/}
						{/*<Box className={'tryDemoTop'}>*/}
						{/*	<Img src={upArrowIcon} alt={'up arrow'} width={16} height={16} disableImageKit />*/}
						{/*	<Label variant={'body2'} weight={'black'}>*/}
						{/*		Try the demo for free!*/}
						{/*	</Label>*/}
						{/*</Box>*/}
						{/*<Box className={'demoLoginButtons'}>*/}
						{/*	<Button look={'outlinedPrimary'}>*/}
						{/*		<Img*/}
						{/*			alt={'demo icon'}*/}
						{/*			width={17}*/}
						{/*			height={16}*/}
						{/*			src={demoUserIcon}*/}
						{/*			disableImageKit*/}
						{/*			mr={8}*/}
						{/*		/>*/}
						{/*		Demo*/}
						{/*	</Button>*/}
						{/*	<Button look={'outlinedPrimary'}>*/}
						{/*		<Img alt={'demo icon'} width={17} height={16} src={googleIcon} disableImageKit mr={8} />*/}
						{/*		Google*/}
						{/*	</Button>*/}
						{/*	<Button look={'outlinedPrimary'}>*/}
						{/*		<Img*/}
						{/*			alt={'demo icon'}*/}
						{/*			width={17}*/}
						{/*			height={16}*/}
						{/*			src={facebookIcon}*/}
						{/*			disableImageKit*/}
						{/*			mr={8}*/}
						{/*		/>*/}
						{/*		Facebook*/}
						{/*	</Button>*/}
						{/*</Box>*/}
						{/*<Box className={'tryDemoBottom'}>*/}
						{/*	<Img src={upArrowIcon} alt={'up arrow'} width={16} height={16} disableImageKit />*/}
						{/*	<Label variant={'body2'} weight={'black'}>*/}
						{/*		Try the demo for free!*/}
						{/*	</Label>*/}
						{/*</Box>*/}
					</Box>
				</Box>
			</HeroBackground>
		</Page>
	);
};

export default LoginPage;
