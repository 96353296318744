import * as React from 'react';
import './Pagination.scss';
import { Box, Icon, Label } from '@redskytech/framework/ui';
import classNames from 'classnames';
import { useMemo } from 'react';

export interface PaginationProps {
	itemsPerPage: number;
	totalItems: number;
	currentPage: number;
	changePage: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = (props) => {
	const pagesToShow: number[] = useMemo(() => {
		const totalPages = Math.ceil(props.totalItems / props.itemsPerPage);
		let pages = [1, totalPages, props.currentPage];
		if (props.currentPage - 1 > 1) pages.push(props.currentPage - 1);
		if (props.currentPage + 1 < totalPages) pages.push(props.currentPage + 1);
		pages.sort((a, b) => a - b);
		return pages;
	}, [props.currentPage, props.totalItems, props.itemsPerPage]);
	const pageNumbers: number[] = useMemo(() => {
		let numbers: number[] = [];
		for (let num = 1; num <= Math.ceil(props.totalItems / props.itemsPerPage); num++) {
			numbers.push(num);
		}
		return numbers;
	}, [props.totalItems, props.itemsPerPage]);

	if (pageNumbers.length < 2)
		return (
			<Box className={'rsPagination'}>
				<Box className={'pageNavigationContainer'}>
					<Box className={'pageItem'} onClick={() => props.changePage(1)}>
						<Label className={'pageLink'} variant={'caption1'} weight={'regular'}>
							1
						</Label>
					</Box>
				</Box>
			</Box>
		);

	function renderPageNumbers() {
		let navigationPages = [];
		for (let i = 0; i < pageNumbers.length; i++) {
			let pageNumber = pageNumbers[i];
			if (!pagesToShow.includes(pageNumber)) {
				navigationPages.push(
					<Box className={'pageItem'}>
						<Label className={'pageLink ellipsis'} variant={'caption1'} weight={'regular'}>
							...
						</Label>
					</Box>
				);
				while (i + 1 < pageNumbers.length && !pagesToShow.includes(pageNumbers[i + 1])) {
					i++;
				}
			} else {
				navigationPages.push(
					<Box key={pageNumber} className={'pageNavigationContainer'}>
						{
							<Box
								className={classNames(`pageItem`, { black: props.currentPage === pageNumber })}
								onClick={() => props.changePage(pageNumber)}
							>
								<Label
									className={classNames(`pageLink`, { black: props.currentPage === pageNumber })}
									variant={'caption1'}
									weight={'regular'}
								>
									{pageNumber}
								</Label>
							</Box>
						}
					</Box>
				);
			}
		}
		return navigationPages;
	}

	return (
		<Box className={'rsPagination'}>
			{props.currentPage !== 1 && (
				<Icon
					className={'navArrow'}
					cursorPointer
					iconImg={'icon-chevron-left'}
					fontSize={14}
					onClick={() => {
						props.changePage(props.currentPage - 1);
					}}
				/>
			)}
			{renderPageNumbers()}
			{props.currentPage !== pageNumbers[pageNumbers.length - 1] && (
				<Icon
					className={'navArrow'}
					cursorPointer
					iconImg={'icon-chevron-right'}
					fontSize={14}
					onClick={() => {
						props.changePage(props.currentPage + 1);
					}}
				/>
			)}
		</Box>
	);
};

export default Pagination;
