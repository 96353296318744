import * as React from 'react';
import './BudgetPage.scss';
import { Box, Label, rsToastify } from '@redskytech/framework/ui';
import { Page } from '@redskytech/framework/996';
import Paper from '../../../components/paper/Paper';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import themes from '../../../themes/themes.scss?export';
import ProgressBar from '../../../components/progressBar/ProgressBar';
import { DateUtils, ObjectUtils, StringUtils, WebUtils } from '../../../utils/utils';
import classNames from 'classnames';
import router from '../../../utils/router';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import LoadingPage from '../../common/loadingPage/LoadingPage';

interface BudgetPageProps {}

const BudgetPage: React.FC<BudgetPageProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [phases, setPhases] = useState<Api.V1.Phase.Version.Get.Res[]>([]);
	useEffect(() => {
		if (!version) return;
		let isCancelled = false;
		(async function getPhases() {
			try {
				const getPhasesResults = await ApiRequestV1.getPhaseVersion({ versionId: version.id });
				if (isCancelled) return;
				setPhases(getPhasesResults);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error Getting Phases'));
			}
		})();
		return () => {
			isCancelled = true;
		};
	}, [version]);

	function renderOverallProgressReport() {
		let currentCombined = 0;
		let consumedCombined = 0;
		let percentage = 0;
		phases.forEach((phaseItem) => {
			percentage += phaseItem.donePercent;
			currentCombined += phaseItem.currentBudget;
			consumedCombined += phaseItem.budgetConsumedAmount;
		});
		const remainingBudgetCombined = currentCombined - consumedCombined;
		percentage = Math.floor(percentage / phases.length);

		const barSections: { percentageOrAmount: number; sectionColor: string }[] = [];
		phases.forEach((phaseItem, index) => {
			barSections.push({
				percentageOrAmount: phaseItem.budgetConsumedAmount,
				sectionColor: ObjectUtils.getProgressBarColor(index)
			});
		});

		return (
			<Paper
				onClick={() => {
					router.navigate('/budget/monthly?id=' + version?.id).catch(console.error);
				}}
			>
				<Label className={'title'} variant={'subheader2'} weight={'extraBold'}>
					PROJECT BUDGET
				</Label>

				<ProgressBar
					barType={'AMOUNT_SPENT'}
					barHeight={'LARGE'}
					markerPercentage={percentage.toString()}
					progressBarSections={barSections.map((item) => item)}
					budget={{
						totalBudget: currentCombined,
						totalSpent: consumedCombined
					}}
				/>
				<Box className={'spendAndRemaining'}>
					<Label variant={'caption1'} weight={'semiBold'}>
						${StringUtils.addCommasToNumber(consumedCombined)} Spent
					</Label>
					<Label variant={'caption1'} weight={'semiBold'}>
						${StringUtils.addCommasToNumber(remainingBudgetCombined)} Remaining
					</Label>
				</Box>
				<Label className={'projectPercentage'} variant={'caption1'} weight={'semiBold'}>
					Project <span>{percentage}%</span> Complete
				</Label>
			</Paper>
		);
	}

	function renderBrokenDownReports() {
		return phases.map((phaseItem, phaseIndex) => {
			const remainingAmount = phaseItem.currentBudget - phaseItem.budgetConsumedAmount;

			return (
				<Paper
					key={phaseItem.name + phaseIndex}
					onClick={() => {
						router.navigate('/budget/phase?pi=' + phaseItem.id + '&col=' + phaseIndex).catch(console.error);
					}}
				>
					<Label className={'title'} variant={'subheader2'} weight={'extraBold'}>
						{phaseItem.name.toUpperCase()} PHASE
					</Label>

					<ProgressBar
						barType={'AMOUNT_SPENT'}
						barHeight={'SMALL'}
						progressBarSections={[
							{
								percentageOrAmount: phaseItem.budgetConsumedAmount,
								sectionColor: ObjectUtils.getProgressBarColor(phaseIndex)
							}
						]}
						budget={{
							totalBudget: phaseItem.currentBudget,
							totalSpent: phaseItem.budgetConsumedAmount
						}}
					/>
					<Box className={'spendAndRemaining'}>
						<Label variant={'caption1'} weight={'semiBold'}>
							${StringUtils.addCommasToNumber(phaseItem.budgetConsumedAmount)} Spent
						</Label>
						<Label variant={'caption1'} weight={'semiBold'}>
							<span className={classNames('isOverBudget', { true: remainingAmount < 0 })}>
								{remainingAmount < 0
									? '-$' + StringUtils.addCommasToNumber(remainingAmount).split('-')[1]
									: '$' + StringUtils.addCommasToNumber(remainingAmount)}
							</span>{' '}
							Remaining
						</Label>
					</Box>
					<Label
						className={'projectPercentage'}
						variant={'caption1'}
						weight={'semiBold'}
						color={themes.neutralBeige600}
					>
						Estimated ${StringUtils.addCommasToNumber(phaseItem.currentBudget)} Total
					</Label>
				</Paper>
			);
		});
	}

	function renderLastUpdated() {
		return (
			<Label variant={'caption2'} weight={'regular'} textAlign={'center'} color={themes.neutralBeige600}>
				Updated {DateUtils.getDateDifferenceFriendly(new Date(), new Date(phases[0].modifiedOn))} ago
			</Label>
		);
	}

	if (!ObjectUtils.isArrayWithData(phases)) return <LoadingPage />;
	return (
		<Page className={'rsBudgetPage'}>
			{renderOverallProgressReport()}
			{renderBrokenDownReports()}
			{renderLastUpdated()}
		</Page>
	);
};

export default BudgetPage;
