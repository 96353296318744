import React, { useEffect, useState } from 'react';
import './ArtifactSearchGroup.scss';
import { ApiRequestV1 } from '../../../../generated/apiRequests.js';
import getArtifactPaged = ApiRequestV1.getArtifactPaged;
import { Box, Icon, Label } from '@redskytech/framework/ui';
import ArtifactItem from '../artifactItem/ArtifactItem.js';
import Pagination from '../../../../components/pagination/Pagination.js';
import { ObjectUtils } from '../../../../utils/utils.js';
import { useRecoilValue } from 'recoil';
import globalState from '../../../../state/globalState';

interface ArtifactSearchGroupProps {
	searchValue: string;
}

const ArtifactSearchGroup: React.FC<ArtifactSearchGroupProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [artifacts, setArtifacts] = useState<Api.V1.Artifact.Paged.Get.Res[]>([]);
	const [totalArtifacts, setTotalArtifacts] = useState<number>(0);
	const [page, setPage] = useState<number>(1);
	useEffect(() => {
		let isCancelled = false;
		(async function getArtifacts() {
			if (!version) return;
			const res = await getArtifactPaged({
				page,
				perPage: 10,
				filter: `(column:name,value:${props.searchValue},type:contains)`,
				versionId: version.id
			});
			if (isCancelled) return;
			setArtifacts(res.data);
			setTotalArtifacts(res.total);
		})();
		return () => {
			isCancelled = true;
		};
	}, [props.searchValue, page]);

	function renderArtifacts() {
		if (!ObjectUtils.isArrayWithData(artifacts)) {
			return (
				<Box className={'noResults'}>
					<Icon iconImg={'icon-search'} fontSize={80} />
					<Label variant={'body1'} weight={'semiBold'}>
						No results found,
					</Label>
					<Label variant={'body1'} weight={'regular'}>
						try a different search.
					</Label>
				</Box>
			);
		}
		return artifacts.map((artifact) => {
			return <ArtifactItem key={artifact.name} artifact={artifact} />;
		});
	}
	return (
		<Box className={'rsArtifactSearchGroup'}>
			<Label variant={'subheader2'} weight={'extraBold'}>
				{totalArtifacts} RESULT{totalArtifacts !== 1 && 'S'}
			</Label>
			{renderArtifacts()}
			<Pagination
				itemsPerPage={10}
				totalItems={totalArtifacts}
				currentPage={page}
				changePage={(nextPage) => setPage(nextPage)}
			/>
		</Box>
	);
};

export default ArtifactSearchGroup;
