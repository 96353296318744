import React from 'react';
import './ArtifactGroup.scss';
import { Box, Label } from '@redskytech/framework/ui';
import ArtifactItem from '../artifactItem/ArtifactItem.js';

interface ArtifactGroupProps {
	group: Api.V1.Artifact.All.Get.Res;
}

const ArtifactGroup: React.FC<ArtifactGroupProps> = (props) => {
	return (
		<Box className={'rsArtifactGroup'}>
			<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={8}>
				<Label variant={'subheader2'} weight={'extraBold'}>
					{props.group.artifactType}S
				</Label>
				{/*<Label variant={'caption1'} weight={'regular'} className={'cursor'}>*/}
				{/*	View All*/}
				{/*</Label>*/}
			</Box>
			<Box className={'artifactGroup'}>
				{props.group.artifact.slice(0, 2).map((artifact) => {
					return <ArtifactItem key={artifact.name} artifact={artifact} />;
				})}
			</Box>
		</Box>
	);
};

export default ArtifactGroup;
