import * as React from 'react';
import './RowGroupHeader.scss';
import themes from '../../themes/themes.scss?export';
import { Box, Label, Icon } from '@redskytech/framework/ui';

interface RowGroupHeaderProps {
	label: string | React.ReactNode;
	onEditClick?: () => void;
}

const RowGroupHeader: React.FC<RowGroupHeaderProps> = (props) => {
	function renderLabel() {
		if (typeof props.label === 'string')
			return (
				<Label variant={'body1'} weight={'semiBold'} display={'inline'}>
					{props.label}
				</Label>
			);
		return props.label;
	}

	return (
		<Box className={'rsRowGroupHeader'}>
			{renderLabel()}
			{props.onEditClick && (
				<Icon
					iconImg={'icon-edit'}
					fontSize={16}
					color={themes.neutralBeige600}
					onClick={props.onEditClick}
					cursorPointer
				/>
			)}
		</Box>
	);
};
export default RowGroupHeader;
