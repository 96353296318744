import * as React from 'react';
import './MeetingDetailsPage.scss';
import { Page } from '@redskytech/framework/996';
import PageHeader from '../../../components/pageHeader/PageHeader';
import router from '../../../utils/router';
import { useEffect, useMemo, useState } from 'react';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import ErrorPage from '../../common/errorPage/ErrorPage';
import LoadingPage from '../../common/loadingPage/LoadingPage';
import {
	Box,
	Button,
	InputText,
	Label,
	popupController,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum,
	Select
} from '@redskytech/framework/ui';
import PaperHeaderBar from '../../../components/paperHeaderBar/PaperHeaderBar';
import LabelInputText from '../../../components/labelInputText/LabelInputText';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { DateUtils, WebUtils } from '../../../utils/utils';
import LabelInputRichText from '../../../components/labelInputRichText/LabelInputRichText';
import LabelSelect from '../../../components/labelSelect/LabelSelect';
import themes from '../../../themes/themes.scss?export';
import LoadingPopup, { LoadingPopupProps } from '../../../popups/loadingPopup/LoadingPopup';
import ConfirmationPopup, { ConfirmationPopupProps } from '../../../popups/confirmationPopup/ConfirmationPopup';
import { StringUtils } from '@redskytech/framework/utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState.js';

interface MeetingDetailsPageProps {}

enum FormKeys {
	TITLE = 'title',
	DESCRIPTION = 'description',
	NOTES = 'notes',
	TAGS = 'tags',
	STATUS = 'status',
	MEETING_TYPE = 'meetingTypeId',
	OCCURRED_ON = 'occurredOn',
	IS_TIMELINE = 'isTimeline',
	LINK = 'link'
}

interface FormData extends Api.V1.Meeting.Patch.Req {
	isTimeline: boolean;
	tags?: string[];
}

enum MeetingIds {
	STANDUP = 1
}

const MeetingDetailsPage: React.FC<MeetingDetailsPageProps> = (props) => {
	const { meetingId } = router.getQueryParams<{ meetingId: number }>([
		{
			key: 'mi',
			type: 'integer',
			alias: 'meetingId',
			default: 0
		}
	]);
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [meetingTypes, setMeetingTypes] = useRecoilState<Api.V1.MeetingType.All.Get.Res[]>(globalState.meetingTypes);

	const [meetingDetails, setMeetingDetails] = useState<Api.V1.Meeting.Paged.Get.Res | undefined>();
	const [fatalErrorMessage, setFatalErrorMessage] = useState<string>('');
	const [tagOptions, setTagOptions] = useState<{ label: string; value: string }[]>([]);
	const [meetingTypeOptions, setMeetingTypeOptions] = useState<{ label: string; value: number }[]>([]);
	const [tagsForMeeting, setTagsForMeeting] = useState<string[]>([]);

	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.TITLE, '', [new RsValidator(RsValidatorEnum.REQ, 'Title is required')]),
			new RsFormControl<string>(FormKeys.DESCRIPTION, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Description is required')
			]),
			new RsFormControl<string>(FormKeys.NOTES, '', []),
			new RsFormControl<number>(FormKeys.MEETING_TYPE, MeetingIds.STANDUP, [
				new RsValidator(RsValidatorEnum.REQ, 'Type is required')
			]),
			new RsFormControl<string>(FormKeys.OCCURRED_ON, '', []),
			new RsFormControl<boolean>(FormKeys.IS_TIMELINE, false),
			new RsFormControl<string>(FormKeys.LINK, '', [
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid URL', (control) => {
					if (!control.value) return true;
					return StringUtils.isValidUrl(control.value.toString());
				})
			]),
			new RsFormControl<string[]>(FormKeys.TAGS, []),
			new RsFormControl<string>(FormKeys.STATUS, 'HIDDEN', [
				new RsValidator(RsValidatorEnum.REQ, 'Status is required')
			])
		])
	);

	const statusOptions = useMemo<{ label: string; value: string }[]>(() => {
		return [
			{ label: 'Visible', value: 'VISIBLE' },
			{ label: 'Hidden', value: 'HIDDEN' }
		];
	}, []);

	useEffect(() => {
		setMeetingTypeOptions(meetingTypes.map((type) => ({ label: type.name, value: type.id })));
	}, [meetingTypes]);

	useEffect(() => {
		if (!meetingId || !version) return;

		(async function GetMeetingDetails() {
			try {
				let responses: [
					Promise<Api.V1.Tag.Unique.All.Get.Res[]>,
					Promise<RedSky.RsPagedResponseData<Api.V1.Tag.Paged.Get.Res[]>>,
					Promise<RedSky.RsPagedResponseData<Api.V1.Meeting.Paged.Get.Res[]>>,
					Promise<Api.V1.MeetingType.All.Get.Res[]>
				] = [
					ApiRequestV1.getTagUniqueAll(),
					ApiRequestV1.getTagPaged({
						perPage: 25,
						page: 1,
						filter: `(column:tag.meetingId,value:${meetingId})`
					}),
					ApiRequestV1.getMeetingPaged({
						filter: `(column:meeting.id,value:${meetingId})`,
						versionId: version.id
					}),
					ApiRequestV1.getMeetingTypeAll()
				];
				let res = await Promise.all(responses);
				const [allTags, tagsForMeetingRes, meetingDetailsRes, newMeetingTypes] = res;

				if (meetingDetailsRes.data.length !== 1) {
					setFatalErrorMessage('Could not find meeting');
					return;
				}

				setTagOptions(allTags.map((tag) => ({ label: tag.name, value: tag.name })));
				setTagsForMeeting(tagsForMeetingRes.data.map((tag) => tag.name));
				setMeetingTypes(newMeetingTypes);

				const meetingData = meetingDetailsRes.data[0];
				setMeetingDetails(meetingData);

				let updatedForm = formGroup.cloneDeep();
				updatedForm.get(FormKeys.TITLE).value = meetingData.title;
				updatedForm.get(FormKeys.DESCRIPTION).value = meetingData.description || '';
				updatedForm.get(FormKeys.NOTES).value = meetingData.notes || '';
				updatedForm.get(FormKeys.STATUS).value = meetingData.status;
				updatedForm.get(FormKeys.TAGS).value = tagsForMeetingRes.data.map((tag) => tag.name);
				updatedForm.get(FormKeys.MEETING_TYPE).value = meetingData.meetingTypeId;
				updatedForm.get(FormKeys.OCCURRED_ON).value = DateUtils.convertIsoStringToHtmlDateInputString(
					meetingData.occurredOn
				);
				updatedForm.get(FormKeys.LINK).value = meetingData.link || '';
				updatedForm.updateInitialValues();
				setFormGroup(updatedForm);
			} catch (e) {
				setFatalErrorMessage(WebUtils.getRsErrorMessage(e, 'Error getting meeting details'));
			}
		})();
	}, [meetingId]);

	if (fatalErrorMessage) return <ErrorPage error={fatalErrorMessage} />;
	if (!meetingDetails) return <LoadingPage />;

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		const updatedFormGroup = formGroup.clone();
		// defaults add to timeline based on meeting type
		if (control.key === FormKeys.MEETING_TYPE) {
			const timelineControl = formGroup.get(FormKeys.IS_TIMELINE);
			timelineControl.value = control.value !== MeetingIds.STANDUP;
			updatedFormGroup.update(timelineControl);
		}
		setFormGroup(updatedFormGroup.update(control));
	}

	function handleAddTagOption(newOption: string) {
		let updatedFormGroup = formGroup.cloneDeep();
		let tagsControl = updatedFormGroup.get<string[]>(FormKeys.TAGS);
		tagsControl.value = [...tagsControl.value, newOption];
		setFormGroup(updatedFormGroup);
		setTagOptions([...tagOptions, { label: newOption, value: newOption }]);
	}

	async function handleSaveMeeting() {
		if (!meetingDetails || !version) return;

		if (!(await formGroup.isValid())) {
			setFormGroup(formGroup.clone());
			rsToastify.error('Please fix the errors in the inputs.', 'Invalid Inputs');
			return;
		}

		let { isTimeline, ...updatedValues } = formGroup.toChangedModel<FormData>();

		// Tags need to be handled differently as we call a different endpoint
		if (updatedValues.tags !== undefined) {
			let newTags: string[] = updatedValues.tags.filter((item) => {
				return !tagsForMeeting.includes(item);
			});
			let deleteTags: string[] = tagsForMeeting.filter((item) => {
				return !updatedValues.tags!.includes(item);
			});
			await ApiRequestV1.putTagMany({
				companyId: version.companyId,
				createTagList: newTags,
				deleteTagList: deleteTags,
				id: meetingId,
				type: 'MEETING'
			});

			setTagsForMeeting(updatedValues.tags);
			delete updatedValues.tags;
		}

		if (Object.keys(updatedValues).length === 0) {
			setFormGroup(formGroup.clone().updateInitialValues());
			popupController.close(LoadingPopup);
			rsToastify.success('Meeting updated successfully');
			return;
		}

		updatedValues.id = meetingDetails.id;

		try {
			popupController.open<LoadingPopupProps>(LoadingPopup, {});
			await ApiRequestV1.patchMeeting(updatedValues);
			setFormGroup(formGroup.clone().updateInitialValues());
			popupController.close(LoadingPopup);
			rsToastify.success('Meeting updated successfully');
		} catch (e) {
			popupController.close(LoadingPopup);
			setFatalErrorMessage(WebUtils.getRsErrorMessage(e, 'Error saving meeting'));
		}
	}

	async function handleDelete() {
		try {
			popupController.open<LoadingPopupProps>(LoadingPopup, {});
			await ApiRequestV1.deleteMeeting({ id: meetingId });
			popupController.close(LoadingPopup);
			rsToastify.success('Meeting successfully deleted');
			router.navigate('/meeting/list').catch(console.error);
		} catch (e) {
			popupController.close(LoadingPopup);
			setFatalErrorMessage(WebUtils.getRsErrorMessage(e, 'Error deleting meeting'));
		}
	}

	return (
		<Page className={'rsMeetingDetailsPage'}>
			<PageHeader
				title={'Edit Meeting'}
				isModified={formGroup.isModified()}
				onSave={handleSaveMeeting}
				onCancel={() => {
					setFormGroup(formGroup.cloneDeep().resetToInitialValue());
				}}
				onBack={() => {
					router.back('/meeting/list');
				}}
			/>
			<Box padding={32} className={'scrolledContent'}>
				<Box flex={'2'}>
					<PaperHeaderBar title={'Meeting Details'}>
						<LabelInputText
							mb={32}
							label={'Title'}
							inputMode={'text'}
							control={formGroup.get(FormKeys.TITLE)}
							updateControl={handleUpdateControl}
						/>
						<LabelInputRichText
							label={'Description'}
							control={formGroup.get(FormKeys.DESCRIPTION)}
							height={200}
							updateControl={handleUpdateControl}
							mb={32}
						/>
						<LabelInputRichText
							label={'Notes'}
							control={formGroup.get(FormKeys.NOTES)}
							height={200}
							updateControl={handleUpdateControl}
							mb={32}
						/>
						<LabelSelect<{ label: string; value: string }, true>
							label={'Tags'}
							placeholder={'Begin typing or select tags'}
							isCreatable
							isMulti
							options={tagOptions}
							control={formGroup.get(FormKeys.TAGS)}
							updateControl={handleUpdateControl}
							onCreateOption={handleAddTagOption}
						/>
						<Label ml={8} mt={4} color={themes.neutralBeige500} variant={'caption1'} weight={'regular'}>
							Add multiple tags at once by hitting enter after each tag
						</Label>
					</PaperHeaderBar>
				</Box>
				<Box flex={'1'}>
					<PaperHeaderBar title={'Status'}>
						<Select<{ label: string; value: string }>
							control={formGroup.get(FormKeys.STATUS)}
							updateControl={handleUpdateControl}
							options={statusOptions}
							mb={24}
						/>
						<Label variant={'body1'} weight={'regular'}>
							Created on {DateUtils.displayFriendlyDateTime(meetingDetails?.createdOn || new Date())}
						</Label>
					</PaperHeaderBar>
					<PaperHeaderBar title={'Organization'}>
						<Box display={'grid'} gap={32}>
							<LabelSelect
								label={'Type'}
								options={meetingTypeOptions}
								control={formGroup.get(FormKeys.MEETING_TYPE)}
								updateControl={handleUpdateControl}
								required
							/>
							<LabelInputText
								className={'dateInput'}
								label={'Date & Time'}
								inputMode={'text'}
								type={'datetime-local'}
								control={formGroup.get(FormKeys.OCCURRED_ON)}
								updateControl={handleUpdateControl}
								placeholder={'Select date and time'}
								icon={[
									{
										position: 'LEFT',
										iconImg: 'icon-calendar',
										color: themes.neutralBeige500
									}
								]}
							/>
							{/*TODO: add back post MVP*/}
							{/*<Checkbox*/}
							{/*	labelText={'Add to timeline'}*/}
							{/*	look={'outlinedPrimary'}*/}
							{/*	control={formGroup.get(FormKeys.IS_TIMELINE)}*/}
							{/*	updateControl={handleUpdateControl}*/}
							{/*	disabled={formGroup.get(FormKeys.MEETING_TYPE).value === MeetingIds.STANDUP}*/}
							{/*/>*/}
						</Box>
					</PaperHeaderBar>
					<PaperHeaderBar title={'Link'}>
						<InputText
							inputMode={'text'}
							placeholder={'URL'}
							control={formGroup.get(FormKeys.LINK)}
							updateControl={handleUpdateControl}
						/>
					</PaperHeaderBar>
					<Button
						look={'outlinedPrimary'}
						onClick={() => {
							popupController.open<ConfirmationPopupProps>(ConfirmationPopup, {
								headerLabel: 'Delete Meeting',
								label: 'Are you sure you want to delete meeting? This action cannot be undone.',
								acceptLabel: 'Delete',
								rejectLabel: 'Cancel',
								onAccept: handleDelete
							});
						}}
						className={'fullWidth'}
						mt={32}
					>
						Delete Meeting
					</Button>
				</Box>
			</Box>
		</Page>
	);
};

export default MeetingDetailsPage;
