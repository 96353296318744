import * as React from 'react';
import './TeamListPage.scss';
import { Page } from '@redskytech/framework/996';
import { useEffect, useState } from 'react';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { Box, Button, popupController, rsToastify } from '@redskytech/framework/ui';
import PageHeader from '../../../components/pageHeader/PageHeader';

import LoadingPage from '../../common/loadingPage/LoadingPage';
import AddEditTeamPopup, { AddEditTeamPopupProps } from '../../../popups/addEditTeamPopup/AddEditTeamPopup';

import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState.js';
import TeamList from './teamList/TeamList.js';
import { WebUtils } from '../../../utils/utils.js';

interface TeamListPageProps {}

const TeamListPage: React.FC<TeamListPageProps> = (props) => {
	const version = useRecoilValue(globalState.version);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [teams, setTeams] = useState<Api.V1.TeamMap.Get.Res[]>([]);

	useEffect(() => {
		getTeams().catch(console.error);
	}, [version]);

	async function getTeams() {
		if (!version) return;
		setIsLoading(true);
		try {
			const res = await ApiRequestV1.getTeamMap({ versionId: version.id });
			setTeams(res);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to get teams for version'), 'Server Error');
		}
		setIsLoading(false);
	}

	function handleAddTeam() {
		popupController.open<AddEditTeamPopupProps>(AddEditTeamPopup, {
			onChange: () => {
				getTeams().catch(console.error);
			},
			onDelete: () => {
				getTeams().catch(console.error);
			}
		});
	}

	function renderHeaderButtons() {
		return (
			<Button look={'containedPrimary'} onClick={handleAddTeam} small>
				Add Team
			</Button>
		);
	}

	if (isLoading) return <LoadingPage />;

	return (
		<Page className={'rsTeamListPage'}>
			<PageHeader title={'Team Lists'} rightNode={renderHeaderButtons()} />
			<Box padding={32} className={'scrolledContent'}>
				{teams.map((team) => {
					return (
						<TeamList key={team.id} team={team} onChange={() => getTeams()} onDelete={() => getTeams()} />
					);
				})}
			</Box>
		</Page>
	);
};

export default TeamListPage;
