import * as React from 'react';
import './SprintPage.scss';
import themes from '../../../themes/themes.scss?export';
import { Page } from '@redskytech/framework/996';
import { Box, Icon, Label } from '@redskytech/framework/ui';
import SectionWithHeader from '../../../components/sectionWithHeader/SectionWithHeader.js';
import ProgressBar from '../../../components/progressBar/ProgressBar.js';
import classNames from 'classnames';
import Paper from '../../../components/paper/Paper.js';
import { useEffect, useState } from 'react';
import { ApiRequestV1 } from '../../../generated/apiRequests.js';
import getSprintAll = ApiRequestV1.getSprintAll;
import { DateUtils, ObjectUtils } from '../../../utils/utils.js';
import StatusChip from '../../../components/statusChip/StatusChip.js';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState.js';
import RichContentDisplay from '../../../components/richContentDisplay/RichContentDisplay.js';

interface SprintPageProps {}

const SprintPage: React.FC<SprintPageProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [sprints, setSprints] = useState<CustomTypes.Sprint[]>([]);
	const [currentSprintIndex, setCurrentSprintIndex] = useState<number>(0);

	useEffect(() => {
		let isCancelled = false;
		(async function getSprints() {
			if (!version) return;
			const res = await getSprintAll({ versionId: version.id });
			if (isCancelled) return;
			setSprints(res);
			setCurrentSprintIndex(0);
		})();
		return () => {
			isCancelled = true;
		};
	}, [version]);

	function renderSprintCarousel() {
		const currentSprint = sprints[currentSprintIndex];
		if (!currentSprint) return <></>;
		return (
			<Box className={'sprintCarousel'}>
				{currentSprintIndex !== 0 && (
					<Icon
						iconImg={'icon-chevron-left'}
						fontSize={16}
						color={themes.neutralBeige600}
						cursorPointer
						onClick={() => setCurrentSprintIndex((prev) => prev - 1)}
					/>
				)}
				<Label className={'currentSprint'} variant={'body1'} weight={'semiBold'}>
					{currentSprint.name}: {DateUtils.formatDate(new Date(currentSprint.startOn), 'MMM-DD')} -{' '}
					{DateUtils.formatDate(new Date(currentSprint.endOn), 'MMM-DD')}
				</Label>
				{currentSprintIndex !== sprints.length - 1 && (
					<Icon
						iconImg={'icon-chevron-right'}
						fontSize={16}
						color={themes.neutralBeige600}
						cursorPointer
						onClick={() => setCurrentSprintIndex((prev) => prev + 1)}
					/>
				)}
				<Label className={'updated'} variant={'caption2'} weight={'regular'} color={themes.neutralBeige600}>
					Updated{' '}
					{Math.floor(DateUtils.daysBetweenStartAndEndDates(new Date(currentSprint.modifiedOn), new Date()))}{' '}
					days ago
				</Label>
			</Box>
		);
	}

	function renderEpicsOverview() {
		const currentSprint = sprints[currentSprintIndex];
		if (!currentSprint) return <></>;
		return (
			<SectionWithHeader title={'EPICS'} className={'epics'} viewAllText={'View All'} viewAllLink={'/'}>
				<Box className={'content'}>
					{' '}
					{currentSprint.epics.map((epic) => {
						let status: 'TO_DO' | 'IN_PROGRESS' | 'DONE' = 'TO_DO';
						if (epic.percentDone === 100) status = 'DONE';
						else if (epic.percentInProgress || epic.percentDone) status = 'IN_PROGRESS';
						return (
							<Box className={'epicDetails'} key={`epic-${epic.id}`}>
								<Box className={'titleStatus'}>
									<Label variant={'body2'} weight={'semiBold'}>
										{epic.name}
									</Label>
									<StatusChip status={status} />
								</Box>
								<ProgressBar
									barType={'PERCENTAGE'}
									barHeight={4}
									progressBarSections={[
										{
											percentageOrAmount: epic.percentToDo,
											sectionColor: themes.accentWarningLight
										},
										{
											percentageOrAmount: epic.percentInProgress,
											sectionColor: themes.accentInfo
										},
										{
											percentageOrAmount: epic.percentDone,
											sectionColor: themes.accentSuccess
										}
									]}
								/>
							</Box>
						);
					})}
				</Box>
			</SectionWithHeader>
		);
	}

	function renderSprintProgress() {
		const currentSprint = sprints[currentSprintIndex];
		if (!currentSprint) return <></>;
		const storyCount = currentSprint.userStories.filter((story) => !story.isStretchGoal).length;
		const donePercent = +(
			(currentSprint.userStories.filter((story) => story.status === 'DONE' && !story.isStretchGoal).length /
				storyCount) *
			100
		).toFixed(2);
		const inProgressPercent = +(
			(currentSprint.userStories.filter((story) => story.status === 'IN_PROGRESS' && !story.isStretchGoal)
				.length /
				storyCount) *
			100
		).toFixed(2);
		const toDoPercent = +(
			(currentSprint.userStories.filter((story) => story.status === 'TO_DO' && !story.isStretchGoal).length /
				storyCount) *
			100
		).toFixed(2);
		return (
			<Paper className={classNames('rsSectionWithHeader', 'progress')}>
				<Box className={'progressTitle'}>
					<Label variant={'subheader2'} weight={'extraBold'}>
						PROGRESS
					</Label>
					<Label variant={'caption2'} weight={'semiBold'} color={themes.accentWarning}>
						{isNaN(donePercent) ? 0 : donePercent}% Complete
					</Label>
				</Box>
				<ProgressBar
					barType={'PERCENTAGE'}
					barHeight={'LARGE'}
					progressBarSections={[
						{
							percentageOrAmount: isNaN(toDoPercent) ? 100 : toDoPercent,
							sectionColor: themes.accentWarningLight
						},
						{
							percentageOrAmount: isNaN(inProgressPercent) ? 0 : inProgressPercent,
							sectionColor: themes.accentInfo
						},
						{ percentageOrAmount: isNaN(donePercent) ? 0 : donePercent, sectionColor: themes.accentSuccess }
					]}
				/>
				<Box className={'keys'}>
					<Box className={'key'}>
						<Box className={'bullet'} bgColor={themes.accentWarningLight} />{' '}
						<Label variant={'caption1'} weight={'semiBold'}>
							To DO
						</Label>
					</Box>
					<Box className={'key'}>
						<Box className={'bullet'} bgColor={themes.accentInfo} />
						<Label variant={'caption1'} weight={'semiBold'}>
							In Progress
						</Label>
					</Box>
					<Box className={'key'}>
						<Box className={'bullet'} bgColor={themes.accentSuccess} />
						<Label variant={'caption1'} weight={'semiBold'}>
							Done
						</Label>
					</Box>
				</Box>
			</Paper>
		);
	}

	function renderStatus() {
		const currentSprint = sprints[currentSprintIndex];
		if (!currentSprint) return <></>;
		let statusColor = themes.accentWarning;
		if (currentSprint.status === 'AHEAD') statusColor = themes.accentSuccess;
		else if (currentSprint.status === 'ON_TRACK') statusColor = themes.accentInfo;
		return (
			<Paper className={classNames('rsSectionWithHeader', 'status')}>
				<Box className={'statusTitle'}>
					<Label variant={'subheader2'} weight={'extraBold'}>
						STATUS
					</Label>
					<Label variant={'body1'} weight={'semiBold'} color={statusColor}>
						{currentSprint.status}
					</Label>
				</Box>
				<Box className={'content'}>
					<Box className={'statusCard'}>
						<Label variant={'caption1'} weight={'semiBold'}>
							PLANNED
						</Label>
						<Label variant={'body1'} weight={'regular'}>
							{currentSprint.plannedHours}
						</Label>
						<Label variant={'caption1'} weight={'semiBold'}>
							Hours
						</Label>
					</Box>
					<Box className={'statusCard'}>
						<Label variant={'caption1'} weight={'semiBold'}>
							UNPLANNED
						</Label>
						<Label variant={'body1'} weight={'regular'}>
							{currentSprint.unplannedHours}
						</Label>
						<Label variant={'caption1'} weight={'semiBold'}>
							Hours
						</Label>
					</Box>
					<Box className={'statusCard'}>
						<Label variant={'caption1'} weight={'semiBold'}>
							COMPLETED
						</Label>
						<Label variant={'body1'} weight={'regular'}>
							{currentSprint.completedHours}
						</Label>
						<Label variant={'caption1'} weight={'semiBold'}>
							Hours
						</Label>
					</Box>
				</Box>
			</Paper>
		);
	}

	function renderTakeaways() {
		const currentSprint = sprints[currentSprintIndex];
		if (!currentSprint?.takeaways) return <></>;
		return (
			<SectionWithHeader title={'TAKEAWAYS'} className={'takeaways'}>
				<RichContentDisplay content={currentSprint.takeaways} />
			</SectionWithHeader>
		);
	}

	function renderSprintPlan() {
		const currentSprint = sprints[currentSprintIndex];
		if (!currentSprint) return <></>;
		return (
			<SectionWithHeader title={'SPRINT PLAN'} className={'userStories'}>
				<Box className={'content'}>
					{currentSprint.userStories
						.filter((story) => !story.isStretchGoal)
						.map((story) => {
							return (
								<Box key={`story-${story.id}`} className={'story'}>
									<Label variant={'caption1'} weight={'regular'} color={themes.neutralBeige500}>
										{story.epicName}
									</Label>
									<Box className={'storyTitle'}>
										<Label variant={'body2'} weight={'semiBold'} color={themes.neutralWhite}>
											{story.name}
										</Label>
										<StatusChip status={story.status} />
									</Box>
									<RichContentDisplay content={story.description} />
								</Box>
							);
						})}
					{ObjectUtils.isArrayWithData(currentSprint.userStories.filter((story) => story.isStretchGoal)) && (
						<Box className={'stretchGoal'}>
							<Label variant={'body2'} weight={'semiBold'} color={themes.neutralBeige600}>
								Stretch Goal
							</Label>
							{currentSprint.userStories
								.filter((story) => story.isStretchGoal)
								.map((story) => {
									return (
										<Box className={'story'} key={`stretch-${story.id}`}>
											<Label
												variant={'caption1'}
												weight={'regular'}
												color={themes.neutralBeige500}
											>
												{story.epicName}
											</Label>
											<Box className={'storyTitle'}>
												<Label
													variant={'body2'}
													weight={'semiBold'}
													color={themes.neutralWhite}
												>
													{story.name}
												</Label>
												<StatusChip status={story.status} />
											</Box>
											<RichContentDisplay content={story.description} />
										</Box>
									);
								})}
						</Box>
					)}
				</Box>
			</SectionWithHeader>
		);
	}

	return (
		<Page className={'rsSprintPage'}>
			{renderSprintCarousel()}
			{renderSprintProgress()}
			{renderStatus()}
			{renderTakeaways()}
			{renderSprintPlan()}
			{renderEpicsOverview()}
		</Page>
	);
};

export default SprintPage;
