import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import './AddEditTeamPopup.scss';
import {
	Box,
	Button,
	Popup,
	popupController,
	PopupProps,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import LabelInputText from '../../components/labelInputText/LabelInputText';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { WebUtils } from '../../utils/utils';
import LabelSelect from '../../components/labelSelect/LabelSelect.js';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState.js';
import ConfirmationPopup, { ConfirmationPopupProps } from '../confirmationPopup/ConfirmationPopup.js';

export interface AddEditTeamPopupProps extends PopupProps {
	teamId?: number;
	companyId?: number;
	teamName?: string;
	onChange: () => void;
	onDelete: () => void;
}

enum FormKeys {
	NAME = 'name',
	COMPANY_ID = 'companyId'
}

const AddEditTeamPopup: React.FC<AddEditTeamPopupProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.NAME, props.teamName || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Name is required')
			]),
			new RsFormControl<number>(FormKeys.COMPANY_ID, props.companyId || 0, [
				new RsValidator(RsValidatorEnum.REQ, 'Company is required')
			])
		])
	);
	const [companies, setCompanies] = useState<Api.V1.Company.All.Get.Res[]>([]);

	useEffect(() => {
		let isCancelled = false;
		(async function getCompanies() {
			const res = await ApiRequestV1.getCompanyAll();
			if (isCancelled) return;
			setCompanies(res);
		})();
		return () => {
			isCancelled = true;
		};
	});

	const companyOptions = useMemo<{ label: string; value: number }[]>(() => {
		return companies.map((company) => {
			return { label: company.name || `company ${company.id}`, value: company.id };
		});
	}, [companies]);

	function handleUpdate(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function handleClose() {
		popupController.close(AddEditTeamPopup);
	}

	async function handleDeleteTeam() {
		if (!props.teamId) return;
		try {
			await ApiRequestV1.deleteTeam({ id: props.teamId });
			rsToastify.success('Team Deleted', 'Success');
			props.onDelete();
			popupController.close(AddEditTeamPopup);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Server Error'), 'Error');
		}
	}

	async function handleSaveTeam() {
		if (!version) return;
		if (!(await formGroup.isValid())) {
			rsToastify.error('Please fix the errors in the inputs', 'Input Error');
			setFormGroup(formGroup.clone());
			return;
		}

		let formData = formGroup.toModel<{ name: string; companyId: number }>();

		try {
			if (props.teamId) {
				await ApiRequestV1.patchTeam({ id: props.teamId, ...formData });
				rsToastify.success('Team Updated', 'Success');
			} else {
				await ApiRequestV1.postTeam({ ...formData, versionId: version.id });
				rsToastify.success('Team Created', 'Success');
			}
			props.onChange();
			popupController.close(AddEditTeamPopup);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Server Error'), 'Error');
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsAddEditTeamPopup'}>
				<PopupHeader title={`${!!props.teamId ? 'Edit' : 'Add'} Team`} onClose={handleClose} />
				<Box className={'content'}>
					<LabelSelect
						isDisabled={!!props.teamId}
						label={'Company'}
						options={companyOptions}
						control={formGroup.get<number>(FormKeys.COMPANY_ID)}
						updateControl={(control) => {
							const nameClone = formGroup.getClone<string>(FormKeys.NAME);
							nameClone.value = companies.find((company) => company.id === control.value)?.name || '';
							handleUpdate(nameClone);
							handleUpdate(control);
						}}
						required
					/>
					<LabelInputText
						label={'Name'}
						inputMode={'text'}
						required
						control={formGroup.get<string>(FormKeys.NAME)}
						updateControl={handleUpdate}
					/>
					<Box className={'buttonGroup'}>
						{!!props.teamId && (
							<Button
								look={'iconSecondary'}
								icon={[
									{
										iconImg: 'icon-trash',
										position: 'LEFT',
										fontSize: 16
									}
								]}
								onClick={() => {
									popupController.open<ConfirmationPopupProps>(ConfirmationPopup, {
										headerLabel: 'Delete Meeting',
										label: `Are you sure you want to delete ${
											formGroup.get<string>(FormKeys.NAME).value
										}? This action cannot be undone.`,
										acceptLabel: 'Delete',
										rejectLabel: 'Cancel',
										onAccept: handleDeleteTeam
									});
								}}
							/>
						)}
						<Button look={'outlinedPrimary'} fullWidth onClick={handleClose}>
							Cancel
						</Button>
						<Button
							look={'containedPrimary'}
							fullWidth
							disabled={!formGroup.isModified()}
							onClick={handleSaveTeam}
						>
							{!!props.teamId ? 'Save' : 'Create'}
						</Button>
					</Box>
				</Box>
			</Box>
		</Popup>
	);
};

export default AddEditTeamPopup;
