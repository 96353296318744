import * as React from 'react';
import './ExternalAppBar.scss';
import { Avatar, Box, Icon, Label, rsToastify, Select } from '@redskytech/framework/ui';
import { useRecoilState } from 'recoil';
import globalState from '../../state/globalState';
import themes from '../../themes/themes.scss?export';
import { useEffect, useRef, useState } from 'react';
import router from '../../utils/router';
import { ApiRequestV1 } from '../../generated/apiRequests.js';
import { WebUtils } from '@redskytech/framework/utils';
import BackButton from '../backButton/BackButton';
import { useCurrentPath } from '@redskytech/framework/hooks';
import { RoutePaths } from '../../routes';

interface ExternalAppBarProps {}

const ExternalAppBar: React.FC<ExternalAppBarProps> = (props) => {
	const routeBackButtonMap = new Map<RoutePaths, RoutePaths>([
		['/artifacts', '/home'],
		['/artifacts/details', '/artifacts'],
		['/team', '/home'],
		['/project', '/home'],
		['/phase/details', '/project'],
		['/sprint', '/home'],
		['/tasks', '/home'],
		['/tasks/all', '/tasks'],
		['/task/external/details', '/tasks'],
		['/meeting', '/home'],
		['/meeting/view', '/meeting'],
		['/meeting/external/details', '/meeting'],
		['/budget', '/home'],
		['/budget/monthly', '/budget'],
		['/budget/phase', '/budget']
	]);

	const [user, setUser] = useRecoilState<Api.V1.User.Get.Res | undefined>(globalState.user);
	const [version, setVersion] = useRecoilState<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const appBarRef = useRef<HTMLDivElement | null>(null);
	const [availableVersions, setAvailableVersions] = useState<CustomTypes.VersionDetails[]>([]);
	const [availableVersionSelect, setAvailableVersionSelect] = useState<{ value: number; label: JSX.Element }[]>([]);
	const currentPath = useCurrentPath();

	useEffect(() => {
		let isCancelled = false;
		(async function getAvailableVersions() {
			const res = await ApiRequestV1.getVersionMe();
			if (isCancelled) return;
			setAvailableVersions(res);
		})();
		return () => {
			isCancelled = true;
		};
	}, []);

	useEffect(() => {
		const newSelects = availableVersions.map((availableVersion: CustomTypes.VersionDetails) => {
			const isDefault = availableVersion.versionId === user?.defaultVersionId;
			return {
				value: availableVersion.versionId,
				label: (
					<Box className={'versionOption'}>
						<Icon
							iconImg={`icon-star${isDefault ? '' : '-outline'}`}
							fontSize={16}
							onClick={async (event) => {
								event.stopPropagation();
								if (!user || isDefault) return;
								try {
									await ApiRequestV1.patchUser({
										id: user.id,
										defaultVersionId: availableVersion.versionId
									});
									setUser((prev) => {
										if (!prev) return;
										return { ...prev, defaultVersionId: availableVersion.versionId };
									});
								} catch (e) {
									rsToastify.error(
										WebUtils.getRsErrorMessage(e, 'Unable to update default version'),
										'Server Error'
									);
								}
							}}
						/>
						<Label variant={'body1'} weight={'bold'}>
							{availableVersion.projectName} - {availableVersion.versionName}
						</Label>
					</Box>
				)
			};
		});
		setAvailableVersionSelect(newSelects);
	}, [availableVersions, version, user?.defaultVersionId]);

	useEffect(() => {
		if (!appBarRef.current) return;
		let height = appBarRef.current.offsetHeight;
		if (height === 0) return;
		let app = document.querySelector<HTMLDivElement>('.App')!;
		app.style.setProperty('--pageOffsetTop', height + 'px');
	}, [appBarRef.current]);

	return (
		<Box className={'rsExternalAppBar'} elementRef={appBarRef}>
			<Box display={'flex'} alignItems={'center'}>
				{routeBackButtonMap.has(currentPath as RoutePaths) && (
					<BackButton backRoute={routeBackButtonMap.get(currentPath as RoutePaths)!} />
				)}
				<Box>
					<Label
						variant={'caption1'}
						weight={'semiBold'}
						color={themes.neutralBeige600}
						textTransform={'uppercase'}
					>
						{version?.phases[0]?.name || ''}
					</Label>
					{availableVersionSelect.length > 1 ? (
						<Select<{ value: number; label: JSX.Element }>
							value={availableVersionSelect.find((availableVersion) => {
								return availableVersion.value === version?.id;
							})}
							onChange={async (newValue) => {
								if (!newValue) return;
								const res = await ApiRequestV1.getVersion({
									versionId: newValue.value
								});
								setVersion(res);
							}}
							options={availableVersionSelect}
							isSearchable={false}
						/>
					) : (
						availableVersionSelect[0]?.label
					)}
				</Box>
			</Box>
			<Avatar
				widthHeight={32}
				name={`${user?.firstName || ''} ${user?.lastName || ''}`}
				onClick={() => {
					router.changeView('profile');
				}}
			/>
		</Box>
	);
};

export default ExternalAppBar;
