import * as React from 'react';
import './TaskItem.scss';
import { Box, Icon, Label } from '@redskytech/framework/ui';
import { DateUtils, ObjectUtils } from '../../utils/utils';
import classNames from 'classnames';

export type TaskType = 'OVERDUE' | 'IN_PROGRESS' | 'COMPLETED';

interface TaskItemProps {
	task: Api.V1.Task.Paged.Get.Res;
	taskType: TaskType;
	onClick?: () => void;
}

const TaskItem: React.FC<TaskItemProps> = (props) => {
	function getCompletedDate() {
		const date = new Date(props.task.completedOn || Date());
		return DateUtils.getMonthName(date) + ' ' + date.getDate();
	}

	function renderTaskDateInfo(): React.ReactNode {
		if (props.taskType === 'OVERDUE') {
			return (
				<Label variant={'caption2'} weight={'semiBold'} className={'pastDueCount'}>
					{DateUtils.getDateDifferenceFriendly(new Date(), new Date(props.task.dueOn || Date()))} past due!
				</Label>
			);
		} else if (props.taskType === 'IN_PROGRESS') {
			return (
				<Label
					variant={'caption2'}
					weight={'semiBold'}
					textTransform={'uppercase'}
					color={ObjectUtils.getTaskColor(props.taskType)}
				>
					due in {DateUtils.getDateDifferenceFriendly(new Date(props.task.dueOn || Date()), new Date())}
				</Label>
			);
		} else if (props.taskType === 'COMPLETED') {
			return (
				<Label
					variant={'caption2'}
					weight={'semiBold'}
					textTransform={'uppercase'}
					color={ObjectUtils.getTaskColor(props.taskType)}
				>
					COMPLETED ON {getCompletedDate()}
				</Label>
			);
		}
	}

	return (
		<Box
			className={classNames('rsTaskItem', {
				completed: props.taskType === 'COMPLETED',
				overdue: props.taskType === 'OVERDUE'
			})}
			onClick={props.onClick}
			style={{ cursor: props.onClick ? 'pointer' : '' }}
		>
			<Box display={'flex'} gap={4} alignItems={'flex-end'}>
				<Icon iconImg={'icon-task'} fontSize={16} />
				<Label variant={'caption2'} weight={'regular'} color={ObjectUtils.getTaskColor(props.taskType)}>
					TASK -
				</Label>
				{renderTaskDateInfo()}
			</Box>
			<Label variant={'body2'} weight={'semiBold'}>
				{props.task.name}
			</Label>
			<Label variant={'caption1'} weight={'regular'}>
				{props.task.description}
			</Label>
			{ObjectUtils.isArrayWithData(props.task.assignedUsers) && (
				<Label variant={'caption1'} weight={'regular'}>
					Assigned to{' '}
					<span style={{ color: ObjectUtils.getTaskColor(props.taskType) }}>
						{props.task.assignedUsers[0].firstName + ' ' + props.task.assignedUsers[0].lastName}
					</span>
				</Label>
			)}
		</Box>
	);
};

export default TaskItem;
