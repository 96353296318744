import * as React from 'react';
import './SprintGroupSection.scss';
import Paper from '../../components/paper/Paper';
import { Label } from '@redskytech/framework/ui';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { DateUtils } from '../../utils/utils';
import themes from '../../themes/themes.scss?export';

interface SprintGroupSectionProps {}

const SprintGroupSection: React.FC<SprintGroupSectionProps> = () => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);

	if (!version) return <></>;

	return (
		<Paper className={'rsSprintGroupSection sprintGroup'}>
			<Label variant={'caption1'} weight={'semiBold'} color={themes.neutralBeige600}>
				REMAINING SPRINTS TO END OF PHASE
			</Label>
			<Label variant={'body1'} weight={'semiBold'} color={themes.neutralBeige300}>
				{version.sprintsLeftInPhase}
			</Label>
			<Label variant={'caption2'} weight={'regular'} color={themes.neutralBeige600}>
				Updated {DateUtils.getDateDifferenceFriendly(new Date(), new Date(version.modifiedOn))} days ago
			</Label>
		</Paper>
	);
};

export default SprintGroupSection;
