import * as React from 'react';
import './TaskListPage.scss';
import { Page } from '@redskytech/framework/996';
import { Box, Button, rsToastify } from '@redskytech/framework/ui';
import PageHeader from '../../../components/pageHeader/PageHeader';
import ListDataTable from '../../../components/listDataTable/ListDataTable';
import { useEffect, useState } from 'react';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { DateUtils, WebUtils } from '../../../utils/utils';
import { Column } from 'primereact/column';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import themes from '../../../themes/themes.scss?export';
import ColumnHeader from '../../../components/listDataTable/columnHeader/ColumnHeader';
import router from '../../../utils/router';
import TableSearchHeader from '../../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import { RoutePaths } from '../../../routes';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState.js';
import useIsMounted from '../../../customHooks/useIsMounted';
import { ObjectUtils } from '@redskytech/framework/utils';

interface TaskListPageProps {}

const FieldKeys: {
	[key in 'TITLE' | 'DUE_IN' | 'ASSIGNED_TO' | 'STATUS' | 'CREATED_ON']: { field: string; sortField: string };
} = {
	TITLE: { field: 'name', sortField: 'task.name' },
	DUE_IN: { field: 'dueOn', sortField: 'task.dueOn' },
	ASSIGNED_TO: { field: 'user.firstName', sortField: 'user.firstName' },
	STATUS: { field: 'status', sortField: 'task.status' },
	CREATED_ON: { field: 'createdOn', sortField: 'task.createdOn' }
};

const TaskListPage: React.FC<TaskListPageProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeSortColumn, setActiveSortColumn] = useState<string>();
	const [globalSearch, setGlobalSearch] = useState<string>('');
	const [currentQuery, setCurrentQuery] = useState<RedSky.PageQuery>();
	const isMounted = useIsMounted();

	const [taskList, setTaskList] = useState<RedSky.RsPagedResponseData<Api.V1.Task.Paged.Get.Res[]>>({
		data: [],
		total: 0
	});

	useEffect(() => {
		if (!currentQuery) return;
		getData(currentQuery);
	}, [version]);

	async function getData(pageQuery: RedSky.PageQuery) {
		setIsLoading(true);
		setActiveSortColumn(pageQuery.sortBy);
		setCurrentQuery(pageQuery);
		try {
			if (!version) return;
			if (pageQuery.sortBy === undefined) {
				delete pageQuery.sortBy;
				delete pageQuery.sortOrder;
			}
			if (!pageQuery.filter) delete pageQuery.filter;

			const res = await ApiRequestV1.getTaskPaged({ ...pageQuery, versionId: version.id });
			if (!isMounted) return;
			setTaskList(res);
			setIsLoading(false);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function handleRowClick({ data }: DataTableRowClickEventParams) {
		router.navigate('/task/details?ti=' + data.id).catch(console.error);
	}

	function handleGenerateTasks() {
		rsToastify.error('Not Implemented', 'Error');
	}

	function handleAddTasks() {
		router.navigate<RoutePaths>('/task/add').catch(console.error);
	}

	function renderHeaderButtons() {
		return (
			<Box display={'flex'} gap={24}>
				<Button look={'outlinedPrimary'} onClick={handleGenerateTasks} small>
					Generate Tasks
				</Button>
				<Button look={'containedPrimary'} onClick={handleAddTasks} small>
					Add Task
				</Button>
			</Box>
		);
	}

	return (
		<Page className={'rsTaskListPage'}>
			<PageHeader title={'Task List'} rightNode={renderHeaderButtons()} />
			<Box padding={32} className={'scrolledContent'}>
				<ListDataTable
					loading={isLoading}
					onClearFilters={() => {}}
					data={taskList}
					getData={getData}
					globalFilter={globalSearch}
					globalFilterFields={['task.name']}
					onRowClick={handleRowClick}
					header={
						<TableSearchHeader searchValue={globalSearch} onChange={(value) => setGlobalSearch(value)} />
					}
				>
					<Column
						field={FieldKeys.TITLE.field}
						sortField={FieldKeys.TITLE.sortField}
						header={
							<ColumnHeader
								label={'Title'}
								isActiveSort={activeSortColumn === FieldKeys.TITLE.sortField}
							/>
						}
						sortable
						// className={classNames({ activeFilter: activeFilters.includes(FieldKeys.PRODUCT) })}
						// filterElement={
						// 	<InputText
						// 		placeholder={'Filter'}
						// 		inputMode={'text'}
						// 		control={formGroup.get(FieldKeys.PRODUCT)}
						// 		updateControl={handleUpdateControl}
						// 	/>
						// }
					/>
					<Column
						sortField={'task.dueOn'}
						header={
							<ColumnHeader
								label={'Due In'}
								isActiveSort={activeSortColumn === FieldKeys.DUE_IN.sortField}
							/>
						}
						sortable
						body={(task: Api.V1.Task.Paged.Get.Res) => {
							if (!task.dueOn) return '-';

							let friendlyDateDiff = DateUtils.getDateDifferenceFriendly(
								new Date(task.dueOn),
								new Date()
							);
							return <>{task.status === 'COMPLETED' ? '-' : friendlyDateDiff}</>;
						}}
					/>
					<Column
						sortField={'user.firstName'}
						header={
							<ColumnHeader
								label={'Assigned To'}
								isActiveSort={activeSortColumn === FieldKeys.ASSIGNED_TO.sortField}
							/>
						}
						sortable
						body={(task: Api.V1.Task.Paged.Get.Res) => {
							if (!ObjectUtils.isArrayWithData(task.assignedUsers)) return <>-</>;
							return (
								<>
									{task.assignedUsers[0].firstName} {task.assignedUsers[0].lastName}
								</>
							);
						}}
					/>
					<Column
						sortField={FieldKeys.STATUS.sortField}
						header={
							<ColumnHeader
								label={'Status'}
								isActiveSort={activeSortColumn === FieldKeys.STATUS.sortField}
							/>
						}
						sortable
						body={(task: Api.V1.Task.Paged.Get.Res) => {
							if (task.status === 'NOT_COMPLETED')
								return <span style={{ color: themes.accentWarning }}>In Progress</span>;
							else if (task.status === 'COMPLETED')
								return <span style={{ color: themes.accentSuccess }}>Completed</span>;
							else return <>Hidden</>;
						}}
					/>
					<Column
						sortField={FieldKeys.CREATED_ON.sortField}
						header={
							<ColumnHeader
								label={'Created On'}
								isActiveSort={activeSortColumn === FieldKeys.CREATED_ON.sortField}
							/>
						}
						sortable
						body={(task: Api.V1.Task.Paged.Get.Res) => {
							return <>{DateUtils.displayFriendlyDateTime(task.createdOn)}</>;
						}}
					/>
				</ListDataTable>
			</Box>
		</Page>
	);
};

export default TaskListPage;
