import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import './AddEditUserPopup.scss';
import {
	Box,
	Button,
	Popup,
	popupController,
	PopupProps,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import LabelInputText from '../../components/labelInputText/LabelInputText';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import ConfirmationPopup, { ConfirmationPopupProps } from '../confirmationPopup/ConfirmationPopup';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import DiscardChangesPopup, { DiscardChangesPopupProps } from '../discardChangesPopup/DiscardChangesPopup';
import LoadingPopup, { LoadingPopupProps } from '../loadingPopup/LoadingPopup';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { WebUtils } from '../../utils/utils';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import LabelSwitch from '../../components/labelSwitch/LabelSwitch';
import serviceFactory from '../../services/serviceFactory.js';
import UserService from '../../services/user/UserService.js';
import CompanyService from '../../services/company/CompanyService.js';
import SparkMD5 from 'spark-md5';

export interface AddEditUserPopupProps extends PopupProps {
	onUpdate: () => void;
	user?: Api.V1.User.Paged.Get.Res;
}

enum FormKeys {
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	EMAIL = 'email',
	PERMISSION = 'role',
	COMPANY_ID = 'companyId',
	PASSWORD = 'password',
	PHONE = 'phone',
	SLACK = 'slackId'
}

const AddEditUserPopup: React.FC<AddEditUserPopupProps> = (props) => {
	const userService = serviceFactory.get<UserService>('UserService');
	const companyService = serviceFactory.get<CompanyService>('CompanyService');
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl(FormKeys.FIRST_NAME, props.user?.firstName || '', [
				new RsValidator(RsValidatorEnum.REQ, 'First name is required')
			]),
			new RsFormControl(FormKeys.LAST_NAME, props.user?.lastName || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Last name is required')
			]),
			new RsFormControl(FormKeys.EMAIL, props.user?.email || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Email is required')
			]),
			new RsFormControl(FormKeys.PERMISSION, props.user?.userRole || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Permission is required')
			]),
			new RsFormControl(FormKeys.COMPANY_ID, props.user?.companies[0]?.id || 1, [
				new RsValidator(RsValidatorEnum.REQ, 'Company is required')
			]),
			new RsFormControl(FormKeys.PASSWORD, '', [
				new RsValidator(
					RsValidatorEnum.CUSTOM,
					'Password is required',
					(control: RsFormControl<IRsFormControl>) => {
						if (props.user) return true;
						return !!control.value?.toString();
					}
				)
			]),
			new RsFormControl(FormKeys.PHONE, props.user?.phone || ''),
			new RsFormControl(FormKeys.SLACK, props.user?.slackId || '')
		])
	);
	const [hasLoginAccess, setHasLoginAccess] = useState<boolean>(props.user?.userPermissionLogin || true);
	const [companyOptions, setCompanyOptions] = useState<{ label: string; value: number }[]>([]);

	const roleOptions = useMemo(() => {
		return [
			{ label: 'Admin', value: 'admin' },
			{ label: 'Client', value: 'client' },
			{ label: 'Developer', value: 'developer' },
			{ label: 'Partner', value: 'partner' },
			{ label: 'PM', value: 'pm' },
			{ label: 'Super Admin', value: 'superAdmin' }
		];
	}, []);

	useEffect(() => {
		(async function getAllCompanies() {
			try {
				let res = await ApiRequestV1.getCompanyAll();
				setCompanyOptions(companyService.createCompanySelectOptions(res));
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
			}
		})();
	}, []);

	function handleUpdate(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function handleClose() {
		if (formGroup.isModified()) {
			popupController.open<DiscardChangesPopupProps>(DiscardChangesPopup, {});
		} else {
			popupController.close(AddEditUserPopup);
		}
	}

	async function handleDelete() {
		if (!props.user) return;
		try {
			popupController.open<LoadingPopupProps>(LoadingPopup, {});
			await ApiRequestV1.deleteUser({ id: props.user.id });
			props.onUpdate();
			popupController.closeAll();
			rsToastify.success('User successfully deleted');
		} catch (e) {
			popupController.close(LoadingPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	async function handleSave() {
		if (!version) return;
		if (!(await formGroup.isValid())) {
			rsToastify.error('Please fix the errors in the inputs', 'Input Error');
			setFormGroup(formGroup.clone());
			return;
		}
		popupController.open<LoadingPopupProps>(LoadingPopup, {});
		let { companyId, password, ...formData } = formGroup.toModel<{
			firstName: string;
			lastName: string;
			email: string;
			phone: string;
			role: string;
			password: string;
			slackId: string;
			companyId: number;
		}>();
		try {
			if (props.user) {
				let updateUser: Api.V1.User.Patch.Req = {
					...formData,
					id: props.user.id,
					permissionLogin: hasLoginAccess
				};
				if (password) {
					password = SparkMD5.hash(password);
					// Todo: Make a method for updating password via admin portal
					rsToastify.warning('Password cannot be updated via admin portal YET.', 'Warning');
				}

				await userService.updateUser(updateUser);
				await ApiRequestV1.patchCompanyUser({
					companyId,
					userId: props.user.id
				});
				rsToastify.success('User Updated', 'Success');
				props.onUpdate();
			} else {
				if (!password) {
					rsToastify.error('Password is required when creating a new user', 'Input Error');
					return;
				}
				await userService.createUser({ ...formData, password, companyId });
				rsToastify.success('User Created', 'Success');
				props.onUpdate();
			}
			popupController.closeAll();
		} catch (e) {
			popupController.close(LoadingPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Server Error'), 'Error');
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsAddEditUserPopup'}>
				<PopupHeader title={`${props.user ? 'Edit' : 'Add'} User`} onClose={handleClose} />
				<Box className={'content'}>
					<LabelInputText
						label={'First Name'}
						inputMode={'text'}
						control={formGroup.get(FormKeys.FIRST_NAME)}
						updateControl={handleUpdate}
						required
					/>
					<LabelInputText
						label={'Last Name'}
						inputMode={'text'}
						control={formGroup.get(FormKeys.LAST_NAME)}
						updateControl={handleUpdate}
						required
					/>
					<LabelInputText
						label={'Email'}
						inputMode={'email'}
						control={formGroup.get(FormKeys.EMAIL)}
						updateControl={handleUpdate}
						required
					/>
					<LabelSelect
						label={'Permission'}
						options={roleOptions}
						control={formGroup.get(FormKeys.PERMISSION)}
						updateControl={handleUpdate}
						required
					/>
					<LabelSelect
						label={'Company'}
						options={companyOptions}
						control={formGroup.get(FormKeys.COMPANY_ID)}
						updateControl={handleUpdate}
						required
					/>
					<LabelInputText
						label={'Password'}
						inputMode={'text'}
						required={!props.user}
						control={formGroup.get(FormKeys.PASSWORD)}
						updateControl={handleUpdate}
					/>
					<LabelInputText
						label={'Phone'}
						inputMode={'tel'}
						control={formGroup.get(FormKeys.PHONE)}
						updateControl={handleUpdate}
					/>
					<LabelInputText
						label={'Slack Link'}
						inputMode={'text'}
						control={formGroup.get(FormKeys.SLACK)}
						updateControl={handleUpdate}
					/>
					{props.user && (
						<LabelSwitch
							label={'Log In Access'}
							labelPosition={'right'}
							checked={hasLoginAccess}
							onChange={(checked) => {
								setHasLoginAccess(checked);
							}}
						/>
					)}
					<Box className={'buttonGroup'}>
						{props.user && (
							<Button
								look={'iconSecondary'}
								icon={[{ iconImg: 'icon-trash', position: 'LEFT' }]}
								onClick={() => {
									popupController.open<ConfirmationPopupProps>(ConfirmationPopup, {
										headerLabel: 'Delete User',
										label: `Are you sure you want to delete user? This action cannot be undone.`,
										acceptLabel: 'Delete',
										rejectLabel: 'Cancel',
										onAccept: handleDelete
									});
								}}
							/>
						)}
						<Button look={'outlinedPrimary'} fullWidth onClick={handleClose}>
							Cancel
						</Button>
						<Button
							look={'containedPrimary'}
							fullWidth
							onClick={handleSave}
							disabled={
								!formGroup.isModified() &&
								!(props.user && hasLoginAccess !== props.user?.userPermissionLogin)
							}
						>
							{props.user ? 'Save' : 'Create'}
						</Button>
					</Box>
				</Box>
			</Box>
		</Popup>
	);
};
export default AddEditUserPopup;
