import * as React from 'react';
import './MeetingPage.scss';
import themes from '../../../themes/themes.scss?export';
import { Page } from '@redskytech/framework/996';
import SectionWithHeader from '../../../components/sectionWithHeader/SectionWithHeader';
import MeetingItem from '../../../components/meetingItem/MeetingItem';
import { Box, Icon, InputText, Label, RsFormControl, RsFormGroup, rsToastify } from '@redskytech/framework/ui';
import useIsAtBreakpoint from '../../../customHooks/useIsAtBreakpoint';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import { useEffect, useState } from 'react';
import { DateUtils, WebUtils } from '../../../utils/utils';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import classNames from 'classnames';
import Pagination from '../../../components/pagination/Pagination';
import MeetingRecurring from '../../../components/meetingRecurring/MeetingRecurring';
import router from '../../../utils/router';

interface MeetingPageProps {}

enum FormKeys {
	SEARCH = 'Search'
}

const MeetingPage: React.FC<MeetingPageProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [searchedMeetings, setSearchedMeetings] = useState<Api.V1.Meeting.Paged.Get.Res[]>();
	const [searchGroup, setSearchGroup] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl<string>(FormKeys.SEARCH, '', [])])
	);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [groupedMeetings, setGroupedMeetings] = useState<Api.V1.Meeting.Grouped.Get.Res[]>([]);
	const isSmallerThan1024 = useIsAtBreakpoint(1023);
	const isSmallerThan768 = useIsAtBreakpoint(767);
	const searchValue: string = searchGroup.get<string>(FormKeys.SEARCH).value.toString();

	useEffect(() => {
		if (!version) return;
		let isCancelled = false;
		(async function getMeetings() {
			const res = await ApiRequestV1.getMeetingGrouped({ versionId: version.id });
			if (isCancelled) return;
			setGroupedMeetings(res);
		})();
		return () => {
			isCancelled = true;
		};
	}, [version]);

	useEffect(() => {
		if (!version) return;
		if (searchValue === '') return;
		const filter = `(column:status,value:VISIBLE)and((column:title,value:${searchValue},type:contains)or(column:description,value:${searchValue},type:contains)or(column:occurredOn,value:${searchValue},type:contains))`;
		(async function searchMeetings() {
			try {
				const result = await ApiRequestV1.getMeetingPaged({
					perPage: 10,
					page: pageNumber,
					sortBy: 'occurredOn',
					sortOrder: 'ASC',
					filter: filter,
					versionId: version.id
				});
				setSearchedMeetings(result.data);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Search Text Failed'), 'Failed to Search');
			}
		})();
	}, [searchGroup]);

	function getMeetingTypeNamesList(): { meetingTypeId: number; meetingTypeName: string }[] {
		const meetingTypeSection: { meetingTypeId: number; meetingTypeName: string }[] = [];
		groupedMeetings.forEach((meeting) => {
			if (
				meetingTypeSection.findIndex((typeString) => meeting.meetingTypeName === typeString.meetingTypeName) ===
				-1
			) {
				meetingTypeSection.push({
					meetingTypeId: meeting.meetingTypeId,
					meetingTypeName: meeting.meetingTypeName
				});
			}
		});
		return meetingTypeSection;
	}

	function renderMeetingReminders(): React.ReactNode {
		return groupedMeetings
			.reduce((allMeetings: Api.V1.Meeting.Grouped.Get.Res['meetings'], group) => {
				return [...allMeetings, ...group.meetings];
			}, [])
			.filter((meeting) => DateUtils.isToday(new Date(meeting.occurredOn)))
			.map((meeting) => {
				return (
					<MeetingItem
						key={meeting.id}
						meeting={meeting}
						isMeetingReminder
						containColor
						onClick={() => router.navigate('/meeting/external/details?id=' + meeting.id)}
					/>
				);
			});
	}

	function renderSearch(): React.ReactNode {
		return (
			<SectionWithHeader
				title={'MEETINGS'}
				className={classNames('searchContainer', {
					search: searchValue !== ''
				})}
				viewAllLink={'/meeting'}
				viewAllText={'Filter'}
				key={'SearchContainerKey'}
			>
				<InputText
					inputMode={'text'}
					placeholder={'Search Meetings'}
					icon={[{ position: 'LEFT', iconImg: 'icon-search' }]}
					control={searchGroup.get(FormKeys.SEARCH)}
					updateControl={(form) => setSearchGroup(searchGroup.clone().update(form))}
				/>
				{searchValue === '' && <MeetingRecurring />}
				{searchValue === '' && renderMeetingReminders()}
			</SectionWithHeader>
		);
	}

	function renderSearchContainer(): React.ReactNode {
		if (!searchedMeetings) return;
		const searchedMeetingItems = searchedMeetings.map((meeting) => {
			return (
				<MeetingItem
					key={meeting.id}
					meeting={meeting}
					onClick={() => router.navigate('/meeting/external/details?id=' + meeting.id)}
				/>
			);
		});
		const noResults = (
			<Box className={'noResults'}>
				<Icon iconImg={'icon-search'} fontSize={80} color={themes.neutralBeige700} />
				<Label variant={'body1'} weight={'semiBold'}>
					No results found,
				</Label>
				<Label variant={'body1'} weight={'regular'}>
					try a different search.
				</Label>
			</Box>
		);
		return (
			<SectionWithHeader
				title={`${searchedMeetings.length} RESULT` + (searchedMeetings.length !== 1 ? 'S' : '')}
				className={'results'}
			>
				{searchedMeetingItems.length !== 0 ? searchedMeetingItems : noResults}
				<Pagination
					itemsPerPage={10}
					totalItems={searchedMeetings.length}
					currentPage={pageNumber}
					changePage={(newPage) => setPageNumber(newPage)}
				/>
			</SectionWithHeader>
		);
	}

	function renderMeetingSectionContainers(): React.ReactNode[] {
		return groupedMeetings.map((section) => {
			return (
				<SectionWithHeader
					key={section.meetingTypeId}
					title={section.meetingTypeName + 's'}
					className={section.meetingTypeName.toLowerCase().replace(' ', '_')}
					viewAllLink={`/meeting/view?ti=${section.meetingTypeId}`}
				>
					{section.meetings.slice(0, 2).map((meeting) => {
						return (
							<MeetingItem
								key={meeting.id}
								meeting={meeting}
								onClick={() => router.navigate('/meeting/external/details?id=' + meeting.id)}
							/>
						);
					})}
				</SectionWithHeader>
			);
		});
	}

	function renderMeetingsBySmallScreenSize() {
		return (
			<Box className={classNames('columnOne', { search: searchValue !== '' })}>
				{searchValue === '' ? renderMeetingSectionContainers() : renderSearchContainer()}
			</Box>
		);
	}

	function renderMeetingSectionsMediumScreens() {
		const meetingSearchContainerList = renderMeetingSectionContainers();
		const meetingSectionSplit = Math.floor(meetingSearchContainerList.length / 2);
		return (
			<>
				<Box className={classNames('columnOne', { search: searchValue !== '' })}>
					{searchValue === ''
						? meetingSearchContainerList.slice(0, meetingSectionSplit)
						: renderSearchContainer()}
				</Box>
				{searchValue === '' && (
					<Box className={'columnTwo'}>{meetingSearchContainerList.slice(meetingSectionSplit)}</Box>
				)}
			</>
		);
	}

	function renderMeetingSectionsLargeScreen(): React.ReactNode {
		const meetingSearchContainerList = renderMeetingSectionContainers();
		const meetingSectionSplit = Math.floor(meetingSearchContainerList.length / 2);
		return (
			<>
				<Box className={classNames('columnOne', { search: searchValue !== '' })}>
					{renderSearch()}
					{searchValue === ''
						? meetingSearchContainerList.slice(0, meetingSectionSplit)
						: renderSearchContainer()}
				</Box>
				{searchValue === '' && (
					<Box className={'columnTwo'}>{meetingSearchContainerList.slice(meetingSectionSplit)}</Box>
				)}
			</>
		);
	}

	function renderGridByScreenSize(): React.ReactNode {
		if (isSmallerThan768) {
			return renderMeetingsBySmallScreenSize();
		} else if (isSmallerThan1024) {
			return renderMeetingSectionsMediumScreens();
		} else {
			return renderMeetingSectionsLargeScreen();
		}
	}

	return (
		<Page className={'rsMeetingPage'}>
			<Box className={'searchSmallScreenContainer'}>{isSmallerThan1024 ? renderSearch() : ''}</Box>
			<Box className={classNames('meetingGrid', { search: searchValue !== '' })}>{renderGridByScreenSize()}</Box>
		</Page>
	);
};

export default MeetingPage;
