import * as React from 'react';
import './BudgetMonthlyBreakdownPage.scss';
import themes from '../../../themes/themes.scss?export';
import { Page } from '@redskytech/framework/996';
import { useEffect, useState } from 'react';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { Box, Label, rsToastify } from '@redskytech/framework/ui';
import { DateUtils, ObjectUtils, StringUtils, WebUtils } from '../../../utils/utils';
import Paper from '../../../components/paper/Paper';
import ProgressBar from '../../../components/progressBar/ProgressBar';
import router from '../../../utils/router';
import BudgetLineChart, { BudgetLineChartData } from '../../../components/budgetLineChart/BudgetLineChart';
import LoadingPage from '../../common/loadingPage/LoadingPage';

interface BudgetMonthlyBreakdownPageProps {}

const BudgetMonthlyBreakdownPage: React.FC<BudgetMonthlyBreakdownPageProps> = (props) => {
	const [phases, setPhases] = useState<Api.V1.Phase.Version.Get.Res[]>([]);

	const params = router.getQueryParams<{ versionId: number }>([
		{
			key: 'id',
			default: '',
			type: 'integer',
			alias: 'versionId'
		}
	]);

	const data = [
		{
			label: 'May 2022',
			amount: 0
		},
		{
			label: 'June 2022',
			amount: 56000
		},
		{
			label: 'July 2022',
			amount: 80000
		}
	] as BudgetLineChartData[];

	useEffect(() => {
		(async function getPhases() {
			try {
				const getPhasesResults = await ApiRequestV1.getPhaseVersion({ versionId: params.versionId });
				setPhases(getPhasesResults);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error Getting Phases'));
			}
		})();
	}, []);

	function renderOverallProgressReport(): React.ReactNode {
		let currentCombined = 0;
		let consumedCombined = 0;
		let percentage = 0;
		phases.forEach((phaseItem) => {
			percentage += phaseItem.donePercent;
			currentCombined += phaseItem.currentBudget;
			consumedCombined += phaseItem.budgetConsumedAmount;
		});
		const remainingBudgetCombined = currentCombined - consumedCombined;
		percentage = Math.floor(percentage / phases.length);

		const barSections: { percentageOrAmount: number; sectionColor: string }[] = [];
		phases.forEach((phaseItem, index) => {
			barSections.push({
				percentageOrAmount: phaseItem.budgetConsumedAmount,
				sectionColor: ObjectUtils.getProgressBarColor(index)
			});
		});

		return (
			<Paper>
				<Label className={'title'} variant={'subheader2'} weight={'extraBold'}>
					PROJECT BUDGET
				</Label>

				<ProgressBar
					barType={'AMOUNT_SPENT'}
					barHeight={'LARGE'}
					markerPercentage={percentage.toString()}
					progressBarSections={barSections.map((item) => item)}
					budget={{
						totalBudget: currentCombined,
						totalSpent: consumedCombined
					}}
				/>
				<Box className={'spendAndRemaining'}>
					<Label variant={'caption1'} weight={'semiBold'}>
						${StringUtils.addCommasToNumber(consumedCombined)} Spent
					</Label>
					<Label variant={'caption1'} weight={'semiBold'}>
						${StringUtils.addCommasToNumber(remainingBudgetCombined)} Remaining
					</Label>
				</Box>
				<Label className={'projectPercentage'} variant={'caption1'} weight={'semiBold'}>
					Project <span>{percentage}%</span> Complete
				</Label>
			</Paper>
		);
	}

	function renderAmountBreakdown(): React.ReactNode {
		return data.map((item) => {
			return (
				<Box className={'amountBreakdown'} key={item.label}>
					<Label variant={'body2'} weight={'semiBold'}>
						{item.label}
					</Label>
					&nbsp;
					<Label variant={'body2'} weight={'regular'}>
						- ${StringUtils.addCommasToNumber(item.amount)}
					</Label>
				</Box>
			);
		});
	}

	function renderLastUpdated(): React.ReactNode {
		return (
			<Label variant={'caption2'} weight={'regular'} textAlign={'center'} color={themes.neutralBeige600}>
				Updated {DateUtils.getDateDifferenceFriendly(new Date(), new Date(phases[0].modifiedOn))} ago
			</Label>
		);
	}

	if (!ObjectUtils.isArrayWithData(phases)) return <LoadingPage />;

	return (
		<Page className={'rsBudgetMonthlyBreakdownPage'}>
			{renderOverallProgressReport()}
			<Paper>
				<Label variant={'subheader2'} weight={'extraBold'} mb={'8px'}>
					${StringUtils.addCommasToNumber(data[data.length - 1].amount)} SPENT
				</Label>
				<BudgetLineChart tableData={data} />
				{renderAmountBreakdown()}
			</Paper>
			{renderLastUpdated()}
		</Page>
	);
};

export default BudgetMonthlyBreakdownPage;
