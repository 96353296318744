import * as React from 'react';
import { useEffect, useState } from 'react';
import './AddArtifactPopup.scss';
import {
	Box,
	Button,
	Popup,
	popupController,
	PopupProps,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import LabelInputText from '../../components/labelInputText/LabelInputText';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import LabelInputRichText from '../../components/labelInputRichText/LabelInputRichText';
import DiscardChangesPopup, { DiscardChangesPopupProps } from '../discardChangesPopup/DiscardChangesPopup';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { WebUtils } from '../../utils/utils';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import router from '../../utils/router';

export interface AddArtifactPopupProps extends PopupProps {}

enum FormKeys {
	TITLE = 'name',
	TYPE = 'type',
	DESCRIPTION = 'description'
}
const AddArtifactPopup: React.FC<AddArtifactPopupProps> = (props) => {
	const version = useRecoilValue(globalState.version);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.TITLE, '', [new RsValidator(RsValidatorEnum.REQ, 'Title is required')]),
			new RsFormControl<string>(FormKeys.TYPE, '', [new RsValidator(RsValidatorEnum.REQ, 'Type is required')]),
			new RsFormControl<string>(FormKeys.DESCRIPTION, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Description is required')
			])
		])
	);
	const [isDisabled, setIsDisabled] = useState<boolean>(true);
	const typeOptions: { label: string; value: Api.V1.Artifact.All.Get.Res['artifactType'] }[] = [
		{ label: 'Change Order', value: 'CHANGE_ORDER' },
		{ label: 'Document', value: 'DOCUMENT' },
		{ label: 'Link', value: 'LINK' }
	];

	useEffect(() => {
		(async function isFormValid() {
			let isValid = await formGroup.isValid();
			setIsDisabled(!isValid);
		})();
	}, [formGroup]);

	async function handleSave() {
		if (!(await formGroup.isValid())) {
			rsToastify.error('Please fix the errors in the inputs', 'Input Error');
			setFormGroup(formGroup.clone());
			return;
		}
		if (!version) return;

		let formData = formGroup.toModel<{ name: string; type: string; description: string }>();

		try {
			let res = await ApiRequestV1.postArtifact({
				...formData,
				versionId: version.id,
				companyId: version.companyId
			});
			rsToastify.success('Artifact Created', 'Success');
			router.navigate(`/artifact/edit?ai=${res.id}`).catch(console.error);

			popupController.close(AddArtifactPopup);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Server Error'), 'Error');
		}
	}

	function handleUpdate(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function handleClose() {
		if (formGroup.isModified()) {
			popupController.open<DiscardChangesPopupProps>(DiscardChangesPopup, {});
		} else {
			popupController.close(AddArtifactPopup);
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsAddArtifactPopup'}>
				<PopupHeader title={'Add Artifact'} onClose={handleClose} />
				<Box className={'content'}>
					<Box display={'flex'} gap={24}>
						<LabelInputText
							label={'Title'}
							inputMode={'text'}
							control={formGroup.get(FormKeys.TITLE)}
							updateControl={handleUpdate}
							required
						/>
						<LabelSelect
							label={'Type'}
							control={formGroup.get(FormKeys.TYPE)}
							updateControl={handleUpdate}
							options={typeOptions}
							placeholder={'Begin typing or select type'}
							required
						/>
					</Box>
					<LabelInputRichText
						label={'Description'}
						control={formGroup.get(FormKeys.DESCRIPTION)}
						updateControl={handleUpdate}
						required
						height={200}
					/>
					<Box className={'buttonGroup'}>
						<Button look={'outlinedPrimary'} fullWidth onClick={handleClose}>
							Cancel
						</Button>
						<Button look={'containedPrimary'} fullWidth onClick={handleSave} disabled={isDisabled}>
							Create
						</Button>
					</Box>
				</Box>
			</Box>
		</Popup>
	);
};
export default AddArtifactPopup;
