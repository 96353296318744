import * as React from 'react';
import './BulletedKey.scss';
import { Box, Label } from '@redskytech/framework/ui';

interface BulletedKeyProps {
	label: string;
	bulletColor: string;
}
const BulletedKey: React.FC<BulletedKeyProps> = (props) => {
	return (
		<Box className={'rsBulletedKey'}>
			<Label variant={'caption1'} weight={'semiBold'}>
				<span className={'infoDot'} style={{ backgroundColor: props.bulletColor }} /> {props.label}
			</Label>
		</Box>
	);
};

export default BulletedKey;
