import * as React from 'react';
import { useState } from 'react';
import './AddSprintPopup.scss';
import {
	Box,
	Button,
	Popup,
	popupController,
	PopupProps,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import DiscardChangesPopup, { DiscardChangesPopupProps } from '../discardChangesPopup/DiscardChangesPopup';
import LoadingPopup, { LoadingPopupProps } from '../loadingPopup/LoadingPopup';
import { WebUtils } from '@redskytech/framework/utils';
import LabelInputText from '../../components/labelInputText/LabelInputText';
import themes from '../../themes/themes.scss?export';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import router from '../../utils/router';

export interface AddSprintPopupProps extends PopupProps {}

enum FormKeys {
	TITLE = 'name',
	START = 'startOn',
	END = 'endOn'
}
const AddSprintPopup: React.FC<AddSprintPopupProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.TITLE, '', [new RsValidator(RsValidatorEnum.REQ, 'Title is required')]),
			new RsFormControl<string>(FormKeys.START, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Start date is required')
			]),
			new RsFormControl<string>(FormKeys.END, '', [new RsValidator(RsValidatorEnum.REQ, 'End date is required')])
		])
	);

	function handleUpdate(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function handleClose() {
		if (formGroup.isModified()) {
			popupController.open<DiscardChangesPopupProps>(DiscardChangesPopup, {});
		} else {
			popupController.close(AddSprintPopup);
		}
	}

	async function handleSave() {
		if (!(await formGroup.isValid())) {
			rsToastify.error('Please fix the errors in the inputs', 'Input Error');
			setFormGroup(formGroup.clone());
			return;
		}
		popupController.open<LoadingPopupProps>(LoadingPopup, {});
		let formData = formGroup.toModel<{ name: string; startOn: string; endOn: string }>();
		try {
			if (!version) return;
			let res = await ApiRequestV1.postSprint({
				...formData,
				companyId: version.companyId
			});
			popupController.closeAll();
			rsToastify.success('Sprint Created', 'Success');
			router.navigate(`/sprint/edit?si=${res.id}`).catch(console.error);
		} catch (e) {
			popupController.close(LoadingPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Server Error'), 'Error');
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsAddSprintPopup'}>
				<PopupHeader title={'Add Sprint'} onClose={handleClose} />
				<Box className={'content'}>
					<LabelInputText
						label={'Title'}
						inputMode={'text'}
						control={formGroup.get<number>(FormKeys.TITLE)}
						updateControl={handleUpdate}
						required
					/>
					<LabelInputText
						className={'dateInput'}
						label={'Start Date'}
						inputMode={'text'}
						type={'date'}
						control={formGroup.get(FormKeys.START)}
						updateControl={handleUpdate}
						icon={[
							{
								position: 'LEFT',
								iconImg: 'icon-calendar',
								color: themes.neutralBeige500
							}
						]}
						required
					/>
					<LabelInputText
						className={'dateInput'}
						label={'End Date'}
						inputMode={'text'}
						type={'date'}
						control={formGroup.get(FormKeys.END)}
						updateControl={handleUpdate}
						icon={[
							{
								position: 'LEFT',
								iconImg: 'icon-calendar',
								color: themes.neutralBeige500
							}
						]}
						required
					/>
					<Box className={'buttonGroup'}>
						<Button look={'outlinedPrimary'} fullWidth onClick={handleClose}>
							Cancel
						</Button>
						<Button
							look={'containedPrimary'}
							fullWidth
							disabled={!formGroup.isModified()}
							onClick={handleSave}
						>
							Create
						</Button>
					</Box>
				</Box>
			</Box>
		</Popup>
	);
};
export default AddSprintPopup;
