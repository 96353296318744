import * as React from 'react';
import './ProjectDetailsPage.scss';
import { Page } from '@redskytech/framework/996';
import PageHeader from '../../../components/pageHeader/PageHeader';
import PaperHeaderBar from '../../../components/paperHeaderBar/PaperHeaderBar';
import { Box, Button, popupController, rsToastify } from '@redskytech/framework/ui';
import { useEffect, useState } from 'react';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import ProjectPhaseDetails from './projectPhaseDetails/ProjectPhaseDetails';
import EditProjectEstimationsPopup, {
	EditProjectEstimationsPopupProps
} from '../../../popups/editProjectEstimationsPopup/EditProjectEstimationsPopup';
import { DataTableRowClickEventParams, DataTableExpandedRows } from 'primereact/datatable';
import ListDataTable from '../../../components/listDataTable/ListDataTable';
import { Column } from 'primereact/column';
import ColumnHeader from '../../../components/listDataTable/columnHeader/ColumnHeader';
import { StringUtils, WebUtils } from '../../../utils/utils';
import RowGroupHeader from '../../../components/rowGroupHeader/RowGroupHeader';
import TableSearchHeader from '../../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import EditPhaseBreakdownsPopup, {
	EditPhaseBreakdownPopupProps
} from '../../../popups/editPhaseBreakdownsPopup/EditPhaseBreakdownPopup';
import EditPhaseBreakdownPopup from '../../../popups/editPhaseBreakdownsPopup/EditPhaseBreakdownPopup';
import useIsMounted from '../../../customHooks/useIsMounted';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';

interface ProjectDetailsPageProps {}

interface PhaseBreakdownList {
	phaseName: string;
	phase: Api.V1.Phase.Version.Get.Res;
	taskName?: string;
	percentage: number;
}

const FieldKeys: { [key in 'TASK' | 'PERCENTAGE']: { field: string; sortField: string } } = {
	TASK: { field: 'taskName', sortField: 'task' },
	PERCENTAGE: { field: 'percentage', sortField: 'percentage' }
};

const ProjectDetailsPage: React.FC<ProjectDetailsPageProps> = (props) => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeSortColumn, setActiveSortColumn] = useState<string>();
	const [globalSearch, setGlobalSearch] = useState<string>('');
	const [expandedRows, setExpandedRows] = useState<any[] | DataTableExpandedRows>([]);
	const [phases, setPhases] = useState<Api.V1.Phase.Version.Get.Res[]>();
	const [projectDetails, setProjectDetails] = useState<Api.V1.Project.Get.Res>();
	const [phaseBreakdowns, setPhaseBreakdowns] = useState<RedSky.RsPagedResponseData<PhaseBreakdownList[]>>({
		data: [],
		total: 0
	});
	const [currentPageQuery, setCurrentPageQuery] = useState<RedSky.PageQuery>();
	const isMounted = useIsMounted();

	useEffect(() => {
		getProjectAndPhasesForVersion().catch(console.error);
	}, [version]);

	useEffect(() => {
		getData({ page: 1, perPage: 10 });
	}, [phases]);

	async function getProjectAndPhasesForVersion() {
		if (!version) return;
		let res = await ApiRequestV1.getPhaseVersion({ versionId: version.id });
		setPhases(res);
		let projectRes = await ApiRequestV1.getProject({ projectId: version.projectId });
		setProjectDetails(projectRes);
	}

	async function getData(pageQuery: RedSky.PageQuery) {
		setIsLoading(true);
		setActiveSortColumn(pageQuery.sortBy);
		setCurrentPageQuery(pageQuery);
		try {
			if (!phases) {
				setIsLoading(false);
				return;
			}
			if (pageQuery.sortBy === undefined) {
				delete pageQuery.sortBy;
				delete pageQuery.sortOrder;
			}
			if (!pageQuery.filter) delete pageQuery.filter;

			const breakdownsArray: PhaseBreakdownList[] = [];

			phases.forEach((value) => {
				Object.keys(value).forEach((key) => {
					if (key.toLowerCase().includes('percent')) {
						let percentage: any = value[key as keyof Api.V1.Phase.Version.Get.Res];
						breakdownsArray.push({
							phaseName: value.name,
							phase: value,
							taskName: StringUtils.camelCaseToHumanReadable(key.replace('Percent', '')),
							percentage: typeof percentage === 'number' ? percentage : 0
						});
					}
				});
			});

			if (isMounted) setPhaseBreakdowns({ data: breakdownsArray, total: breakdownsArray.length });
			setIsLoading(false);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function handleEstimateUpdate() {
		getProjectAndPhasesForVersion().catch(console.error);
	}

	async function handleBreakdownUpdate() {
		if (!currentPageQuery) return;
		await getData(currentPageQuery);
	}

	function handleEditClick(data: PhaseBreakdownList) {
		popupController.open<EditPhaseBreakdownPopupProps>(EditPhaseBreakdownPopup, {
			phaseWorkSummary: data.phase,
			onUpdate: handleBreakdownUpdate
		});
	}

	function handleRowClick({ data }: DataTableRowClickEventParams) {
		popupController.open<EditPhaseBreakdownPopupProps>(EditPhaseBreakdownPopup, {
			phaseWorkSummary: data.phaseWorkTaskSummary,
			onUpdate: handleBreakdownUpdate
		});
	}

	function renderPhaseDetails() {
		return phases?.map((phase) => {
			return <ProjectPhaseDetails key={phase.name} percentage={phase.donePercent || 0} phaseName={phase.name} />;
		});
	}

	return (
		<Page className={'rsProjectDetailsPage'}>
			<PageHeader title={'Project'} />
			<Box className={'content'}>
				<PaperHeaderBar
					title={'Project Estimations'}
					rightNode={
						<Button
							look={'iconSecondary'}
							icon={[{ iconImg: 'icon-edit', position: 'LEFT' }]}
							onClick={() => {
								popupController.open<EditProjectEstimationsPopupProps>(EditProjectEstimationsPopup, {
									phases: phases || [],
									project: projectDetails || {
										id: 0,
										createdOn: '',
										modifiedOn: '',
										companyId: 0,
										donePercent: 0
									},
									onUpdate: handleEstimateUpdate
								});
							}}
						/>
					}
				>
					<Box>{renderPhaseDetails()}</Box>
					<Box className={'projectTotal'}>
						<ProjectPhaseDetails percentage={projectDetails?.donePercent || 0} isProjectTotal />
					</Box>
				</PaperHeaderBar>

				<Box>
					<PaperHeaderBar title={'Phase Breakdowns'} contentPadding={'none'}>
						<TableSearchHeader searchValue={globalSearch} onChange={(value) => setGlobalSearch(value)} />
					</PaperHeaderBar>
					<ListDataTable
						loading={isLoading}
						onClearFilters={() => {}}
						rows={500}
						data={phaseBreakdowns}
						getData={getData}
						globalFilter={globalSearch}
						globalFilterFields={['phase.name']}
						onRowClick={handleRowClick}
						rowGroupMode="subheader"
						groupRowsBy="phaseName"
						expandableRowGroups
						expandedRows={expandedRows}
						onRowToggle={(e) => setExpandedRows(e.data)}
						rowGroupHeaderTemplate={(data) => (
							<RowGroupHeader
								label={`${data.phaseName?.replace('/', '&')} Phase`}
								onEditClick={() => handleEditClick(data)}
							/>
						)}
					>
						<Column
							field={FieldKeys.TASK.field}
							sortField={FieldKeys.TASK.sortField}
							header={
								<ColumnHeader
									label={'Task'}
									isActiveSort={activeSortColumn === FieldKeys.TASK.sortField}
								/>
							}
						/>
						<Column
							field={FieldKeys.PERCENTAGE.field}
							sortField={FieldKeys.PERCENTAGE.sortField}
							header={
								<ColumnHeader
									label={'Percentage Spent'}
									isActiveSort={activeSortColumn === FieldKeys.PERCENTAGE.sortField}
								/>
							}
							body={(data) => {
								return `${data.percentage}%`;
							}}
						/>
					</ListDataTable>
				</Box>
			</Box>
		</Page>
	);
};
export default ProjectDetailsPage;
