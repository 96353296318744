import * as React from 'react';
import './MeetingItem.scss';
import themes from '../../themes/themes.scss?export';
import { Box, Icon, Label } from '@redskytech/framework/ui';
import classNames from 'classnames';
import { DateUtils } from '../../utils/utils';
import RichContentDisplay from '../richContentDisplay/RichContentDisplay.js';

interface MeetingItemProps {
	meeting: Api.V1.Meeting.Paged.Get.Res | Api.V1.Meeting.Grouped.Get.Res['meetings'][0];
	isMeetingReminder?: boolean;
	containColor?: boolean;
	showFullDescription?: boolean;
	showMeetingNotes?: boolean;
	onClick?: () => void;
}

const MeetingItem: React.FC<MeetingItemProps> = (props) => {
	function renderMeetingInformationAsReminder(): React.ReactNode {
		return (
			<>
				<Box display={'flex'} alignItems={'center'} gap={'4px'}>
					<Icon iconImg={'icon-notification'} color={themes.accentInfo} />
					<Label variant={'caption2'} weight={'semiBold'} color={themes.accentInfo}>
						MEETING REMINDER
					</Label>
				</Box>
				<Label variant={'body2'} weight={'semiBold'}>
					{props.meeting.title} {DateUtils.displayFriendlyTime(new Date(props.meeting.occurredOn))}
				</Label>
				<Label className={'description'} variant={'caption1'} weight={'regular'}>
					{props.meeting.description}
				</Label>
			</>
		);
	}

	function renderMeetingNotes(): React.ReactNode {
		let meetingNotes = !!props.meeting.notes ? props.meeting.notes.trim() : '<p>No Notes Yet</p>';
		return (
			<>
				<Box display={'flex'} alignItems={'baseline'} gap={'4px'} mt={20}>
					<Icon iconImg={'icon-edit-note'} fontSize={16} color={themes.accentInfo} />
					<Label variant={'caption2'} weight={'regular'} color={themes.accentInfo}>
						MEETING NOTES
					</Label>
				</Box>
				<RichContentDisplay content={meetingNotes} />
			</>
		);
	}

	function renderMeetingInformation(): React.ReactNode {
		return (
			<>
				<Label variant={'body2'} weight={'semiBold'} color={themes.neutralWhite}>
					{props.meeting.title}, {DateUtils.getMonthName(new Date(props.meeting.occurredOn))}{' '}
					{new Date(props.meeting.occurredOn).getDate()}
				</Label>
				<RichContentDisplay content={props.meeting.description.trim()} />
				{props.showMeetingNotes && renderMeetingNotes()}
			</>
		);
	}

	return (
		<Box
			className={classNames('rsMeetingItem', { containGradient: props.containColor })}
			onClick={props.onClick}
			style={props.onClick && { cursor: 'pointer' }}
		>
			{props.isMeetingReminder ? renderMeetingInformationAsReminder() : renderMeetingInformation()}
		</Box>
	);
};

export default MeetingItem;
