import * as React from 'react';
import './BudgetPhaseDetailsPage.scss';
import themes from '../../../themes/themes.scss?export';
import { Page } from '@redskytech/framework/996';
import { Box, Label, rsToastify } from '@redskytech/framework/ui';
import { DateUtils, ObjectUtils, StringUtils, WebUtils } from '../../../utils/utils';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import BudgetPieChart, { PieChartData } from '../../../components/budgetPieChart/BudgetPieChart';
import { useEffect, useState } from 'react';
import Paper from '../../../components/paper/Paper';
import SectionWithHeader from '../../../components/sectionWithHeader/SectionWithHeader';
import ProgressBar from '../../../components/progressBar/ProgressBar';
import classNames from 'classnames';
import { useOnClickOutsideRef } from '@redskytech/framework/hooks';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import router from '../../../utils/router';
import LoadingPage from '../../common/loadingPage/LoadingPage';

interface BudgetPhaseDetailsPageProps {}

const BudgetPhaseDetailsPage: React.FC<BudgetPhaseDetailsPageProps> = () => {
	const version = useRecoilValue<Api.V1.Version.Get.Res | undefined>(globalState.version);
	const [phaseBreakdown, setPhaseBreakdown] = useState<Api.V1.Phase.Get.Res>();
	const [messageShow, setMessageShow] = useState<boolean>(false);
	const [currentClick, setCurrentClick] = useState<number>(-1);
	const [chartData, setChartData] = useState<PieChartData[]>([]);
	const params = router.getQueryParams<{ phaseId: number; color: string }>([
		{
			key: 'pi',
			default: '',
			type: 'integer',
			alias: 'phaseId'
		},
		{
			key: 'col',
			default: '',
			type: 'integer',
			alias: 'color'
		}
	]);
	const previewRef = useOnClickOutsideRef(() => {
		if (messageShow) {
			setMessageShow(false);
			setCurrentClick(-1);
		}
	});

	useEffect(() => {
		(async function getBudgetPhase() {
			try {
				const response = await ApiRequestV1.getPhase({ id: params.phaseId });
				setPhaseBreakdown(response);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to get phase breakdown'));
			}
		})();
	}, [params.phaseId]);

	useEffect(() => {
		if (!phaseBreakdown) return;

		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD'
		});

		const developmentAmount = calculateBudgetBasedOnPercentage(phaseBreakdown.developmentSpentPercent);
		const bugsAmount = calculateBudgetBasedOnPercentage(phaseBreakdown.bugsSpentPercent);
		const pmAmount = calculateBudgetBasedOnPercentage(phaseBreakdown.pmSpentPercent);
		const meetingsAmount = calculateBudgetBasedOnPercentage(phaseBreakdown.meetingsSpentPercent);
		const adminAmount = calculateBudgetBasedOnPercentage(phaseBreakdown.administrativeSpentPercent);
		const designAmount = calculateBudgetBasedOnPercentage(phaseBreakdown.designSpentPercent);
		const otherAmount = calculateBudgetBasedOnPercentage(phaseBreakdown.otherSpentPercent);
		const qaAmount = calculateBudgetBasedOnPercentage(phaseBreakdown.qaSpentPercent);

		const percentTotal = Object.keys(phaseBreakdown)
			.filter((key) => key.includes('SpentPercent'))
			.reduce((totalPercent, key) => {
				let percentToAdd = phaseBreakdown[key as keyof Api.V1.Phase.Get.Res] as number;
				return totalPercent + percentToAdd;
			}, 0);
		let remainingAmount = 0;
		if (percentTotal < 100) {
			remainingAmount = calculateBudgetBasedOnPercentage(100 - percentTotal);
		}

		setChartData([
			{
				label: `Development - ${phaseBreakdown.developmentSpentPercent}%, ${formatter.format(
					developmentAmount
				)}`,
				amount: developmentAmount,
				labelColor: themes.budgetDev,
				labelBorder: themes.budgetDev,
				status: 'HEALTHY',
				unhealthyInformationBoxContent: {
					phase: 'Development',
					averagePercentage: 65
				}
			},
			{
				label: `Bugs - ${phaseBreakdown.bugsSpentPercent}%, ${formatter.format(bugsAmount)}`,
				amount: bugsAmount,
				labelColor: themes.budgetBugs,
				labelBorder: themes.budgetBugs,
				status: 'HEALTHY',
				unhealthyInformationBoxContent: {
					phase: 'Bugs',
					averagePercentage: 12
				}
			},
			{
				label: `PM - ${phaseBreakdown.pmSpentPercent}%, ${formatter.format(pmAmount)}`,
				amount: pmAmount,
				labelColor: themes.budgetPM,
				labelBorder: themes.budgetPM,
				status: 'HEALTHY',
				unhealthyInformationBoxContent: {
					phase: 'Project Management',
					averagePercentage: 9.2
				}
			},
			{
				label: `Meetings - ${phaseBreakdown.meetingsSpentPercent}%, ${formatter.format(meetingsAmount)}`,
				amount: meetingsAmount,
				labelColor: themes.budgetMeetings,
				labelBorder: themes.budgetMeetings,
				status: 'UNHEALTHY',
				unhealthyInformationBoxContent: {
					phase: 'Meetings',
					averagePercentage: 7.4
				}
			},
			{
				label: `Administrative - ${phaseBreakdown.administrativeSpentPercent}%, ${adminAmount}`,
				amount: adminAmount,
				labelColor: themes.budgetAdmin,
				labelBorder: themes.budgetAdmin,
				status: 'HEALTHY',
				unhealthyInformationBoxContent: {
					phase: 'Administrative',
					averagePercentage: 12.2
				}
			},
			{
				label: `Design - ${phaseBreakdown.designSpentPercent}%, ${formatter.format(designAmount)}`,
				amount: designAmount,
				labelColor: themes.budgetDesign,
				labelBorder: themes.budgetDesign,
				status: 'UNHEALTHY',
				unhealthyInformationBoxContent: {
					phase: 'Design',
					averagePercentage: 11
				}
			},
			{
				label: `Other - ${phaseBreakdown.otherSpentPercent}%, ${formatter.format(otherAmount)}`,
				amount: otherAmount,
				labelColor: themes.budgetOther,
				labelBorder: themes.budgetOther,
				status: 'HEALTHY',
				unhealthyInformationBoxContent: {
					phase: 'Other',
					averagePercentage: 5
				}
			},
			{
				label: `QA - ${phaseBreakdown.qaSpentPercent}%, ${formatter.format(qaAmount)}`,
				amount: qaAmount,
				labelColor: themes.budgetQA,
				labelBorder: themes.budgetQA,
				status: 'HEALTHY',
				unhealthyInformationBoxContent: {
					phase: 'QA',
					averagePercentage: 13
				}
			},
			{
				label: `Remaining - ${remainingAmount}%, ${formatter.format(remainingAmount)}`,
				amount: remainingAmount,
				labelColor: '#170D0D',
				labelBorder: themes.neutralBeige700,
				status: 'HEALTHY',
				unhealthyInformationBoxContent: {
					phase: 'You are over budget.',
					averagePercentage: 0
				}
			}
		]);
	}, [phaseBreakdown]);

	function calculateBudgetBasedOnPercentage(budgetPercentage: number): number {
		if (!phaseBreakdown) return 0;
		return Math.floor((phaseBreakdown.currentBudget * budgetPercentage) / 100);
	}

	function isUnhealthy(status: string, index: number) {
		if (!version) return;
		if (status === 'UNHEALTHY') {
			setMessageShow(!messageShow);
			setCurrentClick(index);
		}
	}

	function renderPhaseOverviewBudget() {
		if (!phaseBreakdown) return;
		let remaining: number = phaseBreakdown.currentBudget - phaseBreakdown.budgetConsumedAmount;
		return (
			<Paper>
				<Label textAlign={'center'} variant={'subheader2'} weight={'extraBold'} mb={'8px'}>
					{phaseBreakdown.name?.toUpperCase()} PHASE
				</Label>
				<ProgressBar
					barType={'AMOUNT_SPENT'}
					barHeight={'SMALL'}
					progressBarSections={[
						{
							sectionColor: ObjectUtils.getProgressBarColor(parseInt(params.color)),
							percentageOrAmount: phaseBreakdown?.budgetConsumedAmount
						}
					]}
					budget={{
						totalBudget: phaseBreakdown.currentBudget,
						totalSpent: phaseBreakdown.budgetConsumedAmount
					}}
				/>

				<Box className={'spendAndRemaining'}>
					<Label variant={'caption1'} weight={'semiBold'}>
						${StringUtils.addCommasToNumber(phaseBreakdown.currentBudget)} Spent
					</Label>
					<Label variant={'caption1'} weight={'semiBold'}>
						<span className={classNames('isOverBudget', { true: remaining < 0 })}>
							{remaining < 0
								? '-$' + StringUtils.addCommasToNumber(remaining).split('-')[1]
								: '$' + StringUtils.addCommasToNumber(remaining)}
						</span>{' '}
						Remaining
					</Label>
				</Box>
				<Label
					className={'projectPercentage'}
					variant={'caption1'}
					weight={'semiBold'}
					color={themes.neutralBeige600}
					textAlign={'center'}
				>
					Estimated ${StringUtils.addCommasToNumber(phaseBreakdown.currentBudget)} Total
				</Label>
			</Paper>
		);
	}

	function renderPhaseBreakdownList() {
		return chartData.map((item, index) => {
			return (
				<Box
					className={'breakdownContainer'}
					key={'breakdownList' + index + item.label}
					display={'flex'}
					onClick={() => {
						if (!version) return;
						isUnhealthy(item.status || 'HEALTHY', index);
					}}
				>
					{item.status === 'UNHEALTHY' && (
						<Box
							elementRef={previewRef as React.RefObject<HTMLDivElement>}
							className={classNames('popoutMessage', {
								show: messageShow && Number(currentClick) === index
							})}
						>
							<Label variant={'caption1'} weight={'regular'}>
								{item.unhealthyInformationBoxContent?.phase || 'Unhealthy'} typically comprise{' '}
								<span>{item.unhealthyInformationBoxContent?.averagePercentage}%</span> of a project’s
								budget.
							</Label>
						</Box>
					)}

					<Box
						className={'budgetColorContainer'}
						bgColor={item.labelColor}
						border={`1px solid ${item.labelBorder}`}
					/>
					<Label
						variant={'body2'}
						weight={'semiBold'}
						color={item.status === 'UNHEALTHY' ? themes.accentWarning : ''}
					>
						{item.label}
					</Label>
				</Box>
			);
		});
	}

	function renderUpdated() {
		if (!phaseBreakdown) return;
		return (
			<Label variant={'caption2'} weight={'regular'} textAlign={'center'} color={themes.neutralBeige600}>
				Updated {DateUtils.getDateDifferenceFriendly(new Date(), new Date(phaseBreakdown.modifiedOn))} ago
			</Label>
		);
	}

	if (ObjectUtils.isEmpty(phaseBreakdown)) return <LoadingPage />;
	return (
		<Page className={'rsBudgetPhaseDetailsPage'}>
			{renderPhaseOverviewBudget()}

			<SectionWithHeader title={'BREAKDOWN'} className={'breakdownSection'}>
				<BudgetPieChart tableData={chartData} />
				<Box className={'breakdownList'}>{renderPhaseBreakdownList()}</Box>
			</SectionWithHeader>

			{renderUpdated()}
		</Page>
	);
};

export default BudgetPhaseDetailsPage;
